import React, { useEffect, useState } from "react";
//import { renderCount } from '../../../../appHelpers/appLoggers';
import {
  IconButton,
  Form,
  Modal,
  Button,
  Divider,
  Message,
  useToaster,
  CheckPicker,
  Checkbox,
  SelectPicker,
  InputNumber,
  Stack,
  Tooltip,
  Whisper,
} from "rsuite";

import EditIcon from "@rsuite/icons/Edit";
import axiosInstance from "../../../../../../appHelpers/axiosInstance";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { FilterSystems } from "../../Users/helpers/FilterableComponent";

export const EditReport = ({
  report,
  machines,
  update,
  setUpdate,
  field_name_tooltip,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();
  // Set Form's inital data prior to editing
  const initFormReport = {
    id: report.id || null,
    author: report.author || null,
    report_name: report.report_name || null,
    systems_list: report.systems_list || null,
    field_name: report.field_name || null,
    operator: report.operator || null,
    threshold: report.threshold || null,
    email_schedule: report.email_schedule || null,
  };

  const handleOpen = () => setOpen(true); // Opens Modal when edit icon is clicked
  const handleClose = () => setOpen(false); // Closes Modal when "Cancel" button is clicked or onSubmit function runs
  const [open, setOpen] = useState(false); // Sets open/close state of Modal. Handled through Modal's props
  const [formValue, setFormValue] = useState(initFormReport); // Initalize form state with report data. Pass formValue to Form component prop

  const [reportName, setReportName] = useState(initFormReport.report_name);

  const [currentReportSys, setCurrentReportSys] = useState([]);
  const [allSystems, setAllSystems] = useState([]);
  const [comboSystems, setComboSystems] = useState([]);

  const [currentEmailSchedule, setCurrentEmailSchedule] = useState([]);
  const [reoprtScheduledDT, setReportScheduleDT] = useState([]);
  const [currentScheduleArray, setCurrentScheduleArray] = useState([]);

  const [fieldName, setFieldName] = useState(null);
  const [availableFieldNames, setAvailableFieldNames] =
    useState(field_name_tooltip);

  const [operator, setOperator] = useState(null);
  const [availableOperators, setAvailableOperators] = useState([]);

  const [threshold, setThreshold] = useState(initFormReport.threshold);

  const [needsOperator, setNeedsOperator] = useState(false);
  const [needsPressure, setNeedsPressure] = useState(false);
  const [needsPercent, setNeedsPercent] = useState(false);

  const handleNameChange = (value) => {
    setReportName(value);
  };

  const handleChangeSchedule = (value) => {
    setReportScheduleDT(value);
  };

  const handleChangeField = (value) => {
    setFieldName(value);
  };

  const handleChangeOperator = (value) => {
    setOperator(value);
  };

  const handleChangeThreshold = (value) => {
    setThreshold(value);
  };

  const setAllScopedSystems = () => {
    setComboSystems([
      ...new Map(
        allSystems.map((sme) => [
          sme,
          {
            value: sme.id,
            label: `${sme.id} - ${sme.manufacturer} - ${sme.modality}`,
          },
        ])
      ).values(),
    ]);
  };

  const setEmailSchedule = () => {
    setCurrentEmailSchedule([
      ...new Map(
        email_schedule_template.map((schedule) => [
          schedule,
          {
            value: schedule,
            label: timeMap.get(schedule),
          },
        ])
      ).values(),
    ]);
  };

  const handleCheckAllSchedule = (value, checked) => {
    setReportScheduleDT(
      checked
        ? [...new Set(email_schedule_template.map((system) => system))]
        : []
    );
  };

  const message = (
    <Message showIcon type="success" header="Success">
      You successfully updated {report?.report_name}.
    </Message>
  );

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const email_schedule_json = {};
      for (let dt of reoprtScheduledDT) {
        email_schedule_json[dt] = true;
      }

      const form_data = {
        id: initFormReport.id,
        author: initFormReport.author,
        field_name: fieldName,
        systems_list: currentReportSys,
        email_schedule: email_schedule_json,
        report_name: reportName,
        operator: operator,
        threshold,
      };

      const response = await axiosInstance({
        method: "PUT",
        url: `/user/reports/${initFormReport.author}/${initFormReport.id}`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(form_data),
      });
      setUpdate(!update);
      toaster.push(message, "topCenter");
      handleClose();
    } catch (error) {
      console.error(error);
      toaster.push(errMessage, "topCenter");
    }
  };

  const setSystemsMetaData = (user_report_systems, all_machines) => {
    if (user_report_systems) {
      const in_current_report = [];
      const delta = [];
      for (let machine of all_machines) {
        if (user_report_systems.includes(machine.id)) {
          in_current_report.push(machine);
        } else {
          delta.push(machine);
        }
      }

      const mergedArray = [...in_current_report, ...delta];
      setAllSystems(mergedArray);
    }
  };

  const operators = ["less_than", "greater_than"];
  const fields_w_opertors = ["he_level_value", "he_pressure_value"];
  const fields_w_pressure = ["he_pressure_value"];
  const fields_w_percent = ["he_level_value"];

  const schedule_to_array = (json_obj) => {
    if (json_obj) {
      const current_schedule_dt = [];

      const sched_array = [];

      let keys = Object.keys(json_obj);

      for (let key of keys) {
        let dt = {
          time: key,
          value: json_obj[key],
        };
        sched_array.push(dt);
        if (dt.value) current_schedule_dt.push(dt.time);
      }

      setCurrentScheduleArray(sched_array);
      setReportScheduleDT(current_schedule_dt);
    }
  };

  useEffect(() => {
    // Convert schedule to array EX: {time: 'fri-00:00', value: false}
    schedule_to_array(initFormReport.email_schedule);
    setEmailSchedule();
    if (machines) {
      setSystemsMetaData(initFormReport.systems_list, machines);
    }
  }, [machines]);

  useEffect(() => {
    setAllScopedSystems();

    setFieldName(initFormReport.field_name);

    setOperator(initFormReport.operator);
    const ops = operators.map((item) => ({
      label: item,
      value: item,
    }));
    setAvailableOperators(ops);
    if (fields_w_opertors.includes(initFormReport.field_name))
      setNeedsOperator(true);
  }, [allSystems]);

  useEffect(() => {
    if (fields_w_opertors.includes(fieldName)) {
      setNeedsOperator(true);
    } else {
      setNeedsOperator(false);
    }
  }, [fieldName]);

  useEffect(() => {
    if (fields_w_pressure.includes(fieldName)) {
      setNeedsPressure(true);
      setNeedsPercent(false);
    } else if (fields_w_percent.includes(fieldName)) {
      setNeedsPercent(true);
      setNeedsPressure(false);
    } else {
      setNeedsPercent(false);
      setNeedsPressure(false);
    }
  }, [fieldName]);

  const TooltipOption = ({ label, tooltip }) => (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={<Tooltip>{tooltip}</Tooltip>}
    >
      <span>{label}</span>
    </Whisper>
  );

  return (
    <>
      <IconButton icon={<EditIcon />} onClick={handleOpen} />
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Edit Report: {report?.report_name}</Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <Form
            fluid
            formValue={formValue}
            onChange={(formValue) => setFormValue(formValue)}
          >
            <Form.Group controlId="author">
              <Form.ControlLabel>Author</Form.ControlLabel>
              <Form.Control name="author" readOnly disabled />
            </Form.Group>
            <Form.Group controlId="report_name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="report_name" onChange={handleNameChange} />
            </Form.Group>
            {needsOperator ? ( // If field_name can associate with an operator (greater_than - less_than) then display operator selector
              <FlexRow>
                <Form.Group controlId="field_name">
                  <Form.ControlLabel>Report Type</Form.ControlLabel>
                  <Form.Control
                    name="select"
                    data={availableFieldNames}
                    value={fieldName}
                    accepter={SelectPicker}
                    searchable={false}
                    onChange={handleChangeField}
                    renderMenuItem={(label, item) => {
                      return (
                        <TooltipOption label={label} tooltip={item.tooltip} />
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="operator">
                  <Form.ControlLabel>Condition</Form.ControlLabel>
                  <Form.Control
                    name="select"
                    data={availableOperators}
                    value={operator}
                    accepter={SelectPicker}
                    searchable={false}
                    onChange={handleChangeOperator}
                  />
                </Form.Group>
              </FlexRow>
            ) : (
              <Form.Group controlId="field_name">
                <Form.ControlLabel>Report Type</Form.ControlLabel>
                <Form.Control
                  name="select"
                  data={availableFieldNames}
                  value={fieldName}
                  accepter={SelectPicker}
                  searchable={false}
                  onChange={handleChangeField}
                  renderMenuItem={(label, item) => {
                    return (
                      <TooltipOption label={label} tooltip={item.tooltip} />
                    );
                  }}
                />
              </Form.Group>
            )}

            {needsPressure ? ( // Display threshold units as pressure
              <Form.Group controlId="threshold">
                <Form.ControlLabel>Pressure Threshold</Form.ControlLabel>
                <Stack>
                  <InputNumber
                    defaultValue={initFormReport.threshold}
                    value={threshold}
                    onChange={handleChangeThreshold}
                    step={0.1}
                    postfix="p"
                  />
                </Stack>
              </Form.Group>
            ) : (
              false
            )}

            {needsPercent ? ( // Display threshold units as percent
              <Form.Group controlId="threshold">
                <Form.ControlLabel>Percentage Threshold</Form.ControlLabel>
                <Stack>
                  <InputNumber
                    defaultValue={initFormReport.threshold}
                    value={threshold}
                    onChange={handleChangeThreshold}
                    step={0.1}
                    max={100}
                    min={0}
                    postfix="%"
                  />
                </Stack>
              </Form.Group>
            ) : (
              false
            )}

            <Form.ControlLabel>Systems List</Form.ControlLabel>
            <FilterSystems
              report={report}
              currentReportSys={currentReportSys}
              setCurrentReportSys={setCurrentReportSys}
              allSystems={allSystems}
            />

            <Form.ControlLabel>Email Schedule</Form.ControlLabel>
            <CheckPicker
              preventOverflow
              placement={"bottomStart"}
              value={reoprtScheduledDT} // value={userCustomers}
              data={currentEmailSchedule} //data={baseCustomers}
              onChange={handleChangeSchedule}
              block
              renderExtraFooter={() => (
                <div style={footerStyles}>
                  {
                    <Checkbox
                      indeterminate={
                        reoprtScheduledDT.length > 0 &&
                        reoprtScheduledDT.length <
                          email_schedule_template.length
                      }
                      checked={
                        reoprtScheduledDT.length ===
                        email_schedule_template.length
                      }
                      onChange={handleCheckAllSchedule}
                    >
                      Check all
                    </Checkbox>
                  }
                </div>
              )}
            />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button appearance="primary" onClick={onSubmit}>
            Submit
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const errMessage = (
  <Message showIcon type="error" header="Error">
    Something went wrong. Please try again.
  </Message>
);

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const email_schedule_template = [
  "sun-00:00",
  "sun-00:30",
  "sun-01:00",
  "sun-01:30",
  "sun-02:00",
  "sun-02:30",
  "sun-03:00",
  "sun-03:30",
  "sun-04:00",
  "sun-04:30",
  "sun-05:00",
  "sun-05:30",
  "sun-06:00",
  "sun-06:30",
  "sun-07:00",
  "sun-07:30",
  "sun-08:00",
  "sun-08:30",
  "sun-09:00",
  "sun-09:30",
  "sun-10:00",
  "sun-10:30",
  "sun-11:00",
  "sun-11:30",
  "sun-12:00",
  "sun-12:30",
  "sun-13:00",
  "sun-13:30",
  "sun-14:00",
  "sun-14:30",
  "sun-15:00",
  "sun-15:30",
  "sun-16:00",
  "sun-16:30",
  "sun-17:00",
  "sun-17:30",
  "sun-18:00",
  "sun-18:30",
  "sun-19:00",
  "sun-19:30",
  "sun-20:00",
  "sun-20:30",
  "sun-21:00",
  "sun-21:30",
  "sun-22:00",
  "sun-22:30",
  "sun-23:00",
  "sun-23:30",
  "mon-00:00",
  "mon-00:30",
  "mon-01:00",
  "mon-01:30",
  "mon-02:00",
  "mon-02:30",
  "mon-03:00",
  "mon-03:30",
  "mon-04:00",
  "mon-04:30",
  "mon-05:00",
  "mon-05:30",
  "mon-06:00",
  "mon-06:30",
  "mon-07:00",
  "mon-07:30",
  "mon-08:00",
  "mon-08:30",
  "mon-09:00",
  "mon-09:30",
  "mon-10:00",
  "mon-10:30",
  "mon-11:00",
  "mon-11:30",
  "mon-12:00",
  "mon-12:30",
  "mon-13:00",
  "mon-13:30",
  "mon-14:00",
  "mon-14:30",
  "mon-15:00",
  "mon-15:30",
  "mon-16:00",
  "mon-16:30",
  "mon-17:00",
  "mon-17:30",
  "mon-18:00",
  "mon-18:30",
  "mon-19:00",
  "mon-19:30",
  "mon-20:00",
  "mon-20:30",
  "mon-21:00",
  "mon-21:30",
  "mon-22:00",
  "mon-22:30",
  "mon-23:00",
  "mon-23:30",
  "tue-00:00",
  "tue-00:30",
  "tue-01:00",
  "tue-01:30",
  "tue-02:00",
  "tue-02:30",
  "tue-03:00",
  "tue-03:30",
  "tue-04:00",
  "tue-04:30",
  "tue-05:00",
  "tue-05:30",
  "tue-06:00",
  "tue-06:30",
  "tue-07:00",
  "tue-07:30",
  "tue-08:00",
  "tue-08:30",
  "tue-09:00",
  "tue-09:30",
  "tue-10:00",
  "tue-10:30",
  "tue-11:00",
  "tue-11:30",
  "tue-12:00",
  "tue-12:30",
  "tue-13:00",
  "tue-13:30",
  "tue-14:00",
  "tue-14:30",
  "tue-15:00",
  "tue-15:30",
  "tue-16:00",
  "tue-16:30",
  "tue-17:00",
  "tue-17:30",
  "tue-18:00",
  "tue-18:30",
  "tue-19:00",
  "tue-19:30",
  "tue-20:00",
  "tue-20:30",
  "tue-21:00",
  "tue-21:30",
  "tue-22:00",
  "tue-22:30",
  "tue-23:00",
  "tue-23:30",
  "wed-00:00",
  "wed-00:30",
  "wed-01:00",
  "wed-01:30",
  "wed-02:00",
  "wed-02:30",
  "wed-03:00",
  "wed-03:30",
  "wed-04:00",
  "wed-04:30",
  "wed-05:00",
  "wed-05:30",
  "wed-06:00",
  "wed-06:30",
  "wed-07:00",
  "wed-07:30",
  "wed-08:00",
  "wed-08:30",
  "wed-09:00",
  "wed-09:30",
  "wed-10:00",
  "wed-10:30",
  "wed-11:00",
  "wed-11:30",
  "wed-12:00",
  "wed-12:30",
  "wed-13:00",
  "wed-13:30",
  "wed-14:00",
  "wed-14:30",
  "wed-15:00",
  "wed-15:30",
  "wed-16:00",
  "wed-16:30",
  "wed-17:00",
  "wed-17:30",
  "wed-18:00",
  "wed-18:30",
  "wed-19:00",
  "wed-19:30",
  "wed-20:00",
  "wed-20:30",
  "wed-21:00",
  "wed-21:30",
  "wed-22:00",
  "wed-22:30",
  "wed-23:00",
  "wed-23:30",
  "thu-00:00",
  "thu-00:30",
  "thu-01:00",
  "thu-01:30",
  "thu-02:00",
  "thu-02:30",
  "thu-03:00",
  "thu-03:30",
  "thu-04:00",
  "thu-04:30",
  "thu-05:00",
  "thu-05:30",
  "thu-06:00",
  "thu-06:30",
  "thu-07:00",
  "thu-07:30",
  "thu-08:00",
  "thu-08:30",
  "thu-09:00",
  "thu-09:30",
  "thu-10:00",
  "thu-10:30",
  "thu-11:00",
  "thu-11:30",
  "thu-12:00",
  "thu-12:30",
  "thu-13:00",
  "thu-13:30",
  "thu-14:00",
  "thu-14:30",
  "thu-15:00",
  "thu-15:30",
  "thu-16:00",
  "thu-16:30",
  "thu-17:00",
  "thu-17:30",
  "thu-18:00",
  "thu-18:30",
  "thu-19:00",
  "thu-19:30",
  "thu-20:00",
  "thu-20:30",
  "thu-21:00",
  "thu-21:30",
  "thu-22:00",
  "thu-22:30",
  "thu-23:00",
  "thu-23:30",
  "fri-00:00",
  "fri-00:30",
  "fri-01:00",
  "fri-01:30",
  "fri-02:00",
  "fri-02:30",
  "fri-03:00",
  "fri-03:30",
  "fri-04:00",
  "fri-04:30",
  "fri-05:00",
  "fri-05:30",
  "fri-06:00",
  "fri-06:30",
  "fri-07:00",
  "fri-07:30",
  "fri-08:00",
  "fri-08:30",
  "fri-09:00",
  "fri-09:30",
  "fri-10:00",
  "fri-10:30",
  "fri-11:00",
  "fri-11:30",
  "fri-12:00",
  "fri-12:30",
  "fri-13:00",
  "fri-13:30",
  "fri-14:00",
  "fri-14:30",
  "fri-15:00",
  "fri-15:30",
  "fri-16:00",
  "fri-16:30",
  "fri-17:00",
  "fri-17:30",
  "fri-18:00",
  "fri-18:30",
  "fri-19:00",
  "fri-19:30",
  "fri-20:00",
  "fri-20:30",
  "fri-21:00",
  "fri-21:30",
  "fri-22:00",
  "fri-22:30",
  "fri-23:00",
  "fri-23:30",
  "sat-00:00",
  "sat-00:30",
  "sat-01:00",
  "sat-01:30",
  "sat-02:00",
  "sat-02:30",
  "sat-03:00",
  "sat-03:30",
  "sat-04:00",
  "sat-04:30",
  "sat-05:00",
  "sat-05:30",
  "sat-06:00",
  "sat-06:30",
  "sat-07:00",
  "sat-07:30",
  "sat-08:00",
  "sat-08:30",
  "sat-09:00",
  "sat-09:30",
  "sat-10:00",
  "sat-10:30",
  "sat-11:00",
  "sat-11:30",
  "sat-12:00",
  "sat-12:30",
  "sat-13:00",
  "sat-13:30",
  "sat-14:00",
  "sat-14:30",
  "sat-15:00",
  "sat-15:30",
  "sat-16:00",
  "sat-16:30",
  "sat-17:00",
  "sat-17:30",
  "sat-18:00",
  "sat-18:30",
  "sat-19:00",
  "sat-19:30",
  "sat-20:00",
  "sat-20:30",
  "sat-21:00",
  "sat-21:30",
  "sat-22:00",
  "sat-22:30",
  "sat-23:00",
  "sat-23:30",
];

function convertTo12HrFormat(timeStr) {
  const [day, time] = timeStr.split("-");
  let [hour, minute] = time.split(":");
  hour = parseInt(hour, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour ? hour : 12; // Convert 0 to 12 for 12AM
  const first_letter = day[0].toUpperCase();
  const last_letters = day.slice(1, 3);
  const upperDay = first_letter + last_letters;
  return `${upperDay}-${hour}:${minute} ${ampm}`;
}

const timeMap = new Map();

email_schedule_template.forEach((timeStr) => {
  const key = convertTo12HrFormat(timeStr);
  timeMap.set(timeStr, key);
});
