import React, { useContext } from 'react';
import styled from 'styled-components';
import backgroundImage from '../../../../images/background/mri_scanner_blue_overlay.jpg'
import { SectionTitleFC } from '../_helpers/SectionTitleFC';
import LineChartIcon from '@rsuite/icons/LineChart';
import TableColumnIcon from '@rsuite/icons/TableColumn';
import DeviceIcon from '@rsuite/icons/Device';
import NoticeIcon from '@rsuite/icons/Notice';
import { DarkModeContext } from '../../../../appContexts/DarkModeContext';
import { UpdateMessageFC } from '../_misc/UpdateMessageFC';

export const WelcomeFC = () => {
   const { darkMode } = useContext(DarkModeContext);

   return (
      <WelcomeSC>
         {/* <UpdateMessageFC/> */}
         <SectionTitleFC title={'WELCOME'} />
         <Title>Welcome to this new release of the Avante Remote Services website.  
         </Title>
         <WelcomeBody darkMode={darkMode}>
            <FeatureBox>
               <BodyText>Sign in to start viewing MMB data using these features:</BodyText>
               <Feature>
                  <FeatureText>Machine Charts</FeatureText>
                  <LineChartIcon style={{ fontSize: "4rem", color: "#ffc555"}}></LineChartIcon>
               </Feature>
               <Feature>
                  <FeatureText>Machine Tables</FeatureText>
                  <TableColumnIcon style={{ fontSize: "4rem", color: "#ffc555"}}></TableColumnIcon>
               </Feature>
               <Feature>
                  <FeatureText>Machine Alerts</FeatureText>
                  <NoticeIcon style={{ fontSize: "4rem", color: "#ffc555"}}></NoticeIcon>
               </Feature>
               <Feature>
                  <FeatureText>Access Anywhere</FeatureText>
                  <DeviceIcon style={{ fontSize: "4rem", color: "#ffc555"}}></DeviceIcon>
               </Feature>
            </FeatureBox>                          
         </WelcomeBody>
      </WelcomeSC>
   );
};

const WelcomeSC = styled.div`
   width: 100vw;
   height: 75vh;

   @media screen and (min-width: 769px) {
      width: 75vw; // OF AppSC
      height: 74vh;
   }
`;


const WelcomeBody = styled.div`
   background: url(${backgroundImage}) no-repeat;
   box-shadow: 0 0 25px 35px ${({ theme, darkMode }) =>
      darkMode ? theme.dark_primary : theme.light_primary} inset;
   display: block;
   background-size: cover;
   width: 100%;
   height: 100%;
   opacity:0.75;
`;

const Title = styled.div `
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   font-family: inherit;
   font-size: 3.5vw;
   font-weight: 400;
   text-align: center;

   a {
      text-decoration: underline;
   }

   @media screen and (min-width: 769px) {
      font-size: 1vw;
      margin-left: 10%;
      margin-right: 10%;
   }
`
const FeatureBox = styled.div`
   font-family: 'Open Sans'
   padding-top: 4vh;
   padding-bottom: 8vh;
   padding: 3px;
   text-align: -webkit-center;

   @media screen and (min-width: 769px) {
      font-family: 'Open Sans'
      padding-top: 4vh;
      padding-bottom: 8vh;
      padding: 3px;
      text-align: -webkit-center;
   }
`;

const Feature = styled.div`
   border: 3px solid ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_primary};
   display: inline-block;
   margin: 3vh 5vw 0 5vw;
   width: 27vw;
   height: 22vh;
   border-radius: 15px;
   padding: 3%;
   text-transform: uppercase;

   :hover {
      background: transparent;
      border: 3px solid #d0d0d0;
   }

   @media screen and (min-width: 769px) {
      text-align-last: center;
      border: 3px solid ${({ theme, darkMode }) =>
         darkMode ? theme.dark_accent : theme.light_primary};
      display: inline-block;
      margin: 3vh 8vw 0 8vw;
      border-radius: 15px;
      width: 10vw;
      height 12vw;
   }
`;

const FeatureText = styled.div`
   display: box;
   font-size: 2.5vw;
   font-weight: 300;
   letter-spacing: 1px;
   color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.light_primary};
   padding-bottom: 2vh;

   @media screen and (min-width: 769px) {
      font-size: 1vw;
   }
`;

const BodyText = styled.div`
   display: box;
   margin-top: 7vh;
   color: #fff;
   font-style: normal;
   font-weight: 500;
   font-size: 3vw;
   width: 50%;
   box-shadow: 0px 4px 2px -2px white;
   padding-bottom: 1%;

   @media screen and (min-width: 769px) {
      margin-top: 3vw;
      font-size: 1vw;
   }
`;