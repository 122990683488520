import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import { AlertsContext } from './_context/AlertsContext';
import { AlertsFC } from './AlertsFC';
import { MachinesContext } from './_context/MachinesContext';
import { MachinesTableFC } from './MachinesTableFC';
import { LoaderFC } from '../../_helpers/LoaderFC';
import { Nav, Navbar } from 'rsuite';
import { HHMTableFC } from './HHMTableFC';
import { InputContext } from '../../_context/InputContext';
import { UserContext } from '../../_context/UserContext';
import HomeIcon from '@rsuite/icons/legacy/Home';
import LineChartIcon from '@rsuite/icons/LineChart';
import PcIcon from '@rsuite/icons/Pc';
import { useHistory } from 'react-router-dom';
import { getMessage } from '../../_utils/util_general';
import { ErrorFC } from '../../_misc/ErrorFC';
import { TableEDU } from './TableEDU';
import { Avatar } from '../../../../../appHelpers/appCommonSC';

export const _DashboardFC = () => {
   // renderCount(`_DashboardFC`);
   const history = useHistory();
   const { getUserProfile, user} = useContext(UserContext);
   const [activeKey, setActiveKey] = useState("1");

   const { loadingAlertConfigs, loadingDashboardAlerts, alertConfigs, defaultAlertConfigs,  dashboardAlerts, getDashboardAlerts, getAlertConfigs } =
      useContext(AlertsContext);

   const {
      loadingMachines,
      loadingHHMMachines,
      loadingNetworkIssues,
      setLoadingMachines,
      setLoadingHHMMachines,
      machines,
      networkIssues,
      HHMMachines,
      getMachines,
      getNetworkIssues,
      getHHMMachines,
      getMachinesError,
      getHHMMachinesError,
   } = useContext(MachinesContext);

   const {
      viewToggle,
      setViewToggle
   } = useContext(InputContext);

   useEffect(() => {
      // renderCount(`_DashboardFC - useEffect`);
      // getAlerts();
      getMachines();
      getNetworkIssues();
      getHHMMachines();
      getAlertConfigs();
      getDashboardAlerts();
      getUserProfile();
      
      return () => {
         // RESET UPON UN-MOUNTING
         // INSTEAD OF UNMOUNTING WE'LL MANUALLY REFRESH
         // setLoadingAlertConfigs(true);
         // setLoadingMachines(true);
         // setLoadingHHMMachines(true);
      };
   }, []);

   // MEMO ALLOWS FOR SPECIFIC STATE DEPENDENCY CONTROL OF CHILD RENDERS
   const memoMachines = useMemo(() => {
      return <MachinesTableFC machines={machines} defaultAlertConfigs={defaultAlertConfigs} alertConfigs={alertConfigs} networkIssues={networkIssues}/>;
   }, [machines, alertConfigs]);

   const memoEDUs = useMemo(() => {
      return <TableEDU machines={machines} defaultAlertConfigs={defaultAlertConfigs} alertConfigs={alertConfigs} networkIssues={networkIssues}/>;
   }, [machines, alertConfigs]);

   const memoHHMMachines = useMemo(() => {
      return <HHMTableFC machines={HHMMachines} defaultAlertConfigs={defaultAlertConfigs} alertConfigs={alertConfigs} networkIssues={networkIssues} />;
   }, [HHMMachines, alertConfigs]);

   const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
      return (
         window.screen.width >= 768 ?
         <Navbar {...props} style={{width: '100%', marginBottom: '1%'}}>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>{} {user.first_name ? `GOOD ${getMessage()}, ${user.first_name?.toUpperCase()}!`: null} </Nav.Item>
            </Nav>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>|</Nav.Item>
            </Nav>
            <Nav onSelect={setViewToggle} activeKey={viewToggle}>
               {machines.length > 0 ?    
                  <Nav.Item eventKey="MMB" icon={<LineChartIcon />}> MAGNET MONITORING </Nav.Item>
               : null
               }
               {machines.length > 0 ?    
                  <Nav.Item eventKey="EDU" icon={<LineChartIcon />}> EDU </Nav.Item>
               : null
               }
               {HHMMachines.length > 0 ?    
                  <Nav.Item eventKey="HHM" icon={<PcIcon />}> SYSTEMS </Nav.Item>
               : null
               }
            </Nav>

            <Nav pullRight onSelect={() => history.push(`/admin/user/${user.email_address}`)}>
               <Nav.Item><Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar></Nav.Item>
            </Nav>
         </Navbar>
         :
         <Navbar {...props} style={{width: '100%', fontSize: '12px'}}>
             <Nav>
               <Nav.Item icon={<HomeIcon />} style={{pointerEvents: 'none'}}>{} {user.first_name ? `GOOD ${getMessage()}, ${user.first_name?.toUpperCase()}!`: null} </Nav.Item>
            </Nav>
            <Nav pullRight onSelect={() => history.push(`/admin/user/${user.email_address}`)}>
               <Nav.Item><Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar></Nav.Item>
            </Nav>
            <Nav onSelect={setViewToggle} activeKey={viewToggle} style={{width: '100%'}}>
               {machines.length > 0 ?    
                  <Nav.Item eventKey="MMB" icon={<LineChartIcon />}> MAGNET MONITORING </Nav.Item>
               : null
               }
               {machines.length > 0 ?    
                  <Nav.Item eventKey="EDU" icon={<LineChartIcon />}> EDU </Nav.Item>
               : null
               }
               {HHMMachines.length > 0 ?    
                  <Nav.Item eventKey="HHM" icon={<PcIcon />}>SYSTEMS</Nav.Item>
               : null
               }
            </Nav>       
         </Navbar>      
      );
   };
   
   useEffect(() => {
      if(!loadingHHMMachines && !loadingMachines) {
         if(machines?.length > 0 && !viewToggle) {
            setViewToggle('MMB');
         } else if (HHMMachines?.length > 0 && !viewToggle){
            setViewToggle('HHM');
         } 
      }
   }, [loadingHHMMachines, loadingMachines]);

   return (
      <DashboardSC>
         {loadingMachines || loadingHHMMachines || loadingAlertConfigs || loadingNetworkIssues ? (
            <LoaderFC/>
         ) : (
            <>              
               <CustomNavbar  appearance='subtle' activeKey={activeKey} onSelect={setActiveKey} /> 

               {!loadingDashboardAlerts ? 
                  <AlertsFC alerts={dashboardAlerts} systems={machines} hhmSystems={HHMMachines}/>
                  : null
               }

               {viewToggle === 'MMB' && !getMachinesError ?
                  memoMachines
               : viewToggle === 'HHM' && !getHHMMachinesError ?
                  memoHHMMachines
               : viewToggle === 'EDU' && !getMachinesError ?
                  memoEDUs
               : getMachinesError || getHHMMachinesError ?
                  <ErrorFC statusCode="500"/>
               : null        
               }  
            </>
         )}
      </DashboardSC>
   );
};

const DashboardSC = styled.div`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;
   height: 100%; // OF BodySC
   width: 100%; // OF BodySC
   opacity: ${({ loadingDashboard }) => (loadingDashboard ? 0 : 1)};
   transition: opacity 0.25s;
`;