import React, { useState } from 'react';

const DisplayMobileSidebarContext = React.createContext();

const DisplayMobileSidebarContextProvider = (props) => {
   const [displayMobileSidebar, setDisplayMobileSidebar] = useState(false);

   return (
      <DisplayMobileSidebarContext.Provider
         value={{
            displayMobileSidebar: displayMobileSidebar,
            setDisplayMobileSidebar: setDisplayMobileSidebar,
         }}
      >
         {props.children}
      </DisplayMobileSidebarContext.Provider>
   );
};

export { DisplayMobileSidebarContext, DisplayMobileSidebarContextProvider };
