import React, { useState } from 'react';

const InputContext = React.createContext();

const InputContextProvider = (props) => {
   const [viewToggle, setViewToggle] = useState(null);
   const [adminCustomerToggle, setAdminCustomerToggle] = useState(null);
   const [adminViewToggle, setAdminViewToggle] = useState('Users');
   const [customerToggle, setCustomerToggle] = useState([]);
   const [manufacturerToggle, setManufacturerToggle] = useState([]);
   const [modalityToggle, setModalityToggle] = useState([]);
   const [modelToggle, setModelToggle] = useState([]);
   const [siteToggle, setSiteToggle] = useState([]);
   const [systemCache, setSystemCache] = useState([]);

   return (
      <InputContext.Provider
         value={{
            viewToggle,
            adminViewToggle,
            adminCustomerToggle,
            customerToggle,
            siteToggle,
            manufacturerToggle,
            modalityToggle,
            modelToggle,
            setViewToggle,
            setAdminViewToggle,
            setAdminCustomerToggle,
            setCustomerToggle,
            setSiteToggle,
            setManufacturerToggle,
            setModalityToggle,
            setModelToggle,
            systemCache,
            setSystemCache
         }}
      >
         {props.children}
      </InputContext.Provider>
   );
};

export { InputContext, InputContextProvider };