// PRIMARY COMPONENT IS PASSED AN ARRAY OF STATE OPTIONS
// AND A REFERENCE TO THE CORRESPONDING SETSTATE FUNCTION

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { renderCount } from '../../../../appHelpers/appLoggers';
import { DarkModeContext } from '../../../../appContexts/DarkModeContext';

const TabOptionFC = ({
   option,
   idx,
   selectedOptionIndex,
   setSelectedOptionIndex,
   setStateToOption,
}) => {
   // renderCount(`TabOptionFC`);
   const { darkMode } = useContext(DarkModeContext);

   const handleClick = () => {
      setSelectedOptionIndex(idx); // UI INDICATOR
      setStateToOption(option); // MODULAR STATE UPDATER
   };
   return (
      <TabOptionSC
         darkMode={darkMode}
         isSelected={idx === selectedOptionIndex ? true : false}
         onClick={() => handleClick()}
      >
         {option}
      </TabOptionSC>
   );
};

// TODO: RENAME TO COMPONENT-SWITCHER > COMPONENT-OPTION
export const TabSelectorFC = ({ options, setStateToOption }) => {
   // renderCount(`TabSelectorFC`);
   const { darkMode } = useContext(DarkModeContext);
   // DEFAULTS TO FIRST OPTION AS SELECTED
   const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
   return (
      <TabSelectorSC darkMode={darkMode}>
         {options.map((option, idx) => {
            return (
               <TabOptionFC
                  key={option}
                  option={option}
                  idx={idx}
                  selectedOptionIndex={selectedOptionIndex}
                  setSelectedOptionIndex={setSelectedOptionIndex}
                  setStateToOption={setStateToOption}
               />
            );
         })}
      </TabSelectorSC>
   );
};

// TODO: MAKE TabSelectorSC width = options.length * fit-content OF
// OPTION WHICH HAS THE MOST CHARTACTERS??? * 1.1 FOR SPACING
const TabSelectorSC = styled.span`
   display: flex;
   justify-content: center;
   align-items: center;
   /* width: clamp(50%, fit-content, 90%); */
   padding: 1rem;
   overflow-x: auto;
   /* border: solid 1px red; */
`;
// TODO: MAKE TabOptionSC width = 1 / options.length %
const TabOptionSC = styled.div`
   display: grid;
   place-items: center;
   color: ${({ theme, darkMode, isSelected }) =>
      darkMode
         ? isSelected
            ? `white`
            : theme.dark_accent
         : isSelected
         ? theme.light_accent
         : theme.light_grey};
   margin: 0 0.5em;
   // APPARENTLY LEAVING COLOR PROP OFF BORDER INHERITS COLOR
   // THIS MAKES HOVER AND TRANSITION WAY EASIER!
   border-bottom: solid 2px;
   letter-spacing: 1px;
   text-transform: uppercase;
   /* border: solid 1px green; */

   :hover {
      cursor: pointer;
      color: ${({ theme, darkMode }) =>
         darkMode ? `white` : theme.light_accent};
   }
   transition: color 0.25s;

   // AT COMMON TABET SCREEN SIZE AND GREATER
   // CHANGE FONT SIZE/MAGIC NUMBER
   @media screen and (min-width: 769px) {
      font-size: 1vw;
   }
`;
