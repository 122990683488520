import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, IconButton, Button, Input, TagPicker, ButtonGroup, Message, Schema, useToaster, InputNumber, Toggle, SelectPicker, Checkbox, CheckPicker, toaster } from 'rsuite';
import ToggleButton from 'rsuite/esm/Picker/ToggleButton';
import styled from 'styled-components';
import { MainDivider, SettingHeader } from '../../helpers/Admin_StyledComponents';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from '@rsuite/icons/Trash';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';

export const GenerateConfigMag = ({configs, regex_models, system}) => { 
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();

   const initAcquisitionConfigs  = configs.map(config => ({
      system_id: config?.system_id ? config?.system_id : id,
      file_name: config?.file_name,
      dir_name: config?.dir_name,
      regex_models: config?.regex_models,
      pg_tables: config?.pg_tables,
      column_name: config?.column_name,
      schedule: config?.schedule,
      agg: config?.agg
   }));

   // Assuming configs is an array of configuration objects
   const [formValueConfigs, setFormValueConfigs]  = useState(initAcquisitionConfigs);

   const addConfig = () => {
      setFormValueConfigs([...formValueConfigs, {
         // New config defaults here
         system_id: id,
         file_name: null,
         dir_name: null,
         regex_models: [],
         pg_tables: [],
         column_name: null,
         schedule: null,
         agg: null,
      }]);
  };

   const removeConfig = index => {
      const updatedConfigs = formValueConfigs.filter((_, i) => i !== index);
      setFormValueConfigs(updatedConfigs);
   };

   const updateConfigAtIndex = (index, updatedConfig) => {
      console.log(index);
      console.log(updatedConfig);
      const updatedConfigs = formValueConfigs.map((config, i) => 
         i === index ? { ...config, ...updatedConfig } : config
      );
      setFormValueConfigs(updatedConfigs);
   };

   const resetForm = () => {
      setFormValueConfigs(initAcquisitionConfigs);
   };

   // PREFILL DATA
   const fileNameSelectData = ["v2_rdu_9600", "v2_rdu_4800", "v2_ge_mm4", "v3_ge_mm3", "v3_ge_mm4",
   "rmmu","rmmu_long","rmmu_short","rmmu_magnet",
   "monitor_System_HumTechRoom.dat","monitor_System_TempTechRoom.dat",
   "monitor_magnet_helium_level_value.dat","monitor_magnet_lt_boiloff.dat","monitor_magnet_pressure.dat","monitor_magnet_pressure_avg.dat","monitor_magnet_pressure_dps.dat","monitor_magnet_quench.dat",
   "monitor_cryocompressor_cerr.dat","monitor_cryocompressor_palm.dat","monitor_cryocompressor_talm.dat","monitor_cryocompressor_time_status.dat",
   "HELIUM_LEVEL.DAT.dat"
   ].map(item => ({
      label: item,
      value: item
   }));

   const pgTableSelectData = [...new Set(regex_models.filter(model => model.pg_table.includes('mag')).map(table => table.pg_table).concat(...[...new Set(configs?.map(config=> config.pg_tables))])),
      "philips_mri_rmmu_magnet","philips_mri_rmmu_short","philips_mri_rmmu_log","philips_mri_rmmu_history","philips_mri_json",
      "philips_mri_monitoring_data","stt_magnet"
   ].map(item => ({
      label: item,
      value: item
   }));

   const regexModelsSelectData = [...new Set(regex_models.filter(model => model.pg_table.includes('mag') && model.pg_table.includes(system.manufacturer.toLowerCase())).map(table => table.id))].map(item => ({
      label: item,
      value: item
   }));

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated this System.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   // UPDATE MAG CONFIGS
   const updateConfigMag = async () => {
      // console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            method: 'POST',
            url: `/network/update/config`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               sme: id,
               configs: formValueConfigs,
               type: 'mag'
            })
         });
         toaster.push(message,'topCenter');
      } catch (error) {
         toaster.push(errMessage,'topCenter');
         console.error(error);
      }
   };

   useEffect(() => {
      
   }, []);

   return (
      <ConfigBox>
         <SettingHeader>
            Mag Configs
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <Button appearance="primary" onClick={updateConfigMag}>Submit</Button>
               <Button appearance="default" onClick={resetForm}>Reset</Button>
            </ButtonGroup>
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <IconButton icon={<PlusIcon />}  onClick={addConfig}/>
            </ButtonGroup> 
         </SettingHeader>

         <MainDivider/>

         {formValueConfigs.map((config, index) => (
            <React.Fragment key={index}>
               <Form fluid
                  formValue={config} 
                  onChange={(value) => updateConfigAtIndex(index, value)}
               >
                  <Form.Group controlId='system_id' style={{width: '10%', marginRight: '1%', display: 'inline-block'}}>
                     <Form.ControlLabel>System ID</Form.ControlLabel>
                     <Form.Control name="system_id" disabled/>
                  </Form.Group>
                  <Form.Group controlId='file_name' style={{width: '30%', marginRight: '1%', display: 'inline-block'}}>
                     <Form.ControlLabel>File Name</Form.ControlLabel>
                     <Form.Control name="file_name" accepter={SelectPicker} data={fileNameSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
                  </Form.Group>

                  {system.manufacturer.toLowerCase() === 'philips' ?
                     <Form.Group controlId='dir_name' style={{width: '20%', marginRight: '1%', display: 'inline-block'}}>
                        <Form.ControlLabel>Directory Name</Form.ControlLabel>
                        <Form.Control name="dir_name" />
                     </Form.Group>
                  : null
                  }
                  
                  <Form.Group controlId='pg_tables' style={{width: '30%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
                     <Form.ControlLabel>PG Tables</Form.ControlLabel>
                     <Form.Control name="pg_tables" accepter={CheckPicker} data={pgTableSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
                  </Form.Group>

                  {system.manufacturer.toLowerCase() !== 'philips' ?
                     <Form.Group controlId='schedule' style={{width: '15%', marginLeft: '1%', display: 'inline-block'}}>
                        <Form.ControlLabel>Schedule</Form.ControlLabel>
                        <Form.Control name="schedule" accepter={InputNumber} min={0}/>
                     </Form.Group>   
                  : null
                  }

                  {system.manufacturer.toLowerCase() !== 'philips' ?
                     <>
                        <Form.Group controlId='regex_models' style={{width: '95%', display: 'inline-block'}}>
                           <Form.ControlLabel>Regex Models</Form.ControlLabel>
                           <Form.Control name="regex_models" accepter={CheckPicker} data={regexModelsSelectData} searchable={false} style={{width: '99%'}} preventOverflow/>
                        </Form.Group>
                        <ButtonGroup style={{ display: 'inline-block'}}>
                           <IconButton icon={<TrashIcon />} color='red' appearance="primary" onClick={() => removeConfig(index)}/>
                        </ButtonGroup>
                     </>
                  :
                     <>
                        <Form.Group controlId='regex_models' style={{width: '42%', display: 'inline-block'}}>
                           <Form.ControlLabel>Regex Models</Form.ControlLabel>
                           <Form.Control name="regex_models" accepter={CheckPicker} data={regexModelsSelectData} searchable={false} style={{width: '99%'}} preventOverflow/>
                        </Form.Group>
                        <Form.Group controlId='column_name' style={{width: '20%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
                           <Form.ControlLabel>Column Name</Form.ControlLabel>
                           <Form.Control name="column_name" preventOverflow/>
                        </Form.Group>
                        <Form.Group controlId='agg' style={{width: '20%', marginLeft: '1%', display: 'inline-block'}}>
                           <Form.ControlLabel>Agg</Form.ControlLabel>
                           <Form.Control name="agg"preventOverflow/>
                        </Form.Group>
                        <ButtonGroup style={{ display: 'inline-block', marginLeft: '10%'}}>
                           <IconButton icon={<TrashIcon />} color='red' appearance="primary" onClick={() => removeConfig(index)}/>
                        </ButtonGroup>
                     </>
                  }      
               <MainDivider/>   
               </Form>
            </React.Fragment>
         ))}
         <MainDivider/>
      </ConfigBox>
   )
}

const ConfigBox = styled.div`
   display: block;
`