import React, { useState, useEffect, useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { _FilterNetworkSystemsFC } from '../helpers/_FilterNetworkSystemsFC';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import { SystemListFC } from './SystemListFC';
import { Loader } from 'rsuite';
import { InputContext } from '../../_context/InputContext';

export const _NetworkFC = (props) => {
   // renderCount(`AdminFC`);
   const { getAccessTokenSilently } = useAuth0();
   const [systems, setSystems] = useState();
   const [filteredSystems, setFilteredSystems] = useState();
   const [displaySystems, setDisplaySystems] = useState();
   const [roles, setRoles] = useState();
   const [loadingSystemData, setLoadingSystemData] = useState([]);
   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);

   const getSystemData = async () => {
      setLoadingSystemData(true);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            url: `/network/systems`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setSystems(res?.data);
      } catch (error) {
         console.error(error);
      }

      setLoadingSystemData(false);
   };

   const filterSystems = () => {
      if(adminCustomerToggle && systems) {
         setFilteredSystems(systems[0]?.filter(system => system.customer_id === adminCustomerToggle));
         setDisplaySystems(systems[0]?.filter(system => system.customer_id === adminCustomerToggle));
      } else if (systems) {
         setFilteredSystems(systems[0]);
         setDisplaySystems(systems[0]);
      }
   }
   
   useEffect(() => {
      getSystemData();
      setRoles(props.userRoles);
   }, []);

   useEffect(() => {
      filterSystems();
   }, [systems, adminCustomerToggle]);

   return (
      <_NetworkSC>
         {loadingSystemData ?      
            <Loader size="md" speed="fast" content="Loading..." style={{paddingTop: '10px'}} center/>      
         :
            <NetList>
               <_FilterNetworkSystemsFC systems={filteredSystems} displaySystems={filteredSystems} setDisplaySystems={setDisplaySystems} />
               <SystemListFC systems={displaySystems} acuamticaSystems={systems[1]}/>       
            </NetList>      
         }
      </_NetworkSC>
   );
};

const _NetworkSC = styled.div`
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
`;

const NetList = styled.div`
   margin-left: 1%;
   margin-right: 1%;
`