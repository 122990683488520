import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, IconButton, Toggle, Checkbox, Tag } from 'rsuite';
import { ListHeader, MainDivider } from '../../../admin/helpers/Admin_StyledComponents';
import { SeverityAlert } from '../../../../../../appHelpers/appCommonSC';
import { DateTime } from 'luxon';
import { UserContext } from '../../../_context/UserContext';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';

export const HHMAlertAllFC = ({ defaultConfigs, handleEnabled }) => {
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();
   const {userRoles} = useContext(UserContext);
   const [alertConfigs, setAlertConfigs] = useState([]);
   const [displayAlertConfigs, setDisplayAlertConfigs] = useState([]);
   const [loadingAlertConfig, setLoadingAlertConfig] = useState(true);
   const [getAlertConfigError, setGetAlertConfigError] = useState(true);
   const [hideAvante, setHideAvante] = useState(false);
   const [defaultIDs, setDefaultIDs] = useState([]);

   const getSystemAlertConfig = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/alert/configs/all-users/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         setAlertConfigs(response.data[0]);
         setDisplayAlertConfigs(response.data[0]);
      } catch (error) {
         setGetAlertConfigError(true);
         console.log(error);
      }
      setLoadingAlertConfig(false);
   };

   const handleHide = () => {
      if (!hideAvante) {
         setDisplayAlertConfigs(alertConfigs.filter(config => (!config.user_id.toLowerCase().includes('avantehs.com'))));
      } else {
         setDisplayAlertConfigs(alertConfigs);
      }

      setHideAvante(!hideAvante);
   }

   useEffect(() => {
      getSystemAlertConfig();
   }, []);

   return (
      <AlertDefaultSC>
         <HeaderText>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={3}>
                  All User Alerts
               </FlexboxGrid.Item>
               {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                  <>
                     <FlexboxGrid.Item colspan={3}>
                        Hide Avante Users
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={1}>
                        <Checkbox style={{display: 'inline-block'}} checked={hideAvante} onChange={handleHide}/>
                     </FlexboxGrid.Item>
                  </> 
               : null
               }
            </FlexboxGrid>
         </HeaderText>

         <MainDivider/>
         {defaultConfigs.filter(config => config.user_id === 'default' && (config.pg_table.toLowerCase().includes('log') || config.pg_table.toLowerCase().includes('hhm'))).map(def => (
            <>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={24} style={{backgroundColor: 'rgb(217, 217, 217)', padding: 5,  borderRadius: 5, border: '1px solid rgb(229, 229, 234)'}}>
                     <Tag color="blue">DEFAULT</Tag> {def.field_name_alias}
                  </FlexboxGrid.Item>
               </FlexboxGrid>

               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={4}  align='center'>
                        USER
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={7}  align='center'>
                        FIELD NAME
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        SEVERITY
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        FREQUENCY
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        ENABLED
                     </FlexboxGrid.Item>
                     {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                        <>
                           <FlexboxGrid.Item colspan={4}  align='center'>
                              UPDATED BY
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              UPDATED
                           </FlexboxGrid.Item>
                        </> 
                     : null
                     }
                  </FlexboxGrid>
               </ListHeader>

               <List size="sm">
                  {displayAlertConfigs.filter(conf => conf.default_id === def.id).map(config => (
                     <List.Item key={config.id}>
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={4}>
                              {config?.user_id}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={7}  align='center'>
                              {config?.field_name_alias}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <b>{config.notify_frequency ? 
                                 config.notify_frequency
                                 : defaultConfigs.find(def => def.id === config.default_id)?.notify_frequency 
                              }</b> hours
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2} align='center'>
                              <div>
                                 {userRoles.includes('superuser') || userRoles.includes('alerter') || userRoles.includes('admin') ?
                                    <Toggle defaultChecked={config.enabled} onClick={() => {handleEnabled(config.id)}}/>
                                    : null
                                 }   
                              </div>
                           </FlexboxGrid.Item>

                           {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                           <>
                              <FlexboxGrid.Item colspan={4} align='center'>
                                 {config?.last_updated_by}
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={3} align='center'>
                                 {DateTime.fromISO(config?.last_update).toFormat('MMM dd, yyyy HH:mm')}
                              </FlexboxGrid.Item>
                           </>
                           : null
                           }
                        </FlexboxGrid>
                     </List.Item>
                  ))}
               </List>
            </>
         ))       
         }         
      </AlertDefaultSC>
   );
};

const AlertDefaultSC = styled.div`
   width: 85vw;
`;

const HeaderText = styled.div `
   width: 100%;
   font-size: 1.3em;

   display: inline-block;

   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;