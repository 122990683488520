import React, { useEffect } from 'react';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';

export const RecentActivity = (props) => { 


   useEffect(() => {
      
   }, [props]);

   return (
      <SettingBody>
         <SettingHeader>
            Recent Activity
         </SettingHeader>

         <MainDivider/>
                          
      </SettingBody>
   );
};