import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getChartTitle } from './getChartTitle';
import { Radio, RadioGroup } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export const ChartSelectorFC = ({ displayStatus, setDisplayStatus }) => {
   // console.log(`ChartSelectorFC`);
   const [expanded, setExpanded] = useState(false);
   const [all, setAll] = useState(true);
   const [localDisplayStatus, setLocalDisplayStatus] = useState(displayStatus);
   const options = Object.keys(displayStatus);

   // THIS IS NEEDED SO THE SELECTOR UI ACCURATELY REFLECTS SELECTED
   // CHARTS WHEN A USER HIDES A CHART WITH THE ON CHART HIDE BUTTON
   useEffect(() => {
      setLocalDisplayStatus(displayStatus);
   }, [displayStatus]);

   const handleExpand = () => {
      // RESET THE LOCAL STATUS B/C USER CLOSED SELECT AFTER
      // POSSIBLY MAKING CHANGES WHICH THEY DID NOT APPLY
      if (expanded) {
         setLocalDisplayStatus(displayStatus);
      }
      setExpanded(!expanded);
   };

   const handleLocalSelect = (option) => {
      setAll(null);
      const stateClone = { ...localDisplayStatus };
      stateClone[option] = !stateClone[option];
      setLocalDisplayStatus(stateClone);
   };

   const handleApply = () => {
      setExpanded(false);
      setDisplayStatus(localDisplayStatus);
   };

   const handleSelection = (option) => {
      setAll(option);
      const stateClone = { ...localDisplayStatus };

      // THIS FEELS ICKY
      for (var prop in displayStatus) {
         if (stateClone.hasOwnProperty(prop)) {
            stateClone[prop] = option;
         }
      }

      setLocalDisplayStatus(stateClone);

   }

   return (
      <ChartSelectorSC>
         <OptionsHeaderSC onClick={handleExpand}>SELECT CHARTS</OptionsHeaderSC>
         {expanded ? (
            <>
               <RadioGroup
                  inline appearance="picker"
                  name="selection"
                  value={all}
                  onChange={handleSelection}
               >
                  <span style={styles.radioGroupLabel}>Select: </span>
                  <Radio value={true}>All</Radio>
                  <Radio value={false}>None</Radio>
               </RadioGroup>

               <hr />
               {options.map((option) => (
                  <OptionSC
                     key={option}
                     onClick={() => {
                        handleLocalSelect(option);
                     }}
                     isSelected={localDisplayStatus[option]}
                  >
                     {getChartTitle(option)}
                  </OptionSC>
               ))}
               <ApplySC onClick={handleApply}>APPLY</ApplySC>
            </>
         ) : null}
      </ChartSelectorSC>
   );
};

const ChartSelectorSC = styled.div`
   background-color: white;
   padding: 0.25rem;
   text-align: center;
   border-radius: 3px;
   box-shadow: 0px 5px 8px lightgrey;
   width: 15vw;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   z-index: 1;
`;

const OptionsHeaderSC = styled.div`
   width: 100%;
   padding: 0.25rem 0;
   user-select: none;
   cursor: pointer;
`;

const OptionSC = styled.div`
   width: 100%;
   margin: 0.25rem 0;
   padding: 0.25rem 0;
   color: ${({ theme, isSelected }) =>
      isSelected ? theme.avanteBlue : 'black'};
   background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.avanteBlueVeryLight : 'white'};
   border-radius: 3px;
   text-align: left;
   text-indent: 0.25rem;
   user-select: none;
   :hover {
      background: lightgrey;
   }
`;

const ApplySC = styled.button`
   width: 100%;
   margin: 0.25rem 0;
   padding: 0.75rem 0;
   color: white;
   background-color: ${({ theme }) => theme.avanteBlue};
   border: none;
   border-radius: 3px;
   cursor: pointer;
`;

const styles = {
   radioGroupLabel: {
     width: '100%',
     padding: '8px 2px 8px 10px',
     display: 'inline-block',
     verticalAlign: 'middle'
   }
 };
 