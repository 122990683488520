import { DateTime } from 'luxon';
import React, { useEffect, useState, useRef } from 'react';
import { Checkbox, CheckboxGroup, IconButton, Input } from 'rsuite';
import styled from 'styled-components';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import { KnowledgeInfoFC } from './KnowledgeInfoFC';

export const LogTableFC = ({dataTable, dataConfig, metadata}) => {
   const [searchText, setSearchText] = useState([{dataKey: ""}]);
   const [displayData, setDisplayData] = useState(dataTable.dataTable);
   const [quickFilters, setQuickFilters] = useState([]);
   const [enabledQuickFilters, setEnabledQuickFilters] = useState([]); 

   let data = dataTable.dataTable;
   let tableConfig = dataTable.dataConfig;
   let columns = tableConfig.length;
   let columnWidth = 125;
   const rowHeight = 40;
   const visibleRowCount = 25;
   const [scrollTop, setScrollTop] = useState(0);
   const containerRef = useRef(null);
   const totalHeight = displayData?.length * rowHeight;
   const startRow = Math.floor(scrollTop / rowHeight);
   const endRow = startRow + visibleRowCount;
   const visibleData = displayData.slice(startRow, endRow);

   const handleScroll = (e) => {
      setScrollTop(e.currentTarget.scrollTop);
   };

   const [columnsVisibility, setColumnsVisibility] = useState(
      tableConfig.reduce((acc, column) => {
         acc[column[0]] = true;
         return acc;
   }, {}));

   const toggleColumnVisibility = (columnName) => {
      setColumnsVisibility(prevVisibility => ({
        ...prevVisibility,
        [columnName]: !prevVisibility[columnName]
      }));
   };

   const unhideAllColumns = () => {
      setColumnsVisibility(tableConfig.reduce((acc, column) => {
         acc[column[0]] = true;
         return acc;
      }, {}));
   };

   const [columnWidths, setColumnWidths] = useState(
      tableConfig.reduce((acc, column) => {
        acc[column[0]] = parseInt(column[2]);
        return acc;
      }, {})
   );

   const [isResizing, setIsResizing] = useState(false);
   const [resizingColumn, setResizingColumn] = useState(null);

   const handleMouseDown = (columnName) => {
      setIsResizing(true);
      setResizingColumn(columnName);
   };

   const handleMouseUp = () => {
      setIsResizing(false);
      setResizingColumn(null);
   };

   const handleMouseMove = (e) => {
      if (isResizing && resizingColumn) {
         const newWidth = columnWidths[resizingColumn] + e.movementX;
         setColumnWidths(prev => ({
            ...prev,
            [resizingColumn]: newWidth > 50 ? newWidth : 50 // Minimum width constraint
         }));
      }
   };

   const [expandedRows, setExpandedRows] = useState({});

   const toggleRowExpansion = (rowIndex) => {
      setExpandedRows(prev => ({ ...prev, [rowIndex]: !prev[rowIndex] }));
   };

   const handleSearchAll = (value) => {
      let tempData = data;

      let filteredData = tempData.filter((item) => {
         return Object.values(item).some((field) =>
            String(field).toLowerCase().includes(value.toLowerCase())
         );
      });

      if (quickFilters) {
         filteredData = filteredData?.filter((item) => {
            return enabledQuickFilters.includes(item['error_type'] || item['err_type']|| item['host_state'] || item['event_type'])
         });
      }
   
      setDisplayData(filteredData);
   }

   const handleQuickFilters = (e) => {
      setEnabledQuickFilters(e);
      let tempData = data;

      const filteredData = tempData?.filter((item) => {
         return e.includes(item['error_type'] || item['err_type'] || item['host_state'] || item['event_type'])
      });

      setDisplayData(filteredData);
   }

   useEffect(() => {
      setQuickFilters([...new Set(data?.map(row => row?.error_type || row?.host_state || row?.event_type || row?.err_type))])
      setEnabledQuickFilters([...new Set(data?.map(row => row?.error_type || row?.host_state || row?.event_type || row?.err_type))])
   }, [dataTable]);

   useEffect(() => {
      
   }, [dataTable, visibleData, enabledQuickFilters]);

   useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isResizing, resizingColumn, columnWidths]);
   
   return (
      <TableSC minWidth={(columnWidth * columns.length) + 250}>
         <button onClick={unhideAllColumns}>Unhide All Columns</button>
         {/* Search All Input Box */}
         <Input placeholder='Search all...' style={{marginBottom: '10px'}} onChange={handleSearchAll}/>
         <div style={{display: 'inline-flex', width:'100%'}}>
            <div>Showing: {displayData.length} / {data.length} rows</div>
            <div style={{paddingLeft: '20px'}}> 
               {quickFilters.length > 1 ?
               <div>
                  Quick Filters:
                  <CheckboxGroup inline name="checkboxList" style={{display: 'inline-flex'}} value={enabledQuickFilters} onChange={handleQuickFilters}>
                     {quickFilters.map(filter => (
                        filter ?
                        <Checkbox value={filter}>{filter}</Checkbox>
                        : <></>
                     ))}
                  </CheckboxGroup> 
               </div>  
               : null
               }
                          
            </div>
         </div>
         
         <Table >
            {/* Table Body */}
            <div
               className="TableContainer"
               ref={containerRef}
               onScroll={handleScroll}
               style={{
                  height: `58vh`,
                  overflowY: 'auto',
               }}
            >
               {/* Column Headers */}
               <TableHeader>
                  {tableConfig.map(column => (
                     columnsVisibility[column[0]] && (
                        column[0] === 'host_datetime' || column[0] === 'capture_datetime' ?
                        <CellHeader key={column[0]} columnWidth={columnWidths[column[0]]}>
                              {column[1]} 
                        </CellHeader>
                        : 
                        <CellHeader key={column[0]} columnWidth={columnWidths[column[0]]}>
                           <div style={{ cursor: 'ew-resize', display: 'block', width: columnWidths[column[0]]}}onMouseDown={() => handleMouseDown(column[0])}>
                              {column[1]} 
                              <IconButton icon={<UnvisibleIcon />} circle size="xs" onClick={() => toggleColumnVisibility(column[0])}/>
                           </div>
                        </CellHeader>
                     )
                  ))}
               </TableHeader>
               <div
                  style={{
                  height: `${totalHeight}px`,
                  position: 'relative'
                  }}
               >
                  {visibleData.map((row, rowIndex) => (
                     <div
                        className={`MainRow ${expandedRows[rowIndex] ? 'expanded' : ''}`}
                        onDoubleClick={() => toggleRowExpansion(rowIndex)}
                        style={{
                           position: 'absolute',
                           top: `${(startRow + rowIndex) * rowHeight}px`,
                           minHeight: `${rowHeight}px`,
                           display: 'flex',
                           backgroundColor: `${row.error_type?.toLowerCase() === 'error' || row.event_type?.toLowerCase() === 'e' ||  row.err_type?.toLowerCase() === 'error' ? '#FF9494'
                                             : row.error_type?.toLowerCase() === 'warning' || row.event_type?.toLowerCase() === 'w' ||  row.err_type?.toLowerCase() === 'warn' ? '#FFFF94'
                                             : row.error_type?.toLowerCase() === 'severe' || row.event_type?.toLowerCase() === 's' ? '#9F2B68' 
                                             : 'white'}`,
                           color: `${row.error_type?.toLowerCase() === 'error' ||row.event_type?.toLowerCase() === 'e' ? '' 
                                   : row.error_type?.toLowerCase() === 'warning' ||row.event_type?.toLowerCase() === 'w' ? '' 
                                   : row.error_type?.toLowerCase() === 'severe' || row.event_type?.toLowerCase() === 's'  ? 'white' 
                                   : ''}`
                        }}
                   >
                     {tableConfig.map((column, colIndex) => (
                        columnsVisibility[column[0]] && (  // Only render the cell if the column is visible
                        column[0] === 'host_datetime' || column[0] === 'capture_datetime' ?
                           <Cell key={`datetime-${rowIndex}`} columnWidth={column[2]}>
                              {DateTime.fromISO(row?.host_datetime).toFormat('ff')}
                           </Cell>
                        : column[0] === 'kdb' ?
                           row['kdb'] ?
                           <Cell key={`${column[0]}=${rowIndex}-${colIndex}`} columnWidth={columnWidths[column[0]]}>
                              <KnowledgeInfoFC data={row} metadata={metadata}/>
                           </Cell>
                           : 
                           <Cell key={`${column[0]}=${rowIndex}-${colIndex}`} columnWidth={columnWidths[column[0]]}>
                           
                           </Cell>
                        :
                        <Cell className='Cell' key={`${column[0]}=${rowIndex}-${colIndex}`} columnWidth={columnWidths[column[0]]}>
                           {row[column[0]]}
                        </Cell>
                        )
                     ))}
                   </div>
                  ))}
               </div>
            </div>
         </Table>
      </TableSC>
   )
};

const TableSC  = styled.div`
   width: 90vw;
   overflow-x: auto;
   font-family: Source Sans Pro;
   font-size: 12px;
   padding-top: 10px;

   .MainRow:hover > div {
      background-color: rgba(0, 0, 0, 0.1);
   }

   .MainRow {
      overflow: hidden;
      transition: height 0.3s ease;
   }

   .MainRow.expanded {
      height: auto !important;
      background-color: #f6f9fc;
      z-index: 1;
   }
    
   .MainRow.expanded .Cell {
      height: auto;
      white-space: normal;
      overflow: visible;
      z-index: 100;
   }

   .TableContainer {
      padding-top: 10px;  /* This value should be the height of your header */
   }
`

const Table  = styled.div`
   width: 90vw
`

const TableHeader = styled.div`
   display: inline-flex;
   border: 1px solid #eceff5;
   background-color: #f6f9fc;
   padding-top: 10px;
   padding-bottom: 10px;
   position: sticky;
   top: 0;
   z-index: 1000;  /* Adjust if needed to ensure header is on top */
`;

const Cell = styled.div`
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   padding-left: 10px;
   border-bottom: 1px solid #f2f2f5;
   padding-top: 10px;
   padding-bottom: 10px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
`
const CellHeader = styled.div`
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   padding-left: 10px;
   padding-top: 4px;
   padding-bottom: 4px;
   border-right: 1px solid #e5e5ea;
`

const ErrorType = styled.div`
   background-color: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
   border-radius: 6px;
   color: ${({ color }) => color ? color : 'black'};
   text-align: center;
   font-weight: bolder;
`