import React, { useState, useEffect } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { Nav, IconButton, Button, List, FlexboxGrid, Toggle, ButtonGroup, Checkbox, CheckboxGroup, Message, useToaster } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import { SectionTitleFC } from '../../_helpers/SectionTitleFC';
import { useHistory, useParams } from 'react-router-dom';
import { MainDivider, SettingBody, SettingHeader, SubHeader } from '../helpers/Admin_StyledComponents';
import SettingIcon from '@rsuite/icons/legacy/Setting';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import VisibleIcon from '@rsuite/icons/Visible';
import { SystemSummaryFC } from './SystemSummaryFC';
import { OverrideSystem } from './Modal/OverrideSystem';
import { GenerateAcquisition } from './_helpers/GenerateAcquisition';
import { GenerateConfigEDU } from './_helpers/GenerateConfigEDU';
import { GenerateConfigMag } from './_helpers/GenerateConfigMag';
import { GenerateConfigLog } from './_helpers/GenerateConfigLog';

export const _NetSystemsFC = (props) => {
   // renderCount(`AdminFC`);
   let history = useHistory();
   const { getAccessTokenSilently } = useAuth0();
   const [system, setSystem] = useState();
   const [visibility, setVisibility] = useState();
   const [processLog, setProcessLog] = useState();
   const [processMag, setProcessMag] = useState();
   const [processEDU, setProcessEDU] = useState();
   const [acumatica, setAcumatica] = useState();
   const [loadingSystemData, setLoadingSystemData] = useState([]);

   // NORMALIZED CONFIGS
   const [configAcquisition, setConfigAcquisition] = useState();
   const [configEDU, setConfigEDU] = useState();
   const [configMag, setConfigMag] = useState();
   const [configLog, setConfigLog] = useState();
   const [regexModels, setRegexModels] = useState();

   const [active, setActive] = useState('Acumatica');
   const { id } = useParams();
   const [disable, setDisable] = useState(true);
   const [value, setValue] = React.useState([]);
   const handleChange = value => setValue(value);
   const [resMsg, setResMsg] = useState("");
   const toaster = useToaster();

   const getSystemData = async () => {
      //console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            url: `/network/system/${id}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setSystem(res?.data[0][0]);
         setAcumatica(res?.data[1][0]);
         setConfigAcquisition(res?.data[2][0]);
         setConfigEDU(res?.data[3][0]);
         setConfigMag(res?.data[4]);
         setConfigLog(res?.data[5]);
         console.log(res?.data[5]);
      } catch (error) {
         console.error(error);
      }
    
      setLoadingSystemData(false);
   };

   const getRegexModels = async () => {
      // console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            url: `/network/regex-models`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         
         setRegexModels(res.data[0]);
      } catch (error) {
         console.error(error);
      }
   };

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated this System.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   const handlePageChange = (e) => {
      setActive(e);
      resetButton();
   }

   const checkButtonStatus = () => {
      if (value.length > 0) {
         setDisable(false)
      } else {
         setDisable(true)
      }
   }

   const resetButton = () => {
      setValue([]);
   }

   const visibilityToggle = () => {
      setVisibility(system?.show_on_website)
   }

   const handleToggle = async () => {
      setVisibility(!visibility);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/network/update/system`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               sme: id,
               updateFields: ['show_on_website']
            }),
         });

         setResMsg(response.data);
         toaster.push(message,'topCenter');
         getSystemData();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      // setLoading(false);
   }

   const onSubmit = async () => {
      // setLoading(true);
      // console.log(`updateSystem`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/network/update/system`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               sme: id,
               updateFields: value
            }),
         });

         setResMsg(response.data);
         toaster.push(message,'topCenter');
         getSystemData();
         resetButton();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      // setLoading(false);
   }

   const handleEDUToggle = async () => {
      setProcessEDU(!processEDU);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'PUT',
            url: `/network/update/process-fields`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               sme: id,
               updateField: 'edu'
            }),
         });

         setResMsg(response.data);
         toaster.push(message,'topCenter');
         getSystemData();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      // setLoading(false);
   }

   const handleLogToggle = async () => {
      setProcessLog(!processLog);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'PUT',
            url: `/network/update/process-fields`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               sme: id,
               updateField: 'log'
            }),
         });

         setResMsg(response.data);
         toaster.push(message,'topCenter');
         getSystemData();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      // setLoading(false);
   }

   const handleMagToggle = async () => {
      setProcessMag(!processMag);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'PUT',
            url: `/network/update/process-fields`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               sme: id,
               updateField: 'mag'
            }),
         });

         setResMsg(response.data);
         toaster.push(message,'topCenter');
         getSystemData();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      // setLoading(false);
   }

   useEffect(() => {
      getSystemData();
      getRegexModels();
   }, []);

   useEffect(() => {
      visibilityToggle();
      setProcessEDU(system?.process_edu);
      setProcessMag(system?.process_mag);
      setProcessLog(system?.process_log);
   }, [system]);

   useEffect(() => {
      checkButtonStatus();
   }, [value]);

   return (
      <>
         <SectionTitleFC title={'NET ADMIN'} />

         <BackButton>
            <IconButton icon={<ArowBackIcon/>} onClick={() => history.goBack()}>Back</IconButton>
         </BackButton>

         {system ? 
            <_CustomerSC>
               <CustomerHeader>      
                  <CustomerInfo>
                     <UserName>
                        {system.id ? system.id : '{No System ID}'}
                     </UserName>
                     
                     <HeaderSubText>
                        {acumatica?.customeruniqueid ? acumatica.customeruniqueid : '{No System Client ID}'}
                     </HeaderSubText>  
                  </CustomerInfo>                                   
               </CustomerHeader> 

               <NavigationPane>
                  <Header>
                     <SettingIcon/>Settings
                  </Header>

                  <Nav appearance="subtle" reversed vertical activeKey={active} onSelect={handlePageChange} style={{ width: '100%', marginTop: '5%'}}>
                     <Nav.Item eventKey="Summary">Summary</Nav.Item>
                     <Nav.Item eventKey="Acumatica">Acumatica</Nav.Item>
                     <Nav.Item eventKey="Processing">Processing</Nav.Item>
                     <Nav.Item eventKey="Acquisition">Acquisition</Nav.Item>
                     <Nav.Item eventKey="Configs">Configs</Nav.Item>
                     <Nav.Item eventKey="Change Logs" disabled>Change Logs</Nav.Item>
                  </Nav>

                  <VisbilityPane>
                     <Header>
                        <VisibleIcon/>Visibility
                     </Header>
                     Clients: <Toggle checked={visibility} checkedChildren="Visible" unCheckedChildren="Visible" onChange={handleToggle}/>
                  </VisbilityPane>

               </NavigationPane>

               <SettingPane>
                  <SettingBody>
                  { active === 'Summary' ?
                     <SystemSummaryFC system={system} />
                  : active === 'Acumatica' ?
                  <>
                     <SettingHeader>
                        Acumatica <OverrideSystem system={system}/>
                     </SettingHeader>

                     <MainDivider/>
                     <SettingBox>
                        <CheckboxGroup name="checkboxList" value={value} onChange={handleChange}>

                           <FlexboxGrid>
                              <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                 
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                 Current
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                 Acumatica
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                 Update 
                              </FlexboxGrid.Item>
                           </FlexboxGrid>
                           <List style={{marginBottom: '2%'}}>
                           <List.Item key={'customeruniqueid'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.customeruniqueid ? null : system?.cus_sys_id !== acumatica?.customeruniqueid ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} CUID
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.cus_sys_id}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.customeruniqueid}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.customeruniqueid ? null : system?.cus_sys_id !== acumatica?.customeruniqueid ?
                                       <Checkbox key={'cb_CUID'} value={'cus_sys_id'} />
                                    : 
                                       <Checkbox key={'cb_CUID'} value={'cus_sys_id'} disabled={true}/>
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'customer'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.customercontractcustomerid ? null : (system?.customer_id !== acumatica?.customercontractcustomerid ||
                                       system?.customer_name !== acumatica?.customercontractcustomername) ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Customer
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.customer_name}
                                    <ItemSubText>{system.customer_id}</ItemSubText>
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.customercontractcustomername}
                                    <ItemSubText>{acumatica?.customercontractcustomerid}</ItemSubText>
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.customercontractcustomerid ? null : (system?.customer_id !== acumatica?.customercontractcustomerid ||
                                       system?.customer_name !== acumatica?.customercontractcustomername) ?
                                       <Checkbox key={'cb_customer_id'} value={'customer'} />
                                    : 
                                       <Checkbox key={'cb_customer_id'} value={'customer'} disabled={true} />
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'site'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.servicecontractcustomerid ? null : (system?.site_id !== acumatica?.servicecontractcustomerid || 
                                       system?.site_name !== acumatica?.servicecontractcustomername ) ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Site
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.site_name}
                                    <ItemSubText>{system?.site_id}</ItemSubText>
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.servicecontractcustomername}
                                    <ItemSubText>{acumatica?.servicecontractcustomerid}</ItemSubText>
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.servicecontractcustomerid ? null : (system?.site_id !== acumatica?.servicecontractcustomerid || 
                                       system?.site_name !== acumatica?.servicecontractcustomername) ?
                                       <Checkbox key={'cb_site_id'} value={'site'} />
                                    : 
                                       <Checkbox key={'cb_site_id'} value={'site'} disabled={true} />
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'room'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.room ? null : system?.room !== acumatica?.room ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Room
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.room}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.room}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.room ? null : system?.room !== acumatica?.room ?
                                       <Checkbox key={'cb_room'} value={'room'} />
                                    : 
                                       <Checkbox key={'cb_room'} value={'room'} disabled={true} />
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'manufacturer'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.manufacturer ? null : acumatica?.manufacturer.split().includes(system?.manufacturer) ?
                                       <RemindRoundIcon  color={'#ffcc00'}/> 
                                       : null
                                    }
                                    {' '} Manfacturer
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.manufacturer}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.manufacturer}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.manufacturer ? null : acumatica?.manufacturer.split().includes(system?.manufacturer)?
                                       <Checkbox key={'cb_manufacturer'} value={'manufacturer'} />
                                    : 
                                       <Checkbox key={'cb_manufacturer'} value={'manufacturer'} disabled={true} />
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'modality'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.modality ? null : system?.modality !== acumatica?.modality ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Modality
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.modality}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.modality}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.modality ? null : system?.modality !== acumatica?.modality ?
                                       <Checkbox key={'cb_modality'} value={'modality'} />
                                    : 
                                       <Checkbox key={'cb_modality'} value={'modality'} disabled={true}/>
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'{model: model}'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.model ? null : system?.model !== acumatica?.model ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Model
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.model}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.model}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.model ? null : system?.model !== acumatica?.model ?
                                       <Checkbox key={'cb_model'} value={'model'} />
                                    : 
                                       <Checkbox key={'cb_model'} value={'model'} disabled={true}/>
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           <List.Item key={'serialnbr'} index={1} size={'md'}>
                              <FlexboxGrid>
                                 <FlexboxGrid.Item colspan={4}>
                                    {!acumatica?.serialnbr ? null : system?.serial_number !== acumatica?.serialnbr ?
                                       <RemindRoundIcon  color={'#ffcc00'}/>
                                       : null
                                    }
                                    {' '} Serial Number
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {system.serial_number}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    {acumatica?.serialnbr}
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {!acumatica?.serialnbr ? null : system?.serial_number !== acumatica?.serialnbr ?
                                       <Checkbox key={'cb_serial_number'} value={'serial_number'} />
                                    : 
                                       <Checkbox key={'cb_serial_number'} value={'serial_number'} disabled={true} />
                                    }
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           </List.Item>
                           </List>  
                        </CheckboxGroup>                                  
                     </SettingBox>
                     
                     <ButtonBar>
                        <ButtonGroup>
                           <Button appearance="primary" onClick={onSubmit} disabled={disable} loading={false}>Submit</Button>
                           <Button appearance="default" onClick={resetButton} disabled={disable}>Reset</Button>
                        </ButtonGroup> 
                     </ButtonBar>             
                  </>
                  : active ==='Processing' ?
                  <>
                     <SettingHeader>
                        Process Fields
                     </SettingHeader>

                     <MainDivider/>

                     <ItemSubText>
                        <Checkbox key={'process_log'} checked={processLog} onClick={() => setProcessLog(handleLogToggle)} disabled={processLog !== null ? false : true}></Checkbox> Log File
                     </ItemSubText>

                     <MainDivider/>

                     <ItemSubText>
                        <Checkbox key={'process_mag'} checked={processMag} onClick={() => setProcessMag(handleMagToggle)} disabled={processMag !== null  ? false : true}></Checkbox> Magnet File
                     </ItemSubText>

                     <MainDivider/>

                     <ItemSubText>
                        <Checkbox key={'process_edu'} checked={processEDU} onClick={() => setProcessEDU(handleEDUToggle)} disabled={processEDU !== null  ? false : true}></Checkbox> EDU 
                     </ItemSubText>

                     <MainDivider/>
                  </>
                  : active === 'Acquisition' ?
                     <GenerateAcquisition acquisition_config={configAcquisition}/>

                  : active === 'Configs' ?
                  <>
                     {system.process_edu ? 
                        <GenerateConfigEDU config={configEDU} regex_models={regexModels}/>
                     : null
                     }

                     {system.process_mag ? 
                        <GenerateConfigMag configs={configMag} system={system} regex_models={regexModels}/>
                     : null
                     }

                     {system.process_log ? 
                        <GenerateConfigLog configs={configLog} system={system} regex_models={regexModels}/>
                     : null
                     }
                  </>
                  : null
                  }
                  </SettingBody> 
               </SettingPane>                            
            </_CustomerSC> 
         : null
         }
      </>
        
   );
};

const _CustomerSC = styled.div`
   width: 100%;

   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
`;

const SettingBox = styled.div`
   padding-top: 1%;
   width: 100%;
`;

const Header = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   padding-left: 2.5%;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const CustomerHeader = styled.div`
   display: block;
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   width: 100%;
`;

const CustomerInfo = styled.div`
   padding-top: 1%;
   width: 100%;
`;

const NavigationPane = styled.div`
   float: left;
   width: 20%;
   padding-left: 2.5%;
`;

const SettingPane = styled.div`
   float: right;
   width: 80%;
`;

const UserName = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   text-align: center;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const ItemSubText = styled.div`
   font-size: 1em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const HeaderSubText = styled.div`
   font-size: 1em;
   color: #97969B;
   font-weight: normal;
   text-align: center;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const BackButton = styled.div`
   position: absolute;
   left: 0;
   padding-top: 5%;
   padding-left: 5%;
   margin-right: auto;
`

const styleCenter = {
   justifyContent: 'center',
   alignItems: 'center',
   height: '30px'
 };

 const ButtonBar = styled.div`
   padding-top: 5vh;
   display: block;
   width: 100%;
`
 
const VisbilityPane = styled.div`
   padding-top: 10%;
   padding-bottom: 1%;
`