import React, { useContext, useEffect, useState } from 'react';
import VisibleIcon from '@rsuite/icons/Visible';
import FunnelIcon from '@rsuite/icons/Funnel';
import styled from 'styled-components';
import { FlexboxGrid, CheckPicker, Toggle } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DarkModeContext } from '../../../../../../../appContexts/DarkModeContext';
import { DateTime, Interval } from 'luxon';
import { RefreshFC } from '../dashboardWidgets/RefreshFC';
import { InputContext } from '../../../../_context/InputContext';
import { UserContext } from '../../../../_context/UserContext';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import { ExportFC } from '../../../../_helpers/fusionCharts/ExportFC';
import { ExportEDU } from '../../../../_helpers/export/ExportEDU';

export const _FilterEquipmentFC = ({machines, displayMachines, setDisplayMachines, recent_data}) => {
   const { darkMode } = useContext(DarkModeContext);

   const {
      userRoles
   } = useContext(UserContext);

   const {
      viewToggle,
      customerToggle,
      siteToggle,
      manufacturerToggle,
      modalityToggle,
      modelToggle,
      setCustomerToggle,
      setSiteToggle,
      setManufacturerToggle,
      setModalityToggle,
      setModelToggle,
      systemCache,
      setSystemCache
   } = useContext(InputContext);

   // Equipment Filtering
   const [toggle, setToggle] = useState(false);
   const [listSites, setListSites] = useState([]);
   const [listCustomers, setListCustomers] = useState([]);
   const [listManufacturers, setListManufacturers] = useState([]);
   const [listModalities, setListModalities] = useState([]);
   const [listModels, setListModels] = useState([]);

   const setFilters = () => {
      setListCustomers([...new Set(machines.map(item => item.customer_name))].sort());
      setListManufacturers([...new Set(machines.map(item => item.manufacturer))].sort());
      setListModalities([...new Set(machines.map(item => item.modality))].sort());
      setListModels([...new Set(machines.map(item => item.model))].sort());

      if([...new Set(machines.map(item => item.customer_name))].length === 1) {
         setListSites([...new Set(machines.map(item => item.site_name))].sort());
      }
   }

   const setFilteredMachines = () => {  
      if (customerToggle.length === 1) {
         setListSites([...new Set(machines.filter(system => (customerToggle.includes(system.customer_name))).map(item => item.site_name))].sort());
      }

      let tempMachines = machines.filter(machine =>
        (customerToggle.length === 0 || customerToggle.includes(machine.customer_name)) &&
        (((siteToggle.length === 0) && !siteToggle.includes(machine.site_name)) || siteToggle.includes(machine.site_name)) &&
        (manufacturerToggle.length === 0 || manufacturerToggle.includes(machine.manufacturer)) &&
        (modalityToggle.length === 0 || modalityToggle.includes(machine.modality)) &&
        (modelToggle.length === 0 || modelToggle.includes(machine.model)) &&
        (toggle && (viewToggle === 'MMB') ? (
         (Interval.fromDateTimes(DateTime.fromISO(machine.capture_datetime), DateTime.now()).length('hours') > 6 && machine.process_mag) 
         || (Interval.fromDateTimes(DateTime.fromISO(machine.edu_datetime), DateTime.now()).length('hours') > 6 && machine.process_edu) 
         || ((machine.capture_datetime === 'undefined' || machine.capture_datetime === null) 
            && (machine.edu_datetime === 'undefined' || machine.edu_datetime === null)))
         : true) &&
         (toggle && viewToggle === 'HHM' ? (
            (Interval.fromDateTimes(DateTime.fromISO(machine.capture_datetime), DateTime.now()).length('hours') > 24) 
            || (Interval.fromDateTimes(DateTime.fromISO(machine.edu_datetime), DateTime.now()).length('hours') > 24) 
            || ((machine.capture_datetime === 'undefined' || machine.capture_datetime === null) 
               && (machine.edu_datetime === 'undefined' || machine.edu_datetime === null)))
            : true)
         &&
         (toggle && viewToggle === 'EDU' ? (
            (Interval.fromDateTimes(DateTime.fromISO(machine.edu_datetime), DateTime.now()).length('hours') > 6 && machine.process_edu) 
               || (( machine.capture_datetime === null) 
                  && (machine.edu_datetime === 'undefined')))
               : true)
      );
      setSystemCache(tempMachines.map(system => system.id));
      setDisplayMachines(tempMachines);
   };

   useEffect(() => {
      setFilters();
      setFilteredMachines();
   }, [machines]);

   useEffect(() => {
      setFilteredMachines();
   }, [customerToggle, siteToggle, manufacturerToggle, modalityToggle, modelToggle, toggle, recent_data]);

   return (
      <>
         {window.screen.width >= 768 ?
            <FilterEquipmentSC darkMode={darkMode}>
               <FlexboxGrid justify="start" style={{ alignItems: 'center', paddingBottom: '5px' }}>
                  <FlexboxGrid.Item colspan={3}>
                     <ItemSubText>Visible Equipment</ItemSubText>
                  </FlexboxGrid.Item>
                  {listCustomers.length > 1 ?
                     <FlexboxGrid.Item colspan={5}>
                        <ItemSubText>Filter Customers</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listCustomers.length === 1 ?
                     <FlexboxGrid.Item colspan={4}>
                        <ItemSubText>Filter Sites</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listManufacturers.length > 1 ?
                     <FlexboxGrid.Item colspan={4}>
                        <ItemSubText>Filter Manufacturers</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {modalityToggle.length > 0 || listModalities.length > 1 ?
                     <FlexboxGrid.Item colspan={3}>
                        <ItemSubText>Filter Modalities</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {(modelToggle.length > 0 || listModels.length > 1) && userRoles.includes('superuser') ?
                     <FlexboxGrid.Item colspan={3}>
                        <ItemSubText>Filter Models</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  <FlexboxGrid.Item colspan={2}>
                     <ItemSubText>Toggle Offline</ItemSubText>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={2}>
                     <ItemSubText>Refreshed Systems</ItemSubText>
                  </FlexboxGrid.Item>

                  {recent_data  ? 
                     <FlexboxGridItem colspan={2}>
                        <ItemSubText>Recent Parameters</ItemSubText>
                     </FlexboxGridItem>
                  : null}
               </FlexboxGrid>
               
               <FlexboxGrid justify="start" style={{ alignItems: 'center' }}>
                  <FlexboxGrid.Item colspan={3}>
                     <VisibleIcon style={{ fontSize: "2em", marginRight: "5px" }} /> {displayMachines?.length}/{machines?.length}
                  </FlexboxGrid.Item>
                  {listCustomers.length > 1 ?
                     <FlexboxGrid.Item colspan={5} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setCustomerToggle} value={customerToggle} data={listCustomers.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Customers'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listCustomers.length === 1 ?
                     <FlexboxGrid.Item colspan={4} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setSiteToggle} value={siteToggle} data={listSites.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Sites'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listManufacturers.length > 1 ?
                     <FlexboxGrid.Item colspan={4} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setManufacturerToggle} value={manufacturerToggle} data={listManufacturers.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Manufacturers'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {modalityToggle.length > 0 || listModalities.length > 1 ?
                     <FlexboxGrid.Item colspan={3} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setModalityToggle} value={modalityToggle} data={listModalities.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Modalities'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {(modelToggle.length > 0 || listModels.length > 1) && userRoles.includes('superuser') ?
                     <FlexboxGrid.Item colspan={3} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setModelToggle} value={modelToggle} data={listModels.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Models'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  <FlexboxGrid.Item colspan={2} style={{ paddingRight: '0.5rem' }}>
                     <Toggle size="md" checkedChildren="OFFLINE" unCheckedChildren="OFFLINE" onChange={setToggle}/>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={2} style={{ paddingRight: '0.5rem' }}>
                     <RefreshFC/>
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item colspan={2} style={{ paddingRight: '0.5rem' }}>
                     {recent_data && viewToggle !== 'EDU' ?       
                        <ExportFC systems={machines} recent_data={recent_data}/>
                     : recent_data && viewToggle === 'EDU' ?
                        <ExportEDU systems={machines} recent_data={recent_data}/>
                     : null
                     }
                  </FlexboxGrid.Item>
               </FlexboxGrid>
            </FilterEquipmentSC>
            : null
         }
      </>
   );
};

const FilterEquipmentSC = styled.div`
    padding: 1%;
    width: 100%;
    box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
    display: block;

    .rs-picker {
        display: inline-flex;
        color: ${({ theme, darkMode }) => darkMode ? theme.dark_accent : theme.light_accent};
        border: solid 1px ${({ theme, darkMode }) => darkMode ? theme.dark_accent : theme.light_accent};
        border-radius: 6px;
        margin-left: 0;
        width: 15vw;

        @media screen and (max-width: 769px) {
            width: 80vw;
            margin-bottom: 0.5rem;
        }
    }
`;

const ItemSubText = styled.div`
    font-size: 0.75em;
    color: #97969B;
    font-weight: normal;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: normal;
    }
`;