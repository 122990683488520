import React, { useContext, useEffect, useState, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styled, { ThemeContext } from 'styled-components';
import { LoadingData } from './_helpers/LoadingData';
import { useParams } from 'react-router-dom';
import { MachineMetadataFC } from '../_helpers/displayFiles/MachineMetadataFC';
import { useAuth0 } from '@auth0/auth0-react';
import { _ChartIndex } from '../_helpers/charts/_ChartIndex';
import { _FusionGridIndex } from '../_helpers/fusionCharts/_FusionGridIndex';
import axiosInstance from '../../../../appHelpers/axiosInstance';
import { renderCount } from '../../../../appHelpers/appLoggers';
import { Loader_MachineMetadataFC } from '../_helpers/displayFiles/loaders/Loader_MachineMetadataFC';
import { FlexboxGrid, Message, Nav, Navbar  } from 'rsuite';
import { EventHistoryFC } from '../_helpers/displayFiles/EventHistoryFC';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InputContext } from '../_context/InputContext';
import PcIcon from '@rsuite/icons/Pc';
import { getMessage } from '../_utils/util_general';
import { UserContext } from '../_context/UserContext';
import { LogTableFC } from './_helpers/LogTableFC';
import { TabSelectorFC } from '../_helpers/TabSelectorFC';
import { LogAlertTableFC } from './_helpers/LogAlertTableFC';
import { Avatar } from '../../../../appHelpers/appCommonSC';
import { MainDivider } from '../admin/helpers/Admin_StyledComponents';
import { DateTime } from 'luxon';
import { FileHeader } from './FileHeader';

export const _HHMSystemFC = () => {
   renderCount('_MachineFC');
   const { getAccessTokenSilently } = useAuth0();
   const [selectedDataView, setSelectedDataView] = useState(`TABLE`);
   const dataViewOptions = [`TABLE`, `ALERTS`]; //`LOG`, When the time is right :P
   const { getUserProfile, user } = useContext(UserContext);
   // ADDED FIELDS
   const [loadingMetadata, setLoadingMetadata] = useState(true);
   const [loadingDataTable, setLoadingDataTable] = useState(true);
   const [loadingRecentFiles, setLoadingRecentFiles] = useState(true);
   const [metadata, setMetadata] = useState([]);
   const [recentFiles, setRecentFiles] = useState([]);
   const [dataTable, setDataTable] = useState([]);
   const [dataConfig, setDataConfig] = useState([]);
   const [getMetadataError, setGetMetadataError] = useState(false);
   const [getDataTableError, setGetDataTableError] = useState(false);
   const [getDataConfigError, setGetDataConfigError] = useState(false);
   const [getRecentFilesError, setGetRecentFilesError] = useState(false);
   const [level, setLevel] = useState(1);
   const { id, dataModel } = useParams();
   // NETWORK ISSUES
   const [loadingIssues, setLoadingIssues] = useState(true);
   const [issues, setIssues] = useState([]);

   // LOG ALERTS
   const [logAlerts, setLogAlerts] = useState([]);
   const [loadingLogAlerts, setLoadingLogAlerts] = useState(false);
   const [getLogAlertsError, setGetLogAlertsError] = useState(false);

   const history = useHistory();
   const [activeKey, setActiveKey] = useState("1");

   const {
      viewToggle,
      setViewToggle
   } = useContext(InputContext);

   const getMachineMetadata = async () => {
      // console.log(`getMachineMetadata`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/metadata/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });
         
         setMetadata(response.data[0][0]);
      } catch (error) {
         console.error(error);
         setGetMetadataError(true);
      }
      setLoadingMetadata(false);
   };

   const getMachineDataTable= async () => {
      // console.log(`getMachineDataTable`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/data/hhm/${id}/0`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         setDataTable(cloneDeep(response.data[0]));
      } catch (error) {
         setGetDataTableError(true);
         console.error(error);
      }
      setLoadingDataTable(false);
   };

   const getLogAlerts = async () => {
      // console.log(`getMachineDataTable`);
      setLoadingLogAlerts(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            method: 'get',
            url: `/alert/log/models/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         setLogAlerts(res.data[0]);
      } catch (error) {
         setGetLogAlertsError(true);
         console.error(error);
      }
      setLoadingLogAlerts(false);
   };

   const getNetworkIssues = async () => {
      setLoadingIssues(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
  
         const res = await axiosInstance({
            url: `/network/issues`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });
  
         setIssues(res?.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingIssues(false);
   };

   const getRecentFiles = async () => {
      setLoadingRecentFiles(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
  
         const res = await axiosInstance({
            url: `/system/recent-files/${id}/`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });
  
         setRecentFiles(res?.data[0][0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingRecentFiles(false);
   };

   useEffect(() => {
      getMachineMetadata();
      getMachineDataTable();
      getNetworkIssues();
      getRecentFiles();
      getLogAlerts();
      getUserProfile();
      setViewToggle('HHM');
   }, []);

   const memoMetadata = useMemo(
      () => <MachineMetadataFC metadata={metadata} />,
      [metadata]
   );

   const memoRecentFiles = useMemo(
      () => <FileHeader recent_files={recentFiles} />,
      [recentFiles]
   );

   const memoMagnetDataTable = useMemo(
      () => <LogTableFC dataTable={dataTable} dataConfig={dataConfig} filterLevel={level} metadata={metadata}/>,
      [dataTable, dataConfig, level]
   );

   const memoLogAlertsTable = useMemo(
      () => <LogAlertTableFC logAlerts={logAlerts} dataConfig={dataTable?.dataConfig} metadata={metadata} refresh={getLogAlerts}/>,
      [logAlerts, dataTable]
   );

   const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
      return (
         window.screen.width >= 768 ?
         <Navbar {...props} style={{width: '100%', marginBottom: '1%'}}>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>{} {user.first_name ? `GOOD ${getMessage()}, ${user.first_name?.toUpperCase()}!`: null} </Nav.Item>
            </Nav>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>|</Nav.Item>
            </Nav>
            <Nav onSelect={() => history.push(`/`)}>
               <Nav.Item eventKey="HHM" icon={<PcIcon />}>SYSTEMS</Nav.Item>
            </Nav>

             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}> / </Nav.Item>
            </Nav>

            <Nav onSelect={setViewToggle} activeKey={viewToggle}>
               <Nav.Item eventKey="HHM">
                  {id}
               </Nav.Item>
            </Nav>
            <Nav pullRight onSelect={() => history.push(`/admin/user/${user.email_address}`)}>
               <Nav.Item><Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar></Nav.Item>
            </Nav>
         </Navbar>    
         : null
      );
   };


   // CONTROLLING THE LOADING CONDITIONAL WORKS NICELY HERE
   // OTHERWISE, A CHECK IS NEEDED IN THE COMPONENT ITSELF FOR DESTRUCTORING
   // DATA FROM THE CONTEXT WHICH MAY OR MAY NOT HAVE LOADED YET
   const DataView = () => {
      switch (selectedDataView) {
         case `TABLE`:
            return (loadingDataTable)  ? <LoadingData /> : !getDataTableError && !getDataConfigError ? memoMagnetDataTable : <>Something Went Wrong</>;
         case `ALERTS`:
         return (loadingLogAlerts || loadingDataTable || loadingMetadata)  ? <LoadingData /> : !getLogAlertsError ? memoLogAlertsTable : <>Something Went Wrong</>; 
         default:
            return <div>NON-CONFORMANT STATE: RemoteFilesFC:DataView</div>;
      }
   };

   return (
      <>
         {/* Bread Crumb Nav */}
         <CustomNavbar  appearance='subtle' activeKey={activeKey} onSelect={setActiveKey} />

         {loadingMetadata ? <Loader_MachineMetadataFC/> : memoMetadata}

         {issues?.filter(issue => issue.id === metadata.id).length > 0 ?  
            issues?.filter(issue => issue.id === metadata.id).map(issue => (
               (!issue.resolved && issue.status === 'active') && issue.processor_type ==='HHM' ?
               <Message showIcon type="warning" header="Warning" closable style={{width: '100%'}}>
                  This System is experiencing issues.
                  <MainDivider/>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={5}>
                        Reported: {DateTime.fromISO(issue.inserted_at).toFormat(
                           'ff'
                        )}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}>
                        Assigned: {issue.assigned}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}>
                        Type: {issue.type}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={12}>
                        Notes: {issue.notes}
                     </FlexboxGrid.Item>
                  </FlexboxGrid>
               </Message>
               : null
            ))        
         : null
         }

         {/* Log Event History Bar */}
         <EventHistoryFC/>

         {loadingRecentFiles ? <></> : memoRecentFiles}

         <TabSelectorFC
               options={dataViewOptions}
               setStateToOption={setSelectedDataView}
         />

         <DataView />
      </>
   );
};