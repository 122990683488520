import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutBtnFC from './LogoutBtnFC';
import LoginBtnFC from './LoginBtnFC';
import styled from 'styled-components';

export const AuthBtnMobileFC = () => {
   const { isLoading, isAuthenticated, loginWithRedirect, logout} = useAuth0();
   console.log(`AuthBtnMobileFC -> ${isAuthenticated}`);

   return isLoading ? (
      // <div>loading</div>
      <></>
   ) : isAuthenticated ? (
        <AuthBtnSC onClick={() => logout({ returnTo: window.location.origin })}>Logout</AuthBtnSC>
   ) : (
        <AuthBtnSC onClick={() => loginWithRedirect()}>Log In</AuthBtnSC>
   );
};

// THIS GETS USED BY BOTH LogoutBtnFC AND LoginBtnFC
const AuthBtnSC = styled.button`
   background-color: orangered;
   color: white;
   border: none;
   border-radius: 4px;
   padding: 0.5rem;
   width: 55%;
   margin-top: 1rem;
   margin-left: 0.75rem;
   position fixed;
   top: 10vh;
   :hover {
      cursor: pointer;
   }
`;
