import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Radio, RadioGroup } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export const ChartSelectorFC = (props) => {
   const [chart, setChart] = useState(1);

   const onChangeChart = (event) => {
      setChart(event);
      props.chart(event);
   };

   useEffect(() => {
      // console.log('Rendered: ChartSelector');
   }, []);

   return (
      <ChartSelectorSC>
         <Form.Group controlId="chartList">
            <RadioGroup
               name="chartList"
               inline
               appearance="picker"
               value={chart}
               onChange={onChangeChart}
            >
               <span style={styles.radioGroupLabel}>Chart Display: </span>
               <Radio value={1}>MultiSeries</Radio>
               <Radio value={2}>Single</Radio>
            </RadioGroup>
         </Form.Group>
      </ChartSelectorSC>
   );
};

const ChartSelectorSC = styled.div`
   padding: 1rem;
`;

const styles = {
   radioGroupLabel: {
      padding: '8px 2px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle',
   },
};