import React, { useState } from 'react';

const UserNotificationPaneContext = React.createContext();

const UserNotificationPaneContextProvider = (props) => {
   //TODO: MAY NEED TO CHANGE DISPLAY -> SHOW IF IT
   // GETS PASSED TO STYLED COMPONENTS AS PROP
   const [globalMessagePane, setGlobalMessagePane] = useState({
      display: false,
      type: `error`,
      message: `Just some info`,
   });

   return (
      <UserNotificationPaneContext.Provider
         value={{
            globalMessagePane: globalMessagePane,
            setGlobalMessagePane: setGlobalMessagePane,
         }}
      >
         {props.children}
      </UserNotificationPaneContext.Provider>
   );
};

export { UserNotificationPaneContext, UserNotificationPaneContextProvider };

// Error Message : What'd you do??? Sorry, we messed up. Our team has been
//       notified and we're deligently working to resolve the issue.
//       Apologies for any inconvenience.
