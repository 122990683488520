// LOGGING
export const renderCount = (path) => {
   console.count(path);
};

export const catchError = (path, message, stacktrace) => {
   console.log(`*** CATCH ERROR ***`);
};

export const nonconformantError = (path, message) => {
   console.log(`*** NON-CONFORMANT ERROR ***`);
};
