import React, { useEffect, useState } from 'react';
import { Loader} from 'rsuite';
import { DateTime, Interval } from 'luxon';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';

export const LastReadFC = ({ recent_datetimes }) => {
   const [lastReadArray, setLastReadArray] = useState([]);

   const getFileDatetimes = async () => {
      setLastReadArray(recent_datetimes);
   };

   useEffect(() => {
      getFileDatetimes();
   }, [recent_datetimes]);

   return (
      <>
      {window.screen.width >= 768 ? 
      <>
         {lastReadArray ?             
            <>             
               {!lastReadArray.capture_datetime || lastReadArray.process_mag !== true ? 
                  null :
                  ((Interval.fromDateTimes(DateTime.fromISO(lastReadArray.capture_datetime), DateTime.now()).length('hours') > 6)) ?
                     <DateTimeBox color={'red'}>
                        <SmallText>Magnet: </SmallText><DateText>{DateTime.fromISO(lastReadArray.capture_datetime).toFormat(
                           'ff'
                        )}</DateText>
                     </DateTimeBox>                     
                  :
                     <DateTimeBox>
                        <SmallText>Magnet: </SmallText><DateText>{DateTime.fromISO(lastReadArray.capture_datetime).toFormat(
                              'ff'
                           )}</DateText>
                     </DateTimeBox>
               }
               {!lastReadArray.edu_datetime || lastReadArray.process_edu !== true ? 
                  null :
                  ((Interval.fromDateTimes(DateTime.fromISO(lastReadArray.edu_datetime), DateTime.now()).length('hours') > 6)) ?
                     <DateTimeBox color={'red'}>
                        <SmallText>EDU: </SmallText><DateText>{DateTime.fromISO(lastReadArray.edu_datetime).toFormat(
                           'ff'
                        )}</DateText>
                     </DateTimeBox>                     
                  :
                     <DateTimeBox>
                        <SmallText>EDU: </SmallText><DateText>{DateTime.fromISO(lastReadArray.edu_datetime).toFormat(
                              'ff'
                           )}</DateText>
                     </DateTimeBox>
               }
            </>
            : <SmallText> No Recent File </SmallText>
         }       
      </>
      :
      <>
         {lastReadArray ? 
            <>
               {lastReadArray.capture_datetime && lastReadArray.process_mag ?
                  <DateText>{DateTime.fromISO(lastReadArray.capture_datetime).toFormat(
                     'ff'
                  )}</DateText>
               : lastReadArray.edu_datetime && lastReadArray.process_edu ?
                  <DateText>{DateTime.fromISO(lastReadArray.edu_datetime).toFormat(
                     'ff'
                  )}</DateText>
                  :
                  <DateText>No Recent Files</DateText>
               }
            </>
         : <DateText> No Recent Files </DateText>
         }
      </>
   } 
   </>
   )
};

const DateTimeBox = styled.div`
   color: ${({ color }) =>
      color ? color : ''};
   display: block;
   padding-right: 1px;

   @media screen and (max-width: 769px) {
      font-size: 0.65em;
   }
`

const SmallText = styled.div`
   width: 45px;
   display:inline-block;
   font-size: 0.8em;
   font-weight: normal;
   color: #97969B;

   @media screen and (max-width: 769px) {
      width: 100%;
      font-size: 0.8em;
   }
`

const DateText = styled.div`
   display:inline-block;
   font-size: 0.90em;

   @media screen and (max-width: 769px) {
      font-size: 0.7em;
   }
`