import React from 'react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { _MaintenanceFC } from './pages/_misc/_MaintenancePageFC';
import { _HomeFC } from './pages/home/_HomeFC';
import LoginRouteFC from '../../auth/LoginRoute';
import { _SystemFC } from './pages/system/_SystemFC';
import { _HHMSystemFC } from './pages/system/_HHMSystemFC';
import { _LocalFileFC } from './pages/localFile/_LocalFileFC';
import { _AdminFC } from './pages/admin/_AdminFC';
import { _UserFC } from './pages/admin/Users/_UserFC';
import { _NetSystemsFC } from './pages/admin/Network/_NetSystemFC';
import { Switch, Route } from 'react-router-dom';
import ProtectedRouteFC from '../../auth/ProtectedRouteFC';
import { useAuth0 } from '@auth0/auth0-react';

export const RouterFC = () => {
   // renderCount(`./components/main/_MainFC`);
   const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'; // Set this flag based on your maintenance status
   const { user, isAuthenticated, isLoading} = useAuth0();

   return (
      <Switch>
         <Route path="/maintenance" component={_MaintenanceFC} />
         {isMaintenanceMode && !isAuthenticated && !isLoading ? (
            <Redirect to="/maintenance" />
         ) : (isMaintenanceMode && isAuthenticated && (user.email?.toLowerCase() !== 'ryan.anderson@avantehs.com'
                                 && user.email?.toLowerCase() !== 'jonathan.pope@avantehs.com'
                                 && user.email?.toLowerCase() !== 'matt.teixeira@avantehs.com')) ? (
                                 
            <Redirect to="/maintenance" />
         ) : (
            <>
               <Route exact path="/maintenance" component={_HomeFC} />
               <Route exact path="/" component={_HomeFC} />
               <Route exact path="/login" component={LoginRouteFC}/>
               <ProtectedRouteFC exact path="/system/:id" component={_SystemFC} />
               <ProtectedRouteFC exact path="/system/hhm/:id" component={_HHMSystemFC} />
               <ProtectedRouteFC path="/local-file" component={_LocalFileFC} />
               <ProtectedRouteFC
                  exact path="/admin"
                  component={_AdminFC}
               />
               <ProtectedRouteFC
                  exact path="/admin/user/:id"
                  component={_UserFC}
               />
               <ProtectedRouteFC
                  exact path="/admin/system/:id"
                  component={_NetSystemsFC}
               />
            </>                                   
         )}                                
      </Switch>
    )
};