import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, IconButton, Button, Input, TagPicker, ButtonGroup, Message, Schema, useToaster, InputNumber, Toggle, SelectPicker, Checkbox, CheckPicker, toaster } from 'rsuite';
import ToggleButton from 'rsuite/esm/Picker/ToggleButton';
import styled from 'styled-components';
import { MainDivider, SettingHeader } from '../../helpers/Admin_StyledComponents';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from '@rsuite/icons/Trash';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
// Requiring the lodash library
const _ = require("lodash");

export const GenerateConfigLog = ({configs, regex_models}) => { 
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();

   const initAcquisitionConfigs = configs.map(config => ({
      system_id: config?.system_id || id,
      file_name: config?.file_name,
      dir_name: config?.dir_name,
      regex_models: config?.regex_models,
      pg_tables: config?.pg_tables,
      column_name: config?.column_name,
      agg: config?.agg
   }));

   // Assuming configs is an array of configuration objects
   const [formValueAcquisitions, setFormValueAcquisitions] = useState(initAcquisitionConfigs);

   const pgTableSelectData = [
      "siemens_cv",
      "siemens_ct",
      "siemens_mri",
      "ge_cv_syserror",
      "ge_ct_gesys",
      "ge_mri_gesys",
      "philips_ct_events",
      "philips_mri_logcurrent",
      "philips_cv_eventlog", "lod",
      "philips_ct_eal",
      "philips_cv_eventlog"
      ].map(item => ({
         label: item,
         value: item
      }));

   const regexModelsSelectData = ["cv_bulk", "cv_groups",
      "ct_eal_v_2",
      "mri_logcurrent",
      "ct_events_v2",
      "eventlog",
      "re_v1",
      "sys_error",
      "block", "sub_block"].map(item => ({
         label: item,
         value: item
   }));
 
   const resetForm = () => {
      setFormValueAcquisitions(initAcquisitionConfigs);
   };

   const handleSetFormValueAcquisition = (index, event) => {
         const updatedFormValues = [...formValueAcquisitions];
         updatedFormValues[index] = event;
         setFormValueAcquisitions(updatedFormValues);
   };

   const addConfig = () => {
      setFormValueAcquisitions([...formValueAcquisitions, {
         system_id: id,
         file_name: null,
         dir_name: null,
         regex_models: [],
         pg_tables: [],
         column_name: null,
         agg: null,
         }
      ]);
   };

   const removeConfig = (index) => {
      const updatedFormValues = [...formValueAcquisitions];
      updatedFormValues.splice(index, 1);
      setFormValueAcquisitions(updatedFormValues);
   };

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated this System.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   // UPDATE MAG CONFIGS
   const updateConfigLog = async () => {
      // console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            method: 'POST',
            url: `/network/update/config`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               sme: id,
               configs: formValueAcquisitions,
               type: 'log'
            })
         });
         toaster.push(message,'topCenter');
      } catch (error) {
         toaster.push(errMessage,'topCenter');
         console.error(error);
      }
   };

   useEffect(() => {
      
   }, []);

   return (
      <ConfigBox>
         <SettingHeader>
            Log Configs
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <Button appearance="primary" onClick={updateConfigLog}>Submit</Button>
               <Button appearance="default" onClick={resetForm}>Reset</Button>
            </ButtonGroup>
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <IconButton icon={<PlusIcon />} onClick={addConfig}/>
            </ButtonGroup>
         </SettingHeader>

         <MainDivider/>

         {formValueAcquisitions.map((config, index) => (
            <Form fluid
               formValue={config}
               onChange={(event) => handleSetFormValueAcquisition(index, event)}
            >
               <Form.Group controlId='system_id' style={{width: '10%', marginRight: '1%', display: 'inline-block'}}>
                  <Form.ControlLabel>System ID</Form.ControlLabel>
                  <Form.Control name="system_id" disabled/>
               </Form.Group>
               <Form.Group controlId='file_name' style={{width: '20%', marginRight: '1%', display: 'inline-block'}}>
                  <Form.ControlLabel>File Name</Form.ControlLabel>
                  <Form.Control name="file_name" style={{width: '100%'}}/>
               </Form.Group>
               <Form.Group controlId='dir_name' style={{width: '20%', marginRight: '1%', display: 'inline-block'}}>
                  <Form.ControlLabel>Directory Name</Form.ControlLabel>
                  <Form.Control name="dir_name" />
               </Form.Group>
               <Form.Group controlId='pg_tables' style={{width: '25%', marginRight: '1%', display: 'inline-block'}}>
                  <Form.ControlLabel>PG Tables</Form.ControlLabel>
                  <Form.Control name="pg_tables" accepter={CheckPicker} data={pgTableSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
               </Form.Group>
               <Form.Group controlId='regex_models' style={{width: '15%', display: 'inline-block'}}>
                  <Form.ControlLabel>Regex Models</Form.ControlLabel>
                  <Form.Control name="regex_models" accepter={CheckPicker} data={regexModelsSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
               </Form.Group> 
               <ButtonGroup style={{marginLeft: '1%', display: 'inline-block'}}>
                  <IconButton icon={<TrashIcon />} color='red' appearance="primary" onClick={() => removeConfig(index)}/>
               </ButtonGroup>
               <MainDivider/>   
            </Form>
         ))}
      </ConfigBox>
   )
}

const ConfigBox = styled.div`
   display: block;
`