import React, { useState, useEffect, useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import {IconButton, List, FlexboxGrid, Loader, Tag} from 'rsuite';
import GearIcon from '@rsuite/icons/Gear';
import { DateTime } from 'luxon';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../appHelpers/axiosInstance';
import { timeAgo } from '../_helpers/TimeAgo';
import { useHistory } from 'react-router-dom';
import AdminIcon from '@rsuite/icons/Admin';
import PeoplesIcon from '@rsuite/icons/Peoples';
import { InviteUser } from './Users/Modal/InviteUser';
import { MachinesContext } from '../home/dashboard/_context/MachinesContext';
import { InputContext } from '../_context/InputContext';
import { Avatar } from '../../../../appHelpers/appCommonSC';

export const UsersListFC = (props) => {
   // renderCount(`AdminFC`);
   const history = useHistory();
   const [users, setUsers] = useState([]);
   const [displayCustomerUsers, setDisplayCustomerUsers] = useState([]);
   const [customerUsers, setCustomerUsers] = useState([]);
   const [unaffiliatedUsers, setUnaffiliatedUsers] = useState([]);
   const [basicUsers, setBasicUsers] = useState([]);
   const [adminUsers, setAdminUsers] = useState([]);
   const [superUsers, setSuperUsers] = useState([]);
   const [loadingUsers, setLoadingUsers] = useState(true);
   const { getAccessTokenSilently } = useAuth0();

   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);

   const {
      loadingMachines,
      machines
   } = useContext(MachinesContext);

   const getUserList = async () => {
      setLoadingUsers(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const headers = {
            authorization: `Bearer ${token}`,
         }

         const userRes = await axiosInstance({
            url: `/admin/user/list`,
            data: ``,
            headers: headers,
         });

         setUsers(userRes?.data[0]);
         setCustomerUsers(userRes?.data[1]);
         setUnaffiliatedUsers(userRes?.data[0].filter((user) => !userRes?.data[1].flatMap((customer) => customer.clients).includes(user.email_address)));
         setBasicUsers(userRes?.data[0].filter(user => user.roles['roles'].includes('basic')));
         setAdminUsers(userRes?.data[0].filter(user => user.roles['roles'].includes('admin')));
         setSuperUsers(userRes?.data[0].filter(user => user.roles['roles'].includes('superusers')));
      } catch (error) {
         console.error(error);
      }

      setLoadingUsers(false);
   };

   const filterCustomer = () => {
      if(adminCustomerToggle) {
         setDisplayCustomerUsers(customerUsers.filter(customer => customer.id === adminCustomerToggle));
      } else {
         setDisplayCustomerUsers(customerUsers);
      }
   }

   useEffect(() => {
      getUserList();
   }, []);

   useEffect(() => {

   }, [loadingMachines]);

   useEffect(() => {
      filterCustomer();
   }, [customerUsers, adminCustomerToggle]);

   return (
      <UsersListSC>
      {loadingUsers || loadingMachines ?      
         <Loader size="md" speed="fast" content="Loading..." style={{paddingTop: '10px'}} center/>  
      :
      window.screen.width >= 768 ? 
         <>
         {displayCustomerUsers?.map((customer, index)  => (
            <ListTable>
               <ListHeader>
                  <FlexboxGrid style={{backgroundColor: 'rgb(243, 248, 252)', color: 'rgb(0, 91, 148)', padding: '5px'}}>
                     <FlexboxGrid.Item colspan={5}>
                        {customer.name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={15}>
                        <PeoplesIcon/> Users: {customer.clients?.length}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align={'right'}>
                        <InviteUser customerData={customer} getUserList={getUserList} systemList={machines}/>
                     </FlexboxGrid.Item>
                  </FlexboxGrid>

                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={2}/>
                     <FlexboxGrid.Item colspan={4}>
                        NAME
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6}>
                        EMAIL ADDRESS
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={3} align='center'>
                        ROLE
                     </FlexboxGrid.Item>    
                     <FlexboxGrid.Item colspan={3} align='center'>
                        ACCOUNT STATUS
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center'>
                        ACTIVITY
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        ACTIONS
                     </FlexboxGrid.Item>          
                  </FlexboxGrid>
               </ListHeader>
            <List size='sm'>
               {customer.clients.map((client) => (
                  users
                     ?.filter((user) => user.email_address.includes(client))
                     .map((user, index) => (
                     <List.Item key={index + '_user'} value={user}>
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={1} align='center'>
                              <Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={1} align='center'>
                              <div>
                              {(user.roles['roles'].includes("developer")) ? 
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#673ab7" style={{verticalAlign: 'middle'}} viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.559 5.448a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z"/>
                                 </svg>
                                 : (user.roles['roles'].includes('superuser')) ? 
                                    <AdminIcon color="red" style={{fontSize: '1.25em'}}/>
                                 : (user.roles['roles'].includes("admin")) ?
                                    <AdminIcon color="#f5a623" style={{fontSize: '1.25em'}}/>
                                 : null}

                              </div>                     
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={4}>
                              {user.first_name + " " + user.last_name}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={6}>
                              {user.email_address}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {user.roles['roles'].includes('developer') ? <Tag color="violet">DEVELOPER</Tag>
                                 : user.roles['roles'].includes('superuser') ? <Tag color="red">SUPERUSER</Tag>
                                 : user.roles['roles'].includes('admin') ? <Tag color="orange">ADMIN</Tag>
                                 : '-' }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {user.status === 'active' ?
                                 <Status color='green'>{user.status}</Status>
                              : user.status === 'invited' ?
                                 <Status color='darkgrey'>{user.status}</Status>
                              :
                                 <Status color='red'>{user.status}</Status>
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={4} align='center'>
                              {user.last_logged_in !== null ?
                                 timeAgo(user.last_logged_in)
                                 :<>never</>
                              }                           
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2} align='center'>
                              <IconButton onClick={() => history.push(`/admin/user/${user.email_address}`)} icon={<GearIcon />} />
                           </FlexboxGrid.Item> 
                        </FlexboxGrid>
                     </List.Item>
                  ))
               ))}
            </List>
            </ListTable>
         ))}

         {unaffiliatedUsers?.length > 0 ?
            <ListTable>
            <ListHeader>
               <FlexboxGrid style={{backgroundColor: 'rgb(243, 248, 252)', color: 'rgb(0, 91, 148)', padding: '5px'}}>
                  <FlexboxGrid.Item colspan={5}>
                     Unaffiliated Users
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                     <PeoplesIcon/> Users: {unaffiliatedUsers.length}
                  </FlexboxGrid.Item>
               </FlexboxGrid>

               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={2}/>
                  <FlexboxGrid.Item colspan={4}>
                     NAME
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                     EMAIL ADDRESS
                  </FlexboxGrid.Item> 
                  <FlexboxGrid.Item colspan={3} align='center'>
                     ROLE
                  </FlexboxGrid.Item>    
                  <FlexboxGrid.Item colspan={3} align='center'>
                     ACCOUNT STATUS
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     ACTIVITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={2} align='center'>
                     ACTIONS
                  </FlexboxGrid.Item>          
               </FlexboxGrid>
            </ListHeader>

            <List>
               {unaffiliatedUsers?.map((user, index)  => (
                  <List.Item key={index + '_unaffuser'} value={user}>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={1} align='center'>
                           <Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1} align='center'>
                           <div>
                           {(user.roles['roles'].includes("developer")) ? 
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#673ab7" style={{verticalAlign: 'middle'}} viewBox="0 0 16 16">
                                 <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.559 5.448a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z"/>
                              </svg>
                              : (user.roles['roles'].includes('superuser')) ? 
                                 <AdminIcon color="red" style={{fontSize: '1.25em'}}/>
                              : (user.roles['roles'].includes("admin")) ?
                                 <AdminIcon color="#f5a623" style={{fontSize: '1.25em'}}/>
                              : null}

                           </div>                     
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                           {user.first_name + " " + user.last_name}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                           {user.email_address}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {user.roles['roles'].includes('developer') ? <Tag color="violet">DEVELOPER</Tag>
                              : user.roles['roles'].includes('superuser') ? <Tag color="red">SUPERUSER</Tag>
                              : user.roles['roles'].includes('admin') ? <Tag color="orange">ADMIN</Tag>
                              : '-' }
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {user.status === 'active' ?
                              <Status color='green'>{user.status}</Status>
                           : user.status === 'invited' ?
                              <Status color='darkgrey'>{user.status}</Status>
                           :
                              <Status color='red'>{user.status}</Status>
                           }
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4} align='center'>
                           {user.last_logged_in !== null ?
                              timeAgo(user.last_logged_in)
                              :<>never</>
                           }                           
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           <IconButton onClick={() => history.push(`/admin/user/${user.email_address}`)} icon={<GearIcon />} />
                        </FlexboxGrid.Item> 
                     </FlexboxGrid>
                  </List.Item>
               ))}
            </List>
            </ListTable>
         : null
         }
         
         </>
         :
         <ListTable>
            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={5}/>
                  <FlexboxGrid.Item colspan={9}>
                     NAME
                  </FlexboxGrid.Item>  
                  <FlexboxGrid.Item colspan={6} align='center'>
                     ACTIVITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     ACTIONS
                  </FlexboxGrid.Item>          
               </FlexboxGrid>
            </ListHeader>
            <List size="sm">
            {users?.map((user, index) => (
               <List.Item key={index} value={user}>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={5}>
                        <Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={9}>
                        {user.first_name + " " + user.last_name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6} align='center'>
                        {user.last_logged_in !== null ?
                           timeAgo(user.last_logged_in)
                           :<>never</>
                        }                           
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center'>
                        <IconButton onClick={() => history.push(`/admin/user/${user.email_address}`)} icon={<GearIcon />} />
                     </FlexboxGrid.Item> 
                  </FlexboxGrid>
               </List.Item> 
            ))}                  
            </List> 
         </ListTable>
      }         
      </UsersListSC>    
   );
};

const UsersListSC = styled.div`
   display: block;
   width: 100%;
   height: auto;
   text-align: -webkit-center;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   @media screen and (min-width: 769px) {
      
   }
`
const ListTable = styled.div`
   width: 100%;
   height: auto;
   padding-top: 5vh;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   text-align: start;

   @media screen and (min-width: 769px) {
      width: 80%;
   }

   .rs-flex-box-grid {
      align-items: center;
   }
`

const ListHeader = styled.div`
   color: grey;
   align-items: start;
`

const Status = styled.div`
   color: ${({ theme, color }) => color};
`