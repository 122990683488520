import subHours from 'date-fns/subHours';

const mostRecentDatetime = new Date();
let liquidHeliumDataSet = [];
let compressorVoltageDataSet = [];
for (let i = 0; i < 4000; i++) {
   let xValue = subHours(mostRecentDatetime, i); // ALL SETS HAVE SAME DATETIME VALUES
   // LIQUID HELIUM SET
   let yValue = Math.random() * 100;
   let lqdp = new Object({ x: xValue, y: yValue });
   // COMPRESSOR VOLTAGE SET
   yValue = Math.random() * 4;
   let cvdp = new Object({ x: xValue, y: yValue });

   liquidHeliumDataSet.push(lqdp);
   compressorVoltageDataSet.push(cvdp);
}

export const gemriMagPayload = {
   liquidHeliumDataSet: liquidHeliumDataSet,
   compressorVoltageDataSet: compressorVoltageDataSet,
};

// gemriMagPayload;
