import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, ButtonToolbar, Button, Input, TagPicker, ButtonGroup, Message, Schema, useToaster } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
// Requiring the lodash library
const _ = require("lodash");

export const ProfileSettings = (props) => { 
   const { getAccessTokenSilently } = useAuth0();
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [currentRoles, setCurrentRoles] = useState(props.roles);
   const [roles, setRoles] = useState([]);
   const [adminRoles, setAdminRoles] = useState([]);
   const [status, setStatus] = useState([]);
   const [baseRoles, setBaseRoles] = useState([]);
   const [blocked, setBlocked] = useState(false);
   const [disable, setDisable] = useState(true);
   const [loading, setLoading] = useState(false);
   const [resMsg, setResMsg] = useState("");
   const toaster = useToaster();

   // FORM VALIDATION
   const formRef = useRef();
   const [formError, setFormError] = useState({});
   const phoneRule = Schema.Types.StringType().containsNumber('Must contain only numbers').maxLength(10, 'Phone Number Must Be Exactly 10 Characters.').minLength(10, 'Phone Number Must Be Exactly 10 Characters.');
      
   const prefillFormFields = () => {
      setFirstName(props.user.first_name);
      setLastName(props.user.last_name);
      setEmail(props.user.email_address);
      setPhoneNumber(props.user.phone_number);
      setStatus(props.user.status);
      setRoles(Object.values(props.user.roles)[0]);
      setBaseRoles(["basic", "admin"].map(role => ({label: role, value: role})));

      if(props.user.status === 'deactivated') {
         setBlocked(true);
      } else {
         setBlocked(false);
      }
   }

   const onSubmit = async () => {
      setLoading(true);

      // CHECK IF USER FORM IS CORRECT
      if (!formRef.current.check()) {
         setLoading(false);
         toaster.push(errPhoneMessage,'topCenter');
         console.error('Form Error');
         return;
      }

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/user/update/profile/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               firstName: firstName,
               lastName: lastName,
               phoneNumber: phoneNumber,
               roleArray: roles,
               userEmail: email,
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoading(false);
   }

   const onSubmitUserStatus = async () => {
      setLoading(true);
      // console.log(`updateUserProfile`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/auth/user/toggle-block`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               user_id: email,
               blocked: !blocked
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoading(false);
   }

   const onSubmitUserReset2FA = async () => {
      setLoading(true);
      // console.log(`updateUserProfile`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/auth/user/reset-2fa`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               user_id: email
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoading(false);
   }

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated <b>{firstName + ' ' + lastName}</b> profile.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   const errPhoneMessage = (
      <Message showIcon type="error" header="Error">
         Phone Number must be empty or atleast 10 digits!
      </Message>
   );

   const checkButtonStatus = () => {
      if (firstName !== props.user.first_name || lastName !== props.user.last_name ||
         email !== props.user.email_address  || _.isEqual((Object.values(props.user.roles['roles'])).sort(), roles?.sort()) === false
         || phoneNumber !== props.user.phone_number)
      {
         setDisable(false)
      } else {
         setDisable(true)
      }
   }

   useEffect(() => {
      prefillFormFields();
      setCurrentRoles(props.roles);
   }, [props.user]);

   useEffect(() => {
      checkButtonStatus();
   }, [firstName, lastName, phoneNumber, email, roles, currentRoles])

   return (
      <SettingBody>
         <SettingHeader>Profile Settings</SettingHeader>

         <MainDivider/>
         
         <Form fluid
          ref={formRef}
          onCheck={setFormError}>
            <FormSection float="left">
               <Form.Group controlId="first-name">
                  <Form.ControlLabel>First Name</Form.ControlLabel>
                  <Form.Control value={firstName} name="first-name" onChange={setFirstName}/>
               </Form.Group>
               <Form.Group controlId="last-name">
                  <Form.ControlLabel>Last Name</Form.ControlLabel>
                  <Form.Control value={lastName} name="last-name" onChange={setLastName}/>
               </Form.Group>
               {currentRoles.includes('admin') || currentRoles.includes('superuser') ?
                  <Form.Group controlId="roles">
                     <Form.ControlLabel>Roles:</Form.ControlLabel>
                     <Form.Control value={roles} name="roles" accepter={TagPicker} data={baseRoles} onChange={setRoles} block/>
                  </Form.Group>
                  :
                  null        
               }              
            </FormSection>
            <FormSection float="right">
               <Form.Group controlId="email-address">
                  <Form.ControlLabel>Email Address</Form.ControlLabel>
                  <Form.Control value={email} name="email-address" disabled/>
               </Form.Group>
               <Form.Group controlId="phone-number">
                  <Form.ControlLabel>Phone Number</Form.ControlLabel>
                  <Form.Control value={phoneNumber} name="phone-number" onChange={setPhoneNumber} rule={phoneRule}/>
               </Form.Group>
            </FormSection>
         </Form>

         <ButtonBar>
            <ButtonGroup>
               <Button appearance="primary" onClick={onSubmit} disabled={disable} loading={loading}>Submit</Button>
               <Button appearance="default" onClick={prefillFormFields} disabled={disable}>Reset</Button>
            </ButtonGroup>

            {currentRoles.includes('admin') || currentRoles.includes('superuser') ?
               <DeactivateBtn>
                  {status === 'active' || status === 'invited' ?
                     <Button color="red" appearance="primary" onClick={onSubmitUserStatus}>
                        Deactivate
                     </Button>
                     :
                     <Button color="green" appearance="primary" onClick={onSubmitUserStatus}>
                        Reactivate
                     </Button>
                  }
               </DeactivateBtn>
            : null        
            }

            {currentRoles.includes('superuser') ?
               <DeactivateBtn>
                  {status === 'active' ?               
                        <Button color="orange" appearance="primary" onClick={onSubmitUserReset2FA} style={{marginRight: 5}}>
                           Reset 2FA
                        </Button>
                     : null
                  }
               </DeactivateBtn>
            : null        
            }    
         </ButtonBar>             
      </SettingBody>
   );
};

const FormSection = styled.div`
   display: block;
   width: 45%;
   float: ${({ theme, float }) => float};

   @media screen and (max-width: 769px) {
      padding-bottom: 10%;
      width: 100%;
   }
`

const DeactivateBtn = styled.div`
   float: right;
`

const ButtonBar = styled.div`
   padding-top: 40vh;
   display: block;
   width: 100%;

   @media screen and (max-width: 769px) {
      padding-bottom: 10%;
   }
`