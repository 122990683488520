import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { Checkbox, CheckPicker, List, FlexboxGrid, Button, ButtonGroup, useToaster, Message } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';

export const AccessListFC = (props) => { 
   const { getAccessTokenSilently } = useAuth0();
   const [baseCustomers, setBaseCustomers] = useState([]);
   const [displayList, setDisplayList] = useState([]);
   const [userDataModel, setUserDataModel] = useState([]);
   const [userSystemListConfig, setUserSystemListConfig] = useState([]);
   const [loadingUserConfig, setLoadingUserConfig] = useState(true);
   const [userCustomers, setUserCustomers] = useState([]);
   const [userSites, setUserSites] = useState([]);
   const [disableButton, setDisableButton] = useState(false);
   const [userManufacturers, setUserManufacturer] = useState([]);
   const [userModalities, setUserModalities] = useState([]);
   const [resMsg, setResMsg] = useState("");
   const toaster = useToaster();

   const handleChangeCustomers = value => {
      setUserCustomers(value);
   };

   const handleCustomerCheckAll = (value, checked) => {
      setUserCustomers(checked ? [...new Set(props.baseSystems.map(system => system.customer_id))] : []);
   };

   const getUserSystemListConfig = async () => {
      // console.log(`getUserSystemListConfig`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            url: `/admin/user/system-list-config/${props.user}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setUserSystemListConfig(response.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingUserConfig(false);
   }

   const setBaseList = () => {
      // Set Admin List to Interact with User
      setBaseCustomers([...new Map(props.baseSystems.map(item =>
         [item['customer_id'], ({value: item['customer_id'], label: item['customer_name']})])).values()]);
   }

   const setDisplayTable = () => {
      setDisplayList(baseCustomers?.filter(customer => {return userCustomers?.includes(customer.value)}));
   }

   const handleButton = () => {
      if(userCustomers.length === 0 && !loadingUserConfig) {
         setDisableButton(true);
         toaster.push(warningMessage,'topCenter');
      } else {
         setDisableButton(false);        
      }
   }

   const createDataModel = () => {
      let userCustomerConfig = [];
      
      for (const customer of userCustomers) {
         let userDataConfig = {};
         let userSiteConfig = [];
         let userModalityConfig = [];
         let userManufacturerConfig = [];

         userDataConfig.customers__id = customer;

         if (userSites.length > 0) {   
            userSites.forEach(site => {
               if (site[0] === customer) {
                  userSiteConfig.push(site[1]);
               }
            })
         }

         if(userManufacturers.length > 0) {
            userManufacturers.forEach(manufacturer => {
               if (manufacturer[0] === customer) {
                  userManufacturerConfig.push(manufacturer[1]);
               }
            })
         }

         if(userModalities.length > 0) {
            userModalities.forEach(modalities => {
               if (modalities[0] === customer) {
                  userModalityConfig.push(modalities[1]);
               }
            })
         }

         if(userSiteConfig.length > 0) {
            if(userDataConfig.and) {
               if(userDataConfig.and.and) {
                  userDataConfig.and.and.and = {sites__id: userSiteConfig};
               } else {
                  userDataConfig.and.and = {sites__id: userSiteConfig};
               }
            } else {
               userDataConfig.and = {sites__id: userSiteConfig};
            }       
         }

         if(userManufacturerConfig.length > 0) {
            if(userDataConfig.and) {
               if(userDataConfig.and.and) {
                  userDataConfig.and.and.and = {systems__manufacturer: userManufacturerConfig};
               } else {
                  userDataConfig.and.and = {systems__manufacturer: userManufacturerConfig};
               }
            } else {
               userDataConfig.and = {systems__manufacturer: userManufacturerConfig};
            }
         }

         if(userModalityConfig.length > 0) {
            if(userDataConfig.and) {
               if(userDataConfig.and.and) {
                  userDataConfig.and.and.and = {systems__modality: userModalityConfig};
               } else {
                  userDataConfig.and.and = {systems__modality: userModalityConfig};
               }
            } else {
               userDataConfig.and = {systems__modality: userModalityConfig};
            }
         }

         userCustomerConfig.push(JSON.parse(JSON.stringify(userDataConfig)));
      }

      setUserDataModel(userCustomerConfig);
   }

   const preFillUserOptions = () => {
      // Prefill Current Selected User Customer List
      let configCustomer = [];
      let configSites = [];
      let configManufacturers = [];
      let configModalities = [];
      
      try {
         userSystemListConfig.forEach(config => {
            configCustomer.push(JSON.parse(JSON.stringify(config.customers__id)))
   
            if(config.and?.sites__id) {
               config.and?.sites__id.forEach(site => {
                  configSites.push([JSON.parse(JSON.stringify(config.customers__id)), site])
               })
            }
            
            if(config.and?.systems__manufacturer) {
               config.and?.systems__manufacturer.forEach(manufacturer => {
                  configManufacturers.push([JSON.parse(JSON.stringify(config.customers__id)), manufacturer])
               })
            } else if (config.and?.and?.systems__manufacturer) {
               config.and?.and?.systems__manufacturer.forEach(manufacturer => {
                  configManufacturers.push([JSON.parse(JSON.stringify(config.customers__id)), manufacturer])
               })
            } else if (config.and?.and?.and?.systems__manufacturer) {
               config.and?.and?.and?.systems__manufacturer.forEach(manufacturer => {
                  configManufacturers.push([JSON.parse(JSON.stringify(config.customers__id)), manufacturer])
               })
            }

            if(config.and?.systems__modality) {
               config.and?.systems__modality.forEach(modality => {
                  configModalities.push([JSON.parse(JSON.stringify(config.customers__id)), modality])
               })
            } else if (config.and?.and?.systems__modality) {
               config.and?.and?.systems__modality.forEach(modality => {
                  configModalities.push([JSON.parse(JSON.stringify(config.customers__id)), modality])
               })
            } else if (config.and?.and?.and?.systems__modalities) {
               config.and?.and?.and?.systems__modality.forEach(modality => {
                  configModalities.push([JSON.parse(JSON.stringify(config.customers__id)), modality])
               })
            }
         })
      } catch {
         console.log("INVALID: Config");
      }
      
      setUserCustomers(configCustomer);
      setUserSites(configSites);
      setUserModalities(configModalities);
      setUserManufacturer(configManufacturers);
   }

   const onSubmitGenerateUserAlerts = async (e) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/generate/model`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               user_id: props.user.toString()
            }),
         });
      } catch (error) {
         console.error(error);
      }
   }

   const onSubmit = async () => {
      // console.log(`updateUserConfig`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/admin/update/system-list-config/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               updateUser: props.user.toString(),
               userModel: userDataModel,
            }),
         });

         setResMsg(response.data);
         onSubmitGenerateUserAlerts();
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoadingUserConfig(false);
   }

   useEffect(() => {
      getUserSystemListConfig();
      setBaseList();
   }, [props.user, props.baseSystems]);

   useEffect(() => {
      preFillUserOptions();
    
   }, [userSystemListConfig]);

   useEffect(() => {
      setDisplayTable();
      createDataModel();
      handleButton();
   }, [userCustomers, userSites, userManufacturers, userModalities]);

   return (
      <SettingBody>
         <SettingHeader> Access List
            <MainDivider/>
            <ItemSubText>
               Current Customer Access
            </ItemSubText>
            <CheckPicker
               preventOverflow
               placement={'bottomStart'}
               value={userCustomers} 
               data={baseCustomers} 
               onChange={handleChangeCustomers} 
               block
               renderExtraFooter={() => (
                  <div style={footerStyles}>
                    <Checkbox
                      indeterminate={userCustomers.length > 0 && userCustomers.length < baseCustomers.length}
                      checked={userCustomers.length === baseCustomers.length}
                      onChange={handleCustomerCheckAll}
                    >
                      Check all
                    </Checkbox>
                  </div>
                )}
            />
         </SettingHeader>
         
         <MainDivider/>

         <ListHeader>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={7} style={{paddingBottom: 10}}>
                  Customer
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={9} style={{paddingBottom: 10}}>
                  Sites
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={4} style={{paddingBottom: 10}}>
                  Manufacturer
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={4} style={{paddingBottom: 10}}>
                  Modality
               </FlexboxGrid.Item>                                       
            </FlexboxGrid>
         </ListHeader>

         <List size="sm">
            {displayList?.map((customer, index) => (
               <List.Item value={customer.customer_id} key={customer.customer_id} index={index + 1}>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={7}>
                        {customer.label}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={9}>
                        <FlexboxGrid>
                           <CheckPicker 
                              placeholder={'All'}
                              preventOverflow
                              placement={'bottomStart'}
                              value={userSites}
                              data={([...new Map((props.baseSystems.filter(system => {return system.customer_id === customer.value})).map(item =>
                                 [item['site_id'], ({value: [item['customer_id'],item['site_id']], label: item['site_name']})])).values()])} 
                              style={{width: '95%'}}
                              onChange={setUserSites}
                              dis
                              block             
                           />
                        </FlexboxGrid>
                     </FlexboxGrid.Item>

                     <FlexboxGrid.Item colspan={4}>
                        <FlexboxGrid>
                           <CheckPicker 
                              placeholder={'All'}
                              preventOverflow
                              placement={'bottomStart'}
                              value={userManufacturers}
                              data={([...new Map((props.baseSystems.filter(system => {return system.customer_id === customer.value})).map(item =>
                                 [item['manufacturer'], ({value: [item['customer_id'], item['manufacturer']], label: item['manufacturer']})])).values()])} 
                              style={{width: '95%'}}
                              onChange={setUserManufacturer}
                              dis
                              block             
                           />
                        </FlexboxGrid>
                     </FlexboxGrid.Item>

                     <FlexboxGrid.Item colspan={4}>
                        <FlexboxGrid>
                           <CheckPicker 
                              placeholder={'All'}
                              preventOverflow
                              placement={'bottomStart'}
                              value={userModalities}
                              data={([...new Map((props.baseSystems.filter(system => {return system.customer_id === customer.value})).map(item =>
                                 [item['modality'], ({value: [item['customer_id'], item['modality']], label: item['modality']})])).values()])} 
                              style={{width: '95%'}}
                              onChange={setUserModalities}
                              dis
                              block             
                           />
                        </FlexboxGrid>
                     </FlexboxGrid.Item>                                           
                  </FlexboxGrid>
               </List.Item> 
            ))}                  
         </List> 

         <MainDivider/>

         <ButtonGroup>
            <Button appearance="primary" onClick={onSubmit} disabled={disableButton}>Apply</Button>
            <Button appearance="default" onClick={preFillUserOptions}>Reset</Button>
         </ButtonGroup>

         <MainDivider/>     
      </SettingBody>
   );
};

const ListHeader = styled.div`
    color: grey;
`

const ItemSubText = styled.div`
   font-size: 0.6em;
   color: #97969B;
   font-weight: normal;
   text-align: left;
   padding-bottom: 0.5%;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const footerStyles = {
   padding: '10px 2px',
   borderTop: '1px solid #e5e5e5'
};
 
const footerButtonStyle = {
   float: 'right',
   marginRight: 10,
   marginTop: 2
};

const message = (
   <Message showIcon type="success" header="Success">
      You successfully updated profile.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);

const warningMessage = (
   <Message showIcon type="warning" header="Warning">
      A User must have at least (1) Customer selected within their Access List.
   </Message>
);