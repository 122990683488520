import React, { useContext } from 'react';
import { DarkModeContext } from '../../../../appContexts/DarkModeContext';
import styled from 'styled-components';

export const SectionTitleFC = ({ title }) => {
   const { darkMode } = useContext(DarkModeContext);

   return (
      <SectionTitleSC darkMode={darkMode}>
         <div>{title}</div>
      </SectionTitleSC>
   );
};

const SectionTitleSC = styled.div`
   display: grid;
   place-items: center;
   font-size: 4vw;
   width: 100%; // OF BodySC
   margin-top: 1rem;
   margin-bottom: 1rem;

   div {
      color: ${({ theme, darkMode }) =>
         darkMode ? theme.dark_accent : theme.light_grey};
      letter-spacing: 0.75rem;
      text-indent: 1rem;
      text-align: center;
   }

   // AT COMMON TABET SCREEN SIZE AND GREATER CONVERT
   // FROM A MOBILE SIDEBAR TO A DESKTOP SIDE PANEL
   // PROPERTIES ADOPTED AT >= 769px
   @media screen and (min-width: 769px) {
      font-size: 2.25vw;
      div {
         letter-spacing: 2.25rem;
         text-indent: 2.25rem;
      }
   }
`;
