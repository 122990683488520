import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FixedOffsetZone } from "luxon";
import styled from 'styled-components';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    // return <div>Loading ...</div>;
    return <></>;
  }

  return (
    isAuthenticated && (
      <ProfileSC>
        {/* <ProfileImage src={user.picture} alt={user.name} />
        <h2>{user.name}</h2> */}
      </ProfileSC>
    )
  );
};

const ProfileSC = styled.div`
  position: fixed;
  left: 2vw;
  top: 3vh;
`;

const ProfileImage = styled.img`
  width: 30%;
  height: 30%;
  border-radius: 50%;
`;
export default Profile;