import React, { useState, useEffect, useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { Loader, Nav } from 'rsuite';
import SettingIcon from '@rsuite/icons/legacy/Setting';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import ImageIcon from '@rsuite/icons/Image';
import { CustomerUserListFC } from './CustomerUserListFC';
import { CustomerSiteListFC } from './CustomerSiteListFC';
import { CustomerSettingsFC } from './CustomerSettingsFC';
import { CustomerSystemsListFC } from './CustomerSystemsListFC';
import { CustomerAcumaticaFC } from './CustomerAcumaticaFC';
import { InputContext } from '../../_context/InputContext';
import * as DOMPurify from 'dompurify';

export const _CustomerFC = ({client, userRoles, setPage}) => {
   // renderCount(`AdminFC`);
   const { getAccessTokenSilently } = useAuth0();
   const [customer, setCustomer] = useState();
   const [customerData, setCustomerData] = useState();
   const [clientLogo, setClientLogo] = useState();
   const [roles, setRoles] = useState();
   const [active, setActive] = useState('Sites');
   const [loadingCustomerData, setLoadingCustomerData] = useState([]);

   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);

   const getCustomerData = async () => {
      setLoadingCustomerData(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         let customerRes;

         if(adminCustomerToggle){
            customerRes = await axiosInstance({
               url: `/customer/data/${adminCustomerToggle}`,
               data: ``,
               headers: { authorization: `Bearer ${token}` },
            });
   
         } else {
            customerRes = await axiosInstance({
               url: `/customer/data/${client}`,
               data: ``,
               headers: { authorization: `Bearer ${token}` },
            });   
         }

         setCustomerData(customerRes?.data[0][0]);
         setClientLogo(<ImgSrcLogo src={DOMPurify.sanitize(customerRes?.data[0][0]?.logo_src)}/>);
      } catch (error) {
         console.error(error);
      }
      setLoadingCustomerData(false);
   };

   const checkAdminCustomerToggle = () => {
      if(adminCustomerToggle) {
         getCustomerData();
         setCustomer(adminCustomerToggle);
         setRoles(userRoles);
      } else if(client) {
         getCustomerData();
         setCustomer(client);
         setRoles(userRoles);
      } else {
         setPage('Customers');
      }
   }
   
   useEffect(() => {
      checkAdminCustomerToggle();
   }, [adminCustomerToggle]);

   return (
      loadingCustomerData ? 
      <_CustomerSC>
         <Loader size="md" speed="fast" content="Loading..." style={{paddingTop: '10px'}} center/>
      </_CustomerSC> 
      :
         <_CustomerSC>
            <CustomerHeader>      
               <CustomerInfo>
                  <UserName>
                     {customerData?.name ? customerData.name : 'No Customer Name'}
                  </UserName>
                  
                  <ItemSubText>
                     {customerData?.id}
                  </ItemSubText>

                     <CustomerLogo>
                     {customerData?.logo_src ?                  
                        clientLogo
                     :
                        <ImageIcon style={{ fontSize: "4em"}}/>         
                     }
                  </CustomerLogo> 
               </CustomerInfo>                                     
            </CustomerHeader>

            <NavigationPane>
               <Header>
                  <SettingIcon/>Settings
               </Header>

               <Nav appearance="subtle" reversed vertical activeKey={active} onSelect={setActive} style={{ width: '100%', marginTop: '5%'}}>
                  <Nav.Item eventKey="Sites">Sites</Nav.Item>
                  <Nav.Item eventKey="Systems">Systems</Nav.Item>
                  <Nav.Item eventKey="Users">Users</Nav.Item>
                  <Nav.Item eventKey="Acumatica">Acumatica</Nav.Item>
                  <Nav.Item eventKey="Settings">Customer Settings</Nav.Item>
               </Nav>
            </NavigationPane>

            <SettingPane>
               { active ==='Users' ?
                  <CustomerUserListFC customer={customer} customerData={customerData}/>
               : active ==='Settings' ?
                  <CustomerSettingsFC customer={customerData}/>
               : active ==='Sites' ?
                  <CustomerSiteListFC customer={customer}/>
               : active ==='Systems' ?
                  <CustomerSystemsListFC customer={customer}/>
               : active ==='Acumatica' ?
                  <CustomerAcumaticaFC customer={customer}/>
               :
                  <></>
               }
            </SettingPane>           
         </_CustomerSC> 
   );
};

const _CustomerSC = styled.div`
   width: 100%;

   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
`;

const Header = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   padding-left: 2.5%;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const CustomerHeader = styled.div`
   display: block;
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   width: 100%;
`;

const CustomerLogo = styled.div`
   padding-right: 1%;
   float: right;
   position: absolute;
   right: 0;
   text-align-last: right;
`;

const CustomerInfo = styled.div`
   padding-top: 1%;
   width: 100%;
   display: grid;
`;

const NavigationPane = styled.div`
   float: left;
   width: 15%;
   padding-left: 2.5%;
`;

const SettingPane = styled.div`
   float: right;
   width: 85%;
`;

const UserName = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   text-align: center;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const ItemSubText = styled.div`
   font-size: 0.9em;
   color: #97969B;
   font-weight: normal;
   text-align: center;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const ImgSrcLogo = styled.img`
   height: 5.5vh;
`