import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosInstance from "../../../../../../appHelpers/axiosInstance";

const MachinesContext = React.createContext();

const MachinesContextProvider = (props) => {
  const [loadingMachines, setLoadingMachines] = useState(true);
  const [loadingNetworkIssues, setLoadingNetworkIssues] = useState(true);
  const [loadingHHMMachines, setLoadingHHMMachines] = useState(true);
  const [loadingDisplayRecentValues, setLoadingDisplayRecentValues] =
    useState(true);
  const [loadingDisplayRecentEDUValues, setLoadingDisplayRecentEDUValues] =
    useState(true);
  const [loadingDisplaySparklineData, setLoadingDisplaySparklineData] =
    useState(true);
  const [getMachinesError, setGetMachinesError] = useState(false);
  const [getHHMMachinesError, setGetHHMMachinesError] = useState(false);
  const [getDisplayRecentValuesError, setLoadingDisplayRecentValuesError] =
    useState(false);
  const [
    getDisplayRecentEDUValuesError,
    setLoadingDisplayRecentEDUValuesError,
  ] = useState(false);
  const [getDisplaySparklineDataError, setLoadingDisplaySparklineDataError] =
    useState(false);
  const [machines, setMachines] = useState();
  const [frozenSystems, setFrozenSystems] = useState();
  const [networkIssues, setNetworkIssues] = useState();
  const [HHMMachines, setHHMMachines] = useState();
  const [displaySparklineData, setDisplaySparklineData] = useState();
  const [displayRecentValues, setDisplayRecentValues] = useState();
  const [displayRecentEDUValues, setDisplayRecentEDUValues] = useState();
  const [displayEvents, setDisplayEvents] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [loadingDisplayEvents, setLoadingDisplayEvents] = useState(true);
  const [loadingDisplayFiles, setLoadingDisplayFiles] = useState(true);
  const [getDisplayEventsError, setGetDisplayEventsError] = useState(false);
  const [getDisplayFilesError, setGetDisplayFilesError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const getMachines = async () => {
    // console.log(`getMachines`);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/metadata`,
        data: ``,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setMachines(response.data[0]);
      setFrozenSystems(response.data[1]);
    } catch (error) {
      console.error(error);
      setGetMachinesError(true);
    }
    setLoadingMachines(false);
  };

  const getHHMMachines = async () => {
    // console.log(`getHHMMachines`);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/hhm/metadata`,
        data: ``,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setHHMMachines(response.data[0]);
    } catch (error) {
      console.error(error);
      setGetHHMMachinesError(true);
    }
    setLoadingHHMMachines(false);
  };

  const getSparklineData = async () => {
    //console.log(`getSparklineData`);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/recent-sparkline-data`,
        data: ``,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setDisplaySparklineData(response.data[0]);
    } catch (error) {
      console.error(error);
      setLoadingDisplaySparklineDataError(true);
    }
    setLoadingDisplaySparklineData(false);
  };

  const getNetworkIssues = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const res = await axiosInstance({
        url: `/network/issues`,
        data: ``,
        headers: { authorization: `Bearer ${token}` },
      });

      setNetworkIssues(res?.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingNetworkIssues(false);
  };

  const getRecentValues = async () => {
    //console.log(`getRecentValues`);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/recent-data`,
        data: ``,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setDisplayRecentValues(response.data[0]);
    } catch (error) {
      console.error(error);
      setLoadingDisplayRecentValuesError(true);
    }
    setLoadingDisplayRecentValues(false);
  };

  const getRecentEDUValues = async () => {
    //console.log(`getRecentValues`);
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/recent-edu-data`,
        data: ``,
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setDisplayRecentEDUValues(response.data[0]);
    } catch (error) {
      console.error(error);
      setLoadingDisplayRecentEDUValuesError(true);
    }
    setLoadingDisplayRecentEDUValues(false);
  };

  const getRecentEvents = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/hhm/recent-events`,
        data: ``,
        headers: { authorization: `Bearer ${token}` },
      });

      setDisplayEvents(response.data[0]);
    } catch (error) {
      console.error(error);
      setGetDisplayEventsError(true);
    }
    setLoadingDisplayEvents(false);
  };

  const getRecentFiles = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        url: `/systems/summary/hhm/recent-files`,
        data: ``,
        headers: { authorization: `Bearer ${token}` },
      });

      setDisplayFiles(response.data[0]);
    } catch (error) {
      console.error(error);
      setGetDisplayFilesError(true);
    }
    setLoadingDisplayFiles(false);
  };

  return (
    <MachinesContext.Provider
      value={{
        loadingMachines,
        loadingNetworkIssues,
        loadingHHMMachines,
        loadingDisplayEvents,
        loadingDisplayRecentValues,
        loadingDisplayRecentEDUValues,
        loadingDisplaySparklineData,
        loadingDisplayFiles,
        setLoadingMachines,
        setLoadingHHMMachines,
        setLoadingDisplayRecentValues,
        setLoadingDisplayRecentEDUValues,
        setLoadingDisplaySparklineData,
        setLoadingDisplayEvents,
        setLoadingDisplayFiles,
        machines,
        frozenSystems,
        networkIssues,
        HHMMachines,
        displayRecentValues,
        displayRecentEDUValues,
        displaySparklineData,
        displayEvents,
        displayFiles,
        getMachines,
        getNetworkIssues,
        getHHMMachines,
        getRecentEvents,
        getRecentValues,
        getRecentEDUValues,
        getSparklineData,
        getRecentFiles,
        getMachinesError,
        getHHMMachinesError,
        getDisplayRecentValuesError,
        getDisplayRecentEDUValuesError,
        getDisplaySparklineDataError,
        getDisplayEventsError,
        getDisplayFilesError
      }}
    >
      {props.children}
    </MachinesContext.Provider>
  );
};

export { MachinesContext, MachinesContextProvider };
