import React, { useContext, useEffect, useState, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { TabSelectorFC } from '../_helpers/TabSelectorFC';
import styled, { ThemeContext } from 'styled-components';
import { LoadingData } from './_helpers/LoadingData';
import { useParams } from 'react-router-dom';
import { MachineMetadataFC } from '../_helpers/displayFiles/MachineMetadataFC';
import { useAuth0 } from '@auth0/auth0-react';
import { _ChartIndex } from '../_helpers/charts/_ChartIndex';
import { _FusionTimeIndex } from '../_helpers/fusionCharts/_FusionTimeIndex';
import axiosInstance from '../../../../appHelpers/axiosInstance';
import { renderCount } from '../../../../appHelpers/appLoggers';
import { LevelSelectorFC } from '../_helpers/LevelSelectorFC';
import { ChartSelectorFC } from '../_helpers/ChartSelectorFC';
import { Loader_MachineMetadataFC } from '../_helpers/displayFiles/loaders/Loader_MachineMetadataFC';
import { _MMBAlertFC } from './_helpers/_MMBAlertFC';
import { FlexboxGrid, Message, Nav, Navbar } from 'rsuite';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import { MainDivider } from '../admin/helpers/Admin_StyledComponents';
import { DateTime } from 'luxon';
import { MagnetHeader } from './MagnetHeader';
import { UserContext } from '../_context/UserContext';
import { TableFC } from './_helpers/TableFC';
import LineChartIcon from '@rsuite/icons/LineChart';
import { getMessage } from '../_utils/util_general';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InputContext } from '../_context/InputContext';
import { Avatar } from '../../../../appHelpers/appCommonSC';
import { ExportMMB } from '../_helpers/export/ExportMMB';

export const _SystemFC = () => {
   //renderCount('_SystemFC');
   const {
      user,
      setUser,
      userRoles,
      getUserProfile,
      loadingUserRoles
   } = useContext(UserContext);
   const { getAccessTokenSilently } = useAuth0();
   const [selectedDataView, setSelectedDataView] = useState(`CHARTS`);
   const dataViewOptions = [`CHARTS`, `TABLE`, 'ALERTS']; //`LOG`, When the time is right :P

   // ADDED FIELDS
   const [loadingMetadata, setLoadingMetadata] = useState(true);
   const [loadingIssues, setLoadingIssues] = useState(true);
   const [loadingDataTable, setLoadingDataTable] = useState(true);
   const [loadingDataConfig, setLoadingDataConfig] = useState(true);
   const [loadingAlertConfig, setLoadingAlertConfig] = useState(true);
   const [metadata, setMetadata] = useState([]);
   const [issues, setIssues] = useState([]);
   const [dataTable, setDataTable] = useState([]);
   const [frozenData, setFrozenData] = useState([]);
   const [dataConfig, setDataConfig] = useState([]);
   const [alertConfig, setAlertConfig] = useState([]);
   const [chartData, setChartData] = useState([]);
   const [getMetadataError, setGetMetadataError] = useState(false);
   const [getDataTableError, setGetDataTableError] = useState(false);
   const [getDataConfigError, setGetDataConfigError] = useState(false);
   const [getAlertConfigError, setGetAlertConfigError] = useState(false);
   const [level, setLevel] = useState(1);
   const [chart, setChart] = useState(1);
   const { id, dataModel } = useParams();
   const history = useHistory();
   const [activeKey, setActiveKey] = useState("1");

   const {
      viewToggle,
      setViewToggle
   } = useContext(InputContext);
   

   const getMachineMetadata = async () => {
      // console.log(`getMachineMetadata`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/metadata/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });
         setMetadata(response.data[0][0]);
      } catch (error) {
         console.log(error);
         setGetMetadataError(true);
      }
      setLoadingMetadata(false);
   };

   const getNetworkIssues = async () => {
      setLoadingIssues(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
  
         const res = await axiosInstance({
            url: `/network/issues`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });
  
         setIssues(res?.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingIssues(false);
   };

   const getMachineDataTable = async () => {
      // console.log(`getMachineDataTable`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/data/${id}/0`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });
         setFrozenData(response.data[0].frozenData);
         setDataTable(cloneDeep(response.data));
         setChartData(cloneDeep(response.data));
      } catch (error) {
         setGetDataTableError(true);
         console.log(error);
      }
      setLoadingDataTable(false);
   };

   const getMachineDataConfig = async () => {
      // console.log(`getMachineDataConfig`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/config/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         setDataConfig(cloneDeep(response.data));
      } catch (error) {
         setGetDataConfigError(true);
         console.log(error);
      }
      setLoadingDataConfig(false);
   };

   const getSystemAlertConfig = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/alert/configs/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });

         setAlertConfig(cloneDeep(response.data[0]));
      } catch (error) {
         setGetAlertConfigError(true);
         console.log(error);
      }
      setLoadingAlertConfig(false);
   };

   const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
      return (
         window.screen.width >= 768 ?
         <Navbar {...props} style={{width: '100%', marginBottom: '1%'}}>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>{} {user.first_name ? `GOOD ${getMessage()}, ${user.first_name?.toUpperCase()}!`: null} </Nav.Item>
            </Nav>
             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}>|</Nav.Item>
            </Nav>
            <Nav onSelect={() => history.push(`/`)}>
               <Nav.Item eventKey="MMB" icon={<LineChartIcon />}>
                  MAGNET MONITORING
               </Nav.Item>
            </Nav>

             <Nav>
               <Nav.Item style={{pointerEvents: 'none'}}> / </Nav.Item>
            </Nav>

            <Nav onSelect={setViewToggle} activeKey={viewToggle}>
               <Nav.Item eventKey="MMB">
                  {id}
               </Nav.Item>
            </Nav>
            <Nav pullRight onSelect={() => history.push(`/admin/user/${user.email_address}`)}>
               <Nav.Item><Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar></Nav.Item>
            </Nav>
         </Navbar>    
         : null
      );
   };

   useEffect(() => {
      getMachineMetadata();
      getMachineDataTable();
      getMachineDataConfig();
      getSystemAlertConfig();
      getNetworkIssues();
      getUserProfile();
      setViewToggle('MMB');
   }, []);

   const memoMetadata = useMemo(
      () => <MachineMetadataFC metadata={metadata} networkIssues={issues}/>,
      [metadata]
   );

   const memoMagnetDataTable = useMemo(
      () => <TableFC metadata={metadata} dataTable={dataTable} dataConfig={dataConfig} filterLevel={level} alertConfigs={alertConfig}/>,
      [dataTable, dataConfig, level]
   );

   const memoMagnetChart = useMemo(
      () => <_FusionTimeIndex metadata={metadata} dataTable={dataTable} dataConfig={dataConfig} filterLevel={chart} engineerLevel={level} alertConfigs={alertConfig} />,
      [dataTable, dataConfig, chart, level]
   );

   const memoAlertConfigTable = useMemo(
      () => <_MMBAlertFC metadata={metadata} dataConfig={dataTable[0]?.chartConfig} dataTable={dataTable[0]?.dataTable[0]} alertConfigs={alertConfig} setAlertConfigs={setAlertConfig} refresh={getSystemAlertConfig}/>,
      [dataTable, dataConfig, alertConfig]
   );

   // CONTROLLING THE LOADING CONDITIONAL WORKS NICELY HERE
   // OTHERWISE, A CHECK IS NEEDED IN THE COMPONENT ITSELF FOR DESTRUCTORING
   // DATA FROM THE CONTEXT WHICH MAY OR MAY NOT HAVE LOADED YET
   const DataView = () => {
      switch (selectedDataView) {
         case `TABLE`:
            return (loadingDataTable || loadingDataConfig || loadingAlertConfig || loadingMetadata)  ? <LoadingData /> : !getDataTableError && !getDataConfigError && dataTable[0]?.dataTable.length > 0 ? memoMagnetDataTable : dataTable[0]?.dataTable.length === 0 ? <>No Data</> : <>Something Went Wrong</>; 
         case `CHARTS`:
            return (loadingDataTable || loadingDataConfig || loadingAlertConfig || loadingMetadata)  ? <LoadingData /> : !getDataTableError && !getDataConfigError && dataTable[0]?.dataTable.length > 0 ?  memoMagnetChart : dataTable[0]?.dataTable.length === 0 ? <>No Data</> :  <>Something Went Wrong</>; 
         case `ALERTS`:
            return (loadingAlertConfig || loadingMetadata) ? <LoadingData /> : !getAlertConfigError ?  memoAlertConfigTable : <>Something Went Wrong</>; 
         default:
            return <div>NON-CONFORMANT STATE: RemoteFilesFC:DataView</div>;
      }
   };

   return (
      <>
         <CustomNavbar  appearance='subtle' activeKey={activeKey} onSelect={setActiveKey} /> 

         {loadingMetadata && loadingIssues ? <Loader_MachineMetadataFC/> : memoMetadata}

         {!loadingDataTable && !loadingAlertConfig && dataTable[0]?.dataTable.length > 0?
            <MagnetHeader metadata={metadata} magnetData={dataTable[0]} dataConfig={dataConfig} filterLevel={level} alertConfigs={alertConfig} />
         : null
         }   

         {issues?.filter(issue => issue.id === metadata.id).length > 0 ?  
            issues?.filter(issue => issue.id === metadata.id).map(issue => (
               (!issue.resolved && issue.status === 'active') && (issue.processor_type ==='MMB' || issue.type === 'MAGNET') ?
               <Message showIcon type="warning" header="Warning" closable style={{width: '100%'}}>
                  This System is experiencing issues.
                  <MainDivider/>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={5}>
                        Reported: {DateTime.fromISO(issue.inserted_at).toFormat(
                           'ff'
                        )}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}>
                        Assigned: {issue.assigned}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}>
                        Type: {issue.type}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={12}>
                        Notes: {issue.notes}
                     </FlexboxGrid.Item>
                  </FlexboxGrid>
               </Message>
               : null
            ))        
         : null
         }

         {frozenData.map((frozen) => (
            <Message showIcon type="error" header="Error" closable style={{width: '100%'}}>
               This System has instances of FROZEN magnet data in the past 6 hours.
               <MainDivider/>           
                  <FlexboxGrid>
                     <FlexboxGridItem colspan={3}>
                        Occurences: {frozen.max}
                     </FlexboxGridItem>
                  </FlexboxGrid>    
                  <FlexboxGrid>
                     <FlexboxGridItem colspan={21}>
                        Occured Between: {DateTime.fromISO(frozen.arr_capture_datetime[0]).toFormat('ff')} - {DateTime.fromISO(frozen.arr_capture_datetime[frozen.arr_capture_datetime.length-1]).toFormat('ff')} 
                     </FlexboxGridItem>
                  </FlexboxGrid>            
            </Message>
         ))}
      
         <Controls>
            <TabSelectorFC
               options={dataViewOptions}
               setStateToOption={setSelectedDataView}
            />

            {window.screen.width >= 768 ?        
               selectedDataView == `CHARTS` ? 
                  <>
                     <ChartSelectorFC chart={setChart} />
                  </>
                  : null
            : null
            }

            {selectedDataView !== 'ALERTS' ?
               <LevelSelectorFC level={setLevel}/>
            : null
            }

            {dataTable[0] && (selectedDataView === `CHARTS` || selectedDataView === `TABLE`) ? 
               <>
                  <ExportMMB systems={[metadata]} recent_data={dataTable} engineerLevel={level} dataConfig={dataConfig} />
               </>
            : null
            }
         

         </Controls>
         
         <DataView />
      </>
   );
};

const Controls = styled.div`
   display: flex;
`;