import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { FlexboxGrid, IconButton, Input, InputGroup, List, Message, Radio, RadioGroup, SelectPicker, useToaster } from 'rsuite';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import { ItemSubText, MainDivider } from '../helpers/Admin_StyledComponents';
import { DateTime } from 'luxon';
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Close';
import { UpdateIssue } from './Modal/UpdateIssue';
import { InputContext } from '../../_context/InputContext';

export const NetworkIssueListFC = () => { 
   const history = useHistory();
   const toaster = useToaster();
   const [addSystem, setAddSystem] = useState('');
   // renderCount(`AdminFC`);
   const { getAccessTokenSilently } = useAuth0();
   const [issues, setIssues] = useState();
   const [displayIssues, setDisplayIssues] = useState();
   const [update, setUpdate] = useState(false);
   const [roles, setRoles] = useState();
   const [loadingNetworkIssues, setLoadingNetworkIssues] = useState([]);
   const [processorPicker, setProcessorPicker] = useState('All');
   const [resolvedPicker, setResolvedPicker] = useState('False');
   const [assignedPicker, setAssignedPicker] = useState(null);
   const [typePicker, setTypePicker] = useState(null);

   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);

   const assignedSelectData = ['R&D', 'Pending-Install', 'Customer', 'Network-Admin', 'Remote-Admin', 'Dev-Ops' ].map(item => ({
      label: item,
      value: item
   }));

   const selectDataType = ['OFFLINE', 'MAGNET', 'EDU'].map(item => ({
      label: item,
      value: item
   }));

   const getNetworkIssues = async () => {
      setLoadingNetworkIssues(true);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            url: `/network/issues`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setIssues(res?.data[0]);
      } catch (error) {
         console.error(error);
      }

      setLoadingNetworkIssues(false);
   };
   
   const handleAddSystem = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/network/add/issue`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: addSystem
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         setUpdate(!update);
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleRemoveSystem = async (e) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'PUT',
            url: `/network/delete/issue`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
               data:  JSON.stringify({
                  issue: {report_id: e}
               }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         setUpdate(!update);
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const filterIssues = () => {
      const tempDisplayIssues = issues?.filter(issue => (resolvedPicker === 'All' || ((issue.resolved ? 'True' : 'False') === resolvedPicker))
                                                && (processorPicker === 'All' || ((issue.processor_type === processorPicker)))
                                                && (assignedPicker === null || ((issue.assigned === assignedPicker)))
                                                && (typePicker  === null || ((issue.type === typePicker)))
                                                && (adminCustomerToggle === null ||(issue.customer_id === adminCustomerToggle))
      );

      setDisplayIssues(tempDisplayIssues);
   }

   useEffect(() => {
      getNetworkIssues();
   }, [update]);

   
   useEffect(() => {
      filterIssues();
   }, [issues, adminCustomerToggle]);

   useEffect(() => {
      filterIssues();
   }, [resolvedPicker, processorPicker, typePicker, assignedPicker]);

   return (
      <NetworkIssueListSC>
         <MainDivider/>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={5} align='center'>
                  <RadioGroup name="radioList_processor" inline appearance="picker" value={processorPicker} onChange={setProcessorPicker}>
                     <span style={styles}>Processor: </span>
                     <Radio value="All">All</Radio>
                     <Radio value="MMB">MMB</Radio>
                     <Radio value="HHM">HHM</Radio>
                  </RadioGroup>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={5} align='center'>
                  <RadioGroup name="radioList_resolved" inline appearance="picker" value={resolvedPicker} onChange={setResolvedPicker}>
                     <span style={styles}>Resolved: </span>
                     <Radio value="All">All</Radio>
                     <Radio value="True">Yes</Radio>
                     <Radio value="False">No</Radio>
                  </RadioGroup>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={5} align='center'>
                  <SelectPicker data={selectDataType} value={typePicker} onChange={setTypePicker} label="Type" placeholder="Default: All" block style={{width: '90%'}} searchable={false}/>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={5} align='center'>
                  <SelectPicker data={assignedSelectData} value={assignedPicker} onChange={setAssignedPicker} label="Assigned" placeholder="Default: All" block style={{width: '90%'}} searchable={false}/>
               </FlexboxGrid.Item>
               

               <FlexboxGrid.Item colspan={1}>
                  
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={3} align='right'>
                  <InputGroup>
                     <Input placeholder={'Add System ID'} value={addSystem} onChange={setAddSystem}/>
                     <InputGroup.Button onClick={handleAddSystem}>
                        <PlusIcon/>
                     </InputGroup.Button>
                  </InputGroup>
               </FlexboxGrid.Item>
            </FlexboxGrid>
            
         <MainDivider/>

         <RowHeader/>
         <List>
            {displayIssues?.map((issue) => (
               <List.Item>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                        {DateTime.fromISO(issue.inserted_at).toFormat(
                           'ff'
                        )}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} style={itemGridStyle}>
                        {issue.customer_name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={5} style={itemGridStyle}>
                        {issue.site_name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} style={itemGridStyle}>
                        {issue.id}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} style={itemGridStyle}>
                        {issue.type}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                        {issue.assigned ? issue.assigned : '-'}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                        {issue.resolved ? 'Yes' : 'No'}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={1} style={itemGridStyle}>
                        <UpdateIssue issue={issue} update={update} setUpdate={setUpdate}/>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={1} style={itemGridStyle}>
                        <IconButton icon={<CloseIcon color={'red'}/>} onClick={()=> handleRemoveSystem(issue.report_id)}/>
                     </FlexboxGrid.Item>
                     
                     <FlexboxGrid.Item colspan={24} style={{padding: 5}}>
                        
                     </FlexboxGrid.Item>

                     <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                        Notes:
                     </FlexboxGrid.Item>
                     
                     <FlexboxGrid.Item colspan={10} style={itemGridStyle}>
                        <ItemSubText>
                           {issue.notes}
                        </ItemSubText>                      
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={10} style={itemGridStyle}>
                        {issue.diagnoses ? issue.diagnoses : ''}
                     </FlexboxGrid.Item>
                  </FlexboxGrid>
               </List.Item>
            ))}           
         </List>
      </NetworkIssueListSC>
   );
};

const NetworkIssueListSC = styled.div`
   padding-left: 2.5%;
   padding-right: 2.5%;
`

const HeaderText = styled.div`
    font-size: 0.8em;
    color: #97969B;
`;
const headerStyle = {
   overflow: 'hidden',
   paddingTop: '1%'
};

const itemGridStyle = {
  
}; 

const RowHeader = () => (
   <FlexboxGrid style={{ paddingTop: 5, paddingBottom: 5 }}>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> Reported </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4} style={headerStyle}>
         <HeaderText> Customer </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={5} style={headerStyle}>
         <HeaderText> Site </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> ID </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> Type </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> Assigned </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> Resolved </HeaderText>
      </FlexboxGrid.Item>
   </FlexboxGrid>
);

const message = (
   <Message showIcon type="success" header="Success">
      You successfully added System to Network Issues List.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);

const styles = {
     width: '90%',
     fontSize: '14px',
     fontWeight: '400',
     padding: '7px 2px 7px 12px',
     display: 'inline-block',
     verticalAlign: 'middle'
 };