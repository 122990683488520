import styled from 'styled-components';

// MAIN USER PROFILE AVATAR (First/Last Name Initials)
export const Avatar = styled.div`
   width: 37px;
   height: 37px;
   border-radius: 50%;
   font-size: 14px;
   font-weight: bolder;
   font-family: roboto, arial;
   color: white;
   line-height: 37px;
   text-align: center;
   background: ${({ theme }) => theme.avante_dark_blue};
   margin: auto;
`;

export const SeverityAlert = styled.div`
   display: inline-block;
   width: 95%;
   padding-top: 1%;
   padding-bottom: 1%;
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
                             : severity === 'medium' ?  '#fda005'
                             : severity === 'low' ? 'darkgrey'
                             : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
                              : severity === 'medium' ?  '#ffeecc'
                              : severity === 'low' ? '#e6e5e3'
                              : '#eeffed'};
   text-align: center;
   border-radius: 5px;
   border: 1px solid #e5e5ea;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;

export const SeverityAlertRow = styled.div`
   width: 100%;
   padding-top: 1%;
   padding-bottom: 1%;
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
                             : severity === 'medium' ?  '#fda005'
                             : severity === 'low' ? 'darkgrey'
                             : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
                              : severity === 'medium' ?  '#ffeecc'
                              : severity === 'low' ? '#e6e5e3'
                              : '#eeffed'};
   border-radius: 5px;
   border: 1px solid #e5e5ea;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;