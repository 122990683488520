import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthBtnSC } from './AuthBtnFC';

const LoginBtnFC = () => {
   const { loginWithRedirect } = useAuth0();

   return <AuthBtnSC onClick={() => loginWithRedirect()}>Log In</AuthBtnSC>;
};

export default LoginBtnFC;
