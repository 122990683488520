import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import { Icon_Admin } from '../../images/icons/Icon_Admin';
import { Icon_Home } from '../../images/icons/Icon_Home';
import { Icon_Website } from '../../images/icons/Icon_Website';

export const NavigatorFC = () => {
   const { darkMode } = useContext(DarkModeContext);
   const { isAuthenticated } = useAuth0();

   return (
      <NavigatorSC darkMode={darkMode}>
         <li className='active'>
            <Link to="/">
               <Icon_Home />
            </Link>
         </li>
         {/* <li>
            <a href='https://remote.avantehs.com/'><Icon_Website/></a>
         </li> */}
         {/* <li>
            <Link to="/local-file">
               <Icon_Upload /> Local File
            </Link>
         </li> */}

         {isAuthenticated ?
            <>    
               <li>
                  <Link to="/admin">
                     <Icon_Admin />
                  </Link>
               </li>
            </>    
            : <></>
         }
      </NavigatorSC>
   );
};

const NavigatorSC = styled.nav`
   display: block;

   @media screen and (min-width: 769px) {
      padding-left: 25%;
      padding-top: 100px;
   }

   li {
      list-style-type: none;
      width: 100%;
   }

   a {
      display: inline-block;
      text-decoration: none;
      width: 100%;
      height: 100%;
      color: ${({ theme, darkMode }) =>
         darkMode ? theme.dark_accent : theme.light_accent};
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 1.25rem;
      font-weight: 600;

      :hover {
         padding-left: 0.25rem;
      }
      
      transition: padding-left 0.25s;
   }

   /* border: solid 1px black; */
`;
