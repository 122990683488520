import React, { useContext } from 'react';
import { DisplayMobileSidebarContext } from '../../appContexts/DisplayMobileSidebarContext';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import styled from 'styled-components';
import { MobileNavigatorFC } from './MobileNavigatorFC';
import { DarkOrLightModeSelectorFC } from '../aside/DarkOrLightModeSelectorFC';

export const _MobileSidebarFC = () => {
   const { displayMobileSidebar } = useContext(DisplayMobileSidebarContext);
   const { darkMode } = useContext(DarkModeContext);
   return (
      <MobileSidebarSC
         displayMobileSidebar={displayMobileSidebar}
         darkMode={darkMode}
      >
         <MobileNavigatorFC />
      </MobileSidebarSC>
   );
};

const MobileSidebarSC = styled.div`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;

   width: 50vw;
   height: 100vh;

   position: fixed;
   z-index: 2;
   top: 0;
   left: 65vw;
   background-color: ${({ theme, darkMode }) =>
         darkMode ? theme.dark_primary : theme.light_primary};
   border-left: 3px solid ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.light_accent};
};
/* 
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent}; */

   transform: ${({ displayMobileSidebar }) =>
      displayMobileSidebar ? `translateX(0)` : `translateX(100%)`};
   transition: transform 0.25s;
`;
