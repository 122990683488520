import React, { useState } from 'react';
// import { renderCount } from '../../../../appHelpers/appLoggers';
// import axios from 'axios';

// CONTEXT API METHODS
const getRowCount = async (
   // setRowCount,
   // setRowCountIsCached,
   adminIsMounted,
   setPerformingOnMountContextCheck
   // setLoadingAdmin
) => {
   // renderCount(`AdminContext - getRowCount`);
   try {
      // const response = await axios.get('http://localhost:3001/admin');
      // console.log(response);
      // setRowCount(response.data[0].estimate);
      // setRowCountIsCached(true);
   } catch (error) {
      console.error(error);
   }
   // AWAITS TRY/CATCH BLOCK
   if (adminIsMounted) {
      // ONLY UPDATE ADMIN STATE WHEN COMPONENT IS MOUNTED
      // ELSE THROWS UPDATE UNMOUNTED STATE 'NO-OP'
      setPerformingOnMountContextCheck(false);
   }
};

// CONTEXT COMPONENTS
const AdminContext = React.createContext();

const AdminContextProvider = (props) => {
   // renderCount(`AdminContext`);
   const [rowCount, setRowCount] = useState();
   const [rowCountIsCached, setRowCountIsCached] = useState(false);

   return (
      <AdminContext.Provider
         value={{
            rowCount: rowCount,
            setRowCount: setRowCount,
            getRowCount: getRowCount,
            rowCountIsCached: rowCountIsCached,
            setRowCountIsCached: setRowCountIsCached,
         }}
      >
         {props.children}
      </AdminContext.Provider>
   );
};

export { AdminContext, AdminContextProvider };
