import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { WelcomeFC } from './WelcomeFC';
import { _DashboardFC } from './dashboard/_DashboardFC';

export const _HomeFC = () => {
   const { isLoading, isAuthenticated } = useAuth0();

   return isLoading ? (
      // <div>loading...</div>
      <></>
   ) : isAuthenticated ? (
      <_DashboardFC />
   ) : (
      <WelcomeFC />
   );
};
