import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, Button, Input, TagPicker, ButtonGroup, Message, Schema, useToaster, InputNumber, Toggle, SelectPicker, Checkbox, CheckPicker, IconButton, toaster } from 'rsuite';
import styled from 'styled-components';
import { MainDivider, SettingHeader } from '../../helpers/Admin_StyledComponents';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';

export const GenerateConfigEDU = ({config, regex_models}) => { 
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();

   console.log(config);
   console.log(regex_models);
   
   const initConfig =  {
      system_id: config?.system_id ? config?.system_id : id,
      file_name: config?.file_name,
      regex_models: config?.regex_models,
      pg_tables: config?.pg_tables,
      schedule: config?.schedule,
   }

   // PREFILL DATA
   let fileNameSelectData = null;
   let pgTableSelectData = null;

   if (config) {
      fileNameSelectData = [...new Set([config?.file_name, "v2_edu", "v2_edu2"])].map(item => ({
         label: item,
         value: item
      }));

      pgTableSelectData = [...new Set(regex_models.filter(model => model.pg_table.includes('edu')).map(table => table.pg_table).concat(...config?.pg_tables))].map(item => ({
         label: item,
         value: item
      }));
   } else {
      fileNameSelectData = [...new Set(["v2_edu", "v2_edu2"])].map(item => ({
         label: item,
         value: item
      }));

      pgTableSelectData = [...new Set(regex_models.filter(model => model.pg_table.includes('edu')).map(table => table.pg_table))].map(item => ({
         label: item,
         value: item
      }));
   }
   


   const [regexModelsSelectData, setRegexModelsSelectData] = useState([]);
   const [formValueConfig, setFormValueConfig] = useState(initConfig);

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated this System.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   // UPDATE EDU CONFIG
   const updateConfigEDU = async () => {
      // console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            method: 'POST',
            url: `/network/update/config-edu`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               sme: id,
               config: formValueConfig,
               type: 'edu'
            })
         });
         toaster.push(message,'topCenter');
      } catch (error) {
         toaster.push(errMessage,'topCenter');
         console.error(error);
      }
   };

   const resetForm = () => {
      setFormValueConfig(initConfig);
   }

   const handleSetFormValueConfig = (event) => {
      setFormValueConfig(event);
   }

   const updateRegexModels = () => {
      if (formValueConfig.file_name?.includes('edu2')) {
         if (formValueConfig.regex_models?.length > 0) {
            setRegexModelsSelectData([...new Set(regex_models.filter(model => model.pg_table?.includes('edu.v2')).map(config => config.id).concat(formValueConfig.regex_models))].map(item => ({
               label: item,
               value: item
            })));
         } else {
            setRegexModelsSelectData([...new Set(regex_models.filter(model => model.pg_table?.includes('edu.v2')).map(config => config.id))].map(item => ({
               label: item,
               value: item
            })));
         }
         
      } else {
         if (formValueConfig.regex_models?.length > 0) {
            setRegexModelsSelectData([...new Set(regex_models.filter(model => model.pg_table?.includes('edu.v1')).map(config => config.id).concat(formValueConfig.regex_models))].map(item => ({
               label: item,
               value: item
            })));
         } else {
            setRegexModelsSelectData([...new Set(regex_models.filter(model => model.pg_table?.includes('edu.v1')).map(config => config.id))].map(item => ({
               label: item,
               value: item
            })));
         }
      }
   }

   useEffect(() => {
      updateRegexModels();
   }, [regex_models]);

   useEffect(() => {
      updateRegexModels();
   }, [formValueConfig.file_name]);
   
   return (
      <ConfigBox>
         <MainDivider/>
         <SettingHeader>
            EDU Config
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <Button appearance="primary" onClick={updateConfigEDU}>Submit</Button>
               <Button appearance="default" onClick={resetForm}>Reset</Button>
            </ButtonGroup> 
         </SettingHeader>

         <MainDivider/>

         <Form fluid
            formValue={formValueConfig}
            onChange={(event) => handleSetFormValueConfig(event)}
         >
            <Form.Group controlId='system_id' style={{width: '20%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>System ID</Form.ControlLabel>
               <Form.Control name="system_id" disabled/>
            </Form.Group>
            <Form.Group controlId='file_name' style={{width: '20%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>File Name</Form.ControlLabel>
               <Form.Control name="file_name" accepter={SelectPicker} data={fileNameSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
            </Form.Group>
            <Form.Group controlId='pg_tables' style={{width: '35%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>PG Tables</Form.ControlLabel>
               <Form.Control name="pg_tables" accepter={CheckPicker} data={pgTableSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
            </Form.Group>
            <Form.Group controlId='schedule' style={{width: '15%', marginLeft: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Schedule</Form.ControlLabel>
               <Form.Control name="schedule" accepter={InputNumber} min={0}/>
            </Form.Group>      
            <Form.Group controlId='regex_models' style={{width: '100%'}}>
               <Form.ControlLabel>Regex Models</Form.ControlLabel>
               <Form.Control name="regex_models" accepter={CheckPicker} data={regexModelsSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
            </Form.Group>    
         </Form>
         <MainDivider/>
      </ConfigBox>
   )
}

const ConfigBox = styled.div`
   display: block;
`