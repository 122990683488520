import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutBtnFC from './LogoutBtnFC';
import LoginBtnFC from './LoginBtnFC';
import styled from 'styled-components';

export const AuthBtnFC = () => {
   const { isLoading, isAuthenticated } = useAuth0();
   // console.log(`AuthBtnFC -> ${isAuthenticated}`);

   return isLoading ? (
      // <div>loading</div>
      <></>
   ) : isAuthenticated ? (
      <LogoutBtnFC />
   ) : (
      <LoginBtnFC />
   );
};

// THIS GETS USED BY BOTH LogoutBtnFC AND LoginBtnFC
export const AuthBtnSC = styled.button`
   background-color: orangered;
   color: white;
   border: none;
   border-radius: 4px;
   padding: 1rem;
   position: absolute;
   right: 5%; // OF HeaderSC
   top: 50%; // OF HeaderSC
   transform: translateY(-50%);

   :hover {
      cursor: pointer;
   }

   display: none;

   // AT COMMON TABET SCREEN SIZE AND GREATER
   // MAKE BUTTON SMALLER
   // PROPERTIES ADOPTED AT >= 769px
   @media screen and (min-width: 769px) {
      display: block;
   }
`;

export const AuthOutBtnSC = styled.button`
   color: white;
   border: none;
   border-radius: 4px;
   padding: 0.75rem;
   position: absolute;
   right: 5%; // OF HeaderSC
   top: 50%; // OF HeaderSC
   transform: translateY(-50%);

   :hover {
      cursor: pointer;
      background-color: orangered;

      svg {
         fill: white;
      }
   }

   transition: 0.75s;
   display: none;

   // AT COMMON TABET SCREEN SIZE AND GREATER
   // MAKE BUTTON SMALLER
   // PROPERTIES ADOPTED AT >= 769px
   @media screen and (min-width: 769px) {
      display: block;
   }
`;
