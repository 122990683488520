import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import { AuthBtnMobileFC } from '../../auth/AuthBtnMobileFC';
import { Icon_Admin } from '../../images/icons/Icon_Admin';
import { Icon_Dashboard } from '../../images/icons/Icon_Dashboard';
import { Icon_Website } from '../../images/icons/Icon_Website';
import { DarkOrLightModeSelectorFC } from '../aside/DarkOrLightModeSelectorFC';

export const MobileNavigatorFC = () => {
   const { darkMode } = useContext(DarkModeContext);
   const { isLoading, isAuthenticated } = useAuth0();

   return (
      <MobileNavigatorSC darkMode={darkMode}>
         <ButtonIcon><DarkOrLightModeSelectorFC/></ButtonIcon>
         <li>
            <Link to='/'><Icon_Dashboard/> Home </Link>
         </li>
         {/* <li>
            <a href='https://remote.avantehs.com/'><Icon_Website/> Prev. Website</a>
         </li> */}
         {/* <li>
            <Link to='/local-file'><Icon_Upload/> Local File </Link>
         </li> */}
         {isAuthenticated ?        
            <li>
               <Link to="/admin">
                  <Icon_Admin /> Admin
               </Link>
            </li>
            : <></>
         }
         <li>
            <AuthBtnMobileFC/>
         </li>
      </MobileNavigatorSC>
   );
};

const MobileNavigatorSC = styled.nav`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: flex-start;
   width: 100%;
   padding-top: 20vh;
   background-color: ${({ theme, darkMode }) =>
         darkMode ? theme.dark_primary : theme.light_primary};

   li {
      list-style-type: none;
      width: 100%;
   }

   a {
      display: inline-block;
      text-decoration: none;
      width: 100%;
      height: 100%;
      color: ${({ theme, darkMode }) =>
         darkMode ? theme.dark_accent : theme.light_accent};
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.75rem;
      font-size: 3vw;
      font-weight: 600;
      letter-spacing: 1px;

      :hover {
         background-color: ${({ theme, darkMode }) =>
            darkMode ? theme.dark_primary : theme.light_primary};
         padding-left: 1rem;
      }
      transition: padding 0.25s;
   }

   @media screen and (min-width: 769px) {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 1px;
   }

   /* border: solid 1px black; */
`;

const ButtonIcon = styled.div`
   display: inline-block;
   margin-left: 28%;
`;