import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

const LoginRouteFC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    // Automatically redirect to login if not authenticated
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      // Redirect to the home page if the user is already authenticated
      history.push('/');
    }
  }, [isAuthenticated, history]);

  return (
    <></>
  );
};

export default LoginRouteFC;