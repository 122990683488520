import React, { useContext } from 'react';
import { renderCount } from '../../appHelpers/appLoggers';
import { UserNotificationPaneContext } from '../../appContexts/UserNotificationPaneContext';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import styled, { keyframes } from 'styled-components';

export const UserNotificationPaneFC = () => {
   // ENV CONDITIONALS
   const { REACT_APP_LOGGER_RENDER_COUNT } = process.env;
   REACT_APP_LOGGER_RENDER_COUNT === 'TRUE' &&
      renderCount(`./UserNotificationPaneFC`);

   const { globalMessagePane, setGlobalMessagePane } = useContext(
      UserNotificationPaneContext
   );
   const { display, type, message } = globalMessagePane;

   const { darkMode } = useContext(DarkModeContext);

   // TODO: STUDY ? VS && CONDITIONAL RENDER STATE CSS TRANSITIONS
   return (
      display && (
         <GlobalMessagePaneSC type={type} darkMode={darkMode}>
            <HeaderSC type={type} darkMode={darkMode}>
               <>
                  {type}
                  <CloseButton
                     onClick={() =>
                        setGlobalMessagePane({
                           display: false,
                           type: ``,
                           message: ``,
                        })
                     }
                  >
                     X
                  </CloseButton>
               </>
            </HeaderSC>
            <BodySC darkMode={darkMode}>{message}</BodySC>
         </GlobalMessagePaneSC>
      )
   );
};

const RevealGMPSC = keyframes`
  0% { opacity: 0; top: 0; }
  100% { opacity: 1; top: 20vh;}
`;

const GlobalMessagePaneSC = styled.div`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;

   height: 30vh;
   width: 75vw;

   position: absolute;
   transition: top 1s;
   left: 50%;
   transform: translateX(-50%);
   z-index: 1;

   border: solid 2px
      ${({ theme, type, darkMode }) =>
         type === `error`
            ? theme.error
            : darkMode
            ? theme.dark_accent
            : theme.light_accent};
   border-radius: 3px;

   box-shadow: ${({ darkMode }) =>
      darkMode ? `3px 3px 3px black` : ` 1px 1px 10px grey`};

   // KEYFRAME ATTRIBUTES
   opacity: 0;
   top: 0;
   animation: ${RevealGMPSC};
   animation-duration: 0.25s;
   animation-fill-mode: forwards;

   // REDUCE WIDTH AT >= 769px, COMMON TABET SCREEN SIZE
   @media screen and (min-width: 769px) {
      width: 45vw;
   }
`;

const HeaderSC = styled.div`
   display: grid;
   place-items: center;
   width: 100%;
   height: 25%;

   position: relative;

   background-color: ${({ theme, type, darkMode }) =>
      type === `error`
         ? theme.error
         : darkMode
         ? theme.dark_accent
         : theme.light_accent};
   color: white;

   font-size: 2rem;
   font-weight: bold;
`;

const CloseButton = styled.div`
   height: 100%; // OF HeaderSC
   position: absolute;
   top: 0;
   right: 0;

   font-size: 0.75rem;
   :hover {
      cursor: pointer;
   }
`;

const BodySC = styled.div`
   display: grid;
   place-items: center;
   width: 100%;
   height: 75%;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_secondary : theme.light_secondary};
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   padding: 5%;
   font-size: 1.25rem;
`;
