import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, IconButton, Toggle } from 'rsuite';
import { ListHeader, MainDivider } from '../../../admin/helpers/Admin_StyledComponents';
import { SeverityAlert } from '../../../../../../appHelpers/appCommonSC';
import { DateTime } from 'luxon';
import { UserContext } from '../../../_context/UserContext';
import { EditAlertFC } from '../EditAlertFC';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TrashIcon from '@rsuite/icons/Trash';
import { AddAlertFC } from '../AddAlertFC';

export const AlertDefaultFC = ({ metadata, displayAlertConfigs, dataConfig, dataTable, handleDelete, handleEnabled, refresh }) => {
   const { id } = useParams();
   const {userRoles} = useContext(UserContext);
   
   return (
      <AlertDefaultSC>
         <HeaderText>Default Alerts {dataTable ? <AddAlertFC dataConfig={dataConfig} dataTable={dataTable} sme={id} refresh={refresh}/> : null } </HeaderText>

         <MainDivider/>

         <ListHeader>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  SEVERITY
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={4} style={{paddingLeft: 10}}>
                  FIELD NAME
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  OPERATOR
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={3}  align='center'>
                  THRESHOLD
               </FlexboxGrid.Item> 
               
               <FlexboxGrid.Item colspan={2}  align='center'>
                  CURRENT STATUS
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={5}  align='center'>
                  MESSAGE
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  FREQUENCY
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  ENABLED
               </FlexboxGrid.Item>              
            </FlexboxGrid>
         </ListHeader>
         <List size="sm">
            {displayAlertConfigs.filter(config => (config.user_id === 'default')).map(config => (
               <List.Item key={config.id} value={config}>
                  <FlexboxGrid>
                  <FlexboxGrid.Item colspan={2}  align='center'>
                        <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} style={{paddingLeft: 10}}>
                        {config?.field_name_alias}: <SmallText>{config?.field_name}</SmallText>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        {config?.operator?.replace(/_/g, " ")} 
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}  align='center'>
                        {config?.threshold} {config?.threshold_units}
                     </FlexboxGrid.Item>
                     
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        {dataTable ?
                           metadata.he_tank_volume && (config.field_name === 'he_level_value' || config.field_name === 'he_level_1_value') ?
                              config?.operator === 'greater_than' && ((parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)) > parseFloat(config?.threshold) ?
                                 <SeverityAlert severity={config?.severity}>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                              : config?.operator === 'less_than'  && ((parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2))  < parseFloat(config?.threshold)  ?
                                 <SeverityAlert severity={config?.severity}>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                              : config?.operator === 'delta_neg' ?
                                 null    
                              : <SeverityAlert>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                           : config?.operator === 'greater_than' && parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold) ?
                              <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                           : config?.operator === 'less_than'  && parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold) ?
                              <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                           : config?.operator === 'equals' && config.field_name === 'comp_vib_status'  ?
                              config.threshold === 0 ? <SeverityAlert severity={config?.severity}>OFF</SeverityAlert> : <SeverityAlert>ON</SeverityAlert>
                           : (config?.operator === 'offline' || config?.operator === 'frozen_host') && Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours) > parseFloat(config?.threshold) ?
                              <SeverityAlert severity={config?.severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>       
                           : (config?.operator === 'offline' || config?.operator === 'frozen_host')?
                              <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>   
                           : config?.operator === 'delta_neg' ?
                              null
                           :
                           <SeverityAlert>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                           : null
                        }             
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={5} align='center'>
                        {dataTable ?
                           config?.operator === 'greater_than' && parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold) ?
                              `${dataTable[config?.field_name]} ${config?.threshold_units} is greater than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                           : config?.operator === 'less_than' && parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold) ?
                              `${dataTable[config?.field_name]} ${config?.threshold_units} is less than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                           : null
                        : null
                        }                       
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}  align='center'>
                        <b>{config.notify_frequency}</b> hours
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        <div>
                           {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                              <Toggle checked={config.enabled} onClick={() => {handleEnabled(config.id)}}/>
                              : null
                           }   
                        </div>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        <div>
                           {dataTable ?
                              userRoles.includes('superuser') || userRoles.includes('alerter') ?  
                              <>   
                                 <EditAlertFC config={config} dataConfig={dataConfig} dataTable={dataTable} sme={id} refresh={refresh}/>
                                 <IconButton icon={<TrashIcon color='red'/>} style={{padding: '5px', marginLeft: '5px', display: 'inline-block'}} onClick={() => {handleDelete(config.id)}} />
                              </>
                              : null
                           : null }            
                        </div>
                     </FlexboxGrid.Item>
                  </FlexboxGrid>
               </List.Item>
            ))}
         </List>    
      </AlertDefaultSC>
   );
};

const AlertDefaultSC = styled.div`
   width: 85vw;
`;

const SmallText = styled.div `
   display: inline-block;
   font-size: 0.85em;
   color: rgb(191, 191, 191);
   
   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;

const HeaderText = styled.div `
   width: 100%;
   font-size: 1.3em;

   display: inline-block;

   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;