import React, { useState, useEffect } from 'react';
import { renderCount } from './appHelpers/appLoggers';
import { ThemeProvider } from 'styled-components';
import { appTheme } from './appHelpers/appTheme';
import { DarkModeContextProvider } from './appContexts/DarkModeContext';
import { _AsideFC } from './components/aside/_AsideFC';
import { _MainFC } from './components/main/_MainFC';
import { DisplayMobileSidebarContextProvider } from './appContexts/DisplayMobileSidebarContext';
import { UserNotificationPaneContextProvider } from './appContexts/UserNotificationPaneContext';
import { _MobileSidebarFC } from './components/mobileSidebar/_MobileSidebarFC';
import { _HeaderFC } from './components/main/header/_HeaderFC';
import styled from 'styled-components';

const AppFC = () => {
   // ENV CONDITIONALS
   // const { REACT_APP_LOGGER_RENDER_COUNT } = process.env;
   // REACT_APP_LOGGER_RENDER_COUNT === 'TRUE' && renderCount(`./AppFC`);

   // renderCount(`./AppFC`);
   // Check if the current domain is the old domain
   if (window.location.hostname === 'remote2.avantehs.com') {
      // Redirect to the new domain
      window.location.href = 'https://avanteconnected.com';
      // You can also return null or an empty component if you want to prevent further rendering
      return null;
   }

   return (
      <ThemeProvider theme={appTheme}>
         <DarkModeContextProvider>
            <DisplayMobileSidebarContextProvider>
               <UserNotificationPaneContextProvider>
                  <AppSC>
                     <_HeaderFC />
                     <_MainFC />
                     <_AsideFC />                    
                     <_MobileSidebarFC />
                  </AppSC>
               </UserNotificationPaneContextProvider>
            </DisplayMobileSidebarContextProvider>
         </DarkModeContextProvider>
      </ThemeProvider>
   );
};

const AppSC = styled.div`
   width: 100vw;
   height: 100vh;
   background-color: ${({ theme }) => theme.light_primary};
   position: relative;
   overflow-x: hidden;
`;

export default AppFC;
