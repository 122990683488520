import React, { useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { IconButton, Form, Modal, Button, Divider, Message, useToaster } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

export const EditSite = ({site, update, setUpdate}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const initFormSite = {
      id: site.id,
      name: site.name,
      address: site.street_address,
      city: site.city,
      state: site.state,
      zip: site.zip,
   }
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [formValue, setFormValue] = useState(initFormSite);

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated {site?.name}.
      </Message>
   );

   const onSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/update/site/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               customer_id: site.customer_id,
               site: formValue
            }),
         });
         setUpdate(!update);
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         handleClose();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }
   
   return (
      <>
         <IconButton icon={<EditIcon />} onClick={handleOpen}/>
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Edit Site: {site?.name}</Modal.Title>
            </Modal.Header>
            <Divider/>
            <Modal.Body>
            <Form fluid
                  formValue={formValue}
                  onChange={formValue => setFormValue(formValue)}
            >
                  <Form.Group controlId="id">
                     <Form.ControlLabel>ID</Form.ControlLabel>
                     <Form.Control name="id" readOnly disabled/>
                  </Form.Group>
                  <Form.Group controlId="name">
                     <Form.ControlLabel>Name</Form.ControlLabel>
                     <Form.Control name="name" />
                  </Form.Group>  
                  <Form.Group controlId="address">
                     <Form.ControlLabel>Address</Form.ControlLabel>
                     <Form.Control name="address" />
                  </Form.Group>     
                  <Form.Group controlId="city">
                     <Form.ControlLabel>City</Form.ControlLabel>
                     <Form.Control name="city" />
                  </Form.Group>
                  <Form.Group controlId="state">
                     <Form.ControlLabel>State</Form.ControlLabel>
                     <Form.Control name="state" />
                  </Form.Group>      
                  <Form.Group controlId="zip">
                     <Form.ControlLabel>Zip</Form.ControlLabel>
                     <Form.Control name="zip" />
                  </Form.Group>            
            </Form>
               
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit}>
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </>
   )
};

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);