import React, { useEffect, useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { IconButton, Form, Modal, Button, SelectPicker, Divider, Message, InputNumber, useToaster, Toggle } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { SeverityAlert } from '../../../../../appHelpers/appCommonSC';
import { DateTime } from 'luxon';

export const AddAlertFC = ({dataConfig, dataTable, sme, refresh}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const initFormSite = {
      user_id: '',
      system_id: sme,
      field_name: '',
      operator: '',
      threshold: '',
      threshold_units: '',
      threshold_offset: '',
      severity: '',
      notify_frequency: ''
   } 
   let fieldSelector = Object.keys(dataTable).filter(key => ['he_level_value', 'helium_level_value', 'he_psi_avg_value', 'capture_datetime', 'he_level_1_value', 'he_level_2_value', 'mag_psia_value', 'he_pressure_value', 'comp_vib_status', 'shield_temp_value', 'host_datetime'].includes(key)).map(operator => ({label: operator, value: operator}));
   const [operatorSelector, setOperatorSelector] = useState(['less_than', 'greater_than', 'equals', 'delta_neg', 'offline', 'frozen_host'].map(operator => ({label: operator.replace(/_/g, " "), value: operator})));
   const severitySelector = ['high', 'medium'].map(operator => ({label: operator, value: operator}));
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [disable, setDisable] = useState(true);
   const [formValue, setFormValue] = useState(initFormSite);
   const message = (
      <Message showIcon type="success" header="Success">
         You successfully added an alert to: {sme}
      </Message>
   );
   
   const handleEnable = () => {
      if (formValue.field_name && formValue.severity && formValue.operator && formValue.notify_frequency) {
         setDisable(false);
      } else {
         setDisable(true);
      }
   }

   const onSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/insert/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: sme,
               model: formValue
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         refresh();
         handleClose();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }
   
   const handleOperatorSelector = () => {
      if(formValue?.field_name === 'capture_datetime') {
         setOperatorSelector(['offline'].map(operator => ({label: operator.replace(/_/g, " "), value: operator})));
         setFormValue({...formValue, operator: 'offline'})
      } else if(formValue?.field_name === 'host_datetime') {
         setOperatorSelector(['frozen_host'].map(operator => ({label: operator.replace(/_/g, " "), value: operator})));
         setFormValue({...formValue, operator: 'frozen_host'})
      }  else if (formValue?.field_name === 'comp_vib_status') {
         setOperatorSelector(['equals'].map(operator => ({label: operator.replace(/_/g, " "), value: operator})));
         setFormValue({...formValue, operator: 'equals'})
      } else {
         setOperatorSelector(['less_than', 'greater_than', 'equals', 'delta_neg'].map(operator => ({label: operator.replace(/_/g, " "), value: operator})));
         setFormValue({...formValue, operator: ''})
      }
   }

   useEffect(() => {
      handleEnable();
   }, [formValue]);

   useEffect(() => {
      handleOperatorSelector();
   }, [formValue.field_name]);

   return (
      <AddAlertSC>
         <IconButton icon={<AddOutlineIcon />} onClick={handleOpen}> Add Default Alert </IconButton>
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Add Alert To: {sme}</Modal.Title>
            </Modal.Header>

            <Divider/>

            <PreviewHeader>
               {formValue.severity ? 
                  <SeverityHeader severity={formValue.severity}><b>{formValue.severity?.toUpperCase()} SEVERITY ALERT </b> </SeverityHeader>
                  : null
               }
            </PreviewHeader>

            <PreviewHeader>
               {formValue.operator === 'offline' ?
                  <b>System Offline</b>
               :formValue.operator === 'frozen_host' ?
                  <b>Frozen Host Data</b>
               :
                  <b>{dataConfig.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.uilabel : formValue.field_name}</b>
               }
               
            </PreviewHeader>
               
            {formValue.field_name && formValue.operator ?
               formValue.operator === 'offline' ?
               <div style={{paddingTop: '2.5%', paddingBottom:'2.5%'}}>
                  Trigger When: System is Offline within past {formValue.threshold} hour(s). 
               </div>
               : formValue.field_name === 'host_datetime' ? 
               <div style={{paddingTop: '2.5%', paddingBottom:'2.5%'}}>
                 Trigger When: System has Frozen data within past {formValue.threshold} hour(s). 
               </div>
               : formValue.field_name === 'comp_vib_status' ? 
               <div style={{paddingTop: '2.5%', paddingBottom:'2.5%'}}>
                  Trigger When: Compressor is {formValue.threshold === true ? 'ON' : 'OFF'}!
               </div>
               :
               <div style={{paddingTop: '2.5%', paddingBottom:'2.5%'}}>
                  Trigger When: {dataConfig.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.uilabel : formValue.field_name} is {formValue.operator.replace(/_/g, " ")} <b>·</b> {formValue.threshold} {dataConfig.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.unit : null}
               </div>
               :
               null
            }
            <PreviewHeader>
               <b>{formValue.notify_frequency ? `Notify Every ${formValue.notify_frequency } hours` : null}</b>
            </PreviewHeader>
            <br/>
            <div>
               Current Severity/Value: {formValue.operator === 'greater_than' && parseFloat(dataTable[formValue.field_name]) > formValue.threshold ?
                  <SeverityAlert severity={formValue.severity}><b>{dataTable[formValue.field_name]} {dataConfig?.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.unit : null}</b></SeverityAlert>
                  :  formValue.operator === 'less_than' && parseFloat(dataTable[formValue.field_name]) < formValue.threshold ?
                  <SeverityAlert severity={formValue.severity}><b>{dataTable[formValue.field_name]} {dataConfig?.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.unit : null}</b></SeverityAlert>
                  :  formValue.operator === 'equals' && formValue.field_name === 'comp_vib_status' ? 
                     dataTable[formValue.field_name] === (formValue.threshold.toString() === 'true' ? 1 : 0)  ?
                        <SeverityAlert severity={formValue.severity}><b>{dataTable[formValue.field_name] === 1 ? 'ON' : 'OFF'}</b></SeverityAlert>
                     : <SeverityAlert><b>{dataTable[formValue.field_name] === 1 || dataTable[formValue.field_name]  === true ? 'ON' : 'OFF'}</b></SeverityAlert>
                  : formValue.operator === 'offline' && formValue.field_name === 'capture_datetime' ?
                     Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours) > parseFloat(formValue.threshold) ?
                     <SeverityAlert severity={formValue.severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours)} {' Hr(s)'}</SeverityAlert>
                     : <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours)} {' Hr(s)'}</SeverityAlert>
                  : formValue.operator === 'frozen_host' && formValue.field_name === 'host_datetime' ?
                     Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours) > parseFloat(formValue.threshold) ?
                     <SeverityAlert severity={formValue.severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours)} {' Hr(s)'}</SeverityAlert>
                     : <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[formValue?.field_name]), 'hours').hours)} {' Hr(s)'}</SeverityAlert>
                  :
                  <SeverityAlert severity={'ok'}>{dataTable[formValue.field_name]} {dataConfig?.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.unit : null}</SeverityAlert>
               }
            </div>

            <Divider/>

            <Modal.Body>
               <Form fluid
                  formValue={formValue}
                  onChange={formValue => setFormValue(formValue)}
               >
                  <Form.Group controlId="field_name">
                     <Form.ControlLabel>Field Name</Form.ControlLabel>
                     <Form.Control name="field_name" accepter={SelectPicker} data={fieldSelector} searchable={false} block/>
                  </Form.Group>  
                  <Form.Group controlId="severity">
                     <Form.ControlLabel>Severity</Form.ControlLabel>
                     <Form.Control name="severity" accepter={SelectPicker} data={severitySelector} searchable={false} block/>
                  </Form.Group>   
                  <Form.Group controlId="operator">
                     <Form.ControlLabel>Operator</Form.ControlLabel>
                     <Form.Control name="operator" accepter={SelectPicker} data={operatorSelector} searchable={false} block/>
                  </Form.Group>
                  {formValue.field_name === 'comp_vib_status' ?
                     <Form.Group controlId="threshold">
                        <Form.ControlLabel>Threshold</Form.ControlLabel>
                        <Form.Control name="threshold" accepter={Toggle} block/>
                     </Form.Group>
                  :
                     <Form.Group controlId="threshold">
                        <Form.ControlLabel>Threshold</Form.ControlLabel>
                        <Form.Control name="threshold" accepter={InputNumber} step={1} block postfix={dataConfig?.find(label => label.pgcolumn === formValue.field_name) ? dataConfig.find(label => label.pgcolumn === formValue.field_name)?.unit : null}/>
                     </Form.Group>
                  }
                  <Form.Group controlId="notify_frequency">
                     <Form.ControlLabel>Notify Frequency</Form.ControlLabel>
                     <Form.Control name="notify_frequency" accepter={InputNumber} step={1} postfix="hours" min={0} max={72} block/>
                  </Form.Group>              
               </Form>            
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit} disabled={disable}>
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </AddAlertSC>
   )
};

const AddAlertSC  = styled.div`
   display: inline-block;
   padding-left: 1%;

   .rs-input-group-addon {
      width: 60px;
      justify-content: center;
   }
`

const PreviewHeader  = styled.div`
   color: rgb(0, 91, 148);
   background-color: rgb(243, 248, 252);
   display: block;
   text-align: center;
`

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);

const SeverityHeader = styled.div`
   padding: 1%;
   border-radius: 5px;
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
                             : severity === 'medium' ?  '#fda005'
                             : severity === 'low' ? 'darkgrey'
                             : 'white'};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
                              : severity === 'medium' ?  '#ffeecc'
                              : severity === 'low' ? '#e6e5e3'
                              : 'green'};
`;

