import React, { useState, useEffect } from 'react';
import { Button, Divider, FlexboxGrid, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import { MainDivider } from '../admin/helpers/Admin_StyledComponents';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../appHelpers/axiosInstance';

export const FileHeader = ({recent_files}) => {
   const { getAccessTokenSilently } = useAuth0();

   const getRecentFile = async (system_id, file_name) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
  
         const response = await axiosInstance({
            url: `/file/download?sme=${system_id}&file_name=${encodeURIComponent(file_name)}`,
            method: 'GET', // Ensure it's a GET request
            responseType: 'blob', // Important to specify 'blob' to handle binary data
            headers: { authorization: `Bearer ${token}` },
         });

         // Convert the response to a blob
         const blob = response.data; // Get the file data as a Blob
         const url = window.URL.createObjectURL(blob); // Create a download URL for the blob
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `${system_id}_${file_name}`); // Set the download attribute with the file name
         document.body.appendChild(link);
         link.click(); // Programmatically click the link to trigger the download
         link.remove(); // Clean up the link after download
      } catch (error) {
         console.error(error);
      }
   }

   useEffect(() => {
      
   }, [recent_files]);

   return (
      recent_files?.files ?
         <MagnetHeaderSC>
            <ValueHeader>Download Recent File(s)</ValueHeader>
            <FlexboxGrid>         
               {recent_files?.files?.map(file => (
                  <FlexboxGrid.Item colspan={4}  key={file}>
                     <FileDownloadIcon/>
                     <Button appearance="link" active  onClick={() => getRecentFile(recent_files.system_id, file)}>
                        {file}
                     </Button>
                  </FlexboxGrid.Item>
               ))}
            </FlexboxGrid>
         </MagnetHeaderSC>
      : null
   );
};


const MagnetHeaderSC = styled.div`
   padding-top: 1%;
   display: block;
   width: 89vw;
   text-align: left;
`

const RecentValueBlock = styled.div`

`

const ValueHeader = styled.div`
   display: block;
   font-size: 0.8em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

