import styled from "styled-components";

const SettingHeader = styled.div`
  font-family: inherit;
  font-size: 1.5vw;
  font-weight: 400;

  @media screen and (max-width: 769px) {
    font-size: 4vw;
  }
`;

const SettingBody = styled.div`
  display: block;
  width: 100%;
  height: auto;
  align-items: center;
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.dark_accent : theme.light_accent};
  padding-left: 2.5%;
  padding-right: 5%;
  padding-bottom: 2.5%;

  .rs-flex-box-grid {
    align-items: center;
  }
`;

const Header = styled.div`
  font-family: inherit;
  font-size: 1.5vw;
  font-weight: 400;

  .rs-icon {
    margin-right: 2.5%;
    vertical-align: text-bottom;
  }
`;

const SubHeader = styled.div`
  font-family: inherit;
  font-size: 1.1vw;
  font-weight: 400;
  color: grey;

  .rs-icon {
    margin-right: 2.5%;
    vertical-align: text-bottom;
  }
`;

const ListTable = styled.div`
  height: auto;
  padding-top: 1vh;
  padding-bottom: 1vh;
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.dark_accent : theme.light_accent};
  text-align: start;
`;

const ListHeader = styled.div`
  padding-bottom: 5px;
  color: grey;
  align-items: start;
`;

const MainDivider = styled.div`
  border-top: 1px solid #d9d9d9;
  margin-top: 1.25%;
  margin-bottom: 1.25%;
`;

const ItemSubText = styled.div`
  font-size: 1em;
  color: #97969b;
  font-weight: normal;

  @media screen and (max-width: 769px) {
    font-size: 0.8em;
    font-weight: normal;
  }
`;

const CreateReport = styled.button`
  color: white;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.4rem;
  background-color: #3498ff;
  transition: background-color 0.2s ease;
  :hover {
    background-color: #2589f5;
  }
`;

const ReportContainer = styled.div`
  overflow-x: auto;
`;

const ReportTable = styled.table`
  width: 100%;
  border-radius: 0.25rem;
`;

const ReportThead = styled.thead`
  background-color: #f7f5f5;
`;

const ReportTr = styled.tr`
  background-color: #f7f5f5;
  white-space: nowrap;
`;

const ReportTh = styled.th`
  background-color: #f7f5f5;
  text-align: left;
  padding-left: 1rem;
`;

const ReportTd = styled.td`
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const ReportTbody = styled.tbody`
  background-color: #f7f5f5;
`;

export {
  SettingBody,
  SettingHeader,
  ListHeader,
  ListTable,
  MainDivider,
  ItemSubText,
  Header,
  SubHeader,
  CreateReport,
  ReportContainer,
  ReportTable,
  ReportThead,
  ReportTr,
  ReportTh,
  ReportTd,
  ReportTbody,
};
