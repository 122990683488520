import React, { useContext } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { LocalFileContext } from '../_contexts/LocalFileContext';
import { readFile } from './readFile';

export const FileSelectorFC = (props) => {
   const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
   const { setParsedLogfile } = useContext(LocalFileContext);

   let selectedFile;

   const files = acceptedFiles.map((file) => {
      selectedFile = file;
      return (
         <li key={file.path}>
            {file.path} - {file.size} bytes
         </li>
      );
   });

   return (
      <FileSelectorSC className="container">
         <DropzoneSC {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a file here, or click to select files</p>
         </DropzoneSC>
         {selectedFile ? (
            <span>
               <h4>Selected File:</h4>
               <ul>{files}</ul>
               <button onClick={() => readFile(selectedFile, setParsedLogfile)}>
                  Read and Parse
               </button>
            </span>
         ) : null}
      </FileSelectorSC>
   );
};

const FileSelectorSC = styled.section`
   height: 30vh;
   width: 90%;
   position: relative;
   border: solid 1px ${({ theme }) => theme.light_grey};
   border-radius: 3px;

   span {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      color: ${({ theme }) => theme.light_accent};
      position: absolute;
      bottom: 5%;
   }

   button {
      background-color: ${({ theme }) => theme.light_secondary};
      color: ${({ theme }) => theme.light_accent};
      padding: 0.75em;
      border: none;
      border-radius: 3px;

      :hover {
         cursor: pointer;
      }
   }
`;

const DropzoneSC = styled.div`
   display: grid;
   place-items: center;
   height: 50%;
   width: 90%;
   position: absolute;
   background-color: ${({ theme }) => theme.light_grey_lighter};
   border: dashed 2px ${({ theme }) => theme.light_grey};
   border-radius: 3px;
   top: 25%;
   left: 50%;
   transform: translateX(-50%);

   :hover {
      cursor: pointer;
   }
`;
