import React, { useEffect, useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { IconButton, Form, Modal, Button, SelectPicker, Divider, Message, InputNumber, useToaster, Input, CheckPicker } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import EditIcon from '@rsuite/icons/Edit';
import axiosInstance from '../../../../../appHelpers/axiosInstance';

export const EditLogAlertFC = ({dataConfig, config, sme, metadata, refresh}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const initFormSite = {
        user_id: config.user_id,
        system_id: sme,
        field_name_alias: config.field_name_alias,
        field_name: config.field_name,
        field_name_multi: [],
        operator: config.operator,
        threshold: config.threshold,
        threshold_two: '',
        threshold_units: config.threshold_units,
        threshold_offset: config.threshold_offset,
        severity: config.severity,
        alert_id: config.id,
        notify_frequency: config.notify_frequency,
    }
    
   const severitySelector = ['high', 'medium'].map(operator => ({label: operator, value: operator}));
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [disable, setDisable] = useState(true);
   const [formValue, setFormValue] = useState(initFormSite);
   const message = (
      <Message showIcon type="success" header="Success">
         You successfully added an alert to: {sme}
      </Message>
   );

   const handleCheckPickerChange = (value) => {
      // Check if the number of selected options exceeds the maximum limit
      if(value.field_name_multi?.length > 2 && value.field_name_multi.length !== 0) {
         const restrictMessage = (<Message showIcon type="error" header="Error">
            You can only choose two columns.
         </Message>)
         value.field_name_multi = value.field_name_multi.slice(0,2);
         toaster.push(restrictMessage,'topCenter');
         setFormValue(value);
      } else {
         setFormValue(value);
      }
    };

   const onSubmit = async () => {
        try {
        const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

        const response = await axiosInstance({
           method: 'POST',
           url: `/alert/log/update/model/`,
           headers: { 
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
              },
           data:  JSON.stringify({
              sme: sme,
              updated_model: formValue
           }),
        });
        // setResMsg(response.data);
        toaster.push(message,'topCenter');
        refresh();
        handleClose();
        } catch (error) {
            console.error(error);
            toaster.push(errMessage,'topCenter');
        }
   }

   useEffect(() => {
     
   }, [formValue]);

   return (
      <AddAlertSC>
         <IconButton icon={<EditIcon />} style={{padding: '5px'}} onClick={handleOpen} />
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Add Alert To: {sme}</Modal.Title>
            </Modal.Header>
            <Divider/>
            <PreviewHeader>
               {formValue.severity ? 
                  <SeverityHeader severity={formValue.severity}><b>{formValue.severity?.toUpperCase()} SEVERITY ALERT </b> </SeverityHeader>
                  : null
               }
               {formValue.field_name_alias ?
                  formValue.field_name_alias
                  : null
               } 
            </PreviewHeader>
               
            {formValue.operator && formValue.operator === 'contains' ? 
               <div style={{paddingTop: '2.5%'}}>
                  <b>Triggered When: </b> The [<b>{dataConfig?.filter(config => config[0] === formValue.field_name).map(column => column[1])}</b>] column contains <b>{formValue.threshold}</b>               
               </div>
               : formValue.operator && formValue.operator === 'offline' ?
               <div style={{paddingTop: '2.5%'}}>
                  <b>Triggered When: </b> <br/> 
                  <>System has been offline for atleast: {formValue.threshold ? formValue.threshold : '-'} hours.</>
               </div>
               : null
            }
            <br/>
           
            <Divider/>
            <Modal.Body>
               <Form fluid
                  formValue={formValue}
                  onChange={formValue => handleCheckPickerChange(formValue)}
               >
                  <Form.Group controlId="severity">
                     <Form.ControlLabel>Severity</Form.ControlLabel>
                     <Form.Control name="severity" accepter={SelectPicker} data={severitySelector} searchable={false} block disabled/>
                  </Form.Group>

                  {formValue.operator === 'contains' || formValue.operator === 'composite_equals' ? 
                     <Form.Group controlId="field_name_alias">
                        <Form.ControlLabel>Message</Form.ControlLabel>
                        <Form.Control name="field_name_alias" accepter={Input} block disabled/>
                     </Form.Group>
                  : null
                  }

                  {formValue.operator === 'offline' ? 
                     <>
                        <Form.Group controlId="threshold">
                           <Form.ControlLabel>Threshold for Hours Offline:</Form.ControlLabel>
                           <Form.Control name="threshold" accepter={InputNumber} min={1} max={168} block/>
                        </Form.Group>
                     </>
                     : null
                  }
                  <Form.Group controlId="notify_frequency">
                     <Form.ControlLabel>Notify Frequency</Form.ControlLabel>
                     <Form.Control name="notify_frequency" accepter={InputNumber} step={1} postfix="hours" min={0} max={72} block/>
                  </Form.Group>     
               </Form>            
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit} >
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </AddAlertSC>
   )
};

const AddAlertSC  = styled.div`
   display: inline-block;
   padding: 1%;
`

const PreviewHeader  = styled.div`
   color: rgb(0, 91, 148);
   background-color: rgb(243, 248, 252);
   display: block;
   text-align: center;
`

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);

const SeverityHeader = styled.div`
   padding: 1%;
   border-radius: 5px;
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
                             : severity === 'medium' ?  '#fda005'
                             : severity === 'low' ? 'darkgrey'
                             : 'white'};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
                              : severity === 'medium' ?  '#ffeecc'
                              : severity === 'low' ? '#e6e5e3'
                              : 'green'};
`;

