import React, { useEffect } from "react";
import styled from 'styled-components';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import "fusiongrid/dist/fusiongrid.css";
import { IconButton } from "rsuite";

export const ExportEDU = ({systems, recent_data}) => {
   const fieldsToRemove = [
      'software_version', 
      'customer_id', 
      'city', 
      'state', 
      'site_id', 
      'serial_number', 
      'room',
      'he_tank_volume',
      'edu_datetime',
      'process_mag',
      'process_edu',
      'version'
   ];

   // Helper function to remove unwanted fields from an object
   const removeFields = (obj, fields) => {
      const newObj = { ...obj };
      fields.forEach(field => {
         delete newObj[field];
         });
      return newObj;
   };

   console.log(recent_data)
   const map = new Map();

   recent_data?.forEach(item => map.set(item.id, { ...item }));
   systems?.forEach(item => {
      if (map.has(item.id)) {
         map.set(item.id, { ...map.get(item.id), ...item });
      }
   });

   const dataArray =  Array.from(map.values()).map(item => removeFields(item, fieldsToRemove));

   // Step 1: Create your data array
   const data = dataArray;

   // Step 2: Convert the JSON data to CSV
   const convertToCSV = (json) => {
      const array = [Object.keys(json[0])].concat(json);

      return array.map(row => {
      return Object.values(row).map(value => {
         return typeof value === 'string' ? JSON.stringify(value) : value;
      }).toString();
      }).join('\n');
   };

   // Step 3: Create a function to download the CSV
   const downloadCSV = () => {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace(/[-:T]/g, '');
      a.href = url;
      a.download = `recent_edu_data_${formattedDate}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
   };

   useEffect(() => {
        
   }, []);

   return (
      <FusionExportSC>
         <IconButton size="sm" icon={<FileDownloadIcon />} onClick={downloadCSV}>Export</IconButton>
      </FusionExportSC>
   );
};

const FusionExportSC = styled.div`
   display: inline-block;
`;
