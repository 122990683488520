import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Message, useToaster } from 'rsuite';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../_context/UserContext';
import { AlertNavFC } from './alerts/AlertNavFC';
import { AlertUnsetFC } from './alerts/AlertUnsetFC';
import { AlertSetFC } from './alerts/AlertSetFC';
import { AlertDefaultFC } from './alerts/AlertDefaultFC';
import { AlertAllFC } from './alerts/AlertAllFC';

export const _MMBAlertFC = ({ metadata, dataConfig, dataTable, alertConfigs, refresh }) => {
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const {user, userRoles} = useContext(UserContext);
   const [adminViewToggle, setAdminViewToggle] = useState('User');
   const [displayAlertConfigs, setDisplayAlertConfigs] = useState(alertConfigs);

   const handleDelete = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/delete/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: id,
               id: config
            }),
         });
         // setResMsg(response.data);
         refresh();
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleEnabled = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/toggle/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: id,
               model_id: config
            }),
         });
         // setResMsg(response.data);
         toaster.push(updateMessage,'topCenter');

         setDisplayAlertConfigs(displayAlertConfigs.map(obj => {
            if (obj.id === config) {
               return { ...obj, enabled: !obj.enabled };
            }
            return obj;
         }));
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleUserEnabled = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/toggle/individual/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: id,
               model_id: config
            }),
         });
         // setResMsg(response.data);
         toaster.push(updateMessage,'topCenter');

         setDisplayAlertConfigs(displayAlertConfigs.map(obj => {
            if (obj.id === config) {
               return { ...obj, enabled: !obj.enabled };
            }
            return obj;
         }));
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleAdd = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/model/add`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               alert_id: config,
               user_id: user.email_address
            }),
         });
         // setResMsg(response.data);
         refresh();
         toaster.push(addMessage,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const filterAlertConfigs = () => {
      let copyAlertConfigs = alertConfigs;
      copyAlertConfigs = copyAlertConfigs?.filter(config => (!config.pg_table.toLowerCase().includes('log') && !config.pg_table.toLowerCase().includes('hhm')))
      setDisplayAlertConfigs(copyAlertConfigs);
   }

   useEffect(() => {
      filterAlertConfigs();
   }, [])

   useEffect(() => {
      
   }, [displayAlertConfigs, dataTable]);

   return (
      <AlertTableSC>
         {/* Admin Alert Nav */}
         <AlertNavFC adminViewToggle={adminViewToggle} setAdminViewToggle={setAdminViewToggle}/>

         {adminViewToggle === 'User' ?
            <>
               <AlertUnsetFC displayAlertConfigs={displayAlertConfigs} dataTable={dataTable} handleAdd={handleAdd}/>
               <AlertSetFC metadata={metadata} displayAlertConfigs={displayAlertConfigs} dataConfig={dataConfig} dataTable={dataTable} handleUserEnabled={handleUserEnabled} handleDelete={handleDelete} refresh={refresh}/>
            </>
            : adminViewToggle === 'Default' ?
               <AlertDefaultFC metadata={metadata} displayAlertConfigs={displayAlertConfigs} dataConfig={dataConfig} dataTable={dataTable} handleAdd={handleAdd} handleEnabled={handleEnabled} handleDelete={handleDelete} refresh={refresh}/>
            : adminViewToggle === 'All' ?
               <AlertAllFC defaultConfigs={alertConfigs} handleEnabled={handleEnabled}/>
            : null
         }     
      </AlertTableSC>
   );
};

const AlertTableSC = styled.div`
   width: 85vw;
`;

const addMessage = (
   <Message showIcon type="success" header="Success">
      You successfully added the alert.
   </Message>
);

const updateMessage = (
   <Message showIcon type="success" header="Success">
      You successfully updated the alert.
   </Message>
);

const message = (
   <Message showIcon type="success" header="Success">
      You successfully deleted the alert.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);