import React, { useState } from 'react';
import { gemriMagPayload } from '../../../../../mockDataBuilders/gemri';

const MagnetFileDataContext = React.createContext();

const MagnetFileDataContextProvider = (props) => {
   const [loadingMagnetFileData, setLoadingMagnetFileData] = useState(true);
   const [magnetFileData, setMagnetFileData] = useState();

   const getMagnetFileData = async () => {
      console.log(`getMagnetFileData`);

      // FAKE API CALL
      setTimeout(() => {
         setMagnetFileData(gemriMagPayload);
         setLoadingMagnetFileData(false);
      }, 2000);
   };

   return (
      <MagnetFileDataContext.Provider
         value={{
            loadingMagnetFileData,
            setLoadingMagnetFileData,
            magnetFileData,
            getMagnetFileData,
         }}
      >
         {props.children}
      </MagnetFileDataContext.Provider>
   );
};

export { MagnetFileDataContext, MagnetFileDataContextProvider };
