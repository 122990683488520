import React, { useState, useEffect,useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
//import { AdminContext } from '../_context/AdminContext';
import { SectionTitleFC } from '../_helpers/SectionTitleFC';
import styled from 'styled-components';
import { _UserFC } from './Users/_UserFC';
import { _CustomerFC } from './Customers/_CustomerFC';
import { _NetworkFC } from './Network/_NetworkFC';
import { UsersListFC } from './UsersListFC';
import { LoaderFC } from '../_helpers/LoaderFC';
import { CustomerListFC } from './CustomerListFC';
import { Nav, Navbar } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { InputContext } from '../_context/InputContext';
import { UserContext } from '../_context/UserContext';
import { NetworkIssueListFC } from './Network/NetworkIssueListFC';
import { AdminNavFC } from './AdminNavFC';
import { MachinesContext } from '../home/dashboard/_context/MachinesContext';
import { IssueTrackerFC } from './IssueTracker/IssueTrackerFC';

export const _AdminFC = () => {
   // renderCount(`AdminFC`);
   const history = useHistory();
   const [activeKey, setActiveKey] = useState("1");
   const [client, setClient] = useState('');
   const {
      adminViewToggle,
      setAdminViewToggle
   } = useContext(InputContext);

   const {
      loadingMachines,
      machines,
      getMachines
   } = useContext(MachinesContext);

   const {
      user,
      setUser,
      userRoles,
      getUserProfile,
      loadingUserRoles
   } = useContext(UserContext);

   const handleOnSelect = (event) => {
      if (event === 'Profile') {
         setAdminViewToggle('Users');
         history.push(`admin/user/${user.email_address}`);
      } else {
         setAdminViewToggle(event);
      }
   }

   const setAdminPage = () => {
      if (!adminViewToggle) {
         if (userRoles.includes('network') && (!userRoles.includes('admin') && !userRoles.includes('superuser'))) {
            setAdminViewToggle('Network');
         } else {
            setAdminViewToggle('Users');
         }
      }
   }

   useEffect(() => {
      getUserProfile();
      getMachines();
   }, []);

   useEffect(() => {
      setAdminPage();
   }, [userRoles]);

   return (loadingUserRoles || loadingMachines) ? (
      <LoaderFC/>
   ) : (
      <>
         {/* Admin Header */}
         <AdminNavFC appearance='subtle' activeKey={activeKey} onSelect={setActiveKey}/>

         <Navigation>
            <Nav activeKey={adminViewToggle} onSelect={handleOnSelect} appearance="subtle" style={{width: '100%'}}>
               {userRoles.includes('admin') || userRoles.includes('superuser') ? 
                  <Nav.Item eventKey="Users">Manage Users</Nav.Item>
               : null                 
               }                
               {userRoles.includes('superuser') ?
                  <Nav.Item eventKey="Customers">Manage Customers</Nav.Item>    
               : null                 
               }
               {userRoles.includes('network') &&  (!userRoles.includes('superuser') && !userRoles.includes('admin')) ? 
                  <>
                     <Nav.Item eventKey="Users">Manage Account</Nav.Item>   
                     <Nav.Item eventKey="Network">Network</Nav.Item>
                     <Nav.Item eventKey="Issues">Issue Tracker</Nav.Item>
                  </>             
               : userRoles.includes('network') ?
                  <>
                     <Nav.Item eventKey="Network">Network</Nav.Item>
                     <Nav.Item eventKey="Issues">Issue Tracker</Nav.Item>
                  </>
               : null                 
               }                                    
            </Nav>
         </Navigation>
         
         <AdminBody>
            {userRoles.includes('superuser') || userRoles.includes('admin') || userRoles.includes('network')  ?
               <>
                  { adminViewToggle === 'Users' ?
                     <UsersListFC setPage={setAdminViewToggle} setUser={setUser}/>
                  : adminViewToggle === 'Customers' && userRoles.includes('superuser') ?
                     <CustomerListFC setPage={setAdminViewToggle} setClient={setClient}/>
                  : adminViewToggle === 'ClientProfile' && userRoles.includes('superuser') ?
                     <_CustomerFC setPage={setAdminViewToggle} client={client}/>
                  : adminViewToggle === 'Network' && (userRoles.includes('network') || userRoles.includes('superuser'))  ?
                     <_NetworkFC client={client}/>
                  : adminViewToggle === 'Issues' && (userRoles.includes('network') || userRoles.includes('superuser'))  ?
                     <IssueTrackerFC />
                  :
                     <></>
                  }
               </>
            :
               history.push(`/admin/user/${user.email_address}`)
            }
         </AdminBody>
      </>
   );
};

const AdminBody = styled.div `
   width: 95vw;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   font-family: inherit;
   font-size: 3.5vw;
   font-weight: 400;

   @media screen and (min-width: 769px) {
      font-size: 1vw;
      margin-left: 10%;
      margin-right: 10%;
   }
`

const Navigation = styled.div `
   display: block;
   width: 100%;
   text-align: center;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   a {
      text-decoration: none;
   }

   @media screen and (min-width: 769px) {
      
   }
`;