import React, { useContext } from 'react';
import styled from 'styled-components';
import { renderCount } from '../../appHelpers/appLoggers';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import { DisplayMobileSidebarContext } from '../../appContexts/DisplayMobileSidebarContext';
import { DarkOrLightModeSelectorFC } from './DarkOrLightModeSelectorFC';
import { NavigatorFC } from './NavigatorFC';
import { ClientLogoFC } from './ClientLogoFC';

export const _AsideFC = () => {
   // renderCount(`./components/aside/_AsideFC`);

   const { darkMode } = useContext(DarkModeContext);
   const { displayMobileSidebar } = useContext(DisplayMobileSidebarContext);
   return (
      <AsideSC darkMode={darkMode} displayMobileSidebar={displayMobileSidebar}>
         {/* <ClientLogoFC /> */}
         <NavigatorFC />
         <BottomPlacement>            
            <DarkOrLightModeSelectorFC />
         </BottomPlacement>
      </AsideSC>
   );
};

const AsideSC = styled.aside`
   width: 68px;
   height: 100%;
   left: 0;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_secondary : theme.light_secondary};
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   border-right: 3px solid ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};;
   position: sticky;
   top: 0;
   display: none;

   // AT COMMON TABET SCREEN SIZE AND GREATER CONVERT
   // FROM A MOBILE SIDEBAR TO A DESKTOP SIDE PANEL
   // PROPERTIES ADOPTED AT >= 769px
   @media screen and (min-width: 769px) {
      display: block;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
   }
`;

const BottomPlacement = styled.div`
   position: absolute;
   bottom: 0;
   padding-left: 30%;
`

