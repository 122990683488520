import React, { useRef, useState } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";

FusionCharts.options.license({
    key: process.env.REACT_APP_FUSION_KEY,
    creditLabel: false,
 });

ReactFC.fcRoot(FusionCharts, TimeSeries);

export const _FusionTimeIndex = ({
    metadata, dataTable, dataConfig, filterLevel, engineerLevel, alertConfigs
}) => {
    const {tableData, chartConfig, filterConfig} = dataTable[0];
    const {dataTables, dataLabels} = dataConfig[0];
    let mobileEnabled = 1;
    const chartWidthRef = useRef();
    const charHeightRef = useRef();
    
    // CALCULATING WIDTH OF CHART
    if (window.screen.width <= 768) {
        chartWidthRef.current = window.screen.width * 0.95;
        charHeightRef.current = window.screen.height * 0.75;
        mobileEnabled = 0;
        filterLevel = 2;
    } else {
        chartWidthRef.current = window.innerWidth * 0.90;
        charHeightRef.current = window.innerHeight * 0.75;
        mobileEnabled = 1;
    }

    // Today's date
    const today = new Date();

    // Date two weeks ago
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(today.getDate() - 14); // Subtract 14 days from today's date

    // FLAT OUT DATA
    const data = dataTable[0].dataTable.map(data => Object.values(data));
    const dataKeys = Object.keys(dataTable[0].dataTable[0]);
    const dataLabelKeys = dataLabels.map(label => label.pgColumn);

    // ENGINER FILTER LEVEL ADDITION (TEMP)
    let filterLevelArray = [1, 2, 3 , 4];

    if (engineerLevel === 1) {
        filterLevelArray = [1];
    } else if (engineerLevel === 2) {
        filterLevelArray = [1,2]
    } else if (engineerLevel === 3) {
        filterLevelArray = [1, 2, 3]
    }

    let filteredChartConfig;

    if (engineerLevel !== 4) {
        let tempConfig = filterConfig?.filter(config => filterLevelArray.includes(config[2]) && dataLabelKeys.includes(config[0]));
        let pgColumns = tempConfig?.map(config => config[0]);

        if (pgColumns?.length > 0) {
            filteredChartConfig = chartConfig.filter(config => pgColumns.includes(config.pgcolumn));
        } else {
            filteredChartConfig = chartConfig.filter(config => dataLabelKeys.includes(config.pgcolumn));
        }     
    } else {
        filteredChartConfig = chartConfig.filter(config => dataLabelKeys.includes(config.pgcolumn));
    }

    // Y-Axis CREATION for MULTIPLE charts
    dataKeys.shift();
    const chart_props = [];

    // GET NUMBER OF CHARTGROUPS
    const chartGroups = [... new Set(filteredChartConfig.map(config=>config.chartgroup))];

    if (filterLevel == 1) {
        // DEFAULT
        chart_props.length = 0;

        // LOOP THROUGH EACH GROUP IF MULTIVARIATE SELECTED
        chartGroups.forEach((group => {

            // DEFAULT VALUES
            const yAxis = [];
            const slicedData = ['capture_datetime'];
            const schema = [
                {
                    name: "Capture Datetime",
                    type: "date",
                    format: '%Y-%m-%dT%H:%M:%S.%L%Z'
                }
            ];
            let chartName = "";

            // FUSION DATASOURCE CREATION
            filteredChartConfig.forEach(config => {
                if(config.chartgroup == group && config.show) {
                    // Default Value
                    slicedData.push(config.pgcolumn);

                    schema.push({
                        name: config.uilabel,
                        type: config.datatype
                    })

                    // Reference Zone
                    const referenceZone = [];
                    alertConfigs.forEach(alert => {
                        let style;
                        if(alert.severity === 'high') {
                            style = highAlert
                        } else if (alert.severity === 'medium') {
                            style = mediumAlert
                        }

                        if (alert.field_name === config.pgcolumn) {
                            if (alert.operator === 'less_than'){
                                referenceZone.push({
                                    label: `${alert.severity?.toUpperCase()} ${alert.field_name_alias} Alert`,
                                    valueMin: 0,
                                    valueMax: alert.threshold,
                                    style: style
                                });
                            } else if (alert.operator === 'greater_than'){
                                referenceZone.push({
                                    label: `${alert.severity?.toUpperCase()} ${alert.field_name_alias} Alert`,
                                    valueMin: alert.threshold,
                                    valueMax: 10000,
                                    style: style
                                });
                            }
                        }
                    });

                    yAxis.push({
                        plot: [
                            {
                                value: config.uilabel,
                                connectnulldata: true,
                                type: "line"
                            }
                        ],
                        format: {
                            suffix: ` ${config.unit === 'LTRS' && metadata.he_tank_volume ? '%' : config.unit}`
                        },
                        referenceZone: referenceZone
                    })

                    chartName += `${config.uilabel} & `
                }
            });

            if (slicedData.length > 1) {
                // FUNCTION: Slice Datatable
                const redux = array => array.map(o => slicedData.reduce((acc, curr) => {
                    acc[curr] = o[curr];
                    return acc;
                }, {}));
                
                // HELIUM CONVERSION
                const heliumConversion = dataTable[0].dataTable.map(item => {  
                    if (item.he_level_value && metadata.he_tank_volume && chartConfig?.filter(config => (config.pgcolumn === 'he_level_value'))[0]?.unit.toUpperCase() === 'LTRS') {
                      return {
                        ...item,
                        he_level_value: (parseFloat(item.he_level_value) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)
                      };
                    } else if (item.he_level_1_value && metadata.he_tank_volume && chartConfig?.filter(config => (config.pgcolumn === 'he_level_1_value'))[0]?.unit.toUpperCase() === 'LTRS') {
                        return {
                          ...item,
                          he_level_1_value: (parseFloat(item.he_level_1_value) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)
                        };
                    } else if (item.he_level_2_value && metadata.he_tank_volume && chartConfig?.filter(config => (config.pgcolumn === 'he_level_2_value'))[0]?.unit.toUpperCase() === 'LTRS') {
                        return {
                          ...item,
                          he_level_2_value: (parseFloat(item.he_level_1_value) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)
                        };
                    }
                    return item;
                });

                const reduxData = redux(heliumConversion);
                const reduxMyNuts = reduxData.map(data => Object.values(data));
                
                // CREATE DATA TABLE
                const fusionTable = new FusionCharts.DataStore().createDataTable(
                    reduxMyNuts,
                    schema
                );

                // CREATE TIMESERIES CHART
                chart_props.push({
                    timeseriesDs: {
                        type: "timeseries",
                        width: chartWidthRef.current,
                        height: "375",
                        dataFormat: 'json',
                        dataEmptyMessage: "Fetching data...",
                        dataSource: {
                            data: fusionTable,
                            chart: {
                                multicanvas: false
                            },
                            legend: {
                                position: 'top'
                            },
                            caption: {
                            text: `${chartName.slice(0, chartName.length-2)} Measurements`
                            },
                            yaxis: yAxis,
                            xAxis: {
                                initialInterval: {
                                  from: twoWeeksAgo.toISOString(),
                                  to:   today.toISOString()
                                }
                            },
                            navigator: {
                                height: 50
                            }
                        }
                    }
                })
            }
        }
    ));
    } else {
        // CREATE SINGLE TIMESERIES CHARTS
        // DEFAULT VALUES
        const yAxis = [];
        const schema = [
            {
                name: "Capture Datetime",
                type: "date",
                format: '%Y-%m-%dT%H:%M:%S.%L%Z'
            }
        ];

        // LOOP THROUGH EACH COLUMN TO CREATE SCHEMA
        dataKeys.forEach(key => {
            // Reference Zone
            const referenceZone = [];
            alertConfigs.forEach(alert => {
                if (alert.field_name === key) {
                    // Set Zone Color
                    let style;
                    if(alert.severity === 'high') {
                        style = highAlertSingle
                    } else if (alert.severity === 'medium') {
                        style = mediumAlertSingle
                    }

                    // Set Zone
                    if (alert.operator === 'less_than'){
                        referenceZone.push({
                            label: `${alert.severity?.toUpperCase()} ${alert.field_name_alias} Alert`,
                            valueMin: 0,
                            valueMax: alert.threshold,
                            style: style
                        });
                    } else if (alert.operator === 'greater_than'){
                        referenceZone.push({
                            label: `${alert.severity?.toUpperCase()} ${alert.field_name_alias} Alert`,
                            valueMin: alert.threshold,
                            valueMax: 10000,
                            style: style
                        });
                    }
                }
            });

            if(filteredChartConfig.find(label => label.pgcolumn === key && label.show)) {
                schema.push({
                    name: filteredChartConfig.find(label => label.pgcolumn === key).uilabel,
                    type: filteredChartConfig.find(label => label.pgcolumn === key).datatype,
                })
                yAxis.push({
                    plot: [
                        {
                            value: filteredChartConfig.find(label => label.pgcolumn === key).uilabel,
                            connectnulldata: false,
                            type: "line",
                            connectNullData: true
                        }
                    ],
                    format: {
                        suffix: ` ${filteredChartConfig.find(label => label.pgcolumn === key).unit}`
                    },
                    referenceZone: referenceZone,
                })
            } else {
                schema.push({
                    name: key,
                    type: "number"
                })
            }
        })

        // CREATE DATA TABLE
        const fusionTableUngrouped = new FusionCharts.DataStore().createDataTable(
            data,
            schema
        );

        let chartSize = 0;

        // CALCULATE SIZE OF CHART GRID
        if(mobileEnabled === 0) {
            chartSize = filteredChartConfig.length * 200;
        } else {
            chartSize = filteredChartConfig.length * 125;
        }

        // CREATE TIMESERIES CHART
        chart_props.push({
            timeseriesDs: {
                type: "timeseries",
                width: chartWidthRef.current,
                height: chartSize,
                dataFormat: 'json',
                dataEmptyMessage: "Fetching data...",
                dataSource: {
                    data: fusionTableUngrouped,
                    legend: {
                        enabled: false,
                        position: 'bottom'
                    },
                    navigator: {
                        height: 200,
                        enabled: false
                    },
                    yaxis: yAxis,
                    xAxis: {
                        initialInterval: {
                          from: twoWeeksAgo.toISOString(),
                          to:   today.toISOString()
                        }
                    },
                }
            }   
        });
    }
    
    return (
    <>
        {chart_props ? 
            chart_props.map((prop, index) => {
                return(
                    <ReactFC key={'chart:'+index} {...prop.timeseriesDs}/>
                )               
            })
            :
            <></>       
        }    
    </>
    );
};

const mediumAlert = {
    "marker": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker-zone": {'fill': 'transparent', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker-notch": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker:hover": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker-text": {'background-color': 'white'}
};

const highAlert = {
    "marker": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker-zone": {'fill': 'transparent', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker-notch": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker:hover": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker-text": {'background-color': 'white'},
};

const mediumAlertSingle = {
    "marker": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker-notch": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker:hover": {'fill': 'rgb(253, 160, 5)', 'stroke': 'rgb(253, 160, 5)', 'stroke-width': '2px'},
    "marker-text": {'background-color': 'white'}
};

const highAlertSingle = {
    "marker": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker-notch": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker:hover": {'fill': 'rgb(229, 27, 29)', 'stroke': 'rgb(229, 27, 29)', 'stroke-width': '2px'},
    "marker-text": {'background-color': 'white'},
};