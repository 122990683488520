import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import { FlexboxGrid, Whisper, Popover} from 'rsuite';
import LocationIcon from '@rsuite/icons/Location';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

export const EventHistoryFC = () => {
   const triggerRef = useRef();
   const { darkMode } = useContext(DarkModeContext);
   const { getAccessTokenSilently } = useAuth0();
   const { id, dataModel } = useParams();
   const [eventHistory, setEventHistory] = useState([]);
   const [getEventHistoryError, setEventHistoryError] = useState(false);
   const [loadingEventHistory, setLoadingEventHistory] = useState(true);
   
   const getEventHistory = async () => {
      // console.log(`getMachineMetadata`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'get',
            url: `/system/event-history/${id}`,
            headers: {
               Authorization: `Bearer ${token}`,
            },
         });
         setEventHistory(response.data[0]);
      } catch (error) {
         console.error(error);
         setEventHistoryError(true);
      }
      setLoadingEventHistory(false);
   };

   useEffect(() => {
      getEventHistory();
   }, []);

   return (
      !loadingEventHistory && eventHistory.length > 0 ?
         <EventHistorySC>
            <FlexboxGrid>
               {eventHistory?.map(event => {
                  const speaker = (
                     <Popover title="Last Read">
                        {DateTime.fromISO(event?.host_datetime).toFormat(
                           'ff'
                        )}
                     </Popover>
                  )
                  return (
                     <Whisper placement="top" speaker={speaker} ref={triggerRef} trigger="hover" key={event?.name}>
                        <FlexboxGrid.Item colspan={4} key={event?.name}>
                           <ItemSubText key={event?.name}>
                              { event?.name === 'tube_serial_no' ? 'Tube Serial #'
                              : event?.name === 'tube_type' ? 'Tube Type' 
                              : event?.name === 'scan_seconds' ? 'Tube Usage' 
                              : event?.name === 'software_version' ? 'Software Version' 
                              : event?.name === 'power_on' ? 'Power On'
                              : event?.name === 'system_scan_seconds' ? 'System Scan Seconds' 
                              : event?.name
                              }
                           </ItemSubText>
                           <SecondaryValueSC>
                              {event?.name === 'scan_seconds' || event?.name === 'system_scan_seconds' ? `${event?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} seconds` 
                              : event?.value}
                           </SecondaryValueSC>
                        </FlexboxGrid.Item>
                     </Whisper>
                  )
               })}
            </FlexboxGrid>
         </EventHistorySC>
      : null
   )
};

const EventHistorySC = styled.div`
   width: 95vw;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   padding: 0 0.5rem 0 0.5rem;

   @media screen and (min-width: 769px) {
      width: 100%;
      padding: 0rem 1rem 0 1rem;
   }
`;

const ItemSubText = styled.div`
   font-size: 0.8rem;
   font-weight: normal;
   text-align: center;
   color: #97969B;

   @media screen and (min-width: 769px) {
      font-size: 0.95rem;
      font-weight: normal;
   }
`;

const SecondaryValueSC = styled.span`
   display: block;
   text-align: center;

   @media screen and (min-width: 769px) {
      width: unset;
   }
`;