export const parseFile = (file) => {
   const GE_CT_PRIMARY =
      /SR\s(?<eventClassID>\d{3})(\r\n|\r|\n)(?<epoch>\d{10})\s(?<simultaneousEventIndex>\d{1,})\s(?<p1>\d{1,})\s.{24}\s(?<messageID>\d+)\s(?<p2>\d+)(\r\n|\r|\n)\s(?<p3>\w+)\s(?<p4>.*)(\r\n|\r|\n)(?<sourceCodeFilename>.*)\s+(?<sourceCodeLineNumber>\d+)(\r\n|\r|\n)(?<message>[\s\S]+?)EN\s\d{3}/g;
   let match;
   let matches = [];
   while ((match = GE_CT_PRIMARY.exec(file)) !== null) {
      const eventObject = {
         fullEvent: match[0],
         eventClassID: parseInt(match.groups.eventClassID),
         epoch: parseInt(match.groups.epoch),
         simultaneousEventIndex: parseInt(match.groups.simultaneousEventIndex),
         p1: parseInt(match.groups.p1),
         messageID: match.groups.messageID,
         p2: parseInt(match.groups.p2),
         p3: match.groups.p3,
         p4: match.groups.p4,
         sourceCodeFilename: match.groups.sourceCodeFilename,
         sourceCodeLineNumber: parseInt(match.groups.sourceCodeLineNumber),
         message: match.groups.message,
      };
      matches.push(eventObject);
   }
   // console.log(matches[0]);
   return matches.reverse();
};
