import React, { useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { IconButton, Form, Modal, Button, SelectPicker, Divider, Message, useToaster } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

export const OverrideSystem = ({system}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const initFormSite = {
      id: system.id,
      site_id: system.site_id,
      cus_sys_id: system.cus_sys_id,
      room: system.room,
      model: system.model,
      serial_number: system.serial_number
   } 
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [formValue, setFormValue] = useState(initFormSite);
   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated {system?.id}.
      </Message>
   );

   const onSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/update/system/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               customer_id: system.customer_id,
               system: formValue
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         handleClose();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   return (
      <>
         <IconButton icon={<EditIcon />} onClick={handleOpen}>Override</IconButton>
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Edit System: {system?.id}</Modal.Title>
            </Modal.Header>
            <Divider/>
            <Modal.Body>
               <Form fluid
                  formValue={formValue}
                  onChange={formValue => setFormValue(formValue)}
               >
                  <Form.Group controlId="id">
                     <Form.ControlLabel>ID</Form.ControlLabel>
                     <Form.Control name="id" readOnly disabled/>
                  </Form.Group>
                  <Form.Group controlId="site_id">
                     <Form.ControlLabel>Site</Form.ControlLabel>
                     <Form.Control name="site_id" readOnly disabled/>
                  </Form.Group>
                  <Form.Group controlId="cus_sys_id">
                     <Form.ControlLabel>CUID</Form.ControlLabel>
                     <Form.Control name="cus_sys_id" />
                  </Form.Group>  
                  <Form.Group controlId="room">
                     <Form.ControlLabel>Room</Form.ControlLabel>
                     <Form.Control name="room" />
                  </Form.Group>     
                  <Form.Group controlId="model">
                     <Form.ControlLabel>Model</Form.ControlLabel>
                     <Form.Control name="model" />
                  </Form.Group>
                  <Form.Group controlId="serial_number">
                     <Form.ControlLabel>Serial Number</Form.ControlLabel>
                     <Form.Control name="serial_number" />
                  </Form.Group>                
               </Form>      
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit}>
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </>
   )
};

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);