import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext, useEffect } from 'react';
import { Form, ButtonToolbar, Button, Input, TagPicker, ButtonGroup, Message, useToaster, Toggle, CheckPicker, Checkbox, FlexboxGrid, List, Panel, Whisper, Popover, SelectPicker, CheckboxGroup } from 'rsuite';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { ListHeader, MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../_context/UserContext';
import { SeverityAlert } from '../../../../../appHelpers/appCommonSC';
// Requiring the lodash library
const _ = require("lodash");

export const AlertSettingsFC = ({user, baseSystems}) => { 
   const { getAccessTokenSilently } = useAuth0();
   const [userAlerts, setUserAlerts] = useState([]);
   const [displayUserAlerts, setDisplayUserAlerts] = useState([]);
   const [notifyEmail, setNotifyEmail] = useState(user.notify_email);
   const [notifySMS, setNotifySMS] = useState(user.notify_sms);
   const [loading, setLoading] = useState(false);
   const [resMsg, setResMsg] = useState("");
   const toaster = useToaster();
   const {
      userRoles
   } = useContext(UserContext);

   const [allAlertIDs, setAllAlertIDs] = useState([]);
   const [enabledAlerts, setEnabledAlerts] = useState([]);

   const handleCheckAll = (enabledAlerts, checked) => setEnabledAlerts(checked ? allAlertIDs : []);

   const [disableButton, setDisableButton] = useState(false);
   const { id } = useParams();

   // Alert Filters
   const [fieldNameFilters, setFieldNameFilters] = useState([]);
   const [customerNameFilters, setCustomerNameFilters] = useState([]);
   const [severityFilters, setSeverityFilters] = useState([]);
   const [manufacturerFilters, setManufacturerFilters] = useState([]);

   const [selectedFieldName, setSelectedFieldName] = useState([]);
   const [selectedCustomerName, setSelectedCustomerName] = useState([]);
   const [selectedSeverity, setSelectedSeverity] = useState([]);
   const [selectedManufacturer, setSelectedManufacturer] = useState([]);

   const handleEmailToggle = async (e) => {
      setNotifyEmail(e)
      setLoading(true);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/user/update/notify/email`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               notifyEmail: e,
               userEmail: user.email_address,
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoading(false);
   }

   const handleSMSToggle = async (e) => {
      setNotifySMS(e)

      setLoading(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/user/update/notify/sms`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               notifysms: e,
               userEmail: user.email_address,
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
      setLoading(false);
   }

   const getUserAlertModels = async (e) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'GET',
            url: `/alert/models/${id}`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
         });

         setUserAlerts(response.data[0]);
         setDisplayUserAlerts(response.data[0]);
         setEnabledAlerts([...new Set(response.data[0]?.filter(alert => alert.enabled).map(model => model.alert_id))]);
         setAllAlertIDs([...new Set(response.data[0]?.map(model => model.alert_id))]);
      } catch (error) {
         console.error(error);
      }
      setLoading(false);
   }

   const onSubmitGenerateUserAlerts = async (e) => {
      setLoading(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/generate/model`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               user_id: id
            }),
         });
         setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }

      getUserAlertModels();
      setLoading(false);
   }

   const handleEnabled = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/model/update/enabled`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
             },
            data:  JSON.stringify({
               user_id: user.email_address,
               enabled_ids: enabledAlerts
            }),
         });
         setResMsg(response.data);
         getUserAlertModels();
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const setFilters = () => {
      setFieldNameFilters([...new Set(userAlerts.map(alert => alert.field_name_alias))].map(
         item => ({ label: item, value: item })
      ));
      
      setCustomerNameFilters([...new Set(userAlerts.map(alert => alert.customer_name))].map(
         item => ({ label: item, value: item })
      ));

      setSeverityFilters([...new Set(userAlerts.map(alert => alert.severity))].map(
         item => ({ label: item, value: item })
      ));

      setManufacturerFilters([...new Set(userAlerts.map(alert => alert.manufacturer))].map(
         item => ({ label: item, value: item })
      ));
   }

   const handleUserFilters = () => {
      const tempMachines = userAlerts.filter(machine =>
         (selectedCustomerName.length === 0 || selectedCustomerName.includes(machine.customer_name)) &&
         (selectedFieldName.length === 0 || selectedFieldName.includes(machine.field_name_alias)) &&
         (selectedManufacturer.length === 0 || selectedManufacturer.includes(machine.manufacturer)) &&
         (selectedSeverity.length === 0 || selectedSeverity.includes(machine.severity))
      );

      setAllAlertIDs([...new Set(tempMachines.map(model => model.alert_id))]);

      setDisplayUserAlerts(tempMachines);
   }

   const handleReset = () => {
      setEnabledAlerts([...new Set(userAlerts.filter(alert => alert.enabled).map(model => model.alert_id))]);
   }

   useEffect(() => {
      getUserAlertModels();
   }, []);

   useEffect(() => {
     setFilters();
     handleUserFilters();
   }, [userAlerts]);

   useEffect(() => {
      handleUserFilters();
   }, [selectedCustomerName, selectedFieldName, selectedManufacturer, selectedSeverity]);

   return (
      <SettingBody>
         <SettingHeader>Alert Settings</SettingHeader>

         <MainDivider/>

         <ItemMainText>
            Notify By
         </ItemMainText>

         <SettingContainer>
            <SettingSubContainer>
               <ItemSubText>
                  Email  
                  <Toggle checked={notifyEmail} onChange={handleEmailToggle} checkedChildren="Enable" unCheckedChildren="Disabled" style={{paddingLeft: 10}}/>
                  <ItemSubText>
                     Destination: {user.email_address}
                  </ItemSubText>
               </ItemSubText>
               
            </SettingSubContainer>

            <SettingSubContainer>
               <ItemSubText>
                  SMS  
                  <Toggle checked={notifySMS} onChange={handleSMSToggle} checkedChildren="Enable" unCheckedChildren="Disabled" style={{paddingLeft: 10}}/>
                  <ItemSubText>
                     Destination: {user.phone_number}
                  </ItemSubText>
               </ItemSubText>
               
            </SettingSubContainer>           
         </SettingContainer>

         <MainDivider/>
            <FlexboxGrid justify="space-between">
               {customerNameFilters.length > 0 ?
                  <FlexboxGrid.Item colspan={6}>
                     <CheckPicker label="Customer" data={customerNameFilters} value={selectedCustomerName} onChange={setSelectedCustomerName} placeholder={'Customer...'} searchable={false} block />
                  </FlexboxGrid.Item>
               : null
               }
               {manufacturerFilters.length > 0 ?
                  <FlexboxGrid.Item colspan={6}>
                     <CheckPicker label="Manufacturer" data={manufacturerFilters} value={selectedManufacturer} onChange={setSelectedManufacturer} placeholder={'Manufacturer...'} searchable={false} block />
                  </FlexboxGrid.Item>
               : null
               }

               {severityFilters.length > 0 ?
                  <FlexboxGrid.Item colspan={5}>
                     <CheckPicker label="Severity" data={severityFilters} value={selectedSeverity} onChange={setSelectedSeverity} placeholder={'Severity...'} searchable={false} block />
                  </FlexboxGrid.Item>
               : null
               }

               {fieldNameFilters.length > 0 ?
                  <FlexboxGrid.Item colspan={5}>
                     <CheckPicker label="Field" data={fieldNameFilters} value={selectedFieldName} onChange={setSelectedFieldName} placeholder={'Field...'} searchable={false}  block />
                  </FlexboxGrid.Item>
               : null
               }
            </FlexboxGrid>
         <MainDivider/>

         <ListHeader>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={3}>
                  SYSTEM ID
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={4}>
                  MANUFACTURER
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={7}>
                  CUSTOMER
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={3}  align='center'>
                  SEVERITY
               </FlexboxGrid.Item> 
               <FlexboxGrid.Item colspan={6}>
                  MESSAGE
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={1}  align='center'>
                  ENABLED
               </FlexboxGrid.Item>            
            </FlexboxGrid>
         </ListHeader>
         
         <List>
            <FlexboxGrid style={{padding: 10}}>
               <FlexboxGrid.Item colspan={18}>
                  <Checkbox
                     indeterminate={enabledAlerts.length > 0 && enabledAlerts.length < allAlertIDs.length}
                     checked={enabledAlerts.length === allAlertIDs.length}
                     onChange={handleCheckAll}
                  >
                     Enable All
                  </Checkbox>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}>
                  <Button color="Red" appearance="primary" onClick={handleReset}>
                     Reset
                  </Button>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={4}>
                  <Button color="green" appearance="primary" onClick={handleEnabled}>
                     Submit Alert Changes
                  </Button>
               </FlexboxGrid.Item>
            </FlexboxGrid>
            <CheckboxGroup
               name="checkboxList"
               value={enabledAlerts}
               onChange={value => {
               console.log(value, 'onChange');
                  setEnabledAlerts(value);
            }}>
            {displayUserAlerts.map(alert => (
               <List.Item>
                  <FlexboxGrid>

                     <FlexboxGrid.Item colspan={3} >
                        {alert.id}
                        <ItemSubText>
                           {alert.cus_sys_id}
                        </ItemSubText>
                     </FlexboxGrid.Item>   

                     <FlexboxGrid.Item colspan={4} >
                        {alert.manufacturer}
                        <ItemSubText>
                           {alert.model}
                        </ItemSubText>
                     </FlexboxGrid.Item>

                     <FlexboxGrid.Item colspan={7}>
                        {alert.customer_name}
                        <ItemSubText>
                           {alert.site_name}
                        </ItemSubText>
                     </FlexboxGrid.Item>    

                     <FlexboxGrid.Item colspan={3}>
                        <SeverityAlert severity={alert.severity}>
                           {alert?.severity.toUpperCase()}
                        </SeverityAlert>
                     </FlexboxGrid.Item>
     
                     <FlexboxGrid.Item colspan={6}>
                        {alert.field_name_alias} is {alert.operator === 'less_than' ? 'less than' 
                           : alert.operator === 'greater_than' ? 'greater than' 
                           : alert.operator 
                        }  {alert.threshold} {alert.threshold_units}
                     </FlexboxGrid.Item>

                     <FlexboxGrid.Item colspan={1} align='center'>
                        <Checkbox value={alert.alert_id} inline/>
                     </FlexboxGrid.Item>
                  </FlexboxGrid>
               </List.Item>
            ))}
            </CheckboxGroup>
         </List>

         <MainDivider/>

         {userRoles.includes('superuser') ?
            <ButtonGroup>
               <Button appearance="default" onClick={onSubmitGenerateUserAlerts}>Generate User Alerts</Button>
            </ButtonGroup>
         : null 
         }

         <MainDivider/>  
      </SettingBody>
   );
};

const ItemMainText = styled.div`
   font-size: 1.25em;
   font-weight: normal;
   text-align: left;
   padding-bottom: 0.5%;
`

const ItemSubText = styled.div`
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`

const SettingContainer = styled.div`
   padding-top: 10px;
   width: 100%;
`

const SettingSubContainer = styled.div`
   display: inline-block;
   width: 45%;
`

const message = (
   <Message showIcon type="success" header="Success">
      You successfully updated profile.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);
