import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { CheckPicker, List, FlexboxGrid, Whisper, Tooltip, Loader } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import { DateTime, Interval } from 'luxon';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { SeverityAlert } from '../../../../../appHelpers/appCommonSC';

export const UserAlerts = ({user}) => { 
   const [detectedAlerts, setDetectedAlerts] = useState([]);
   const [displayAlerts, setDisplayAlerts] = useState([]);
   const [uniqueSystems, setUniqueSystems] = useState([]);
   const [checkSystems, setCheckedSystems] = useState([]);
   const [loadingDetectedAlerts, setLoadingDetectedAlerts] = useState(true);
   const { getAccessTokenSilently } = useAuth0();

   const getUserDetectedAlerts = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
  
         const res = await axiosInstance({
            url: `/alert/detected/${user}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });
  
         setDetectedAlerts(res?.data[0]);
         setDisplayAlerts(res?.data[0]);
         setUniqueSystems([...new Set(res?.data[0].map(alert => alert.system_id))])   
      } catch (error) {
         console.error(error);
      }
      setLoadingDetectedAlerts(false);
   };

   const filterAlertList = () => {
      if (checkSystems.length > 0) {
         setDisplayAlerts(
            detectedAlerts.filter(alert => checkSystems.includes(alert.system_id))
         );
      } else {
         setDisplayAlerts(detectedAlerts); 
      }   
   }

   useEffect(() => {
      getUserDetectedAlerts();
   }, [user]);

   useEffect(() => {
      filterAlertList();
   }, [checkSystems]);

   useEffect(() => {

   }, [displayAlerts]);

   return (
      <SettingBody>
         <SettingHeader>
            Alert History
         </SettingHeader>

         <MainDivider/>
         {loadingDetectedAlerts ? 
            <Loader/>
            :
            <>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={7} align='center'>
                     <b>Unique Systems:</b> {uniqueSystems.length}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={10} align='center'>
                     <b>Sort By:</b> <CheckPicker value={checkSystems} onChange={setCheckedSystems} data={uniqueSystems.map(item => ({ label: item, value: item }))} style={{ width: 224 }} placeholder="All Systems" />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7} align='center'>
                     <b>Triggered Alerts: </b>{displayAlerts.length}
                     <Whisper followCursor speaker={<Tooltip>Triggered Alerts in the Past 7 Days.</Tooltip>}>
                        <InfoOutlineIcon style={{paddingLeft: '2%', paddingBottom: '1%', fontSize: '1.25em'}}/>
                     </Whisper>
                  </FlexboxGrid.Item>
               </FlexboxGrid>
               <MainDivider/>

               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={3} style={{paddingLeft: 10}}>
                        SYSTEM ID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} style={{paddingLeft: 10}}>
                        INFO
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        SEVERITY
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={8} style={{paddingLeft: 15}}>
                        MESSAGE
                     </FlexboxGrid.Item>    
                     <FlexboxGrid.Item colspan={2} align='center'>
                        EMAIL
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        SMS
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center'>
                        SENT AT
                     </FlexboxGrid.Item>                                       
                  </FlexboxGrid>
               </ListHeader>

               <List size="sm">
                  {displayAlerts?.map((alert, index) => (
                     <ListRow status={alert?.email_status}>
                        <List.Item value={alert.customer_id} key={alert.customer_id} index={index + 1}>
                           <FlexboxGrid>
                              <FlexboxGrid.Item colspan={3} style={{paddingLeft: 10}}>
                                 {alert.cus_sys_id ?
                                    <>
                                       <ItemMainText>{alert.cus_sys_id}</ItemMainText>
                                       <ItemSubText> {alert.system_id} </ItemSubText>
                                    </>
                                    : alert.system_id
                                 }
                              </FlexboxGrid.Item> 
                              <FlexboxGrid.Item colspan={3} style={{paddingLeft: 10}}>
                                 {alert.manufacturer ?
                                    <>
                                       <ItemMainText>{alert.manufacturer}</ItemMainText>
                                       <ItemSubText> {alert.model} </ItemSubText>
                                    </>
                                    : <></>
                                 }
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={2} align='center'>
                                 <SeverityAlert severity={alert?.severity}>{alert?.severity.toUpperCase()}</SeverityAlert>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={8} style={{paddingLeft: 15}}>
                                 {alert.operator === 'offline' || alert.operator === 'frozen_host' ? 
                                    <>
                                       {alert.field_name_alias}: {alert.threshold} {alert.threshold_units}
                                    </>
                                 : alert.operator === 'contains' || alert.operator === 'composite_equals' ? 
                                    <> 
                                       {alert.field_name_alias} 
                                    </>
                                 : alert.operator === 'equals' && alert.field_name_alias?.toLowerCase() === 'compressor' ?
                                    <>
                                       {alert.field_name_alias} is OFF!
                                    </> 
                                 :
                                    <>
                                       {alert.field_name_alias} {alert.resolved_field_units} is {alert.operator.replace(/_/g, ' ')} {alert.threshold} {alert.threshold_units}
                                    </> 
                                 }
                                 
                              </FlexboxGrid.Item>         
                              <FlexboxGrid.Item colspan={2} align='center'>
                                 {alert.email_status}
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={2} align='center'>
                                 {alert.sms_status && alert.severity === 'medium' ? 
                                    null : alert.sms_status
                                 }
                              </FlexboxGrid.Item>       
                              <FlexboxGrid.Item colspan={4} align='center'>
                                 {DateTime.fromISO(alert.inserted_at).toFormat(
                                    'ff'
                                 )}
                              </FlexboxGrid.Item>   
                           </FlexboxGrid>
                        </List.Item>
                     </ListRow>
                  ))}
               </List>
            </>
            
         }
      
         
      </SettingBody>
   );
};

const ListHeader = styled.div`
    color: grey;
`

const ListRow = styled.div`
   .rs-list-item {
      background-color: ${({ status}) => status === 'SENT' ? 'rgb(240 255 240)' : status === 'SUPPRESSED' ? '#f2f2f5' : status === 'DISABLED' ? '#f2f2f5'  : status === 'FAILED' ? 'RED' : 'white'};
   }
`

const ItemMainText = styled.div`
    font-size: 0.95em;
    font-weight: 500;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: bold;
    }
`;

const ItemSubText = styled.div`
    font-size: 0.8em;
    color: #97969B;
    font-weight: normal;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: normal;
    }
`;