import React, { useState, useEffect } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { List, FlexboxGrid, Loader } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import LocationIcon from '@rsuite/icons/Location';
import { ListHeader, ListTable, MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import { EditSite } from './Modal/EditSite';

export const CustomerSiteListFC = (props) => {
   // renderCount(`AdminFC`);
   const [sites, setSites] = useState([]);
   const [activeSites, setActiveSites] = useState([]);
   const [inactiveSites, setInactiveSites] = useState([]);
   const [update, setUpdate] = useState(false);
   const [loadingSites, setLoadingSites] = useState(true);
   const { getAccessTokenSilently } = useAuth0();

   const getSiteList = async () => {
      setLoadingSites(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const siteRes = await axiosInstance({
            url: `/customer/sites/${props.customer}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setSites(siteRes?.data[0]);
         setActiveSites(siteRes?.data[0].filter((system) => {return system.systems > 0}));
         setInactiveSites(siteRes?.data[0].filter((system) => {return system.systems == 0}));
      } catch (error) {
         console.error(error);
      }
      setLoadingSites(false);
   };

   useEffect(() => {
      getSiteList();
   }, [props.customer, update]);

   useEffect(() => {
      
   }, [sites, activeSites, inactiveSites]);

   return (
      <SettingBody> 
         <MainDivider/>

         <SettingHeader>
            <LocationIcon/> Sites
            <SubText> Active Sites: {activeSites?.length} / {sites?.length} </SubText>
            <SubText> Total Systems: {sites?.reduce((a, b) => a + parseInt(b.systems), 0)} </SubText>
         </SettingHeader>

         <MainDivider/>
            
         <ListTable>
            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={1}>
                     
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                     SITE ID
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={7}>
                     NAME
                  </FlexboxGrid.Item> 
                  <FlexboxGrid.Item colspan={2} align='center'>
                     STATE
                  </FlexboxGrid.Item>    
                  <FlexboxGrid.Item colspan={2}>
                     CITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}>
                     ADDRESS
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={2} align='center'>
                     ZIP
                  </FlexboxGrid.Item>     
                  <FlexboxGrid.Item colspan={2} align='center'>
                     SYSTEMS
                  </FlexboxGrid.Item>       
               </FlexboxGrid>
            </ListHeader>

            <ActiveText>ACTIVE</ActiveText>

            {activeSites.length > 0 ?
               <List size="sm">
               {activeSites?.map((site, index) => (
                  <List.Item key={index} value={site}>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={1}>
                           <EditSite site={site} update={update} setUpdate={setUpdate}/>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}>
                           {site.id ? site.id : ""}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={7}>
                           {site.name ? site.name : ""}                      
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.state ? site.state : ""}                    
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} >
                           {site.city ? site.city : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                           {site.street_address ? site.street_address : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.zip ? site.zip : ""} 
                        </FlexboxGrid.Item> 
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.systems ? site.systems : ""} 
                        </FlexboxGrid.Item> 
                     </FlexboxGrid>
                  </List.Item> 
               ))}                  
            </List>
            : loadingSites ?
               <Loader content="Loading..." />
            :
               <> None </>
            }          
         </ListTable>

         <ActiveText>INACTIVE</ActiveText>

         {inactiveSites.length > 0 ?
            <List size="sm">
               {inactiveSites?.map((site, index) => (
                  <List.Item key={index} value={site}>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={1}>
                           <EditSite site={site} update={update} setUpdate={setUpdate}/>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}>
                           {site.id ? site.id : ""}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={7}>
                           {site.name ? site.name : ""}                      
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.state ? site.state : ""}                    
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} >
                           {site.city ? site.city : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                           {site.street_address ? site.street_address : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.zip ? site.zip : ""} 
                        </FlexboxGrid.Item> 
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.systems ? site.systems : ""} 
                        </FlexboxGrid.Item> 
                     </FlexboxGrid>
                  </List.Item> 
               ))}                  
            </List>
         : loadingSites ?
            <Loader content="Loading..." />
         :
            <> None </>
         }                
      </SettingBody>
   );
};

const SubText = styled.div`
   padding-left: 2.5%;
   padding-right: 2.5%;
   display: inline-block;
   font-family: inherit;
   font-size: 1.25vw;
   font-weight: 300;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const ActiveText = styled.div`
   font-family: inherit;
   font-size: 1.0vw;
   font-weight: 300;
   color: rgb(0, 91, 148);
   padding-bottom: 0.5%;
   padding-top: 0.5%;
   padding-left: 1%;
   background-color: rgb(243, 248, 252);

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;