import React, { useState, useEffect } from 'react';
import { List, FlexboxGrid, IconButton, Tooltip, Whisper } from 'rsuite';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import styled from 'styled-components';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { useHistory } from 'react-router-dom';
import { _FilterNetworkSystemsFC } from '../helpers/_FilterNetworkSystemsFC';
import VisibleIcon from '@rsuite/icons/Visible';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import CheckRoundIcon from '@rsuite/icons/CheckRound';

export const SystemListFC = ({systems}) => { 
   const history = useHistory();

   useEffect(() => {

   }, [systems]);

   return (
      <SystemListSC>
         <List size={'sm'}>
         {systems ? 
           systems.map((system, index) => (
               <List.Item value={system.id} key={system.id} index={index + 1}>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={1}>
                        <ItemBox>                       
                           {system.show_on_website ?
                              <Whisper followCursor speaker={<Tooltip>Visible to Clients</Tooltip>}>            
                                 <VisibleIcon color={'green'}/> 
                              </Whisper>             
                           :
                              <Whisper followCursor speaker={<Tooltip>Not Visible to Clients</Tooltip>}>                      
                                 <UnvisibleIcon color={'red'}/> 
                              </Whisper>                  
                           }
                           {system.descrepency === true ?  
                              <Whisper followCursor speaker={<Tooltip>Acumatica Discrepencies</Tooltip>}>   
                                 <RemindRoundIcon  color={'#ffcc00'} style={{marginLeft: '2.5%'}}/>        
                              </Whisper>                     
                           : system.descrepency === false ?  
                              null
                           :
                              <Whisper followCursor speaker={<Tooltip>Not In Acumatica</Tooltip>}>   
                                 <RemindRoundIcon  color={'black'} style={{marginLeft: '2.5%'}}/>        
                              </Whisper>  
                           }
                        </ItemBox>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}>
                        {system.id}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3}>
                        {system.manufacturer}
                        <ItemSubText>{system.model}</ItemSubText>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}>
                        {system.modality}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={5}>
                        {system.customer_name}
                        <ItemSubText>{system.customer_id}</ItemSubText>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={7}>
                        {system.site_name}
                        <ItemSubText>{system.site_id}</ItemSubText>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2}>
                        {system.process_edu ? 
                           <Whisper followCursor speaker={<Tooltip>Process EDU: Enabled</Tooltip>}> 
                              <CheckRoundIcon  color={'green'} style={{marginRight: '2.5%'}}/> 
                           </Whisper>
                        : 
                           <Whisper followCursor speaker={<Tooltip>Process EDU: Disabled</Tooltip>}>
                              <CheckRoundIcon  color={'grey'} style={{marginLeft: '2.5%'}}/> 
                           </Whisper>
                        }
                        |
                        {system.process_mag ? 
                           <Whisper followCursor speaker={<Tooltip>Process Mag: Enabled</Tooltip>}> 
                              <CheckRoundIcon  color={'green'} style={{marginRight: '2.5%', marginLeft: '2.5%'}}/> 
                           </Whisper>
                        : 
                           <Whisper followCursor speaker={<Tooltip>Process Mag: Disabled</Tooltip>}>
                              <CheckRoundIcon  color={'grey'} style={{marginLeft: '2.5%'}}/> 
                           </Whisper>
                        }
                        |
                        {system.process_log ? 
                           <Whisper followCursor speaker={<Tooltip>Process Log: Enabled</Tooltip>}>
                              <CheckRoundIcon  color={'green'} style={{marginLeft: '2.5%'}}/> 
                           </Whisper> 
                        : 
                           <Whisper followCursor speaker={<Tooltip>Process Log: Disabled</Tooltip>}>
                              <CheckRoundIcon  color={'grey'} style={{marginLeft: '2.5%'}}/> 
                           </Whisper>
                        }
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={1}>
                        <IconButton onClick={() => history.push(`/admin/system/${system.id}`)} icon={<ArrowRightLineIcon size="5em"/>}/>
                     </FlexboxGrid.Item>                    
                  </FlexboxGrid>
               </List.Item>
            ))
         : null
         }
         </List>
      </SystemListSC>
   );
};

const SystemListSC = styled.div`

`

const ItemSubText = styled.div`
   font-size: 0.8em;
   color: #97969B;
   font-weight: normal;
   text-align: left;
   padding-bottom: 0.5%;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const ItemBox = styled.div`
   padding: 10px;
   line-height: 16px;
   display: inline;
`;