import React, { useContext, useEffect, useState } from 'react';
import VisibleIcon from '@rsuite/icons/Visible';
import FunnelIcon from '@rsuite/icons/Funnel';
import styled from 'styled-components';
import { FlexboxGrid, CheckPicker, Toggle, Input, Divider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DateTime, Interval } from 'luxon';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';

export const _FilterNetworkSystemsFC = ({displaySystems, systems, setDisplaySystems}) => {
   const { darkMode } = useContext(DarkModeContext);
   const [displayMachines, setDisplayMachines] = useState(displaySystems);
   const [machines, setMachines] = useState(systems);

   // Equipment Filtering
   const [customers, setCustomers] = useState([]);
   const [manufacturers, setManufacturers] = useState([]);
   const [modalities, setModalities] = useState([]);
   const [listCustomers, setListCustomers] = useState([]);
   const [listManufacturers, setListManufacturers] = useState([]);
   const [listModalities, setListModalities] = useState([]);

   const setFilters = () => {
      setListCustomers([...new Set(systems?.map(item => item.customer_name))]);
      setListManufacturers([...new Set(systems?.map(item => item.manufacturer))]);
      setListModalities([...new Set(systems?.map(item => item.modality))]);
   }

   const setFilteredMachines = () => {
      let tempMachines = machines?.filter(machine =>
         (customers.length === 0 || customers.includes(machine.customer_name)) &&
         (manufacturers.length === 0 || manufacturers.includes(machine.manufacturer)) &&
         (modalities.length === 0 || modalities.includes(machine.modality))
      );

      setDisplaySystems(tempMachines);
      setDisplayMachines(tempMachines);
   }

   const handleSearch = (value) => {

      let tempMachines = machines.filter(machine =>
         (customers.length === 0 || customers.includes(machine.customer_name)) &&
         (manufacturers.length === 0 || manufacturers.includes(machine.manufacturer)) &&
         (modalities.length === 0 || modalities.includes(machine.modality))
      );

      const filteredData = tempMachines.filter((item) => {
         return Object.values(item).some((field) =>
            String(field).toLowerCase().includes(value.toLowerCase())
         );
      });

      setDisplayMachines(filteredData);
      setDisplaySystems(filteredData);
   };

   useEffect(() => {
      setFilters()
   }, [systems, displaySystems]);

   useEffect(() => {
      setFilteredMachines();
   }, [customers, manufacturers, modalities]);

   return (
      <>
         {window.screen.width >= 768 ?
            <FilterEquipmentSC darkMode={darkMode}>
               <FlexboxGrid justify="start" style={{ alignItems: 'center', paddingBottom: '5px' }}>
                  <FlexboxGrid.Item colspan={3}>
                     <ItemSubText>Visible Equipment</ItemSubText>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                     <ItemSubText>Filter Equipment</ItemSubText>
                  </FlexboxGrid.Item>
                  {listCustomers.length > 1 ?
                     <FlexboxGrid.Item colspan={6}>
                        <ItemSubText>Filter Customers</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listManufacturers.length > 1 ?
                     <FlexboxGrid.Item colspan={4}>
                        <ItemSubText>Filter Manufacturers</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listModalities.length > 1 ?
                     <FlexboxGrid.Item colspan={4}>
                        <ItemSubText>Filter Modalities</ItemSubText>
                     </FlexboxGrid.Item>
                     : null
                  }
               </FlexboxGrid>
               
               <FlexboxGrid justify="start" style={{ alignItems: 'center' }}>
                  <FlexboxGrid.Item colspan={3}>
                     <VisibleIcon style={{ fontSize: "2em", marginRight: "5px" }} /> {displaySystems?.length}/{systems?.length}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}>
                     <FunnelIcon style={{ fontSize: "1.5em", marginRight: "5px" }} /> Select Filter
                  </FlexboxGrid.Item>
                  {listCustomers.length > 1 ?
                     <FlexboxGrid.Item colspan={6} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setCustomers} data={listCustomers.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Customers'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listManufacturers.length > 1 ?
                     <FlexboxGrid.Item colspan={4} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setManufacturers} data={listManufacturers.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Manufacturers'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
                  {listModalities.length > 1 ?
                     <FlexboxGrid.Item colspan={4} style={{ paddingRight: '0.5rem' }}>
                        <CheckPicker onChange={setModalities}data={listModalities.map(item => ({ label: item, value: item }))} appearance="subtle" searchable={false} placeholder={'All Modalities'} style={{ width: '100%' }} />
                     </FlexboxGrid.Item>
                     : null
                  }
               </FlexboxGrid>
            </FilterEquipmentSC>
            : null
         }
         <Input
            placeholder="Search..."
            onChange={handleSearch}
            style={{ marginBottom: 10, marginTop: 10}}
         />
      </>
   );
};

const FilterEquipmentSC = styled.div`
   padding: 1%;
   box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
   display: block;

   .rs-picker {
      display: inline-flex;
      color: ${({ theme, darkMode }) => darkMode ? theme.dark_accent : theme.light_accent};
      border: solid 1px ${({ theme, darkMode }) => darkMode ? theme.dark_accent : theme.light_accent};
      border-radius: 6px;
      margin-left: 0;
      width: 15vw;

      @media screen and (max-width: 769px) {
         width: 80vw;
         margin-bottom: 0.5rem;
      }
   }
`;

const ItemSubText = styled.div`
   font-size: 0.75em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;