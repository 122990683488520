import React, { useState, useContext, useEffect } from 'react';
import CanvasJSReact from './canvasjs.react';
import styled, { ThemeContext } from 'styled-components';
import { getDataMappingConfig } from './_helpers/getDataMappingConfig';
import { getChartTitle } from './_helpers/getChartTitle';
import { ChartSelectorFC } from './_helpers/ChartSelectorFC';
import { getYaxisRange } from './_helpers/getYaxisRange';
import { getYaxisTitle } from './_helpers/getYaxisTitle';
import { getDiscreteValues } from './_helpers/getDiscreteValues';
import { getDataObjects } from './_helpers/getDataObjects';
// import { ChartSelectorFC } from './_helpers/ChartSelectorFC';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { LevelSelectorFC } from '../LevelSelectorFC';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const _ChartIndex = ({
   magnetData,
   chartConfig,
   magnetAlertSettings,
   tableConfig,
   filterLevel,
}) => {
   // console.log(`_ChartIndex: ${payloadType}`);
   // console.log(magnetAlertSettings);
   const themeContext = useContext(ThemeContext);
   const { darkMode } = useContext(DarkModeContext);
   const [level, setLevel] = useState(filterLevel);
   const accentColor = darkMode ? themeContext.dark_accent : themeContext.light_accent;
   const primaryColor = darkMode ? themeContext.dark_primary : themeContext.light_primary;
   let magnetAlertSettingsObj = {};
   let magnetAlertSettingFields;

   // FORMAT magnetAlertSettings AS NESTED OBJ WITH ParameterName AS KEY
   // FROM [{k:v, k:v, ... }] TO {k:{k:v}, k:{k:v} ... }
   if (magnetAlertSettings) {
      magnetAlertSettings.forEach((setting) => {
         magnetAlertSettingsObj[setting[0]] = {
            MaxRedAlertPoint: setting[1],
            MaxYellowAlertPoint: setting[2],
            MinYellowAlertPoint: setting[3],
            MinRedAlertPoint: setting[4],
         };
      });

      // CLEAN magnetAlertSettingsObj BY REMOVING NULL K/V PAIRS
      // FROM {k:{k:v, k:null}, k:{k:null, k:v} ... } TO {k:{k:v}, k:{k:v} ... }
      // THIS MAKES ENSUING CROSS CHECK OF magnetAlertSettings AND magnetDataConfigs EASIER, NO NULLS
      for (const key in magnetAlertSettingsObj) {
         let dirtyObj = magnetAlertSettingsObj[key];
         for (const key in dirtyObj) {
            if (dirtyObj[key] == null) {
               delete dirtyObj[key];
            }
         }
      }

      // EXTRACT ARRAY OF KEYS FROM magnetAlertSettingsObj
      magnetAlertSettingFields = Object.keys(magnetAlertSettingsObj);
   }

   //const mappingConfig = getDataMappingConfig(payloadType);
   const mappingConfig = chartConfig;

   // INITIALIZE magnetDataConfigs ARRAY
   // EX. [{dataFields:['LHePct'],dataType:'continuous', dataSource:'magnetData', dataSets:{}, alertPointSets: {}}, {...}}
   let magnetDataConfigs = [];
   if (magnetData) {
      // console.log(magnetData)
      magnetDataConfigs = mappingConfig.map((fieldConfigArray) => ({
         dataKey: fieldConfigArray.parameter,
         dataFields: [`${fieldConfigArray.parameter}`],
         dataType: fieldConfigArray.datatype,
         displayByDefault: fieldConfigArray.show,
         chartTitle: fieldConfigArray.charttitle,
         unit: fieldConfigArray.unit,
         dataSets: {},
         alertPointSets: {},
      }));

      // RE-FORMAT magnetDataConfigs BY ADDING EMPTY ARRAYS IN dataSets OBJECT USING dataField AS KEY
      // EX. [{dataFields:[''],dataType:'continuous', dataSource:'magnetData', dataSets:{'LHePct': []}, alertPointSets: {}}, {...}}
      magnetDataConfigs.forEach(({ dataFields, dataSets }) => {
         dataFields.forEach((dataField) => {
            dataSets[dataField] = [];
         });
      });

      if (magnetAlertSettings) {
         // RE-FORMAT magnetDataConfigs BY ADDING EMPTY ARRAYS IN alertPointSets OBJECT USING fieldAlert AS KEY
         // EX. [{ALL OTHER K:V PAIRS, alertPointSets: {MinRedAlertPoint: []}}, {...}}
         magnetDataConfigs.forEach(({ dataFields, alertPointSets }) => {
            dataFields.forEach((dataField) => {
               if (magnetAlertSettingFields.includes(dataField)) {
                  for (const fieldAlert in magnetAlertSettingsObj[dataField]) {
                     alertPointSets[fieldAlert] = [];
                  }
               }
            });
         });
      }

      // ITERATE magnetData AND BUILD OUT dataSets AND alertPointSets FOR EACH DateTime
      // BOTH dataSets AND alertPointSets ARE OBJECTS WHICH CONTAIN K/V PAIRS OF dataField:[]
      // THE ARRAYS ARE POPULATED WITH {x: DateTime, y: SomeValue}
      for (let i = 0; i < magnetData.length; i++) {
         const dataValuesObject = magnetData[i];
         const dt = new Date(dataValuesObject.capture_datetime);

         magnetDataConfigs.forEach((config) => {
            for (const dataField in config.dataSets) {
               config.dataSets[dataField].push({
                  x: dt,
                  // CONTINUOUS VALUES CAN BE DIRECTLY INSERTED WHILE DISCRETE HAS TO BE INTERPRETED
                  y:
                     config.dataType === 'continuous'
                        ? dataValuesObject[dataField]
                        : getDiscreteValues(dataValuesObject[dataField]),
               });
               if (magnetAlertSettingsObj[dataField]) {
                  for (const alertType in magnetAlertSettingsObj[dataField]) {
                     config.alertPointSets[alertType].push({
                        x: dt,
                        y: magnetAlertSettingsObj[dataField][alertType],
                     });
                  }
               }
            }
         });
      }
   }

   const combinedConfigs = [...magnetDataConfigs];
   // console.log(`combinedConfigs`);
   // console.log(combinedConfigs);

   // BUILD DISPLAY CHART OBJECT AND SET STATE
   let defaultDisplayObj = {};
   combinedConfigs.forEach(({ dataKey, displayByDefault }) => {
      // COMPOSITE KEY ALLOWS FOR MAGNET/EDU DIFFERENTIATION
      // NEEDED FOR FIELDS WITH SAME NAME, EX: CompressorStatus
      // const dataKey = `${dataSource}_${dataFields[0]}`;
      defaultDisplayObj[dataKey] = displayByDefault;
   });
   const [displayStatus, setDisplayStatus] = useState(defaultDisplayObj);
   // console.log('defaultDisplayObj');
   // console.log(defaultDisplayObj);

   const combinedChartOptions = combinedConfigs.map((config) => {
      const {
         dataFields,
         dataType,
         chartTitle,
         unit,
         dataSets,
         alertPointSets,
         dataKey,
      } = config;

      const [min, max] = getYaxisRange(
         dataFields,
         dataType,
         dataSets,
         alertPointSets
      );
      
      return {
         dataKey: dataKey,
         zoomEnabled: true,
         title: {
            text: chartTitle,
            fontColor: accentColor,
         },
         axisX: {
            gridColor: accentColor,
            titleFontColor: accentColor,
            labelFontColor: accentColor,
         },
         axisY: {
            minimum: min,
            maximum: max,
            title: unit,
            gridThickness: 0.5,
            gridDashType: 'dash',
            gridColor: accentColor,
            titleFontColor: accentColor,
            labelFontColor: accentColor,
         },
         data: getDataObjects(dataSets, alertPointSets, themeContext, darkMode),
         backgroundColor: primaryColor,
         colorSet: accentColor,
         height: 250
      };
   });

   // console.log(`combinedChartOptions`);
   // console.log(combinedChartOptions);
   const handleChartDisplay = (dataKey) => {
      let stateClone = { ...displayStatus };
      stateClone[dataKey] = false;
      setDisplayStatus(stateClone);
   };

   const handleLevelDisplay = () => {
      let stateClone = { ...displayStatus };
      
      {tableConfig.map((map, x) => {
         if (map[2] <= filterLevel) {
            stateClone[map[0]] = true;
         } else {
            stateClone[map[0]] = false;
         }
      })}

      setDisplayStatus(stateClone);
   };

   useEffect(() => {
      handleLevelDisplay();
   }, [level]);

   return (
      <>
         <ChartsWrapper darkMode={darkMode}>
            {/* <ChartSelectorFC
               displayStatus={displayStatus}
               setDisplayStatus={setDisplayStatus}
            /> */}
            {combinedChartOptions.map((chartOptions) => {
               const shouldDisplay = displayStatus[chartOptions.dataKey];
               return shouldDisplay ? (
                  <ChartWrapper key={chartOptions.dataKey} darkMode={darkMode}>
                     <>
                        <HideChart
                           darkMode={darkMode}
                           onClick={() => handleChartDisplay(chartOptions.dataKey)}
                        >
                           X
                        </HideChart>
                        <CanvasJSChart options={chartOptions} />
                     </>
                  </ChartWrapper>
               ) : null;
            })}
         </ChartsWrapper>
      </>
   );
};

const ChartsWrapper = styled.div`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   min-height: 200px;
   // padding-top: 2.5rem; // ACCOUNTS FOR OptionsHeaderSC
   position: relative;
`;

const ChartWrapper = styled.div`
   width: 95%;
   margin-bottom: 1rem;
   box-shadow: ${({ darkMode }) =>
      darkMode ? `1px 1px 10px black` : ` 1px 1px 10px grey`};
   position: relative;
`;

const HideChart = styled.button`
   color: grey;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_primary : theme.light_primary};
   padding: 0.25rem;
   border: none;
   position: absolute;
   top: 0;
   right: 0;
   z-index: 1;
   cursor: pointer;

   :hover {
      color: red;
   }
`;
