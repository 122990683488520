import styled from 'styled-components';

// GETS A GENERAL TIMEOFDAY MESSAGE
export const getMessage = () => {
   const hour = new Date().getHours();
   let timeOfDay;

   if (hour < 12) {
      timeOfDay = 'MORNING';
   } else if (hour >= 12 && hour < 17) {
      timeOfDay = 'AFTERNOON';
   } else {
      timeOfDay = 'EVENING';
   }

   return timeOfDay;
}

export const AlertText = styled.div `
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
      : severity === 'medium' ?  '#fda005'
      : severity === 'low' ? 'darkgrey'
      : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
      : severity === 'medium' ?  '#ffeecc'
      : severity === 'low' ? '#e6e5e3'
      : ''};
   font-size: 0.95em;
   text-align: center;
`;

// Function to sanitize a string to be used as an image source (URL)
export const sanitizeImageUrl = (url) => {
   // Define a regular expression pattern to match a safe URL format
   const urlPattern = /^(https?:\/\/|data:image\/[a-z]+;base64,)[^\s]*$/i;
 
   // Check if the URL matches the safe pattern
   if (urlPattern.test(url)) {
     return url; // The URL is safe, return it as is
   } else {
     // The URL is not safe, return a default or empty URL or handle it as needed
     // Add Later
     return null; // Replace with your default image URL
   }
 }