import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthOutBtnSC } from './AuthBtnFC';
import { Icon_Logout } from '../images/icons/Icon_Logout';

const LogoutBtnFC = () => {
   const { logout } = useAuth0();

   return (
      <AuthOutBtnSC onClick={() => logout({ returnTo: window.location.origin })}>
         <Icon_Logout/>
      </AuthOutBtnSC>
   );
};

export default LogoutBtnFC;
