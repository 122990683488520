import React, { PureComponent } from 'react';
import styled from 'styled-components';

class Sparkline extends PureComponent {
   render() {
   const { data, units, type, width, height, tank} = this.props;
   
   if (!data || data.length < 1) return null;  // Not enough data

   let normalizedData = data;
   let normalizedUnits = units;
   let toFixInt = 3;
   if(type==='helium') {
      toFixInt = 2;

      if(units?.toUpperCase() === 'LTRS' && tank) {
         normalizedData = data.map(value => (value / tank) * 100);
         normalizedUnits = '%'
      }
   }

   // Find max and min in data to normalize the y-coordinates
   let maxY = Math.max(...normalizedData);
   let minY = Math.min(...normalizedData);

   // Add offsetCushion
   const maxYOffSet = Math.max(...normalizedData) + 0.50;
   const minYOffSet = Math.min(...normalizedData) - 0.50;

   // Convert data to SVG path
   const pathData = normalizedData
      .map((value, index) => {
         const x = (width / (normalizedData.length - 1)) * index;
         const y = height - (height * (value - minYOffSet) / (maxYOffSet - minYOffSet));
         return `${x},${y}`;
      })
   .join(' ');

    return (
      <SparklineSC>
         <DataBox>
            <SmallText>Max:</SmallText> <RegularText>{maxY.toFixed(toFixInt)}</RegularText> <UnitText>{normalizedUnits}</UnitText>
         </DataBox>
         <svg width={width} height={height}>
            <polyline fill="none" stroke="#2991c8" strokeWidth="1.5" points={pathData} />
         </svg>
         <DataBox>
            <SmallText>Min:</SmallText> <RegularText>{minY.toFixed(toFixInt)}</RegularText> <UnitText>{normalizedUnits}</UnitText>
         </DataBox>
      </SparklineSC>     
    );
  }
}

const SparklineSC = styled.div`
   font-weight: normal;
   color: #97969B;

   @media screen and (max-width: 769px) {
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 5px;
   }
`

const DataBox = styled.div`
   display: flex;
   width: 100%;
`

const SmallText = styled.div `
   display: inline-block;
   width: 28px;
   font-size: 0.8em;
   
   @media screen and (max-width: 769px) {
      width: 25px;
      font-size: 0.7em;
   }
`;

const UnitText = styled.div `
   display: inline-block;
   font-size: 0.7em;
   padding-left: 2%;
   
   @media screen and (max-width: 769px) {
      font-size: 0.6em;
   }
`;

const RegularText = styled.div `
   display: inline-block;
   font-weight: bold;
   font-size: 0.8em;

   @media screen and (max-width: 769px) {
      font-size: 0.7em;
   }
`;
export default Sparkline;