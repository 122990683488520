import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Message } from 'rsuite';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';

export const ErrorFC = ({statusCode}) => {

   return (
      <ErrorSC>
         <h1>Error: {statusCode}</h1>
         <h4>We're internally sorry :(</h4>
         <svg width="200" height="150" xmlns="http://www.w3.org/2000/svg">
            <circle cx="75" cy="60" r="5" fill="#333" />
            <circle cx="125" cy="60" r="5" fill="#333" />
            <path d="M 70 85 Q 100 70, 130 85" fill="none" stroke="#333" stroke-width="1.5" />
         </svg>
      </ErrorSC>
   );
}

const ErrorSC = styled.div`
   h1 {
      font-size: 40px;
   }
`


