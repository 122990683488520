import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';
import { IconButton } from 'rsuite';
import ReloadIcon from '@rsuite/icons/Reload';
import { MachinesContext } from '../../_context/MachinesContext';

export const RefreshFC = () => {
   const {
      setLoadingMachines,
      setLoadingHHMMachines,
      getMachines,
      getHHMMachines
   } = useContext(MachinesContext);

   const handleRefresh = () => {
      setLoadingMachines(true);
      setLoadingHHMMachines(true);
      getMachines();
      getHHMMachines();
   }

   useEffect(() => {

   }, [])

   return (
   <RefreshSC>
      {window.screen.width > 768 ?    
         <IconButton size="sm" icon={<ReloadIcon />} onClick={handleRefresh}>Refresh</IconButton>
         :
         <IconButton size="sm" icon={<ReloadIcon />} onClick={handleRefresh}/>
      }
   </RefreshSC>
   )
}

const RefreshSC = styled.div`
   display: inline-block;
`
