import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { Form, Button, ButtonGroup, Message, useToaster } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
// Requiring the lodash library
const _ = require("lodash");

export const CustomerSettingsFC = (props) => { 
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const [customerName, setCustomerName] = useState("");
   const [customerID, setCustomerID] = useState("");
   const [customerLogo, setCustomerLogo] = useState("");
      
   const prefillFormFields = () => {
      setCustomerID(props.customer?.id);

      if(props.customer?.name) {
         setCustomerName(props.customer?.name);
      }

      if(props.customer?.logo_src) {
         setCustomerLogo(props.customer?.logo_src);
      }
   }

   const onSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/update/client/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               customer_id: customerID,
               customer_name: customerName,
               customer_logo: customerLogo
            }),
         });
         //setResMsg(response.data);
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   useEffect(() => {
      prefillFormFields();
   }, [props.customer]);

   return (
      <SettingBody>
         <MainDivider/>
         
         <SettingHeader>
            Customer Settings
         </SettingHeader>

         <MainDivider/>
         
         <Form fluid>
            <FormSection float="left">
               <Form.Group controlId="customer-id">
                  <Form.ControlLabel>Customer ID</Form.ControlLabel>
                  <Form.Control value={customerID} name="first-name" readOnly disabled/>
               </Form.Group>
               <Form.Group controlId="customer-name">
                  <Form.ControlLabel>Customer Name</Form.ControlLabel>
                  <Form.Control value={customerName} onChange={setCustomerName} name="last-name" />
               </Form.Group>    
               <Form.Group controlId="customer-logo">
                  <Form.ControlLabel>Customer Logo</Form.ControlLabel>
                  <Form.Control value={customerLogo} onChange={setCustomerLogo} name="customer-logo"/>
               </Form.Group>       
            </FormSection>
            <FormSection float="right">

            </FormSection>
         </Form>

         <ButtonBar>
            <ButtonGroup>
               <Button appearance="primary" onClick={onSubmit}>Submit</Button>
               <Button appearance="default" onClick={prefillFormFields}>Reset</Button>
            </ButtonGroup>
         </ButtonBar>           
      </SettingBody>
   );
};

const FormSection = styled.div`
   display: block;
   width: 45%;
   float: ${({ theme, float }) => float};
`

const ButtonBar = styled.div`
   padding-top: 40vh;
   display: block;
   width: 100%;

   @media screen and (max-width: 769px) {
      padding-bottom: 10%;
   }
`

const message = (
   <Message showIcon type="success" header="Success">
      You successfully updated this Customer.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);