import React, { useContext } from 'react';
import styled from 'styled-components';
import { DarkModeContext } from '../../appContexts/DarkModeContext';

export const Icon_Dashboard = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <Icon_DashboardSC darkMode={darkMode}>
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16">
                <path d="M5.5 5a.5.5 0 01.5.5v5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5zM9.5 8a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5z"></path>
                <path d="M13 1a2 2 0 012 2v10a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2h10zm0 1H3a1 1 0 00-.993.883L2 3v10a1 1 0 00.883.993L3 14h10a1 1 0 00.993-.883L14 13V3a1 1 0 00-.883-.993L13 2z"></path>
            </svg>
        </Icon_DashboardSC>
    )
};

const Icon_DashboardSC = styled.div`
   display: inline-block;
   margin: 0 auto;

   svg {
        vertical-align: text-bottom;
        fill: ${({ theme, darkMode }) =>
            darkMode ? theme.dark_accent : theme.avante_dark_blue};
   }

   svg rect {
        fill: ${({ theme }) => theme.avante_light_blue};
   }
`;
