import React, { useEffect, useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { IconButton, Form, Modal, Button, SelectPicker, Divider, Message, useToaster, Input, Toggle } from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

export const UpdateIssue = ({issue, update, setUpdate}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const initFormSite = {
      id: issue.id,
      type: issue.type,
      notes: issue.notes,
      assigned: issue.assigned,
      diagnoses: issue.diagnoses,
      resolved: issue.resolved,
      report_id: issue.report_id,
      processor_type: issue.processor_type
   } 

   const selectData = ['OFFLINE', 'MAGNET', 'EDU'].map(item => ({
      label: item,
      value: item
   }));

   const selectProcessor = ['MMB', 'HHM'].map(item => ({
      label: item,
      value: item
   }));

   const assignedSelectData = ['Remote Tech Team', 'Jerome', 'Joe', 'Customer - Jerome', 'Customer - Joe' ].map(item => ({
      label: item,
      value: item
   }));
   
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [formValue, setFormValue] = useState(initFormSite);
   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated {issue?.id}.
      </Message>
   );

   const onSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'PUT',
            url: `/network/update/issues`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               issue: formValue
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         setUpdate(!update);
         handleClose();
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   useEffect(() => {
      setFormValue({
         id: issue.id,
         type: issue.type,
         notes: issue.notes,
         assigned: issue.assigned,
         diagnoses: issue.diagnoses,
         resolved: issue.resolved,
         report_id: issue.report_id,
         processor_type: issue.processor_type,
      })
   }, [issue]);

   return (
      <>
         <IconButton icon={<EditIcon />} onClick={handleOpen}/>
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Edit Issue For: {issue?.id}</Modal.Title>
            </Modal.Header>
            <Divider/>
            <Modal.Body>
               <Form fluid
                  formValue={formValue}
                  onChange={formValue => setFormValue(formValue)}
               >
                  <Form.Group controlId="processor_type">
                     <Form.ControlLabel>Processor</Form.ControlLabel>
                     <Form.Control name="processor_type" accepter={SelectPicker} data={selectProcessor} searchable={false} block/>
                  </Form.Group>
                  <Form.Group controlId="type">
                     <Form.ControlLabel>Type</Form.ControlLabel>
                     <Form.Control name="type" accepter={SelectPicker} data={selectData} searchable={false} block/>
                  </Form.Group>
                  <Form.Group controlId="notes">
                     <Form.ControlLabel>Note</Form.ControlLabel>
                     <Form.Control name="notes" accepter={Textarea} />
                  </Form.Group>  
                  <Form.Group controlId="assigned">
                     <Form.ControlLabel>Assigned</Form.ControlLabel>
                     <Form.Control name="assigned" placeholder={formValue.assigned} accepter={SelectPicker} data={assignedSelectData} searchable={false} block />
                  </Form.Group>     
                  <Form.Group controlId="diagnoses">
                     <Form.ControlLabel>Diagnoses</Form.ControlLabel>
                     <Form.Control name="diagnoses" accepter={Textarea}/>
                  </Form.Group>
                  <Form.Group controlId="resolved">
                     <Form.ControlLabel>Resolved</Form.ControlLabel>
                     <Form.Control name="resolved" accepter={Toggle}/>
                  </Form.Group>                
            </Form>
               
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit}>
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </>
   )
};

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);