import React, { useContext } from 'react';
import styled from 'styled-components';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import { FlexboxGrid, Tooltip, Whisper} from 'rsuite';
import { IconButton } from 'rsuite';
import LocationIcon from '@rsuite/icons/Location';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import { UserContext } from '../../_context/UserContext';
import { useHistory } from 'react-router-dom';

export const MachineMetadataFC = ({ metadata }) => {
   renderCount('MachineMetadataFC');
   const history = useHistory();
   const { darkMode } = useContext(DarkModeContext);
   const {
      userRoles
   } = useContext(UserContext);

   const speaker = (
      <Tooltip>
        Avante ID: {metadata?.id}
      </Tooltip>
    );

   return (
      <MachineMetadataSC darkMode={darkMode}>
         <FlexboxGrid>
            <FlexboxGrid.Item colspan={21}>
               <ItemMainText>
                  <LocationIcon /> {metadata.name}
               </ItemMainText>                                   
               <ItemSubText>
                  Room: {metadata.room ? metadata.room : 'N/A'}
               </ItemSubText>
            </FlexboxGrid.Item>
            {userRoles.includes('superuser') || userRoles.includes('network') ?
            <FlexboxGrid.Item colspan={3} style={{alignSelf: 'center'}}>
               <IconButton onClick={() => history.push(`/admin/system/${metadata.id}`)} icon={<SettingHorizontalIcon />} size="md" style={{float: 'right'}}>Net Admin</IconButton>                              
            </FlexboxGrid.Item>
            :
            <FlexboxGrid.Item colspan={3} style={{alignSelf: 'center'}}>
                                            
            </FlexboxGrid.Item>
            }
            
         </FlexboxGrid>

         <Divider darkMode={darkMode} />  

         <FlexboxGrid>
            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     ID
                  </ValueHeader>                          
                     {metadata?.cus_sys_id ?
                        <Whisper placement="bottom" trigger="hover"
                           speaker={speaker}
                        >
                           <SecondaryValueSC>
                              {metadata.cus_sys_id}
                           </SecondaryValueSC>
                        </Whisper>
                     :  <SecondaryValueSC>
                           {metadata.id}
                        </SecondaryValueSC>}              
               </SecondaryInfoSC>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     SYSTEM
                  </ValueHeader>
                  <SecondaryValueSC>
                     {metadata?.manufacturer} &#183; {metadata?.modality}    
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={6}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     MODEL
                  </ValueHeader>
                  <SecondaryValueSC>
                     {metadata?.model}    
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     SERIAL#
                  </ValueHeader>        
                  <SecondaryValueSC>
                     {metadata?.serial_number ? metadata.serial_number : 'N/A'}
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     S/V
                  </ValueHeader>            
                  <SecondaryValueSC>
                     {metadata?.software_version ? metadata.software_version : 'N/A'}
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>
         </FlexboxGrid>
         <Divider darkMode={darkMode} />       
      </MachineMetadataSC>
   );
};

// TODO: 1 - MAYBE FIND EXACT WIDTH TO MATCH TABLE BELOW 2 - SET COLOR(S) TO A THEME VALUE
const MachineMetadataSC = styled.div`
   width: 95vw;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   padding: 0 0.5rem 0 0.5rem;

   @media screen and (min-width: 769px) {
      width: 94vw;
   }
`;

const Divider = styled.hr`
   width: 100%;
   height: 0px;
   margin-top: 6px;
   margin-bottom: 10px;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   @media screen and (min-width: 769px) {
      width: 100%;
   }
`;

const ValueHeader = styled.div`
   display: block;
   font-size: 0.6em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const SecondaryInfoSC = styled.div`
   display: block;
   font-size: 0.68rem;
   text-align-last: center;

   @media screen and (min-width: 769px) {
      display: block;
      font-size: 1rem;
   }
`;

const SecondaryValueSC = styled.span`
   display: block;

   @media screen and (min-width: 769px) {
      width: unset;
   }
`;

const ItemMainText = styled.div`
   font-size: 0.75rem;
   font-weight: bolder;

   @media screen and (min-width: 769px) {
      font-size: 0.75rem;
   }

   @media screen and (min-width: 1401px) {
      font-size: 1rem;
   }
`;

const ItemSubText = styled.div`
   font-size: 0.8rem;
   font-weight: normal;
   color: #97969B;

   @media screen and (min-width: 769px) {
      font-size: 0.95rem;
      font-weight: normal;
   }
`;