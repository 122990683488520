import React, { useState } from 'react';
import { renderCount } from '../appHelpers/appLoggers';

const DarkModeContext = React.createContext();

const DarkModeContextProvider = (props) => {
   // renderCount(`./appContexts/DarkModeContext`);

   const [darkMode, setDarkMode] = useState(false);

   return (
      <DarkModeContext.Provider
         value={{ darkMode: darkMode, setDarkMode: setDarkMode }}
      >
         {props.children}
      </DarkModeContext.Provider>
   );
};

export { DarkModeContext, DarkModeContextProvider };
