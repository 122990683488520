import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';

const AlertsContext = React.createContext();

const AlertsContextProvider = (props) => {
   const [loadingAlertConfigs, setLoadingAlertConfigs] = useState(true);
   const [loadingDashboardAlerts, setLoadingDashboardAlerts] = useState(true);
   const [getLoadingAlertConfigsError, setGetLoadingAlertConfigsError] = useState(false);
   const [getLoadingDashboardAlertsError, setGetLoadingDashboardAlertsError] = useState(false);
   const [alertConfigs, setAlertConfigs] = useState([]);
   const [defaultAlertConfigs, setDefaultAlertConfigs] = useState([])
   const [dashboardAlerts, setDashboardAlerts] = useState([]);
   const { getAccessTokenSilently } = useAuth0();

   const getAlertConfigs = async () => {
      // console.log(`getAlertConfigs`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            url: `/alert/configs`,
            data: ``,
            headers: {
               authorization: `Bearer ${token}`,
            },
         });

         setAlertConfigs(response.data[0].filter(config => config.user_id !== 'default'));
         setDefaultAlertConfigs(response.data[0].filter(config => config.user_id === 'default'));
      } catch (error) {
         console.error(error);
         setGetLoadingAlertConfigsError(true);
      }
      setLoadingAlertConfigs(false);
   };

   const getDashboardAlerts = async () => {
      // console.log(`getAlertConfigs`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            url: `/alert/dashboard`,
            data: ``,
            headers: {
               authorization: `Bearer ${token}`,
            },
         });

         setDashboardAlerts(response.data[0]);
      } catch (error) {
         console.error(error);
         setGetLoadingDashboardAlertsError(true);
      }
      setLoadingDashboardAlerts(false);
   };

   return (
      <AlertsContext.Provider
         value={{
            loadingAlertConfigs,
            loadingDashboardAlerts,
            setLoadingAlertConfigs,
            setLoadingDashboardAlerts,
            alertConfigs,
            defaultAlertConfigs,
            dashboardAlerts,
            setAlertConfigs,
            getAlertConfigs,
            getDashboardAlerts
         }}
      >
         {props.children}
      </AlertsContext.Provider>
   );
};

export { AlertsContext, AlertsContextProvider };
