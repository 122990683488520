import React, { useState } from 'react';

// const fakeApiWithError = (setGlobalMessagePane, setParsedLogfile) => {
//    setTimeout(() => {
//       setGlobalMessagePane({
//          display: true,
//          type: `error`,
//          message: `Test API Error`,
//       });
//       //setParsedLogfile(`UPDATED`);
//    }, 2000);
// };

const LocalFileContext = React.createContext();

const LocalFileContextProvider = (props) => {
   const [parsedLogfile, setParsedLogfile] = useState();

   return (
      <LocalFileContext.Provider
         value={{
            parsedLogfile: parsedLogfile,
            setParsedLogfile: setParsedLogfile,
         }}
      >
         {props.children}
      </LocalFileContext.Provider>
   );
};

export { LocalFileContext, LocalFileContextProvider };
