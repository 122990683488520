import React, { useContext } from 'react';
import { renderCount } from '../../appHelpers/appLoggers';
import { DarkModeContext } from '../../appContexts/DarkModeContext';
import styled from 'styled-components';
import { _HeaderFC } from './header/_HeaderFC';
import { _PagesFC } from './pages/_PagesFC';
import { UserNotificationPaneFC } from './UserNotificationPaneFC';
import { UpdateMessageFC } from './pages/_misc/UpdateMessageFC';

export const _MainFC = () => {
   // renderCount(`./components/main/_MainFC`);
   const { darkMode, setDarkMode } = useContext(DarkModeContext);

   return (
      <MainSC darkMode={darkMode}>
         {/* <UpdateMessageFC/> */}
         <_PagesFC />
         <UserNotificationPaneFC />
      </MainSC>
   );
};

const MainSC = styled.main`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;
   width: 100%; // OF AppSC
   min-height: 100%;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_primary : theme.light_primary};
   position: absolute;
   top: 0;
   right: 0;
`;
