export const appTheme = {
   light_primary: 'hsl(0, 0%, 100%)',
   light_secondary: 'hsl(207, 60%, 97%)',
   light_accent: 'hsl(203, 100%, 29%)', //ADB
   light_grey: 'hsl(0, 0%, 75%)',
   light_grey_lighter: 'hsl(0, 0%, 96%)',
   dark_primary: 'hsl(212, 39%, 16%)',
   dark_secondary: 'hsl(212, 39%, 13%)',
   dark_accent: 'hsl(209, 30%, 72%)',
   error: 'hsl(0, 67%, 61%)',
   avante_dark_blue: 'hsl(203, 100%, 29%)',
   avante_light_blue: 'hsl(205, 65%, 65%)',
   avante_accent_green: 'hsl(165, 100%, 33%)',
   // auth0_orange: 'hsl(17, 98%, 39%)',
   // error_accent: 'hsl(0, 96%, 61%)', NOT USED YET
};

// AVANTE DARK BLUE (ADB) : hsl(203, 100%, 29%) : rgba(0, 91, 148, 1)
// AVANTE LIGHT BLUE: rgba(53, 131, 185, 1)
// AVANTE ACCENT GREEN: rgba(0, 167, 124, 1)
