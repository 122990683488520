import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Form, Radio, RadioGroup } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';

export const LevelSelectorFC = (props) => {
   const [level, setLevel] = useState(1);

   const onChangeLevel = (event) => {
      setLevel(event);
      props.level(event);
   };

   useEffect(() => {
      // console.log('Rendered: LevelSelector');
   }, []);

   return (
      <LevelSelectorSC>
         <Form.Group controlId="levelList">
            <RadioGroup
               name="levelList"
               inline
               appearance="picker"
               value={level}
               onChange={onChangeLevel}
            >
               <span style={styles.radioGroupLabel}>Parameter Filter Level: </span>
               <Radio value={1}>1</Radio>
               <Radio value={2}>2</Radio>
               <Radio value={3}>3</Radio>
               <Radio value={4}>All</Radio>
            </RadioGroup>
         </Form.Group>
      </LevelSelectorSC>
   );
};

const LevelSelectorSC = styled.div`
   padding: 1rem;
`;

const styles = {
   radioGroupLabel: {
      padding: '8px 2px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle',
   },
};
