import React, { useContext } from 'react';
import styled from 'styled-components';
import AdminIcon from '@rsuite/icons/Admin';
import { Nav } from 'rsuite';
import { MainDivider } from '../../../admin/helpers/Admin_StyledComponents';
import { UserContext } from '../../../_context/UserContext';

export const AlertNavFC = ({ adminViewToggle, setAdminViewToggle }) => {
   const {user, userRoles} = useContext(UserContext);

   return (
      <AlertNavSC>
         {userRoles.includes('superuser') || userRoles.includes('alerter') ?
         <>
            <Nav activeKey={adminViewToggle} onSelect={setAdminViewToggle} appearance='subtle'>
               <Nav.Item disabled style={{color: 'inherit', paddingLeft: 0}} icon={<AdminIcon style={{fontSize: '1.5em'}}/>}>Admin Alert Center</Nav.Item>
               <Nav.Item eventKey='User'>User Alerts</Nav.Item>
               <Nav.Item eventKey='Default'>Manage Default Alerts</Nav.Item>
               <Nav.Item eventKey='All'>All User Alerts</Nav.Item>
            </Nav>

            <MainDivider/>
         </>
         : userRoles.includes('admin') ?
            <>
               <Nav activeKey={adminViewToggle} onSelect={setAdminViewToggle} appearance='subtle'>
                  <Nav.Item disabled style={{color: 'inherit', paddingLeft: 0}} icon={<AdminIcon style={{fontSize: '1.5em'}}/>}>Admin Alert Center</Nav.Item>
                  <Nav.Item eventKey='User'>User Alerts</Nav.Item>
                  <Nav.Item eventKey='All'>All User Alerts</Nav.Item>
               </Nav>

               <MainDivider/>
            </>
         : null
         }
      </AlertNavSC>
   );
};

const AlertNavSC = styled.div`
   width: 85vw;
`;