import React, { useState, useEffect,useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
//import { AdminContext } from '../_context/AdminContext';
import { SectionTitleFC } from '../_helpers/SectionTitleFC';
import styled from 'styled-components';
import { _UserFC } from './Users/_UserFC';
import { _CustomerFC } from './Customers/_CustomerFC';
import { _NetworkFC } from './Network/_NetworkFC';
import { UsersListFC } from './UsersListFC';
import { LoaderFC } from '../_helpers/LoaderFC';
import { CustomerListFC } from './CustomerListFC';
import { Nav, Navbar, SelectPicker } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { InputContext } from '../_context/InputContext';
import { UserContext } from '../_context/UserContext';
import { NetworkIssueListFC } from './Network/NetworkIssueListFC';
import { getMessage } from '../_utils/util_general';
import { MachinesContext } from '../home/dashboard/_context/MachinesContext';
import { Avatar } from '../../../../appHelpers/appCommonSC';

export const AdminNavFC = ({ onSelect, activeKey, ...props }) => {
   const history = useHistory();
   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);
   
   const {
      loadingMachines,
      machines,
   } = useContext(MachinesContext);

   const { user } = useContext(UserContext);

   const uniqueCustomerIds = new Set();

   const data = machines
      .filter(item => !uniqueCustomerIds.has(item.customer_id) && uniqueCustomerIds.add(item.customer_id))
      .map(item => ({ label: item.customer_name, value: item.customer_id }));

   return (user) ? (
      <Navbar style={{width: '100%', marginBottom: '1%', backgroundColor: 'rgb(255, 255, 255)', color: '#8e8e93'}}>
          <Nav>
            <Nav.Item style={{pointerEvents: 'none'}}>{} {user.first_name ? `GOOD ${getMessage()}, ${user.first_name?.toUpperCase()}!`: null} </Nav.Item>
         </Nav>
          <Nav>
            <Nav.Item style={{pointerEvents: 'none'}}>|</Nav.Item>
         </Nav>
          <Nav>
            <Nav.Item style={{pointerEvents: 'none'}}>{} ADMINISTRATION </Nav.Item>
         </Nav>
          <Nav>
            {data.length > 1 ?
               <Nav.Item>
                  <SelectPicker label="Customer" loading={loadingMachines} placeholder='All' data={data} style={{ width: 300 }} value={adminCustomerToggle} onChange={setAdminCustomerToggle} searchable={false}/>
               </Nav.Item>
            : null
            }           
         </Nav>
         <Nav pullRight onSelect={() => history.push(`/admin/user/${user.email_address}`)}>
            <Nav.Item><Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar></Nav.Item>
         </Nav>
      </Navbar>
   )
   : null
};
