import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../appHelpers/axiosInstance';

const UserContext = React.createContext();

const UserContextProvider = (props) => {
   const [user, setUser] = useState('MMB');
   const [userRoles, setUserRoles] = useState('Users');
   const [loadingUserRoles, setLoadingUserRoles] = useState('Users');
   const { getAccessTokenSilently } = useAuth0();

   const getUserProfile = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const userRes = await axiosInstance({
            url: `/user/profile`,
            data: ``,
            headers: {authorization: `Bearer ${token}`}
         });

         setUser(userRes?.data[0][0]);
         setUserRoles(userRes?.data[0][0]['roles'].roles);
      } catch (error) {
         console.error(error);
      }
      setLoadingUserRoles(false);
   };

   return (
      <UserContext.Provider
         value={{
            user,
            setUser,
            userRoles,
            loadingUserRoles,
            getUserProfile
         }}
      >
         {props.children}
      </UserContext.Provider>
   );
};

export { UserContext, UserContextProvider };