import React, { useContext } from 'react';
import { appLoggers, renderCount } from '../../../appHelpers/appLoggers';
import { DisplayMobileSidebarContext } from '../../../appContexts/DisplayMobileSidebarContext';
import { DarkModeContext } from '../../../appContexts/DarkModeContext';
import styled from 'styled-components';

export const MobileSidebarToggleFC = () => {
   // ENV CONDITIONALS
   const { REACT_APP_LOGGER_RENDER_COUNT } = process.env;
   REACT_APP_LOGGER_RENDER_COUNT === 'TRUE' &&
      renderCount(`./components/main/header/MobileSidebarToggleFC`);

   const { darkMode } = useContext(DarkModeContext);

   const { displayMobileSidebar, setDisplayMobileSidebar } = useContext(
      DisplayMobileSidebarContext
   );

   return (
      <>
         <MobileSidebarToggleSC
            darkMode={darkMode}
            displayMobileSidebar={displayMobileSidebar}
            onClick={() => setDisplayMobileSidebar(!displayMobileSidebar)}
         >
            <svg
               xmlns='http://www.w3.org/2000/svg'
               height='24px'
               viewBox='0 0 24 24'
               width='24px'
               fill='#000000'
            >
               <path d='M0 0h24v24H0V0z' fill='none' />
               <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z' />
            </svg>
         </MobileSidebarToggleSC>
      </>
   );
};

const MobileSidebarToggleSC = styled.div`
   position: fixed;
   left: 100%;
   top: 30px;
   transform: translate(-100%, -50%);
   left: 96vw;
   z-index: 1;

   svg {
      // CHANGE FILL OF SVG IF displayMobileSidebar HAS BEEN OPENED FOR CONTRAST
      // THIS IS ALSO DEPENDENT ON DARK VS LIGHT MODE
      fill: ${({ theme, darkMode, displayMobileSidebar }) =>
         darkMode
            ? displayMobileSidebar
               ? theme.dark_accent
               : theme.dark_accent
            : displayMobileSidebar
            ? theme.light_accent
            : theme.light_accent};
      transform: ${({ theme, displayMobileSidebar }) =>
         displayMobileSidebar ? `rotate(180deg)` : `rotate(0deg)`};
      transition: fill 0.25s, transform 0.25s;
   }

   @media screen and (min-width: 769px) {
      display: none;
   }
`;
