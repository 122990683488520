import React, { useContext } from 'react';
import { renderCount } from '../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { MobileSidebarToggleFC } from './MobileDisplayToggleFC';
import { DarkModeContext } from '../../../appContexts/DarkModeContext';
import { AvanteLogoFC } from './AvanteLogoFC';
import { AuthBtnFC } from '../../../auth/AuthBtnFC';
import Profile from '../../../auth/Profile';

export const _HeaderFC = () => {
   // renderCount(`./components/main/header/_HeaderFC`);

   const { darkMode } = useContext(DarkModeContext);

   return (
      <HeaderSC darkMode={darkMode}>
         <AvanteLogoFC />
         <AuthBtnFC />
         <Profile/>
         <MobileSidebarToggleFC />
      </HeaderSC>
   );
};

const HeaderSC = styled.header`
   width: 100%;
   height: 60px;
   position: fixed;
   z-index: 100;
   background: rgba(255, 255, 255, 0.50);
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(14.5px);
   -webkit-backdrop-filter: blur(14.5px);
`;
