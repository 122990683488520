import React, { useContext } from 'react';
import styled from 'styled-components';
import { renderCount } from '../../../../../../appHelpers/appLoggers';
import { Placeholder } from 'rsuite';
import { DarkModeContext } from '../../../../../../appContexts/DarkModeContext';
import { FlexboxGrid} from 'rsuite';
import LocationIcon from '@rsuite/icons/Location';

export const Loader_MachineMetadataFC = () => {
   renderCount('MachineMetadataFC');
   const { darkMode } = useContext(DarkModeContext);

   return (
      <MachineMetadataSC darkMode={darkMode}>
         <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
               <ItemMainText>
                  <LocationIcon /> <PlaceholderText size='70px'> &nbsp; </PlaceholderText>
               </ItemMainText>                                   
               <ItemSubText>
                  Room: <PlaceholderText size='70px'> &nbsp; </PlaceholderText>
               </ItemSubText>
            </FlexboxGrid.Item>
         </FlexboxGrid>

         <Divider darkMode={darkMode} />  

         <FlexboxGrid>
            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     ID
                  </ValueHeader>         
                  <SecondaryValueSC>
                     <PlaceholderText size='50px'> &nbsp; </PlaceholderText>
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     SYSTEM
                  </ValueHeader>
                  <SecondaryValueSC>
                     <PlaceholderText size='50px'> &nbsp; </PlaceholderText>   
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={6}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     MODEL
                  </ValueHeader>
                  <SecondaryValueSC>
                     <PlaceholderText size='50px'> &nbsp; </PlaceholderText>   
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     SERIAL#
                  </ValueHeader>        
                  <SecondaryValueSC>
                     <PlaceholderText size='50px'> &nbsp; </PlaceholderText>
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={4}>
               <SecondaryInfoSC>
                  <ValueHeader>
                     S/V
                  </ValueHeader>            
                  <SecondaryValueSC>
                     <PlaceholderText size='50px'> &nbsp; </PlaceholderText>
                  </SecondaryValueSC>
               </SecondaryInfoSC>
            </FlexboxGrid.Item>
         </FlexboxGrid>
         <Divider darkMode={darkMode} /> 
      </MachineMetadataSC>
   );
};

// TODO: 1 - MAYBE FIND EXACT WIDTH TO MATCH TABLE BELOW 2 - SET COLOR(S) TO A THEME VALUE
const MachineMetadataSC = styled.div`
   width: 96vw;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   padding: 0 0.5rem 0 0.5rem;

   @media screen and (min-width: 769px) {
      width: 94vw;
   }
`;

const Divider = styled.hr`
   width: 100%;
   height: 0px;
   margin-top: 6px;
   margin-bottom: 10px;
   background-color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   @media screen and (min-width: 769px) {
      width: 100%;
   }
`;

const ValueHeader = styled.div`
   display: block;
   font-size: 0.6em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

const SecondaryInfoSC = styled.div`
   display: block;
   font-size: 0.68rem;
   text-align: center;

   @media screen and (min-width: 769px) {
      display: block;
      font-size: 1rem;
   }
`;

const SecondaryValueSC = styled.span`
   display: block;
   text-align: center;

   @media screen and (min-width: 769px) {
      width: unset;
   }
`;

const ItemMainText = styled.div`
   font-size: 0.75rem;
   font-weight: bolder;

   @media screen and (min-width: 769px) {
      font-size: 0.75rem;
   }

   @media screen and (min-width: 1401px) {
      font-size: 1rem;
   }
`;

const ItemSubText = styled.div`
   font-size: 0.8rem;
   font-weight: normal;
   color: #97969B;

   @media screen and (min-width: 769px) {
      font-size: 0.95rem;
      font-weight: normal;
   }
`;

const PlaceholderText = styled.div`
   display: inline-block;
   width: ${({ size }) =>
      size ? `${size}` : `100px`};
   height: 90%;
   border-radius: 60px;
   background: linear-gradient(-45deg,#f2f2f5 25%,#e5e5ea 37%,#f2f2f5 63%);
   background: linear-gradient(-45deg,var(--rs-placeholder) 25%,var(--rs-placeholder-active) 37%,var(--rs-placeholder) 63%);
   background-size: 400% 100%!important;
   -webkit-animation: placeholder-active 3s ease infinite;
   animation: placeholder-active 3s ease infinite;
`;