import React, { useRef, useState } from "react";
import styled, { ThemeContext } from 'styled-components';
import FusionCharts from "fusioncharts";
import { ReactFusionGrid } from "react-fusiongrid";
import FusionGrid from "fusiongrid";
import { DateTime } from 'luxon';
import "fusiongrid/dist/fusiongrid.css";

FusionGrid.options.license({
   key: process.env.REACT_APP_FUSION_KEY,
   creditLabel: false,
});

ReactFusionGrid.fgRoot(FusionGrid);

export const _FusionGridIndex = ({
   dataHHM, dataConfig, filterLevel
}) => {
   const schema = [];
   const columns = [];

   dataHHM.dataConfig.forEach(config => {
      if (config[0] === 'host_datetime') {
         schema.push({name: config[1], field: config[1]});
         columns.push({field: config[1], name: config[1], width: config[2], formatter: function(params) { return DateTime.fromISO(params.cellValue).toFormat('MM/dd/yyyy hh:mm:ss a')}});        
      } else {
         schema.push({name: config[1]})
         columns.push({field: config[1], name: config[1], width: config[2], tooltip: {cellTooltip: function (params) {
             return (
               params.cellValue
             );
         },}})
      }
   });

   const data = dataHHM.dataTable.map(data => Object.values(data));

   const dataStore = new FusionGrid.DataStore();

   const dataTable = dataStore.createDataTable(data, schema, {
      enableIndex: false
   });

   const Tooltip = ({ children }) => {
      const [isTooltipVisible, setTooltipVisible] = useState(false);
      const handleMouseEnter = () => setTooltipVisible(true);
      const handleMouseLeave = () => setTooltipVisible(false);
      
      return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {isTooltipVisible && (
            <div
              style={{
                position: 'absolute',
                zIndex: '9999',
                backgroundColor: 'white',
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '2px 2px 2px grey',
              }}
            >
              {children}
            </div>
          )}
        </div>
      );
   };  

   const gridConfig = {
      columns: columns,
      defaultColumnOptions: {
         searchable: true
      },
      pagination: {
         showRecordCount: true,
         pageSize: {           
            default: 300
        }
     }
   };

   return (
      <FusionGridSC>
         <ReactFusionGrid data={dataTable} height={'80vh'} width={'100%'} config={gridConfig}/>;
      </FusionGridSC>
   )
};

const FusionGridSC = styled.div`
   width: 97.5%;

   .searchBox {
      margin: 10px;
      width: 100%;
   }
`

