import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Message } from 'rsuite';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';

export const UpdateMessageFC = () => {
   const [padding, setPadding] = useState(20);

   const handleMessageClose = () => {
      setPadding(0);
   }

   const currentHour = new Date().getHours();

   useEffect(() => {
    
   }, [padding]);

   return (
   <UpdateMessageSC>
      <MessageBox paddingSize={padding}>
         <Message closable showIcon type="info" header="Scheduled Maintenance Notification: October 6th beginning at 9:30AM EST." onClose={handleMessageClose}>
            We wanted to inform you that we have updated our Authentication platform. If this is your first time signing in after October 6th, please hit forgot password and you will be sent an email to reset your password. <br/>
            If email is not in your inbox, please check your 'junk' or 'spam' folder.
         
            If you run into any issues, please reach out to RemoteTechnology@Avantehs.com for assistance. 
            <br/><br/>Sincerely,<br/>

            Avante Remote Technology Team
         </Message>
      </MessageBox>
   </UpdateMessageSC>
   );
}

const MessageBox = styled.div`
   padding: ${({ paddingSize }) =>  paddingSize ? `${paddingSize}px` : '0px'};

   width: 80%;
`

const UpdateMessageSC = styled.div`
   .rs-message-info {
      background-color: rgb(0, 91, 148);
      color: white;
   }

   .rs-message-warning {
      background-color:  #ffb300;
      color: white;
   }

    .rs-message-info .rs-icon {
      fill: white;
   }

   .rs-message-warning .rs-icon {
      fill: black;
   }
   
   .rs-message-info .rs-message-body {
      color: white;
   }

   .rs-message-warning .rs-message-body {
      color: black;
   }

   .rs-message-info .rs-message-header {
      color: white;
   }
   
   width: 95vw;
`