import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { FlexboxGrid, List, IconButton, Message, Loader, useToaster } from 'rsuite';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import PlusIcon from '@rsuite/icons/Plus';
import { MainDivider, SettingBody, SettingHeader, ListTable, ListHeader } from '../helpers/Admin_StyledComponents';
// Requiring the lodash library
const _ = require("lodash");

export const CustomerAcumaticaFC = (props) => { 
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const [acumaticaSites, setAcumaticaSites] = useState([]);
   const [acumaticaSystems, setAcumaticaSystems] = useState([]);
   const [loadingAcumaticaData, setLoadingAcumaticaData] = useState(true);

   const getCustomerAcumaticaData = async () => {
      setLoadingAcumaticaData(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            url: `/customer/acumatica/${props.customer}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setAcumaticaSites(res?.data[0]);
         setAcumaticaSystems(res?.data[1]);
      } catch (error) {
         console.error(error);
      }
      setLoadingAcumaticaData(false);
   };

   const handleAddSite = async (id) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
         
         const message = (
            <Message showIcon type="success" header="Success">
               You successfully updated added a new Site.
            </Message>
         );

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/insert/site/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               customer_id: props.customer,
               id: id
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         setAcumaticaSites(acumaticaSites.filter(site => {return site.id !== id}));
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleAddSystem = async (id) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
         const message = (
            <Message showIcon type="success" header="Success">
               You successfully updated added {id}.
            </Message>
         );

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/insert/system/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               customer_id: props.customer,
               id: id
            }),
         });
         // setResMsg(response.data);
         toaster.push(message,'topCenter');
         setAcumaticaSystems(acumaticaSystems.filter(system => {return system.id !== id}));
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   useEffect(() => {
      getCustomerAcumaticaData();
   }, [props.customer]);

   return (
      <SettingBody>
         <MainDivider/>

         <SettingHeader>
            Acumatica
            <SubText>New Sites: {acumaticaSites?.length}</SubText>
            <SubText>New Systems: {acumaticaSystems?.length}</SubText>
         </SettingHeader>

         <MainDivider/>

         <ActiveText>NEW SITES</ActiveText>

         {acumaticaSites.length > 0 ?
            <ListTable>
               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={1}>

                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        SITE ID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={7}>
                        NAME
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={2} align='center'>
                        STATE
                     </FlexboxGrid.Item>    
                     <FlexboxGrid.Item colspan={3}>
                        CITY
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6}>
                        ADDRESS
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        ZIP
                     </FlexboxGrid.Item>        
                  </FlexboxGrid>
               </ListHeader>

               <List size="sm">
               {acumaticaSites?.map((site, index) => (
                  <List.Item key={index} value={site}>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={1} align='center'>
                           <IconButton icon={<PlusIcon style={{fontSize: '1em', padding: 0, margin: 0}}/>} onClick={() => handleAddSite(site.id)}/>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {site.id ? site.id : ""}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={7}>
                           {site.name ? site.name : ""}                      
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.state ? site.state : ""}                    
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} >
                           {site.city ? site.city : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                           {site.street_address ? site.street_address : ""}  
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2} align='center'>
                           {site.zip ? site.zip : ""} 
                        </FlexboxGrid.Item> 
                     </FlexboxGrid>
                  </List.Item> 
               ))}                  
               </List>
            </ListTable>
            : loadingAcumaticaData ?
               <Loader content="Loading..." />
            :
               <ListTable> None </ListTable>
         }
      
         <ActiveText>NEW SYSTEMS</ActiveText>

         {acumaticaSystems.length > 0 ?
            <ListTable>
               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={1} align='center'>
                  
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        ID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        CUID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        MANUFACTURER
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={3} align='center'>
                        MODALITY
                     </FlexboxGrid.Item>   
                     <FlexboxGrid.Item colspan={4} align='center'>
                        MODEL
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        S/N
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center'>
                        ROOM
                     </FlexboxGrid.Item>  
                  </FlexboxGrid>
               </ListHeader>

               <List size='sm'>
                  {acumaticaSystems?.map((system, index) => (
                     <List.Item key={index} value={system}>     
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={1} align='center'>
                              <IconButton icon={<PlusIcon style={{fontSize: '1em', padding: 0, margin: 0}}/>} onClick={() => handleAddSystem(system.id)}/>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {system.id ? system.id : ""}  
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {system.cus_sys_id ? system.cus_sys_id : ""}  
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {system.manufacturer ? system.manufacturer : ""}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {system.modality ? system.modality : ""} 
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={4} align='center'>
                              {system.model ? system.model : ""}  
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3} align='center'>
                              {system.serial_number ? system.serial_number : ""}  
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={4} align='center'>
                              {system.room ? system.room : ""}  
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     </List.Item>    
                  ))}
               </List>      
            </ListTable>
            : loadingAcumaticaData ?
               <Loader content="Loading..." />
            : 
               <ListTable> None </ListTable>
         }               
      </SettingBody>
   );
};

const SubText = styled.div`
   padding-left: 2.5%;
   padding-right: 2.5%;
   display: inline-block;
   font-family: inherit;
   font-size: 1.25vw;
   font-weight: 300;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const ActiveText = styled.div`
   font-family: inherit;
   font-size: 1.0vw;
   font-weight: 300;
   color: rgb(0, 91, 148);
   padding-bottom: 0.5%;
   padding-top: 0.5%;
   padding-left: 1%;
   background-color: rgb(243, 248, 252);

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);