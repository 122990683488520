import React, { useEffect, useState } from "react";
import { CheckPicker, Checkbox } from "rsuite";
import styled from "styled-components";

export const FilterSystems = ({
  report,
  currentReportSys,
  setCurrentReportSys,
  allSystems,
}) => {
  // Step 1: Prepare data for CheckPicker
  const customerNames = Array.from(
    new Set(allSystems.map((item) => item.customer_name))
  ).map((name) => ({ label: name, value: name }));
  const manufacturers = Array.from(
    new Set(allSystems.map((item) => item.manufacturer))
  ).map((manufacturer) => ({ label: manufacturer, value: manufacturer }));
  const modalities = Array.from(
    new Set(allSystems.map((item) => item.modality))
  ).map((modality) => ({ label: modality, value: modality }));

  // Step 2: State Management for Selected Filters
  const [selectedCustomerNames, setSelectedCustomerNames] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedModalities, setSelectedModalities] = useState([]);

  const [viewAvanteId, setViewAvanteId] = useState(true);

  // Set Systems
  const [filteredSystems, setFilteredSystems] = useState([]);

  const handleCheckAll = (value, checked) => {
    let all_sme = filteredData.map((system) => system.id);

    setCurrentReportSys(
      checked ? [...new Set(all_sme.map((system) => system))] : []
    );
  };

  // Old End

  const setSystems = (filteredData) => {
    setFilteredSystems([
      ...new Map(
        filteredData.map((system) => [
          system,
          {
            value: system.id,
            label: system.cus_sys_id
              ? `${system.cus_sys_id} - ${system.manufacturer} - ${system.modality}`
              : `${system.id} - ${system.manufacturer} - ${system.modality}`,
          },
        ])
      ).values(),
    ]);
  };

  const setSystemsAvanteId = (filteredData) => {
    // Show customer ID
    if (!viewAvanteId) {
      setFilteredSystems([
        ...new Map(
          filteredData.map((system) => [
            system,
            {
              value: system.id,
              label: system.cus_sys_id
                ? `${system.cus_sys_id} - ${system.manufacturer} - ${system.modality}`
                : `${system.id} - ${system.manufacturer} - ${system.modality}`,
            },
          ])
        ).values(),
      ]);
      setViewAvanteId(!viewAvanteId);
    } else {
      // Show Avante ID
      setFilteredSystems([
        ...new Map(
          filteredData.map((system) => [
            system,
            {
              value: system.id,
              label: `${system.id} - ${system.manufacturer} - ${system.modality}`,
            },
          ])
        ).values(),
      ]);
      setViewAvanteId(!viewAvanteId);
    }
  };

  // Step 3: Filtering the Data
  const filteredData = allSystems.filter(
    (item) =>
      (selectedCustomerNames.length === 0 ||
        selectedCustomerNames.includes(item.customer_name)) &&
      (selectedManufacturers.length === 0 ||
        selectedManufacturers.includes(item.manufacturer)) &&
      (selectedModalities.length === 0 ||
        selectedModalities.includes(item.modality))
  );

  // On Change

  const handleChangeSystem = (value) => {
    setCurrentReportSys(value);
  };

  // useEffect

  useEffect(() => {
    setCurrentReportSys(report.systems_list || null);
  }, []);

  useEffect(() => {
    setSystems(filteredData);
  }, [selectedCustomerNames, selectedManufacturers, selectedModalities]);

  return (
    <FilterContainer>
      <FlexRow>
        <CheckPicker
          data={customerNames}
          onChange={setSelectedCustomerNames}
          placeholder="Customer"
        />
        <CheckPicker
          data={manufacturers}
          onChange={setSelectedManufacturers}
          placeholder="Manufacturer"
        />
        <CheckPicker
          data={modalities}
          onChange={setSelectedModalities}
          placeholder="Modality"
        />
      </FlexRow>

      {/* Step 4: Display Filtered Data */}
      <FilterContainer>
        <CheckPicker
          data={filteredSystems}
          value={currentReportSys}
          onChange={handleChangeSystem}
          placeholder="Systems"
          block
          renderExtraFooter={() => (
            <FlexRowN>
              {currentReportSys.length <= 1 && allSystems.length <= 1 ? (
                true
              ) : (
                <div style={footerStyles}>
                  {
                    <Checkbox
                      indeterminate={
                        currentReportSys.length > 0 &&
                        currentReportSys.length < filteredData.length
                      }
                      checked={currentReportSys.length === filteredData.length}
                      onChange={handleCheckAll}
                    >
                      Check all
                    </Checkbox>
                  }
                </div>
              )}
              <div style={footerStyles}>
                {
                  <Checkbox onChange={() => setSystemsAvanteId(filteredData)}>
                    Avante ID
                  </Checkbox>
                }
              </div>
            </FlexRowN>
          )}
        />
      </FilterContainer>
    </FilterContainer>
  );
};

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FlexRowN = styled.div`
  display: flex;
  flex-direction: row;
  `;

const FilterContainer = styled.div`
  margin-bottom: 1rem;
`;
