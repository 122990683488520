import GlobalIcon from '@rsuite/icons/Global';

import React, { useContext } from 'react';
import styled from 'styled-components';
import { DarkModeContext } from '../../appContexts/DarkModeContext';

export const Icon_Website = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <Icon_WebsiteSC darkMode={darkMode}>
            <svg viewBox="0 0 16 16" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 1a7.5 7.5 0 110-15 7.5 7.5 0 010 15z"></path>
                <path d="M3.585 5.04a8.084 8.084 0 01-.785-.426L2 4l.5-.5h.442c.263.208.603.413.988.6C4.558 2.615 5.543 1.333 7 1.09V2c-.879 0-1.682 1.037-2.236 2.453C5.657 4.78 6.658 5 7.5 5c1.528 0 3.574-.721 4.558-1.5h.442l.5.5-.8.614C10.982 5.372 9.084 6 7.5 6c-.976 0-2.072-.238-3.054-.599A11.618 11.618 0 004 8.5C4 11.436 5.371 15 7 15v.98c-3-.5-4-5.48-4-7.48 0-.828.171-2.154.585-3.46z"></path>
                <path d="M10.236 12.547C9.343 12.22 8.342 12 7.5 12c-1.528 0-3.574.721-4.558 1.5H2.5L2 13l.8-.614C4.018 11.628 5.916 11 7.5 11c.976 0 2.072.238 3.054.599.282-.992.446-2.083.446-3.099C11 5.564 9.629 2 8 2v-.91c3 .5 4 5.41 4 7.41 0 .823-.169 2.151-.578 3.463.28.134.542.276.778.423L13 13l-.5.5h-.442a5.46 5.46 0 00-.975-.594C10.456 14.42 9.467 15.736 8 15.98V15c.879 0 1.682-1.037 2.236-2.453z"></path>
                <path d="M7 8V2h1v6h6v1H8v6H7V9H1V8z"></path>
            </svg>
        </Icon_WebsiteSC>
    )
};

const Icon_WebsiteSC = styled.div`
   display: inline-block;
   text-align: right;
   margin: 0 auto;

   svg {
        vertical-align: text-bottom;
        fill: ${({ theme, darkMode }) =>
            darkMode ? theme.dark_accent : theme.avante_dark_blue};
   }

   svg rect {
        fill: ${({ theme }) => theme.avante_light_blue};
   }
`;
