import React, { useEffect } from "react";
import styled from 'styled-components';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import "fusiongrid/dist/fusiongrid.css";
import { IconButton } from "rsuite";

export const ExportMMB = ({systems, recent_data, dataConfig, engineerLevel}) => {

   const fieldsToRemove = [
      'software_version', 
      'customer_id', 
      'city', 
      'state', 
      'site_id', 
      'serial_number', 
      'room',
      'he_tank_volume',
      'edu_datetime',
      'process_mag',
      'process_edu',
      'version'
   ];

   let filterLevelArray = [1, 2, 3 , 4];

   if (engineerLevel === 1) {
      filterLevelArray = [1];
   } else if (engineerLevel === 2) {
      filterLevelArray = [1,2]
   } else if (engineerLevel === 3) {
      filterLevelArray = [1, 2, 3]
   }

   // Filtering based on the filter level
   const filteredConfig = recent_data[0].filterConfig.filter(item => filterLevelArray.includes(item[2]));

   const filteredPgColumns = filteredConfig.map(item => item[0]);

   const filteredArray = dataConfig[0]?.dataLabels?.filter(item => filteredPgColumns.includes(item.pgColumn));

   filteredArray?.unshift({uiLabel: 'DateTime', pgColumn: 'capture_datetime', uiPosition: 1})
   // Map over each data entry
   const transformedDataEntries = recent_data[0].dataTable.map(dataEntry => {
      // Reduce the mapping array to transform each data entry
      return filteredArray?.reduce((acc, { pgColumn, uiLabel }) => {
         if (dataEntry[pgColumn] !== undefined) {
               acc[uiLabel] = dataEntry[pgColumn]; // Use pgColumn to fetch the value and map it to uiLabel
         }
         return acc;
      }, {});
   });

   // Helper function to remove unwanted fields from an object
   const removeFields = (obj, fields) => {
      const newObj = { ...obj };
      fields.forEach(field => {
         delete newObj[field];
         });
      return newObj;
   };

   const dataArray =  Array.from(transformedDataEntries.values()).map(item => removeFields(item, fieldsToRemove));

   // Step 1: Create your data array
   const data = dataArray;

   // Step 2: Convert the JSON data to CSV
   const convertToCSV = (json) => {
      const array = [Object.keys(json[0])].concat(json);

      return array.map(row => {
      return Object.values(row).map(value => {
         return typeof value === 'string' ? JSON.stringify(value) : value;
      }).toString();
      }).join('\n');
   };

   // Step 3: Create a function to download the CSV
   const downloadCSV = () => {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace(/[-:T]/g, '');
      a.href = url;
      a.download = `${systems[0].id}_mag_data_${formattedDate}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
   };

   useEffect(() => {
        
   }, []);

   return (
      <FusionExportSC>
         <IconButton size="sm" icon={<FileDownloadIcon />} onClick={downloadCSV}>Export</IconButton>
      </FusionExportSC>
   );
};

const FusionExportSC = styled.div`
    align-content: center;
`;
