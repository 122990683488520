import React, { useContext } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, IconButton } from 'rsuite';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { ListHeader, MainDivider } from '../../../admin/helpers/Admin_StyledComponents';
import { SeverityAlert } from '../../../../../../appHelpers/appCommonSC';
import { DateTime } from 'luxon';
import { UserContext } from '../../../_context/UserContext';

export const AlertUnsetFC = ({ displayAlertConfigs, dataTable, handleAdd }) => {

   return (
      <AlertUnsetSC>
         {displayAlertConfigs.filter(config => (config.user_id === 'default' && !displayAlertConfigs.map(alert => alert['default_id']).includes(config.id))).length > 0 ?
         <>
            <HeaderText>Unset Default Alerts</HeaderText>

            <MainDivider/>

            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={3}  align='center'>
                     FIELD NAME
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}  align='center'>
                     OPERATOR
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}  align='center'>
                     THRESHOLD
                  </FlexboxGrid.Item> 
                  <FlexboxGrid.Item colspan={3}  align='center'>
                     SEVERITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}  align='center'>
                     CURRENT STATUS
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={5}  align='center'>
                     MESSAGE
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3}  align='center'/>         
               </FlexboxGrid>
            </ListHeader>
            <List>
               {displayAlertConfigs.filter(config => (config.user_id === 'default' && !displayAlertConfigs.map(alert => alert['default_id']).includes(config.id))).map(config => (
                  <List.Item key={config.id} value={config}>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           {config?.field_name_alias}
                           <SmallText>{config?.field_name}</SmallText>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           {config?.operator?.replace(/_/g, " ")} 
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           {config?.threshold} {config?.threshold_units}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                              {dataTable ?
                                 config?.operator === 'greater_than' && parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold) ?
                                    <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                 : config?.operator === 'less_than'  && parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold)  ?
                                    <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                 : config?.operator === 'equals' && config.field_name === 'comp_vib_status'  ?
                                    dataTable[config?.field_name] === 0 ? <SeverityAlert severity={config?.severity}>OFF</SeverityAlert> : <SeverityAlert>ON</SeverityAlert>
                                 : (config?.operator === 'offline' || config?.operator === 'frozen_host') && Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours) > parseFloat(config?.threshold) ?
                                    <SeverityAlert severity={config?.severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>       
                                 : (config?.operator === 'offline' || config?.operator === 'frozen_host') ?
                                    <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>   
                                 :
                                 <SeverityAlert>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                              : null
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={5} align='center'>
                              {dataTable ?                          
                                 config?.operator === 'greater_than' &&  parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold)  ?
                                    `${dataTable[config?.field_name]} ${config?.threshold_units} is greater than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                                 : config?.operator === 'less_than' &&  parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold)  ?
                                    `${dataTable[config?.field_name]} ${config?.threshold_units} is less than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                                 : null
                              : null
                              }
                           </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           <IconButton icon={<AddOutlineIcon color='green'/>} style={{ marginLeft: '5px', display: 'inline-block'}} onClick={() => {handleAdd(config.id)}}>Add</IconButton>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>
                  </List.Item>
               ))}
            </List>
         </> : null
        }
      </AlertUnsetSC>
   );
};

const AlertUnsetSC = styled.div`
   width: 85vw;
`;

const SmallText = styled.div `
   display: inline-block;
   font-size: 0.85em;
   color: rgb(191, 191, 191);
   
   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;

const HeaderText = styled.div `
   width: 100%;
   font-size: 1.3em;

   display: inline-block;

   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;