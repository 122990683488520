import React, { useEffect, useRef, useState } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import { IconButton, Form, Modal, Button, SelectPicker, Divider, Message, useToaster, Schema, TagPicker, CheckPicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

export const InviteUser = ({customerData, getUserList, systemList}) => {
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();

   // User Profile Template
   const initFormSite = {
      email_address: null,          // REQUIRED
      first_name: null,             // REQUIRED
      last_name: null,              // REQUIRED
      phone_number: null,           // OPTIONAL
      user_roles: ['basic'],        // REQUIRED
      customer_id: customerData.id, // AUTOSCOPE ID
      system_list_config: []        // REQUIRED     
   }

   // FORM VALIDATION
   const formRef = useRef();
   const [formError, setFormError] = useState({});
   const existRule = Schema.Types.StringType().isRequired('This field is required.');
   const emailRule = Schema.Types.StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.');
   const phoneRule = Schema.Types.StringType().containsNumber('Must contain only numbers').maxLength(10, 'Phone Number Must Be Exactly 10 Characters.').minLength(10, 'Phone Number Must Be Exactly 10 Characters.');

   // Handle Modal Open/Close
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);

   // INITIALIZE FORM
   const [formValue, setFormValue] = useState(initFormSite);
   const roles = ['basic', 'admin'].map(item => ({
      label: item,
      value: item
   }));

   // INITALIZE SITES FOR ACCESS
   const sites = ([...new Map(systemList?.filter(system => system.customer_id === customerData.id).map(item => [item.site_id, ({
      label: item.site_name,
      value: item.site_id
   })])).values()]);
 
   // SUCCESS MESSAGE
   const message = (
      <Message showIcon type="success" header="Success">
         User Has Been Successfully Invited
      </Message>
   );

   const initForm = () => {
      setFormValue({
         email_address: null,          // REQUIRED
         first_name: null,             // REQUIRED
         last_name: null,              // REQUIRED
         phone_number: null,           // OPTIONAL
         user_roles: ['basic'],        // REQUIRED
         customer_id: customerData.id, // AUTOSCOPE ID
         system_list_config: []        // REQUIRED     
      });
   }

   const onSubmit = async () => {

      // CHECK IF USER FORM IS CORRECT
      if (!formRef.current.check()) {
         console.error('Form Error');
         return;
      }

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/auth/user/invite-user`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               user_form: formValue
            }),
         });
         toaster.push(message,'topCenter');
         getUserList();
         handleClose();
      } catch (error) {
         toaster.push(<Message showIcon type="error" header="Error">{error.response.data.message.toString()}</Message>,'topCenter');
      }
   }

   useEffect(() => {
      initForm();
   }, [customerData]);

   return (
      <>
         <IconButton appearance="primary" icon={<PlusIcon/>} onClick={handleOpen}> Invite User</IconButton>
         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Invite New User to: <b>{customerData.name}</b></Modal.Title>
            </Modal.Header>
            <Divider/>
            <Modal.Body>
               <Form fluid
                  ref={formRef}
                  onCheck={setFormError}
                  formValue={formValue}
                  onChange={formValue => setFormValue(formValue)}
               >
                  <Form.Group controlId="first_name">
                     <Form.ControlLabel style={{display: 'inline-block'}}>First Name</Form.ControlLabel>
                     <Form.HelpText tooltip>User's First Name is Required</Form.HelpText>
                     <Form.Control name="first_name" rule={existRule}/>
                     
                  </Form.Group>
                  <Form.Group controlId="last_name">
                     <Form.ControlLabel style={{display: 'inline-block'}}>Last Name</Form.ControlLabel>
                     <Form.HelpText tooltip>User's Last Name is Required</Form.HelpText>
                     <Form.Control name="last_name" rule={existRule}/>
                  </Form.Group>
                  <Form.Group controlId="email_address">
                     <Form.ControlLabel style={{display: 'inline-block'}}>Email Address</Form.ControlLabel>
                     <Form.HelpText tooltip>User's Email Address is Required</Form.HelpText>
                     <Form.Control name="email_address" rule={emailRule}/>                    
                  </Form.Group>  
                  <Form.Group controlId="phone_number">
                     <Form.ControlLabel style={{display: 'inline-block'}}>Phone Number</Form.ControlLabel>
                     <Form.HelpText tooltip>Phone Number Should Contain Only 10 Digits and No Dashes</Form.HelpText>
                     <Form.Control name="phone_number" rule={phoneRule}/>
                  </Form.Group>     
                  <Form.Group controlId="user_roles">
                     <Form.ControlLabel>Roles</Form.ControlLabel>
                     <Form.Control name="user_roles" accepter={TagPicker} data={roles} block/>
                  </Form.Group>
                  <Form.Group controlId="system_list_config">
                     <Form.ControlLabel>Site Access</Form.ControlLabel>
                     <Form.Control name="system_list_config" preventOverflow placeholder='All' accepter={CheckPicker} data={sites} block/>
                  </Form.Group>                
               </Form>      
            </Modal.Body>

            <Modal.Footer>
               <Button appearance="primary" onClick={onSubmit}>
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>      
      </>
   );
}

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);