import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { Form, Button, ButtonGroup, InputNumber, SelectPicker, Checkbox, toaster, Message } from 'rsuite';
import styled from 'styled-components';
import { MainDivider, SettingHeader } from '../../helpers/Admin_StyledComponents';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';

export const GenerateAcquisition = ({acquisition_config, regex_models}) => { 
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();

   const initAcquisitionConfig =  {
      system_id: acquisition_config?.system_id ? acquisition_config?.system_id : id,
      host_ip: acquisition_config?.host_ip,
      mmb_ip: acquisition_config?.mmb_ip,
      protocal: acquisition_config?.protocal,
      debian_server_path: acquisition_config?.debian_server_path,
      credentials_group: acquisition_config?.credentials_group,
      acquisition_script: acquisition_config?.acquisition_script,
      run_group: acquisition_config?.run_group,
      host: acquisition_config?.host,
      user_id: acquisition_config?.user_id,
      acqu_point: acquisition_config?.acqu_point,
      file_version: acquisition_config?.file_version,
      alt_data_source: acquisition_config?.alt_data_source,
      host_path: acquisition_config?.host_path,
      cerb_file: acquisition_config?.cerb_file,
      vpn: acquisition_config?.vpn
   }

   // PREFILL DATA
   const protocolSelectData = ['ssh', 'lftp', 'rsync'].map(item => ({
      label: item,
      value: item
   }));

   const acquPointSelectData = ['mmb', 'host'].map(item => ({
      label: item,
      value: item
   }));
   
   const [formValueAcquisition, setFormValueAcquisition] = useState(initAcquisitionConfig);

   const resetForm = () => {
      setFormValueAcquisition(initAcquisitionConfig);
   }

   const handleSetFormValueAcquisition = (event) => {
      setFormValueAcquisition(event);
   }

   const message = (
      <Message showIcon type="success" header="Success">
         You successfully updated this System.
      </Message>
   );

   const errMessage = (
      <Message showIcon type="error" header="Error">
         Something went wrong. Please try again.
      </Message>
   );

   // UPDATE EDU CONFIG
   const updateConfigEDU = async () => {
      // console.log(`getSystemData`);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const res = await axiosInstance({
            method: 'POST',
            url: `/network/update/config-acquisition`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               sme: id,
               config: formValueAcquisition
            })
         });
         toaster.push(message,'topCenter');
      } catch (error) {
         toaster.push(errMessage,'topCenter');
         console.error(error);
      }
   };

   useEffect(() => {
      
   }, []);

   return (
      <ConfigBox>
         <SettingHeader>
            Acquisition
            <ButtonGroup style={{paddingLeft: '1%'}}>
               <Button appearance="primary" onClick={updateConfigEDU}>Submit</Button>
               <Button appearance="default" onClick={resetForm}>Reset</Button>
            </ButtonGroup> 
         </SettingHeader>

         <MainDivider/>

         <Form fluid
            formValue={formValueAcquisition}
            onChange={(event) => handleSetFormValueAcquisition(event)}
         >
            <Form.Group controlId='system_id' style={{width: '100%'}}>
               <Form.ControlLabel>System ID</Form.ControlLabel>
               <Form.Control name="system_id" disabled/>
            </Form.Group>
            <Form.Group controlId='host_ip' style={{width: '23.5%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Host IP</Form.ControlLabel>
               <Form.Control name="host_ip"/>
            </Form.Group>
            <Form.Group controlId='mmb_ip' style={{width: '23.5%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>MMB IP</Form.ControlLabel>
               <Form.Control name="mmb_ip"/>
            </Form.Group>
            <Form.Group controlId='host' style={{width: '23.5%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Host</Form.ControlLabel>
               <Form.Control name="host"/>
            </Form.Group>
            <Form.Group controlId='user_id' style={{width: '23.5%', marginLeft: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>User ID</Form.ControlLabel>
               <Form.Control name="user_id"/>
            </Form.Group>
            <Form.Group controlId='host_path' style={{width: '25%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Host Path</Form.ControlLabel>
               <Form.Control name="host_path"/>
            </Form.Group>
            <Form.Group controlId='debian_server_path' style={{width: '45%', marginLeft: '1%', marginRight: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Debian Server Path</Form.ControlLabel>
               <Form.Control name="debian_server_path" />
            </Form.Group>
            <Form.Group controlId='acquisition_script' style={{width: '26%', marginLeft: '1%', display: 'inline-block'}}>
               <Form.ControlLabel>Acquisition Script</Form.ControlLabel>
               <Form.Control name="acquisition_script"/>
            </Form.Group>
            <Form.Group controlId='protocal' style={{width: '21%',  marginRight: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>Protocol</Form.ControlLabel>
               <Form.Control name="protocal"  accepter={SelectPicker} data={protocolSelectData} searchable={false} style={{width: '100%'}}/>
            </Form.Group>
            <Form.Group controlId='acqu_point' style={{width: '21.5%', marginRight: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>Acqu Point</Form.ControlLabel>
               <Form.Control name="acqu_point" accepter={SelectPicker} data={acquPointSelectData} searchable={false} style={{width: '100%'}} preventOverflow/>
            </Form.Group>
            <Form.Group controlId='vpn' style={{width: '10%', marginLeft: '2.5%', marginRight: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>VPN</Form.ControlLabel>
               <Form.Control name="vpn" accepter={Checkbox}/>
            </Form.Group>
            <Form.Group controlId='credentials_group' style={{width: '15%', marginRight: '2.5%', marginLeft: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>Credentials Group</Form.ControlLabel>
               <Form.Control name="credentials_group" accepter={InputNumber} min={0}/>
            </Form.Group>
            <Form.Group controlId='run_group' style={{width: '15%', marginLeft: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>Run Group</Form.ControlLabel>
               <Form.Control name="run_group" accepter={InputNumber} min={0}/>
            </Form.Group>      
            <Form.Group controlId='file_version' style={{width: '20%', marginRight: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>File Version</Form.ControlLabel>
               <Form.Control name="file_version"/>
            </Form.Group>
            <Form.Group controlId='alt_data_source' style={{width: '45%', marginLeft: '2.5%', marginRight: '2.5%',display: 'inline-block'}}>
               <Form.ControlLabel>Alt Data Source</Form.ControlLabel>
               <Form.Control name="alt_data_source" />
            </Form.Group>           
            <Form.Group controlId='cerb_file' style={{width: '25%', marginLeft: '2.5%', display: 'inline-block'}}>
               <Form.ControlLabel>Cerb File</Form.ControlLabel>
               <Form.Control name="cerb_file"/>
            </Form.Group>
         </Form>
      </ConfigBox>
   )
}

const ConfigBox = styled.div`
   display: block;
`