import styled from 'styled-components';
import { List, FlexboxGrid, Popover, Checkbox } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { AlertText } from '../../../_utils/util_general';
import { AlertsContext } from '../_context/AlertsContext';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../../appHelpers/axiosInstance';
import { InputContext } from '../../../_context/InputContext';
import RemindFillIcon from '@rsuite/icons/RemindFill';

export const AlertListFC = ({user, system}) => {
   const { getAccessTokenSilently } = useAuth0();
   const { alertConfigs, defaultAlertConfigs, setAlertConfigs} = useContext(AlertsContext);
   const { viewToggle } = useContext(InputContext);

   const onClickEnabled = async (alert) => {
      const updatedAlertConfigs = alertConfigs.map((config) => {
         if (config.id === alert.id) {
            return {
               ...config,
               enabled: !config.enabled,
            };
         }
         return config;
      });

      // Call a function to update the alertConfigs state
      setAlertConfigs(updatedAlertConfigs);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/toggle/individual/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: system.id,
               model_id: alert.id
            }),
         });
         
      } catch (error) {
         console.error(error);
      }
   }

   return (
      <>
         <FlexboxGrid>
            <FlexboxGrid.Item colspan={22} style={{alignSelf: 'center'}}>
               Your Currently Set {viewToggle === 'MMB' ? 'Magnet' : viewToggle === 'EDU' ? 'EDU' : viewToggle === 'HHM' ? 'Log' : ''} Alerts:
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={2} style={{alignSelf: 'center'}}>
               Enabled
            </FlexboxGrid.Item>
         </FlexboxGrid>
         <List size='sm'>
            {user ? 
               alertConfigs?.filter(config => (config.system_id === system.id 
                  && (viewToggle === 'MMB' ? (!config.pg_table.toLowerCase().includes('log') && !config.pg_table.toLowerCase().includes('hhm')) 
                     : viewToggle === 'EDU' ? config.pg_table.toLowerCase().includes('edu') 
                     : viewToggle === 'HHM' ? (config.pg_table.toLowerCase().includes('log') || config.pg_table.toLowerCase().includes('hhm')) 
                     : true))).map((alert, index) => (             
                  <List.Item key={`${index}_${alert.id}`} style={defaultAlertConfigs.find(dConfig => (dConfig.id === alert.default_id))?.enabled ? {backgroundColor: '', height: '45px'} : {backgroundColor: '#e5e5ea', height: '65px'}}>
                     
                     {/* Shows Globally Disabled Banner if Default is Disabled */}
                     {!defaultAlertConfigs.find(dConfig => (dConfig.id === alert.default_id))?.enabled ?
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={24} style={{paddingLeft: 5}}>
                              <RemindFillIcon/> This alert has been globally disabled!
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     : null
                     }

                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={4} style={{alignSelf: 'center'}}>
                           <AlertText severity={alert.severity}>{alert.severity}</AlertText>
                        </FlexboxGrid.Item>

                        {viewToggle !== 'HHM' ?
                           <>
                              <FlexboxGrid.Item colspan={5} style={{alignSelf: 'center', padding: '1%'}}>
                                 <b>{alert.field_name_alias}</b>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={13} style={{alignSelf: 'center', padding: '1%'}}>
                                 {alert.field_name === 'comp_vib_status' ? 
                                    <>Trigger: {alert.field_name_alias} is OFF!</>
                                 : (alert.operator?.toLowerCase() === 'offline' || alert.operator?.toLowerCase() === 'frozen_host') ?
                                    <>Trigger: {alert.field_name_alias} within past {alert.threshold} {alert.threshold_units}.</>
                                 :
                                    <>Trigger: {alert.field_name_alias} is {alert.operator?.replace(/_/g, ' ')} {alert.threshold} {alert.threshold_units}.</>
                                 }
                              </FlexboxGrid.Item>
                           </>
                           :
                           <FlexboxGrid.Item colspan={17} style={{alignSelf: 'center', padding: '1%'}}>
                              {(alert.operator?.toLowerCase() === 'offline' || alert.operator?.toLowerCase() === 'frozen_host') ?
                                 <b>Trigger: {alert.field_name_alias} within past {alert.threshold} {alert.threshold_units}.</b>
                              :
                                 <b>{alert.field_name_alias}</b>
                              }  
                           </FlexboxGrid.Item>
                        }
                        {!defaultAlertConfigs.find(dConfig => (dConfig.id === alert.default_id))?.enabled ?
                           <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>
                              <Checkbox value={alert.enabled} checked={alert.enabled} disabled/>
                           </FlexboxGrid.Item>                        
                        : 
                           <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>
                              <Checkbox value={alert.enabled} checked={alert.enabled} onChange={() => (onClickEnabled(alert))}/>
                           </FlexboxGrid.Item>    
                        }  
                     </FlexboxGrid>
                  </List.Item>
               ))
               : null
            }
         </List>
      </>
   ) 
};
