import React, { useState, useEffect } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import {IconButton, List, FlexboxGrid, Modal, Button, SelectPicker, Divider, Checkbox, CheckboxGroup } from 'rsuite';
import GearIcon from '@rsuite/icons/Gear';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import AdminIcon from '@rsuite/icons/Admin';
import PeoplesIcon from '@rsuite/icons/Peoples';
import { ListHeader, ListTable, MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import { timeAgo } from '../../_helpers/TimeAgo';
import { useHistory } from 'react-router-dom';
import PlusIcon from '@rsuite/icons/Plus';
import { InviteUser } from './Modal/InviteUser';
import { Avatar } from '../../../../../appHelpers/appCommonSC';

export const CustomerUserListFC = (props) => {
   // renderCount(`AdminFC`);
   const history = useHistory();
   const [users, setUsers] = useState([]);
   const [removeUsers, setRemoveUsers] = useState([]);
   const [addUserValue, setAddUserValue] = useState(null);
   const [getAddUsers, setGetAddUsers] = useState([]);
   const [listAddUsers, setListAddUsers] = useState([]);
   const [basicUsers, setBasicUsers] = useState([]);
   const [adminUsers, setAdminUsers] = useState([]);
   const [loadingUsers, setLoadingUsers] = useState(true);
   const [loadingAddUsers, setLoadingAddUsers] = useState(true);
   const { getAccessTokenSilently } = useAuth0();
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);

   const getUserList = async () => {
      setLoadingUsers(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });
         
         const userRes = await axiosInstance({
            url: `/customer/users/${props.customer}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setUsers(userRes?.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingUsers(false);
   };

   const getAddUserList = async () => {
      setLoadingAddUsers(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const userRes = await axiosInstance({
            url: `/admin/user/list`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setGetAddUsers(userRes?.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingAddUsers(false);
   };

   const handleAddUserSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/update/users/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               customer_id: props.customer,
               user: addUserValue,
               addUser: true
            }),
         });

         getUserList();
         // setResMsg(response.data);
         // toaster.push(message,'topCenter');
      } catch (error) {
         // console.error(error);
         // toaster.push(errMessage,'topCenter');
      }
      setAddUserValue(null);
   }

   const handleRemoveUserSubmit = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/customer/update/users/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
            },
            data:  JSON.stringify({
               customer_id: props.customer,
               user: removeUsers,
               addUser: false
            }),
         });

         setRemoveUsers([]);
         getUserList();
         // setResMsg(response.data);
         // toaster.push(message,'topCenter');
      } catch (error) {
         // console.error(error);
         // toaster.push(errMessage,'topCenter');
      }
      setAddUserValue(null);
   }

   const filterUserList = () => {
      setBasicUsers(users.filter(user => (user.roles['roles'].includes('basic') && !user.roles['roles'].includes('admin'))));
      setAdminUsers(users.filter(user => user.roles['roles'].includes('admin')));
   }

   const initAddUserList = () => {
      setListAddUsers(getAddUsers.map(user => ({ label: `${user.first_name} ${user.last_name}`, value: user.email_address })))
   }
      
   useEffect(() => {
      getUserList();
      getAddUserList();
   }, []);

   useEffect(() => {
      filterUserList();
      initAddUserList();
   }, [users, getAddUsers]);

   return (
      <SettingBody>
         <MainDivider/>

         <SettingHeader>
            <p style={{display: 'inline-block', paddingRight: '1%'}}>Customer Users </p>
            <IconButton icon={<PlusIcon />} onClick={handleOpen}>Add</IconButton>
            <InviteUser customerData={props.customerData} getUserList={getUserList}/>
            
            {removeUsers.length > 0 ?
               <Button color="red" appearance="primary" style={{marginLeft: '1%'}} onClick={handleRemoveUserSubmit}>
                  Remove Users
               </Button>
               : null
            }
         </SettingHeader>

         <MainDivider/>

         <CheckboxGroup
            name="checkboxList"
            value={removeUsers}
            onChange={value => {
               setRemoveUsers(value);
            }}
         >
         <ListTable>
            <FlexboxGrid>
               <FlexboxGrid.Item  colspan={3}>
                  <HeaderText>
                     <AdminIcon/>Admins
                  </HeaderText>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item  colspan={4}>
                  <SubText>
                     #: {adminUsers?.length}
                  </SubText>
               </FlexboxGrid.Item>
            </FlexboxGrid>

            <MainDivider/>
            
            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={1}/>
                  <FlexboxGrid.Item colspan={2}/>
                  <FlexboxGrid.Item colspan={4}>
                     NAME
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                     EMAIL ADDRESS
                  </FlexboxGrid.Item> 
                  <FlexboxGrid.Item colspan={2}>
                     ROLE
                  </FlexboxGrid.Item>    
                  <FlexboxGrid.Item colspan={3} align='center'>
                     STATUS
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     ACTIVITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={2} align='center'>
                     ACTIONS
                  </FlexboxGrid.Item>          
               </FlexboxGrid>
            </ListHeader>
            <List size="sm" hover>
            {adminUsers?.map((user, index) => (
               <List.Item key={index} value={user}>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={1}>
                        <Checkbox value={user.email_address}/>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        <Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.first_name + " " + user.last_name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.email_address}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {Object.values(user.roles['roles'][0])}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center' onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.status === 'active' ?
                        <Status color='green'>{user.status}</Status>
                        : user.status === 'invited' ?
                        <Status color='darkgrey'>{user.status}</Status>
                        :
                        <Status color='red'>{user.status}</Status>
                        }
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center' onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                     {user.last_logged_in !== null ?
                        timeAgo(user.last_logged_in)
                        :
                        <>never</>
                     }                           
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        <IconButton onClick={() => history.push(`/admin/user/${user.email_address}`)} icon={<GearIcon />} />
                     </FlexboxGrid.Item> 
                  </FlexboxGrid>
               </List.Item> 
            ))}                  
            </List>             
         </ListTable>

         <ListTable>
            <FlexboxGrid>
               <FlexboxGrid.Item  colspan={3}>
                  <HeaderText>
                     <PeoplesIcon/>Users
                  </HeaderText>
               </FlexboxGrid.Item>
               <FlexboxGrid.Item  colspan={4}>
                  <SubText>
                     #: {basicUsers?.length}
                  </SubText>
               </FlexboxGrid.Item>
            </FlexboxGrid>

            <MainDivider/>

            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={1}/>
                  <FlexboxGrid.Item colspan={2}/>
                  <FlexboxGrid.Item colspan={4}>
                     NAME
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={6}>
                     EMAIL ADDRESS
                  </FlexboxGrid.Item> 
                  <FlexboxGrid.Item colspan={2}>
                     ROLE
                  </FlexboxGrid.Item>    
                  <FlexboxGrid.Item colspan={3} align='center'>
                     STATUS
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     ACTIVITY
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={2} align='center'>
                     ACTIONS
                  </FlexboxGrid.Item>          
               </FlexboxGrid>
            </ListHeader>
            <List size="sm" hover>
            {basicUsers?.map((user, index) => (
               <List.Item key={index} value={user}>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={1}>
                        <Checkbox value={user.email_address}/>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        <Avatar>{(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}</Avatar>
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.first_name + " " + user.last_name}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.email_address}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {Object.values(user.roles['roles'][0])}
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center' onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                        {user.status === 'active' ?
                        <Status color='green'>{user.status}</Status>
                        : user.status === 'invited' ?
                        <Status color='darkgrey'>{user.status}</Status>
                        :
                        <Status color='red'>{user.status}</Status>
                        }
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={4} align='center' onClick={() => history.push(`/admin/user/${user.email_address}`)}>
                     {user.last_logged_in !== null ?
                        timeAgo(user.last_logged_in)
                        :
                        <>never</>
                     }                           
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} align='center'>
                        <IconButton onClick={() => history.push(`/admin/user/${user.email_address}`)} icon={<GearIcon />} />
                     </FlexboxGrid.Item> 
                  </FlexboxGrid>
               </List.Item> 
            ))}                
            </List>             
         </ListTable>
         </CheckboxGroup>  

         <Modal
            size="xs"
            open={open}
            onClose={handleClose}
         >
            <Modal.Header>
               <Modal.Title>Add User to Customer</Modal.Title>
            </Modal.Header>
            <Divider/>
            <Modal.Body>
               <SmallText>Existing Users</SmallText>
               <SelectPicker label="User" data={listAddUsers} value={addUserValue} onChange={setAddUserValue} block/>
            </Modal.Body>

            <Modal.Footer>
               <Button onClick={handleAddUserSubmit} appearance="primary">
                  Submit
               </Button>
               <Button onClick={handleClose} appearance="subtle">
                  Cancel
               </Button>
            </Modal.Footer>         
         </Modal>
      </SettingBody>    
   );
};

const Status = styled.div`
   color: ${({ theme, color }) => color};
`

const HeaderText = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const SubText = styled.div`
   font-family: inherit;
   font-size: 1.25vw;
   font-weight: 300;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const SmallText = styled.div`
   font-family: inherit;
   font-size: 0.8vw;
   font-weight: 300;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

