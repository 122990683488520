import React, { useEffect, useState } from 'react';
import { Placeholder, Loader, Whisper, Popover, Button } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import 'rsuite/dist/rsuite.min.css';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import parse from 'html-react-parser';

export const KnowledgeInfoFC = ({data, metadata}) => {
   const { getAccessTokenSilently } = useAuth0();
   const [knowledgeDB, setKnowledgeDB] = useState([]);
   const {manufacturer, modality} = metadata
   const [loading, setLoading] = useState(true);

   const GetKnowledgeDB = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         if (loading) {
            const response = await axiosInstance({
               method: 'POST',
               url: `/system/kdb/info`,
               headers: { 
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${token}`,
               },
               data:  JSON.stringify({
                  data: data,
                  manufacturer: manufacturer,
                  modality: modality
               }),
            });
            setKnowledgeDB(response.data[0][0]);
            console.log(response.data[0][0]);
         }       
         setLoading(false);
      } catch (error){
         console.log(error);
         setKnowledgeDB(null);
      }
   };

   const speaker = (
      <Popover style={{width:'40%', overflow: 'overlay'}}>
      {loading ? 
         <div>
            <Placeholder.Paragraph rows={8} />
            <Loader center content="loading" />
         </div>
      :
         <>
            {manufacturer === 'Philips' ?
            <>
               <Header>{knowledgeDB?.identifier}</Header>
               {knowledgeDB?.data_type?.toString().toLowerCase().includes('warn') ?
                  <WarningHeader bgColor={'#FFFF94'} color={'black'}>{knowledgeDB?.data_type}</WarningHeader>
               : <WarningHeader color={'black'}>{knowledgeDB?.data_type}</WarningHeader>
               }
               <KDBMessage>{knowledgeDB?.message_details ? <><b>Details:</b> {HTMLReactParser(knowledgeDB?.message_details)}</> : ''}</KDBMessage>
               <p>{knowledgeDB?.troubleshooting ? <><b>Troubleshooting:</b> {knowledgeDB?.troubleshooting}</> : <></>}</p>
               <p>{knowledgeDB?.source ? <><b>Source:</b> {knowledgeDB?.source} </> : <></>}</p>
            </>
            : manufacturer === 'Siemens' ?
            <>
               <Header>{knowledgeDB?.identifier ? knowledgeDB?.identifier : knowledgeDB?.source } : {knowledgeDB?.data_type ? knowledgeDB?.data_type : knowledgeDB?.message_code} </Header>
               {data.host_state?.toString().toLowerCase().includes('w') ?
                  <WarningHeader bgColor={'#FFFF94'} color={'black'}>Warning</WarningHeader>
                  : data.host_state?.toString().toLowerCase().includes('e') ?
                  <WarningHeader bgColor={'rgb(255, 148, 148)'} color={'white'}>Error</WarningHeader>
                  : data.host_state?.toString().toLowerCase().includes('i') ?
                  <WarningHeader>Information</WarningHeader>
                  : <></>
               }
               <KDBMessage>{knowledgeDB?.message_details ? <><b>Details:</b> {HTMLReactParser(knowledgeDB?.message_details)}</> : ''}</KDBMessage>
               <p>{knowledgeDB?.troubleshooting ? <><b>Troubleshooting:</b> {knowledgeDB?.troubleshooting}</> : <></>}</p>
               <p>{knowledgeDB?.source ? <><b>Source:</b> {knowledgeDB?.source} </> : <></>}</p>
            </>
            : manufacturer === 'GE' ?
            <>
               <Header>{knowledgeDB?.identifier} : {knowledgeDB?.data_type} </Header>
               <KDBMessage>{knowledgeDB?.message_details ? <><b>Details:</b> {HTMLReactParser(knowledgeDB?.message_details)}</> : ''}</KDBMessage>
               <p>{knowledgeDB?.troubleshooting ? <><b>Troubleshooting:</b> {knowledgeDB?.troubleshooting}</> : <></>}</p>
               <p>{knowledgeDB?.source ? <><b>Source:</b> {knowledgeDB?.source} </> : <></>}</p>
            </>
            : <></>
            }
         </>
      }         
      </Popover>
   );

    useEffect(() => {

    }, [data]);

   return (
      <KnowledgeInfoSC>
         <Whisper placement="right" trigger="hover" controlId="control-id-hover" speaker={speaker} onEnter={GetKnowledgeDB} enterable preventOverflow>
            <Button color="blue" appearance="primary" size="sm">KDB</Button>
         </Whisper>
      </KnowledgeInfoSC>    
   );
}

const KnowledgeInfoSC = styled.div`
  
`;

const Header = styled.h4`
    font-size: medium;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 5px;
    color: rgb(243, 248, 252);
    background-color: rgb(0, 91, 148);
    text-align: center;
    box-shadow: grey 1px 1px 10px;
`

const WarningHeader = styled.h6`
    color: ${({ color }) => color ? color : 'black'};
    background-color: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
    text-align: center;
    box-shadow: grey 1px 1px 10px;
    margin-bottom: 10px;
`

const KDBMessage = styled.div`
    font-size: small;
    padding-bottom: 1%;
    
    h1 {
        font-size: 20px;
        line-height: 30px;
    }

    h3 {
        font-size: 20px;
    }
`