import React, { useState, useEffect, useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { List, FlexboxGrid, Loader } from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../appHelpers/axiosInstance';
import ImageIcon from '@rsuite/icons/Image';
import { InputContext } from '../_context/InputContext';
import { sanitizeImageUrl } from '../_utils/util_general';
import * as DOMPurify from 'dompurify';

export const CustomerListFC = (props) => {
   // renderCount(`AdminFC`);
   const { getAccessTokenSilently } = useAuth0();
   const [displayClients, setDisplayClients] = useState([]);
   const [clients, setClients] = useState([]);
   const [clientLogos, setClientLogos] = useState();
   const [loadingClients, setLoadingClients] = useState(true);

   const {
      adminCustomerToggle,
      setAdminCustomerToggle
   } = useContext(InputContext);

   const sanitizeCustomerLogo = (url) => {
      return <ImgSrcLogo src={DOMPurify.sanitize(url)}/>
   };

   const getClientList = async () => {
      setLoadingClients(true);

      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const clientRes = await axiosInstance({
            url: `/admin/customer/list`,
            data: ``,
            headers: {authorization: `Bearer ${token}`}
         });

         setClients(clientRes?.data[0]);
         setClientLogos(clientRes?.data[0]?.map(client => sanitizeImageUrl(client.logo_src)));
      } catch (error) {
         console.error(error);
      }
      setLoadingClients(false);
   };

   const handleSelect = (e) => {
      props.setClient(e);
      props.setPage('ClientProfile');
   };

   const filterCustomer = () => {
      if(adminCustomerToggle) {
         props.setPage('ClientProfile');
         setDisplayClients(clients.filter(customer => customer.id === adminCustomerToggle));
      } else {
         setDisplayClients(clients);
      }
   }

   useEffect(() => {
      getClientList();
   }, []);

   useEffect(() => {
      filterCustomer();
   }, [clients, adminCustomerToggle]);

   return (   
      <CustomersSC> 
         {loadingClients ?      
            <Loader size="md" speed="fast" content="Loading..." style={{paddingTop: '10px'}} center/>  
         : window.screen.width >= 768 ? 
            <ListTable>
               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={5} align='center'/>
                     <FlexboxGrid.Item colspan={10}>
                        CUSTOMER
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} align='center'>
                        USERS
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={3} align='center'>
                        SITES
                     </FlexboxGrid.Item>    
                     <FlexboxGrid.Item colspan={3} align='center'>
                        SYSTEMS
                     </FlexboxGrid.Item>  
                  </FlexboxGrid>
               </ListHeader>
               <List size="md" hover>
               {displayClients?.map((client, index) => (
                  <List.Item key={index} value={client.id}>
                     <FlexboxGrid onClick={() => handleSelect(client.id)}>
                        <FlexboxGrid.Item colspan={5} align='center'>
                           {client.logo_src ?
                              <div style={{ display: 'block' }}>
                                 {sanitizeCustomerLogo(clientLogos[index])}
                              </div>
                              
                           :
                              <div style={{ display: 'block' }}>
                                 <ImageIcon style={{ fontSize: "2.5em", height: '95%'}}/>
                              </div>                   
                           }
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={10}>
                           {client.name}
                           <SmallText>{client.id}</SmallText>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {client.clients?.length > 0 ? `${client.clients.length}` : '-'}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {client.site_count ? `${client.site_count}` : '0'}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3} align='center'>
                           {client.systems_count ? `${client.systems_count}` : '0'}
                        </FlexboxGrid.Item>
                     </FlexboxGrid>
                  </List.Item> 
               ))} 
               </List>
            </ListTable>
         :
            <ListTable>
               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={8}>
                        
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={11}>
                        CUSTOMER
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={5} align='center'>
                        USERS
                     </FlexboxGrid.Item> 
                  </FlexboxGrid>
               </ListHeader>
               <List size="md" hover>
                  {displayClients?.map((client, index) => (
                     <List.Item key={index} value={client.id}>
                        <FlexboxGrid onClick={() => handleSelect(client.id)}>
                           <FlexboxGrid.Item colspan={8}  align='center'>
                              {client.logo_src ?
                                 <div style={{ display: 'block' }}>
                                    <ImgSrcLogo src={encodeURI(clientLogos[index])}/>
                                 </div>
                                 
                              :
                                 <div style={{ display: 'block' }}>
                                    <ImageIcon style={{ fontSize: "2.5em", height: '95%'}}/>
                                 </div>                   
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={11}>
                              {client.name}
                              <div>
                                 <SmallText>{client.id}</SmallText>
                              </div>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={5} align='center'>
                              {client.clients ? `${client.clients.length}` : '0'}
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     </List.Item> 
                  ))} 
               </List>
            </ListTable>
         }
      </CustomersSC>
   )
};

const CustomersSC = styled.div `
   display: block;
   width: 100%;
   height: auto;
   text-align: -webkit-center;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

   @media screen and (min-width: 769px) {
      
   }
`

const SmallText = styled.div`
   width: 45px;
   display:block;
   font-size: 0.8em;
   font-weight: normal;
   color: #97969B;

   @media screen and (max-width: 769px) {
      width: 100%;
      display: inline;
      font-size: 1.0em;
   }
`
const ListTable = styled.div`
   width: 100%;
   height: auto;
   padding-top: 5vh;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   text-align: start;

   @media screen and (min-width: 769px) {
      width: 80%;
   }

   .rs-flex-box-grid {
      align-items: center;
   }
`

const ListHeader = styled.div`
   color: grey;
   align-items: start;
`

const ImgSrcLogo = styled.img`
   width: auto;
   height: 40px;
`