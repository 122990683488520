import React, { useState, useEffect, useContext } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import ToolsIcon from '@rsuite/icons/Tools';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';

export const _MaintenanceFC = () => {
    return (
        <MaintenanceSC>
            <div class="vh">
                <div class="wrap">
                    <h1>Maintenance Mode</h1>

                    <ToolsIcon style={{fontSize: '5em'}}/>

                    <h2>We'll be back soon!</h2>

                    <h3>Thank you for your understanding.</h3>
                </div>
            </div>
        </MaintenanceSC>
    );
}

const MaintenanceSC = styled.div`

    height: 60vh;
    width: 100vw;
    .vh { height: 100%; align-items: center; display: flex; }
    .vh > div { width: 100%; text-align: center; vertical-align: middle; }

    .wrap {text-align: center;}
    .wrap h1 {font-size: 30px;font-weight: 700;margin-bottom:3%;width: 100%}
    .wrap h2 {font-size: 24px;font-weight: 500;margin-top:3%;}
    .wrap h3 {font-size: 18px;font-weight: 400;line-height:}
    .wrap h4 {font-size: 10px;font-weight: 700;margin-bottom:3%;color:black;width: 100%}
`