import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, Grid, Loader, Popover, Whisper } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { SectionTitleFC } from '../../_helpers/SectionTitleFC';
import { useHistory } from 'react-router-dom';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import LocationIcon from '@rsuite/icons/Location';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { appTheme } from '../../../../../appHelpers/appTheme';
import { _FilterEquipmentFC } from './_helpers/dashboardFilters/_FilterEquipmentFC';
import { DateTime, Interval } from 'luxon';
import { AlertListFC } from './_helpers/AlertListFC';
import { UserContext } from '../../_context/UserContext';
import NoticeIcon from '@rsuite/icons/Notice';
import { MachinesContext } from './_context/MachinesContext';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import ImportIcon from '@rsuite/icons/Import'

export const HHMTableFC = ({ machines, alertConfigs, networkIssues  }) => {
   // renderCount('MachinesTableFC');
   const history = useHistory();
   const { user } = useContext(UserContext);
   const { getAccessTokenSilently } = useAuth0();
   const [displayMachines, setDisplayMachines] = useState(machines);
   const [displayCustomers, setDisplayCustomers] = useState([]);
   const [displayFileDatetimes, setDisplayFileDatetimes] = useState([]);
   const { darkMode } = useContext(DarkModeContext);
   let clickStart;

   const {
      loadingDisplayEvents,
      setLoadingDisplayEvents,
      displayEvents,
      getRecentEvents,
      getDisplayEventsError
   } = useContext(MachinesContext);

   const speakerElement = (system) => (
      <Popover style={{width: '550px'}}>
         <AlertListFC user={user} system={system}/>
      </Popover>
   );

   useEffect(() => {
      getRecentEvents(); 
   }, [machines]);

   useEffect(() => {
      setDisplayCustomers([...new Set(displayMachines.map(item => item.customer_name))])
   }, [displayMachines]);

   return (
      displayMachines ? 

      <MachineTableSC darkMode={darkMode}>

         {/* Filter Control Header Row */}
         <_FilterEquipmentFC machines={machines} displayMachines={displayMachines} setDisplayMachines={setDisplayMachines} />

         {window.screen.width >= 768 ?
            <List size='sm' hover>
               {/* Header For Equipment List */}
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={customer}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}`, opacity: '90%' }}>
                        <FlexboxGrid.Item colspan={24} style={customerStyle}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {index === 0 ? <RowHeader /> : <></>}

                     {displayMachines?.flatMap(machine => machine.customer_name === customer ?
                        <List.Item value={machine.id} key={machine.id +`_hhm`} index={index + 1 +`_hhm`} size='sm'>
                           {/* Checks whether there's an unresolved issue */}
                           {networkIssues ?
                           networkIssues[0]?.filter(issue => issue.id === machine.id && (!issue.resolved && issue.status === 'active') && issue.processor_type === 'HHM').length>0 ? 
                              <FlexboxGrid style={{backgroundColor: '#fffaf2', paddingLeft: 10, marginBottom: 5}}>
                                 <FlexboxGrid.Item colspan={1}>
                                    <RemindFillIcon color='#ffb300' />
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={23} style={{color: 'grey'}}>
                                    Warning: This system has an unresolved reported issue.
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                              : null
                           : null
                           }
                           <FlexboxGrid onMouseDown={event => {
                                 clickStart = event.timeStamp; // Record the timestamp of the mouse down event
                              }}
                              onMouseUp={event => {
                                 const clickEnd = event.timeStamp; // Get the timestamp of the mouse up event
                                 const clickDuration = clickEnd - clickStart; // Calculate the duration of the click
                                 const modalElement = document.getElementById('control-id-hover');

                                 if (clickDuration < 200 && !modalElement?.contains(event.target)) {
                                    history.push(`/system/hhm/${machine.id}`); // Navigate to the next page if the click was short
                                 }
                              }}
                           >
                              <FlexboxGrid.Item colspan={2} style={itemGridStyle}>
                                 <ItemContent>
                                    {machine.cus_sys_id ?
                                       <>
                                          <ItemMainText> {machine.cus_sys_id} </ItemMainText>
                                          <ItemSubText> {machine.id} </ItemSubText>
                                       </>
                                       :
                                       <ItemMainText> {machine.id} </ItemMainText>
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={7} style={itemGridStyle}>
                              <ItemContent>
                                    <ItemMainText><LocationIcon /> {machine.site_name}</ItemMainText>
                                    {/* <ItemSubText>
                                       Room: {system.room ? system.room : 'N/A'}
                                    </ItemSubText> */}
                                    <ItemSubText>{machine.state} · {machine.city} </ItemSubText>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                                 <ItemContent>
                                    <ItemMainText>{machine.manufacturer}</ItemMainText>
                                    <ItemSubText>{machine.model ? machine.model : 'N/A'}</ItemSubText>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={2} style={itemGridStyle}>
                                 <ItemContent>
                                    <ItemMainText>{machine.modality}</ItemMainText>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={1} style={itemGridStyle}>
                                 {alertConfigs?.filter(config => (config.system_id === machine.id && config.user_id.toLowerCase() === user?.email_address.toLowerCase() 
                                    && (config.pg_table.includes('log') || config.pg_table.includes('hhm')) && config.enabled))?.length !== 0 ? 
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(machine)} enterable>
                                       <NoticeIcon color={appTheme.avante_dark_blue}/>
                                    </Whisper>                            
                                    : alertConfigs?.filter(config => (config.system_id === machine.id  && config.user_id.toLowerCase() === user?.email_address.toLowerCase() && (config.pg_table.includes('log') || config.pg_table.includes('hhm')) )).length !== 0 ?
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(machine)} enterable>
                                       <NoticeIcon color='grey'/>
                                    </Whisper>    
                                    :
                                    <NoticeIcon color='red'/> 
                                 }
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={itemGridStyle}>
                                 <RecentEventBox>
                                    {loadingDisplayEvents ?
                                       <Loader speed="fast" />
                                    : displayEvents?.filter(events => events.system_id === machine.id)?.map(event => (
                                       event?.name === 'system_scan_seconds' ?
                                          <RecentEventItem>
                                             <ItemSubText>Sys Scan Secs:</ItemSubText>
                                             <ItemMainText>{event.value} <EventUnits>secs</EventUnits></ItemMainText>
                                          </RecentEventItem>
                                       : event?.name === 'scan_seconds' ?
                                          <RecentEventItem>
                                             <ItemSubText>Tube Usage:</ItemSubText>
                                             <ItemMainText>{event.value} <EventUnits>secs</EventUnits></ItemMainText>
                                          </RecentEventItem>
                                       : event?.name === 'power_on' ?
                                          // <RecentEventItem>
                                          //    <ItemSubText>Power On:</ItemSubText>
                                          //    <ItemMainText>{event.value} <EventUnits>hours</EventUnits></ItemMainText>
                                          // </RecentEventItem>
                                          <></>
                                       : event?.name === 'software_version' ?
                                          <RecentEventItem>
                                             <ItemSubText>Software Version:</ItemSubText>
                                             <ItemMainText>{event.value} </ItemMainText>
                                          </RecentEventItem>
                                       : event?.name === 'tube_type' ?
                                          <RecentEventItem>
                                             <ItemSubText>Tube Type:</ItemSubText>
                                             <ItemMainText>{event.value} </ItemMainText>
                                          </RecentEventItem>
                                       : event?.name === 'tube_serial_no' ?
                                       <RecentEventItem>
                                          <ItemSubText>Tube Serial #:</ItemSubText>
                                          <ItemMainText>{event.value} </ItemMainText>
                                       </RecentEventItem>
                                    :
                                       <RecentEventItem>
                                          <ItemSubText>{event.name}</ItemSubText>
                                          <ItemMainText>{event.value} </ItemMainText>
                                       </RecentEventItem>
                                    ))}              
                                 </RecentEventBox>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={3} style={itemChartStyle}>
                                 <ItemContent>                           
                                    {machine.host_datetime !== null ?
                                       ((Interval.fromDateTimes(DateTime.fromISO(machine.host_datetime), DateTime.now()).length('hours') > 24)) ?
                                          <DateTimeBox color={'red'}>
                                             <SmallText>Log: </SmallText><DateText>{DateTime.fromISO(machine.host_datetime).toFormat(
                                                   'ff'
                                             )}</DateText>
                                          </DateTimeBox>                     
                                       : 
                                          <DateTimeBox>
                                             <SmallText>Log: </SmallText><DateText>{DateTime.fromISO(machine.host_datetime).toFormat(
                                                      'ff'
                                                )}</DateText>
                                          </DateTimeBox>
                                    : null
                                    }
                                    {machine.capture_datetime !== null ?
                                       ((Interval.fromDateTimes(DateTime.fromISO(machine.capture_datetime), DateTime.now()).length('hours') > 24)) ?
                                          <DateTimeBox color={'red'}>
                                             <SmallText><ImportIcon size='1em'/> </SmallText><DateText>{DateTime.fromISO(machine.capture_datetime).toFormat(
                                                   'ff'
                                             )}</DateText>
                                          </DateTimeBox>                     
                                       : 
                                          <DateTimeBox>
                                             <SmallText><ImportIcon size='1em'/> </SmallText><DateText>{DateTime.fromISO(machine.capture_datetime).toFormat(
                                                      'ff'
                                                )}</DateText>
                                          </DateTimeBox>
                                    : null
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>
                           </FlexboxGrid>
                        </List.Item>
                        : 
                        <React.Fragment key={machine.id}></React.Fragment>
                     )}
                  </React.Fragment>
               ))}
            </List>
            :
            <List hover size="sm">
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={customer}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 5, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}` }}>
                        <FlexboxGrid.Item colspan={24} style={{ ...customerStyle, alignItems: 'center' }}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {displayMachines.flatMap(machine => machine.customer_name === customer ?
                        <React.Fragment key={machine.id}>
                           <FlexboxGrid justify="center" onClick={event => {
                              history.push(`/system/hhm/${machine.id}`);
                           }}>
                              <FlexboxGrid.Item colspan={14} style={{ ...itemGridStyleLocation, textAlign: 'left', justifyContent: 'left' }}>
                                 <LocationHeader>
                                    <ItemMainText> {machine.site_name && machine.site_name !== 'undefined' ? <><LocationIcon /> {machine.site_name}</> : <></>}</ItemMainText>
                                    <ItemSubText>
                                       {machine.room ? <>Room: {machine.room}</> : <></>}
                                    </ItemSubText>
                                 </LocationHeader>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={10} style={{ ...itemGridStyleLocation, textAlign: 'right', justifyContent: 'right' }}>                                                    
                                 {((Interval.fromDateTimes(DateTime.fromISO(machine.capture_datetime), DateTime.now()).length('hours') > 24)) ?
                                          <DateTimeBox color={'red'}>
                                             <SmallText>Log: </SmallText><DateText>{DateTime.fromISO(machine.capture_datetime).toFormat(
                                                   'ff'
                                             )}</DateText>
                                          </DateTimeBox>                     
                                       :
                                       <DateTimeBox>
                                          <SmallText>Log: <DateText>{DateTime.fromISO(machine.capture_datetime).toFormat(
                                                   'ff'
                                             )}</DateText>
                                             </SmallText>
                                       </DateTimeBox>
                                 }
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={24} style={{ ...itemGridStyleLocation, textAlign: 'right', justifyContent: 'right' }}>
                                 {((machine.site || machine.room) && (machine.site !== 'undefined' && machine.room !== 'undefined')) || displayFileDatetimes ?
                                    <hr style={{ margin: 0, padding: 0, marginTop: '0%', paddingBottom: '1%', width: '95vw' }} /> : <></>
                                 }
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={{ ...itemGridStyle, textAlign: 'left', justifyContent: 'left' }}>
                                 <ItemContent>
                                    {machine.clientequipmentid ?
                                       <div>
                                          <ItemMainText> {machine.clientequipmentid} </ItemMainText>
                                          <ItemSubText> {machine.id} </ItemSubText>
                                       </div>
                                       :
                                       <ItemMainText> {machine.id} </ItemMainText>
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={{ ...itemGridStyle, textAlign: 'left', justifyContent: 'left' }}>
                                 <ItemContent>
                                       {machine.manufacturer ? <ValueBox> {`${machine.manufacturer}`} </ValueBox> : <></>}
                                 </ItemContent>              
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={{ ...itemGridStyle, textAlign: 'left', justifyContent: 'left' }}>
                                 <ItemContent>
                                       {machine.modality ? <ValueBox> {`${machine.modality}`} </ValueBox> : <></>}
                                 </ItemContent>              
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={{ ...itemGridStyle, textAlign: 'left', justifyContent: 'left' }}>
                                 <ItemContent>
                                       {machine.model ? <ValueBox> {`${machine.model}`} </ValueBox> : <></>}
                                 </ItemContent>              
                              </FlexboxGrid.Item>
                           </FlexboxGrid>
                           <hr style={{ margin: 0, padding: 0, marginTop: '2%', paddingBottom: '5%', width: '95vw' }} />
                        </React.Fragment>
                        : <React.Fragment key={machine.id}></React.Fragment>
                     )}
                  </React.Fragment>
               ))}
            </List>
         }
      </MachineTableSC>
      :
      <></>
   );
};

const MachineTableSC = styled.div`
   width: 96vw;
   color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.light_accent};

   .rs-list-item {
      background-color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_primary : theme.light_primary};
   } 

   padding-bottom: 50px;

   @media screen and (min-width: 769px) {
      width: 94vw;
   }
`;

const ItemContent = styled.div`
   display: block;
   overflow: hidden;
   text-align: left;
`;

const ItemMainText = styled.div`
   font-size: 1em;
   font-weight: 500;

   @media screen and (max-width: 769px) {
      font-size: 0.9em;
      font-weight: bolder;
   }
`;

const ItemSubText = styled.div`
   font-size: 0.75em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.9em;
      font-weight: normal;
   }
`;

const HeaderText = styled.div`
   font-size: 0.8em;
   color: #97969B;
`;

const LocationHeader = styled.div`
   font-size: 0.75em;
`;

const CustomerText = styled.div`
   padding-left: 0.5%;
   font-size: 0.85em;
   color:  ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.avante_dark_blue};

   @media screen and (max-width: 769px) {
      font-size: 0.7em;
   }
`;

const headerStyle = {
   display: 'flex',
   paddingLeft: '1%',
   paddingRight: '1%',
   flexDirection: 'column',
   alignItems: 'flex-start',
   overflow: 'hidden'
};

const customerStyle = {
   display: 'flex',
   paddingRight: '1%',
   flexDirection: 'column',
   alignItems: 'flex-start',
   overflow: 'hidden',
   fontWeight: 'bold',
   position: 'sticky',
   top: '0'
};

const itemGridStyle = {
   display: 'flex',
   alignItems: 'center',
   alignSelf: 'center',
   height: '30px',
   paddingLeft: '1%',
   paddingRight: '1%'
};

const itemGridStyleLocation = {
   alignItems: 'center',
   paddingBottom: '1%',
   paddingLeft: '1%',
};

const itemChartStyle = {
   display: 'grid',
   alignItems: 'center',
   height: '60px',
   paddingLeft: '1%',
   paddingRight: '1%',
   flexDirection: 'column'
};

const ValueBox = styled.div`
    font-size: 0.70em;
    overflow: hidden;
    display: inline;

    @media screen and (max-width: 769px) {
        display: block;
    }
`;

const DateTimeBox = styled.div`
    color: ${({ color }) =>
        color ? color : ''};
    display: block;
    padding-right: 1px;

    @media screen and (max-width: 769px) {
        font-size: 0.65em;
    }
`

const SmallText = styled.div`
    width: 25px;
    display:inline-block;
    font-size: 0.8em;
    font-weight: normal;
    color: #97969B;

    @media screen and (max-width: 769px) {
        width: 100%;
        display: inline;
        font-size: 1.0em;
    }
`

const DateText = styled.div`
    display:inline-block;
    font-size: 0.90em;

    @media screen and (max-width: 769px) {
        font-size: 1.0em;
    }
`

const RowHeader = () => (
   <FlexboxGrid justify="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> EquipmentID </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={7} style={headerStyle}>
         <HeaderText> Location </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> Manufacturer </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> Modality </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={1} style={headerStyle}>
         
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={6} style={headerStyle}>
         <HeaderText> Recent Events </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> DateTime </HeaderText>
      </FlexboxGrid.Item>
   </FlexboxGrid>
);

const RecentEventBox = styled.div`
   display: inline-flex;
   width: 100%;
`

const RecentEventItem = styled.div`
   display: block;
   padding-right: 1%;
   padding-top: 1%;
   width: 33%;
`

const EventUnits = styled.p`
    font-size: 0.75em;
    color: #97969B;
    font-weight: normal;
    display: inline;

    @media screen and (max-width: 769px) {
        font-size: 0.9em;
        font-weight: normal;
    }
`;