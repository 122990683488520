import React, { useState, useEffect } from 'react';
import { List, FlexboxGrid, IconButton, Tooltip, Whisper } from 'rsuite';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import styled from 'styled-components';
import { Header, ItemSubText, ListHeader, MainDivider, SettingBody, SettingHeader, SubHeader } from '../helpers/Admin_StyledComponents';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { useHistory, useParams } from 'react-router-dom';
import { _FilterNetworkSystemsFC } from '../helpers/_FilterNetworkSystemsFC';
import VisibleIcon from '@rsuite/icons/Visible';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import DetailIcon from '@rsuite/icons/Detail';
import ToolsIcon from '@rsuite/icons/Tools';
import NoticeIcon from '@rsuite/icons/Notice';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import ReadyRoundIcon from '@rsuite/icons/ReadyRound';

export const SystemSummaryFC = ({system}) => { 
   const history = useHistory();
   const { id } = useParams();
   const [systems, setSystems] = useState([]);
   const [displaySystems, setDisplaySystems] = useState([]);
   const [acumaticaSystems, setAcumaticaSystems] = useState([]);
   const [status, setStatus] = useState('');

   const handleStatus = (e) => {
    console.log(e)
   }
  
   useEffect(() => {
      
   }, [system]);

   return (
      <SystemSummarySC> 
        <Header>
            Summary
        </Header>

        <MainDivider/>
        
        <LocationPanel>
            <SubHeader>
                System Info
            </SubHeader>
            <MainDivider/>
            <PanelItem>
                <ItemBox>
                    {system.id}
                </ItemBox>

                <ItemBox>
                    <ItemSubText>
                        {system.cus_sys_id}
                    </ItemSubText>
                </ItemBox>       
            </PanelItem>

            <PanelItem>
                <ItemBox>
                    {system.manufacturer} {system.modality ? `- ${system.modality}` : ''}
                </ItemBox>

                <ItemBox>
                    <ItemSubText>
                        {system.model}
                    </ItemSubText>
                </ItemBox>   
            </PanelItem>
            <MainDivider/>
        </LocationPanel>

        <LocationPanel>
            <SubHeader>
                Location Info
            </SubHeader>
            <MainDivider/>
            <PanelItem>
                <ItemBox>
                    {system.customer_name}
                </ItemBox>

                <ItemBox>
                    <ItemSubText>
                        {system.customer_id}
                    </ItemSubText>
                </ItemBox>       
            </PanelItem>

            <PanelItem>
                <ItemBox>
                    {system.site_name}
                </ItemBox>

                <ItemBox>
                    <ItemSubText>
                        {system.site_id}
                    </ItemSubText>
                </ItemBox>   
            </PanelItem>

            <PanelItem>
                Room
                <ItemSubText>
                    {system.room ? system.room : 'N/A'}
                </ItemSubText>
            </PanelItem>
            <MainDivider/>
        </LocationPanel>
      </SystemSummarySC>
   );
};

const SystemSummarySC = styled.div`

`

const LocationPanel = styled.div`
    padding-top: 10px;
    width: 100%;
    display: block;
`

const PanelItem = styled.div`
    min-width: 150px;
    padding-right: 5%;
    display: inline-block;
`

const ItemBox = styled.div`
    display: block;
`

