import React, { useState } from "react";
//import { renderCount } from '../../../../appHelpers/appLoggers';
import {
  IconButton,
  Modal,
  Button,
  Divider,
  Message,
  useToaster,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import axiosInstance from "../../../../../../appHelpers/axiosInstance";
import { useAuth0 } from "@auth0/auth0-react";

export const DeleteReport = ({ report, update, setUpdate }) => {
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();

  const handleOpen = () => setOpen(true); // Opens Modal when edit icon is clicked
  const handleClose = () => setOpen(false); // Closes Modal when "Cancel" button is clicked or onSubmit function runs
  const [open, setOpen] = useState(false); // Sets open/close state of Modal. Handled through Modal's props

  const message = (
    <Message showIcon type="success" header="Success">
      You successfully updated {report?.report_name}.
    </Message>
  );

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}`,
      });

      const response = await axiosInstance({
        method: "DELETE",
        url: `/user/reports/${report.author}/${report.id}`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      setUpdate(!update);
      toaster.push(message, "topCenter");
      handleClose();
    } catch (error) {
      console.error(error);
      toaster.push(errMessage, "topCenter");
    }
  };

  return (
    <>
      <IconButton icon={<TrashIcon />} onClick={handleOpen} />
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Report: {report?.report_name}</Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>Delete This Report?</Modal.Body>

        <Modal.Footer>
          <Button appearance="primary" onClick={onSubmit}>
            Submit
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const errMessage = (
  <Message showIcon type="error" header="Error">
    Something went wrong. Please try again.
  </Message>
);
