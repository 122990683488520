import React from 'react';
import styled from 'styled-components';
// import { renderCount } from '../../../appHelpers/appLoggers';
// import { DarkModeContext } from '../../../appContexts/DarkModeContext';
import { AlertsContextProvider } from './home/dashboard/_context/AlertsContext';
import { MachinesContextProvider } from './home/dashboard/_context/MachinesContext';
// import { LogFileDataContextProvider } from './machine/_contexts/LogFileDataContext';
import { MagnetFileDataContextProvider } from './system/_contexts/MagnetFileDataContext';
import { LocalFileContextProvider } from './localFile/_contexts/LocalFileContext'; // TODO: MIGRATE TO LOCAL-FILE CONTEXT
import { AdminContextProvider } from './_context/AdminContext';
import { Switch, Route } from 'react-router-dom';
import ProtectedRouteFC from '../../../auth/ProtectedRouteFC';
import { _HomeFC } from './home/_HomeFC';
// import { WelcomeFC } from './home/WelcomeFC';
// import { _DashboardFC } from './home/dashboard/_DashboardFC';
import { _LocalFileFC } from './localFile/_LocalFileFC';
import { _AdminFC } from './admin/_AdminFC';
import { _UserFC } from './admin/Users/_UserFC';
import { _NetSystemsFC } from './admin/Network/_NetSystemFC';
import { InputContextProvider } from './_context/InputContext';
import { UserContextProvider } from './_context/UserContext';
import { _HHMSystemFC } from './system/_HHMSystemFC';
import { _SystemFC } from './system/_SystemFC';
import LoginRouteFC from '../../../auth/LoginRoute';
import { _MaintenanceFC } from './_misc/_MaintenancePageFC';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { RouterFC } from '../RouterFC';

export const _PagesFC = () => {
   // renderCount(`_PagesFC`);
   return (
      <PagesSC>
         <UserContextProvider>
            <AlertsContextProvider>
               <MachinesContextProvider>
                  <InputContextProvider>
                     <MagnetFileDataContextProvider>
                        <LocalFileContextProvider>
                           <AdminContextProvider>
                              <RouterFC/>
                           </AdminContextProvider>
                        </LocalFileContextProvider>
                     </MagnetFileDataContextProvider>
                  </InputContextProvider>
               </MachinesContextProvider>
            </AlertsContextProvider>
         </UserContextProvider>
      </PagesSC>
   );
};

// CAN'T USE styled.body TAG, INVALID NESTING
const PagesSC = styled.section`
   padding-top: 60px;
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;
   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};
   width: 100%;

   @media screen and (min-width: 769px) {
      padding-left: 68px;
   }
`;
