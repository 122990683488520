import axios from 'axios'

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_EXPRESS_URL,
});

// Axios Initialization
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default axiosInstance;