import React, { useContext, useEffect, useState, Suspense } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, Loader, Message, Whisper, Popover, Checkbox } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { useHistory } from 'react-router-dom';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import LocationIcon from '@rsuite/icons/Location';
import { LastReadFC } from './_helpers/dashboardWidgets/LastReadFC';
import { RecentValuesFC } from './_helpers/dashboardWidgets/RecentValuesFC';
import { appTheme } from '../../../../../appHelpers/appTheme';
import { _FilterEquipmentFC } from './_helpers/dashboardFilters/_FilterEquipmentFC';
import { MachinesContext } from './_context/MachinesContext';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import LightSparkline from './_helpers/dashboardWidgets/LightSparklineFC'
import NoticeIcon from '@rsuite/icons/Notice';
import { AlertText } from '../../_utils/util_general';
import { UserContext } from '../../_context/UserContext';
import { AlertListFC } from './_helpers/AlertListFC';

export const MachinesTableFC = ({ machines, alertConfigs, networkIssues }) => {
   // renderCount('MachinesTableFC');
   const history = useHistory();
   const [displayMachines, setDisplayMachines] = useState(machines);
   const [displayCustomers, setDisplayCustomers] = useState([]);
   const [displayFileDatetimes, setDisplayFileDatetimes] = useState([]);
   const { darkMode } = useContext(DarkModeContext);
   const { user } = useContext(UserContext);
   let clickStart;

   const {
      loadingDisplayRecentValues,
      loadingDisplaySparklineData,
      displayRecentValues,
      displaySparklineData,
      getRecentValues,
      getSparklineData,
      frozenSystems,
   } = useContext(MachinesContext);

   const speakerElement = (system) => (
      <Popover style={{width: '600px'}}>
         <AlertListFC user={user} system={system}/>
      </Popover>
   );

   const RowHeader = () => (
      <FlexboxGrid justify="center" style={{ paddingTop: 5, paddingBottom: 5 }}>
         <FlexboxGrid.Item colspan={2} style={headerStyle}>
            <HeaderText> EquipmentID </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={5} style={headerStyle}>
            <HeaderText> Location </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={3} style={headerStyle}>
            <HeaderText> Manufacturer </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={1} style={headerStyle}>
            <HeaderText>  </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={3} style={headerStyle}>
            <HeaderText> Helium Q24H </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={3} style={headerStyle}>
            <HeaderText> Pressure Q24H </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={3} style={headerStyle}>
            <HeaderText> Recent Parameters 
            </HeaderText>
         </FlexboxGrid.Item>
         <FlexboxGrid.Item colspan={4} style={headerStyle}>
            <HeaderText> DateTime </HeaderText>        
         </FlexboxGrid.Item>
      </FlexboxGrid>
   );

   useEffect(() => {
      getRecentValues();
      getSparklineData();
   }, [machines]);

   useEffect(() => {
      setDisplayCustomers([...new Set(displayMachines?.map(item => item.customer_name))]);
   }, [displayMachines]);

   useEffect(() => {
      
   }, [loadingDisplayRecentValues]);

   return (
      displayMachines ?

      <MachineTableSC darkMode={darkMode}>

         {/* Filter Control Header Row */}
         <_FilterEquipmentFC machines={machines} displayMachines={displayMachines} setDisplayMachines={setDisplayMachines} recent_data={displayRecentValues}  />

         {window.screen.width >= 768 ?
            <List size='sm' hover>
               {/* Header For Equipment List */}
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={customer}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}`, opacity: '90%' }}>
                        <FlexboxGrid.Item colspan={24} style={customerStyle}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {index === 0 ? <RowHeader /> : <></>}

                     {displayMachines.flatMap(system => system.customer_name === customer ?
                        <List.Item value={system.id} key={system.id} index={index + 1} style={{backgroundColor: `${displayRecentValues?.find(data => data.system_id === system.id)?.comp_vib_status === false && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('comp_vib_status') && config.user_id !== 'default' && config.enabled)).length !== 0 
                                                                                                                                                                                                              && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('comp_vib_status') && config.user_id === 'default' && config.enabled)).length !== 0 ? 'rgb(255, 242, 242)' : ''}`}}>
                           {/* Checks whether there's an unresolved issue */}
                           {networkIssues ?
                              networkIssues[0]?.filter(issue => issue.id === system.id && (!issue.resolved && issue.status === 'active') && (issue.processor_type ==='MMB' || issue.type === 'MAGNET')).length>0 ? 
                              <FlexboxGrid style={{backgroundColor: '#fffaf2', paddingLeft: 10, marginBottom: 5}}>
                                 <FlexboxGrid.Item colspan={1}>
                                    <RemindFillIcon color='#ffb300' />
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={23} style={{color: 'grey'}}>
                                    Warning: This system has an unresolved reported issue.
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           :null
                           : null
                           }

                           {/* Checks whether there's an unresolved issue */}
                           {frozenSystems?.filter(frozen => frozen.system_id === system.id).length > 0 && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('host_datetime') && config.operator.includes('frozen_host') && config.user_id !== 'default' && config.enabled)).length !== 0
                                                                                                       && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('host_datetime') && config.operator.includes('frozen_host') && config.user_id === 'default' && config.enabled)).length !== 0 ? 
                              <FlexboxGrid style={{backgroundColor: 'rgb(255, 242, 242)', paddingLeft: 10, marginBottom: 5}}>
                                 <FlexboxGrid.Item colspan={1}>
                                    <RemindFillIcon color='red' />
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={23} style={{color: 'grey'}}>
                                    Error: This system has instances of FROZEN data in the past 6 hours.
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           :null
                           }

                           {/* Checks whether there's an unresolved issue */}
                           {displayRecentValues?.find(data => data.system_id === system.id)?.comp_vib_status === false && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('comp_vib_status') && config.user_id !== 'default' && config.enabled)).length !== 0 
                                                                                                                       && alertConfigs?.filter(config => (config.system_id === system.id && config.field_name.includes('comp_vib_status') && config.user_id === 'default' && config.enabled)).length !== 0 ? 
                              <FlexboxGrid style={{backgroundColor: 'rgb(255, 242, 242)', paddingLeft: 10}}>
                                 <FlexboxGrid.Item colspan={1}>
                                    <RemindFillIcon color='red' />
                                 </FlexboxGrid.Item>
                                 <FlexboxGrid.Item colspan={23} style={{color: 'grey'}}>
                                    EDU: Compressor appears to be off!
                                 </FlexboxGrid.Item>
                              </FlexboxGrid>
                           : null
                           }
                           
                           <FlexboxGrid 
                              onMouseDown={event => {clickStart = event.timeStamp;}} // Record the timestamp of the mouse down event                     
                              onMouseUp={event => {
                                 const clickEnd = event.timeStamp; // Get the timestamp of the mouse up event
                                 const clickDuration = clickEnd - clickStart; // Calculate the duration of the click
                                 const modalElement = document.getElementById('control-id-hover');

                                 if (clickDuration < 200 && !modalElement?.contains(event.target)) {
                                    history.push(`/system/${system.id}`); // Navigate to the next page if the click was short
                                 }
                              }}
                           >
                              <FlexboxGrid.Item colspan={2} style={itemGridStyle}>
                                 <ItemContent>
                                    {system.cus_sys_id ?
                                       <>
                                          <ItemMainText> {system.cus_sys_id} </ItemMainText>
                                          <ItemSubText> {system.id} </ItemSubText>
                                       </>
                                       :
                                       <ItemMainText> {system.id} </ItemMainText>
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={5} style={itemGridStyle}>
                                 <ItemContent>
                                    <ItemMainText><LocationIcon /> {system.site_name}</ItemMainText>
                                    {/* <ItemSubText>
                                       Room: {system.room ? system.room : 'N/A'}
                                    </ItemSubText> */}
                                    <ItemSubText>{system.state} · {system.city} </ItemSubText>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={3} style={itemGridStyle}>
                                 <ItemContent>
                                    <ItemMainText>{system.manufacturer}</ItemMainText>
                                    <ItemSubText>
                                       {system.model ? system.model : 'N/A'}
                                    </ItemSubText>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={1} style={itemGridStyle}>
                                 {alertConfigs?.filter(config => (config.system_id === system.id && !config.pg_table.includes('log') && config.enabled)).length !== 0 ? 
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(system)} enterable>
                                       <NoticeIcon color={appTheme.avante_dark_blue}/>
                                    </Whisper>                            
                                    : alertConfigs?.filter(config => (config.system_id === system.id && !config.pg_table.includes('log'))).length !== 0 ?
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(system)} enterable>
                                       <NoticeIcon color='grey'/>
                                    </Whisper>    
                                    :
                                    <NoticeIcon color='red'/> 
                                 }
                              </FlexboxGrid.Item>

                              {/* Sparkline Box for Helium Levels */}
                              <FlexboxGrid.Item colspan={3} style={itemChartStyle}>
                                 {loadingDisplaySparklineData ?
                                    <Loader speed="fast" />
                                    :
                                    <LightSparkline type={'helium'} units={displaySparklineData?.find(data => data.system_id === system.id)?.he_level_units} data={displaySparklineData?.find(data => data.system_id === system.id)?.helium_array.filter(Number)} width={125} height={25} tank={system.he_tank_volume}/>
                                 }           
                              </FlexboxGrid.Item>

                              {/* Sparkline Box for Pressure Levels */}
                              <FlexboxGrid.Item colspan={3} style={itemChartStyle}>
                                 {loadingDisplaySparklineData ?
                                    <Loader speed="fast" />
                                    :
                                    <LightSparkline type={'pressure'} units={displaySparklineData?.find(data => data.system_id === system.id)?.pressure_units} data={displaySparklineData?.find(data => data.system_id === system.id)?.pressure_array.filter(Number)} width={125} height={25} tank={system.he_tank_volume}/>
                                 }  
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={3} style={itemChartStyle}>
                                 <ItemContent>
                                    {loadingDisplayRecentValues ?
                                       <Loader speed="fast" />
                                       :
                                       <RecentValuesFC recent_values={displayRecentValues?.find(data => data.system_id === system.id)} alertConfigs={alertConfigs?.filter(config => config.system_id === system.id)} tank={system.he_tank_volume}/>
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={4} style={itemChartStyle}>
                                 <ItemContent>                           
                                    <LastReadFC recent_datetimes={machines.find(data => data.id === system.id)} />
                                 </ItemContent>
                              </FlexboxGrid.Item>
                           </FlexboxGrid>
                        </List.Item>
                        : 
                        <React.Fragment key={system.id}></React.Fragment>
                     )}
                  </React.Fragment>
               ))}
            </List>
            :
            // MOBILE VIEW
            <List hover>
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={customer}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, marginBottom: 5, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}` }}>
                        <FlexboxGrid.Item colspan={24} style={{ ...customerStyle, alignItems: 'center' }}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {displayMachines.flatMap(system => system.customer_name === customer ?
                        <React.Fragment key={system.id}>
                           <FlexboxGrid justify="center" onClick={event => {
                              history.push(`/system/${system.id}`);
                           }}>
                              <FlexboxGrid.Item colspan={14} style={{textAlign: 'left', justifyContent: 'left'}}>
                                 <LocationHeader>
                                    <ItemMainText> {system.site_name && system.site_name !== 'undefined' ? <><LocationIcon /> {system.site_name}</> : <></>}</ItemMainText>
                                 </LocationHeader>
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={10} style={{ ...itemGridStyleLocation, textAlign: 'right', justifyContent: 'right'}}>
                                 <LastReadFC recent_datetimes={machines.find(data => data.id === system.id)} />
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={24} style={{ ...itemGridStyleLocation, textAlign: 'right', justifyContent: 'right' }}>
                                 <hr style={{ margin: 0, padding: 0, marginTop: '0%', paddingBottom: '1%', width: '95vw' }} />
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={6} style={{ ...itemGridStyle, textAlign: 'left', justifyContent: 'left' }}>
                                 <ItemContent>
                                    {system.cus_sys_id ?
                                       <div>
                                          <ItemMainText> {system.cus_sys_id} </ItemMainText>
                                          <ItemSubText> {system.id} </ItemSubText>
                                       </div>
                                       :
                                       <ItemMainText> {system.id} </ItemMainText>
                                    }
                                    <div>
                                       {system.manufacturer ? <ValueBox> {`${system.manufacturer} - ${system.modality}`}</ValueBox> : <></>}
                                       {system.model ? <ValueBox><ItemSubText>{system.model}</ItemSubText></ValueBox> : <></>}
                                    </div>
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              {/* Sparkline Box for Helium Levels */}
                              <FlexboxGrid.Item colspan={9} style={{ ...itemChartStyle, justifyContent: 'center' }}>
                                 <ItemContent>
                                    {loadingDisplaySparklineData ?
                                       <Loader speed="fast" />
                                       :
                                       <LightSparkline type={'helium'} units={displaySparklineData?.find(data => data.system_id === system.id)?.he_level_units} data={displaySparklineData?.find(data => data.system_id === system.id)?.helium_array.filter(Number)} width={120} height={25} />
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>

                              {/* Sparkline Box for Pressure Levels */}
                              <FlexboxGrid.Item colspan={9} style={{ ...itemChartStyle, justifyContent: 'center' }}>
                                 <ItemContent>
                                    {loadingDisplaySparklineData ?
                                       <Loader speed="fast" />
                                       :
                                       <LightSparkline type={'pressure'} units={displaySparklineData?.find(data => data.system_id === system.id)?.pressure_units} data={displaySparklineData?.find(data => data.system_id === system.id)?.pressure_array.filter(Number)} width={120} height={25} />
                                    }
                                 </ItemContent>
                              </FlexboxGrid.Item>
                           </FlexboxGrid>

                           <hr style={{ margin: 0, padding: 0, marginTop: '2%', paddingBottom: '2%', width: '95vw' }} />
                        </React.Fragment>
                        : <React.Fragment key={system.id}></React.Fragment>
                     )}
                  </React.Fragment>
               ))}
            </List>
         }
      </MachineTableSC>
      :
      <></>
   );
};

const MachineTableSC = styled.div`
   width: 96vw;
   color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.light_accent};

   .rs-list-item {
      background-color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_primary : theme.light_primary};
   } 

   padding-bottom: 50px;

   @media screen and (min-width: 769px) {
      width: 94vw;
   }
`;

const ItemContent = styled.div`
   display: block;
   overflow: hidden;
   text-align: left;
`;

const ItemMainText = styled.div`
   font-size: 0.95em;
   font-weight: 500;
   
   @media screen and (max-width: 769px) {
      margin-top: 4px;
      padding: 0;
      font-size: 0.9em;
      font-weight: bolder;
   }
`;

const ItemSubText = styled.div`
    font-size: 0.8em;
    color: #97969B;
    font-weight: normal;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: normal;
    }
`;

const HeaderText = styled.div`
    font-size: 0.8em;
    color: #97969B;
`;

const LocationHeader = styled.div`
    font-size: 0.75em;
`;

const CustomerText = styled.div`
    padding-left: 0.5%;
    font-size: 0.85em;
    color:  ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.avante_dark_blue};

    @media screen and (max-width: 769px) {
        font-size: 0.7em;
    }
`;

const headerStyle = {
   display: 'flex',
   paddingLeft: '1%',
   paddingRight: '1%',
   flexDirection: 'column',
   alignItems: 'flex-start',
   overflow: 'hidden'
};

const customerStyle = {
   display: 'flex',
   paddingRight: '1%',
   flexDirection: 'column',
   alignItems: 'flex-start',
   overflow: 'hidden',
   fontWeight: 'bold',
   position: 'sticky',
   top: '0'
};

const itemGridStyle = {
   display: 'flex',
   alignItems: 'center',
   height: '60px',
   paddingLeft: '1%',
   paddingRight: '1%'
};

const itemGridStyleLocation = {
   display: 'block',
   alignItems: 'center',
   paddingBottom: '1%',
   paddingLeft: '1%',
};

const itemChartStyle = {
   display: 'grid',
   alignItems: 'center',
   height: '60px',
   paddingLeft: '1%',
   paddingRight: '1%',
   flexDirection: 'column'
};

const ValueBox = styled.div`
    font-size: 0.70em;
    overflow: hidden;
    display: inline;

    @media screen and (max-width: 769px) {
        display: block;
    }
`;

