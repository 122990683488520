import React, { useContext, useEffect, useState, Suspense } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, Loader, Message, Badge, Modal, Whisper, Popover, Toggle, Checkbox, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DarkModeContext } from '../../../../../appContexts/DarkModeContext';
import { useHistory } from 'react-router-dom';
import { renderCount } from '../../../../../appHelpers/appLoggers';
import LocationIcon from '@rsuite/icons/Location';
import { LastReadFC } from './_helpers/dashboardWidgets/LastReadFC';
import { RecentValuesFC } from './_helpers/dashboardWidgets/RecentValuesFC';
import { appTheme } from '../../../../../appHelpers/appTheme';
import { _FilterEquipmentFC } from './_helpers/dashboardFilters/_FilterEquipmentFC';
import { MachinesContext } from './_context/MachinesContext';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import NoticeIcon from '@rsuite/icons/Notice';
import { DateTime, Interval } from 'luxon';
import { UserContext } from '../../_context/UserContext';
import { AlertListFC } from './_helpers/AlertListFC';

export const TableEDU = ({ machines, alertConfigs, networkIssues }) => {
   // renderCount('MachinesTableFC');
   const { user } = useContext(UserContext);
   const history = useHistory();
   const [eduMachines, setEDUMachines] = useState(machines.filter(system => system.process_edu === true));
   const [displayMachines, setDisplayMachines] = useState(machines.filter(system => system.process_edu === true));
   const [displayCustomers, setDisplayCustomers] = useState([...new Set(machines.filter(system => system.process_edu === true).map(customer => customer.customer_name))]);
   const [displayFileDatetimes, setDisplayFileDatetimes] = useState([]);
   const { darkMode } = useContext(DarkModeContext);
   let clickStart;

   const speakerNetworkIssues = (
      <Tooltip>
         Warning: This system has an unresolved reported issue.
      </Tooltip>
   );

   const speakerElement = (system) => (
      <Popover style={{width: '600px'}}>
         <AlertListFC user={user} system={system}/>
      </Popover>
   );

   const {
      loadingDisplayRecentEDUValues,
      setLoadingDisplaySparklineData,
      displayRecentEDUValues,
      getRecentEDUValues,
      getDisplayRecentEDUValuesError
   } = useContext(MachinesContext);

   useEffect(() => {
      getRecentEDUValues();
   }, [machines]);

   return (
      displayMachines ?
         
         <MachineTableSC darkMode={darkMode}>
            {/* Filter Control Header Row */}
            <_FilterEquipmentFC machines={eduMachines} displayMachines={displayMachines} setDisplayMachines={setDisplayMachines} recent_data={displayRecentEDUValues} />

            {window.screen.width >= 768 ?
            <List size='sm' hover>
               {/* Header For Equipment List */}
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={`customer_`+index}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}`, opacity: '90%' }}>
                        <FlexboxGrid.Item colspan={24} style={customerStyle}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {index === 0 ? <RowHeader /> : <></>}
                     {displayMachines.flatMap((system, index) => system.customer_name === customer ?
                        <List.Item key={index + '_edu'} style={{height: '50px'}}>
                           <FlexboxGrid onMouseDown={event => {
                                 clickStart = event.timeStamp; // Record the timestamp of the mouse down event
                              }}
                              onMouseUp={event => {
                                 const clickEnd = event.timeStamp; // Get the timestamp of the mouse up event
                                 const clickDuration = clickEnd - clickStart; // Calculate the duration of the click
                                 const modalElement = document.getElementById('control-id-hover');

                                 if (clickDuration < 200 && !modalElement?.contains(event.target)) {
                                    history.push(`/system/${system.id}`); // Navigate to the next page if the click was short
                                 }
                              }}
                           >
                              <FlexboxGrid.Item align='center' colspan={1} style={{alignSelf: 'center'}}>
                                 {loadingDisplayRecentEDUValues ?
                                    <Loader speed="fast"/>                            
                                    : 
                                    <div style={{display: 'flex'}}>
                                       <div style={{width: '50%', textAlign: 'center', alignSelf: 'center'}}>
                                          {displayRecentEDUValues?.filter(edu => edu.id === system.id).map(data => (
                                             <VersionText>{data.version}</VersionText>
                                          ))}
                                       </div>  
                                       <div style={{width: '40%', textAlign: 'center', alignSelf: 'center'}}>
                                          {networkIssues ?
                                             networkIssues[0]?.filter(issue => issue.id === system.id && (!issue.resolved && issue.status === 'active')).length > 0 ?
                                             <Whisper placement="bottom" controlId="control-id-hover-alert" trigger="hover" speaker={speakerNetworkIssues}>
                                                <RemindFillIcon color='#ffb300' />
                                             </Whisper>
                                             : null
                                             : null
                                          }
                                       </div>
                                    </div>                               
                                 }
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={2}  style={{alignSelf: 'center'}}>
                                 <ItemMainText>{system.id}</ItemMainText>
                                 <ItemSubText>{system.cus_sys_id}</ItemSubText>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={3}  style={{alignSelf: 'center'}}>
                                 <ItemMainText>{system.manufacturer}</ItemMainText>
                                 <ItemSubText>{system.modality} | {system.model}</ItemSubText>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={4}  style={{alignSelf: 'center'}}>
                                 <ItemMainText>{system.site_name}</ItemMainText>
                                 <ItemSubText>{system.state} · {system.city} </ItemSubText>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={1} align='center' style={{alignSelf: 'center'}}>
                                 {alertConfigs?.filter(config => (config.system_id === system.id && config.user_id.toLowerCase() === user?.email_address.toLowerCase() && config.pg_table.includes('edu') && config.enabled)).length !== 0 ? 
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(system)} enterable>
                                       <NoticeIcon color={appTheme.avante_dark_blue}/>
                                    </Whisper>                            
                                    : alertConfigs?.filter(config => (config.system_id === system.id  && config.user_id.toLowerCase() === user?.email_address.toLowerCase() && config.pg_table.includes('edu'))).length !== 0 ?
                                    <Whisper preventOverflow placement="left" trigger="hover" controlId="control-id-hover" speaker={speakerElement(system)} enterable>
                                       <NoticeIcon color='grey'/>
                                    </Whisper>    
                                    :
                                    <NoticeIcon color='red'/> 
                                 }
                              </FlexboxGrid.Item>
                              {loadingDisplayRecentEDUValues ?
                                 <>
                                    <FlexboxGrid.Item colspan={13} align='center' style={{alignSelf: 'center'}}> <Loader speed="fast"/> </FlexboxGrid.Item>
                                 </>
                              : displayRecentEDUValues.filter(edu => edu.id === system.id).map(data => (
                                 <>
                                    <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>      
                                       {data.temp_probe_0_value?.includes('-196') ? <AlertText severity='off'>OFFLINE</AlertText> : data.temp_probe_1_value ? `${data.temp_probe_0_value} ${data.temp_probe_0_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>      
                                       {data.temp_probe_1_value?.includes('-196') ? <AlertText severity='off'>OFFLINE</AlertText>
                                       : data.temp_probe_1_value ? `${data.temp_probe_1_value} ${data.temp_probe_1_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>      
                                       {data.room_temp_value ? `${data.room_temp_value} ${data.room_temp_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>      
                                       {data.room_humidity_value ? `${data.room_humidity_value} ${data.room_humidity_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>      
                                       {data.comp_vib_status === null ? `` : data.comp_vib_status === true ? <AlertText>ON</AlertText> : <AlertText severity='high'>OFF</AlertText>}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} style={{alignSelf: 'center'}}>      
                                    {((Interval.fromDateTimes(DateTime.fromISO(data?.edu_datetime), DateTime.now()).length('hours') > 6)) ?
                                       <DateTimeBox color={'red'}>
                                          {DateTime.fromISO(data?.edu_datetime).toFormat(
                                             'ff'
                                          )}
                                       </DateTimeBox>                     
                                    :
                                       <DateTimeBox>
                                          {DateTime.fromISO(data?.edu_datetime).toFormat(
                                                'ff'
                                          )}
                                       </DateTimeBox>
                                    }
                                    </FlexboxGrid.Item>
                                 </>
                              ))}
                           </FlexboxGrid>
                        </List.Item>
                        : null
                     )}
                  </React.Fragment>
            ))}
            </List>
            :
               <List size='sm' hover>
               {/* Header For Equipment List */}
               {displayCustomers.map((customer, index) => (
                  <React.Fragment key={`customer_`+index}>
                     <FlexboxGrid justify="center" style={{ paddingTop: 2, paddingBottom: 2, backgroundColor: `${darkMode ? appTheme.dark_accent : appTheme.light_secondary}`, opacity: '90%' }}>
                        <FlexboxGrid.Item colspan={24} style={customerStyle}>
                           <CustomerText> {customer} </CustomerText>
                        </FlexboxGrid.Item>
                     </FlexboxGrid>

                     {index === 0 ? <RowHeader /> : <></>}
                     {displayMachines.flatMap((system, index) => system.customer_name === customer ?
                        <List.Item key={index + '_edu'} style={{height: '50px', fontSize: '11px'}}>
                           <FlexboxGrid>

                              <FlexboxGrid.Item colspan={4}  style={{alignSelf: 'center'}}>
                                 <ItemMainText>{system.id}</ItemMainText>
                                 <ItemSubText>{system.cus_sys_id}</ItemSubText>
                              </FlexboxGrid.Item>

                              {loadingDisplayRecentEDUValues ?
                                 <>
                                    <FlexboxGrid.Item colspan={20} align='center' style={{alignSelf: 'center'}}> <Loader speed="fast"/> </FlexboxGrid.Item>
                                 </>
                              : displayRecentEDUValues.filter(edu => edu.id === system.id).map(data => (
                                 <>
                                    <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>      
                                       {data.temp_probe_0_value?.includes('-196') ? <AlertText severity='off'>OFFLINE</AlertText> : data.temp_probe_1_value ? `${data.temp_probe_0_value} ${data.temp_probe_0_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>      
                                       {data.temp_probe_1_value?.includes('-196') ? <AlertText severity='off'>OFFLINE</AlertText>
                                       : data.temp_probe_1_value ? `${data.temp_probe_1_value} ${data.temp_probe_1_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>      
                                       {data.room_temp_value ? `${data.room_temp_value} ${data.room_temp_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>      
                                       {data.room_humidity_value ? `${data.room_humidity_value} ${data.room_humidity_units}` : null}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>      
                                       {data.comp_vib_status === null ? `` : data.comp_vib_status === true ? <AlertText>ON</AlertText> : <AlertText severity='high'>OFF</AlertText>}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={5} style={{alignSelf: 'center'}}>      
                                    {((Interval.fromDateTimes(DateTime.fromISO(data?.edu_datetime), DateTime.now()).length('hours') > 6)) ?
                                       <DateTimeBox color={'red'}>
                                           {DateTime.fromISO(data?.edu_datetime).toFormat('LLL dd - hh:mm a')}
                                       </DateTimeBox>                     
                                    :
                                       <DateTimeBox>
                                          {DateTime.fromISO(data?.edu_datetime).toFormat('LLL dd - hh:mm a')}
                                       </DateTimeBox>
                                    }
                                    </FlexboxGrid.Item>
                                 </>
                              ))}
                           </FlexboxGrid>
                        </List.Item>
                        : null
                     )}
                  </React.Fragment>
            ))}
            </List>
            }      
         </MachineTableSC>
      : <></>
   );
};

const MachineTableSC = styled.div`
   width: 96vw;
   color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_accent : theme.light_accent};

   .rs-list-item {
      background-color: ${({ theme, darkMode }) =>
   darkMode ? theme.dark_primary : theme.light_primary};
   } 

   padding-bottom: 50px;

   @media screen and (min-width: 769px) {
      width: 94vw;
   }
`;

const AlertText = styled.div `
   color: ${({ severity }) =>  severity === 'high' ? '#fff;' 
      : severity === 'medium' ?  '#fda005'
      : severity === 'low' ? 'darkgrey'
      : severity === 'off' ? 'white'
      : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#f44336;' 
      : severity === 'medium' ?  '#ffeecc'
      : severity === 'low' ? '#e6e5e3'
      : severity === 'off' ? 'lightgrey'
      : ''};
   width: 50%; 
   padding: 1%;
   text-align: center;
   border-radius: 6px;
   display: inline-block;
   line-height: 20px;
   font-size: 12px;
`;

const VersionText = styled.div `
   align-self: center;
   font-weight: bolder;
   color: rgb(0, 91, 148);
   background-color: lightgrey;
   text-align: center;
   border-radius: 6px;
   line-height: 20px;
`;

const ItemMainText = styled.div`
   font-size: 0.95em;
   font-weight: 500;
   text-overflow: ellipsis;

   @media screen and (max-width: 769px) {
      font-size: 0.9em;
      font-weight: bolder;
   }
`;

const ItemSubText = styled.div`
    font-size: 0.8em;
    color: #97969B;
    font-weight: normal;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: normal;
    }
`;

const HeaderText = styled.div`
    font-size: 0.8em;
    color: #97969B;
`;

const headerStyle = {
   display: 'flex',
   flexDirection: 'column',
   overflow: 'hidden'
};

const RowHeader = () => (
   window.screen.width >= 768 ?
   <FlexboxGrid justify="right" style={{ paddingTop: 5, paddingBottom: 5 }}>
      <FlexboxGrid.Item colspan={1} style={headerStyle}/>
      <FlexboxGrid.Item colspan={2} style={headerStyle}>
         <HeaderText> EquipmentID </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> Manufacturer </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4} style={headerStyle}>
         <HeaderText> Location </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={1} style={headerStyle} align='center'>
         <HeaderText> Alerts </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle} align='center'>
         <HeaderText> Probe #1 </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle} align='center'>
         <HeaderText> Probe #2 </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle} align='center'>
         <HeaderText> Room Temp </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle} align='center'>
         <HeaderText> Room Humidity </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2} style={headerStyle} align='center'>
         <HeaderText> Compressor </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle}>
         <HeaderText> Date </HeaderText>
      </FlexboxGrid.Item>
   </FlexboxGrid>
   :
   <FlexboxGrid justify="right" style={{ paddingTop: 5, paddingBottom: 5 }}>
      <FlexboxGrid.Item colspan={4} style={headerStyle}>
         <HeaderText> System ID </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle} align='center'>
         <HeaderText> Probe <br/>#1 </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle} align='center'>
         <HeaderText> Probe <br/>#2 </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle} align='center'>
         <HeaderText> Room Temp </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle} align='center'>
         <HeaderText> Room Humidity </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={3} style={headerStyle} align='center'>
         <HeaderText> Comp </HeaderText>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={5} style={headerStyle} align='center'>
         <HeaderText> Date </HeaderText>
      </FlexboxGrid.Item>
   </FlexboxGrid>
);

const DateTimeBox = styled.div`
   color: ${({ color }) =>
      color ? color : ''};
   display: block;
   padding-right: 1px;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
   }
`

const customerStyle = {
   display: 'flex',
   paddingRight: '1%',
   flexDirection: 'column',
   alignItems: 'flex-start',
   overflow: 'hidden',
   fontWeight: 'bold',
   position: 'sticky',
   top: '0'
};

const CustomerText = styled.div`
    padding-left: 0.5%;
    font-size: 0.85em;
    color:  ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.avante_dark_blue};

    @media screen and (max-width: 769px) {
        font-size: 0.7em;
    }
`;