import React, { useState, useEffect } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import {List, FlexboxGrid, Loader} from 'rsuite';
import { useAuth0 } from '@auth0/auth0-react';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import PcIcon from '@rsuite/icons/Pc';
import { ListHeader, ListTable, MainDivider, SettingBody, SettingHeader } from '../helpers/Admin_StyledComponents';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import VisibleIcon from '@rsuite/icons/Visible';
import { EditSystem } from './Modal/EditSystem';

export const CustomerSystemsListFC = (props) => {
   // renderCount(`AdminFC`);
   const [systems, setSystems] = useState([]);
   const [activeSystems, setActiveSystems] = useState([]);
   const [sites, setSites] = useState([]);
   const [siteList, setSiteList] = useState([]);
   const [update, setUpdate] = useState(false);
   const [loadingSystems, setLoadingSystems] = useState(true);
   const { getAccessTokenSilently } = useAuth0();

   const getSystemsList = async () => {
      setLoadingSystems(true);
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const siteRes = await axiosInstance({
            url: `/customer/systems/${props.customer}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setSystems(siteRes?.data[0]);
         setActiveSystems(siteRes?.data[0].filter(system => {return (system.show_on_website)}));
      } catch (error) {
         console.error(error);
         setLoadingSystems(false);
      }
      setLoadingSystems(false);
   };

   const getSiteList = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const siteRes = await axiosInstance({
            url: `/customer/sites/${props.customer}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setSiteList(siteRes?.data[0].map((site) => {return {value: site.id, label: `${site.id}: ${site.name}`}}));      
      } catch (error) {
         console.error(error);
      }
   };

   const initSites = () => {
      setSites([...new Set(systems.map(system => system.site_name))]);
   }

   useEffect(() => {
      getSystemsList();
      getSiteList();
   }, [props.customer, update]);

   useEffect(() => {
      initSites();
   }, [systems]);

   useEffect(() => {
      
   }, [sites, siteList]);

   return (
      <SettingBody>
         <MainDivider/> 

         <SettingHeader>
            <PcIcon/> Systems
            <SubText> Visible Systems: {activeSystems?.length} / {systems?.length} </SubText>
         </SettingHeader>

         <MainDivider/>

         <ListTable>
            <ListHeader>
               <FlexboxGrid>
                  <FlexboxGrid.Item colspan={1} align='center'>
                     
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3} align='center'>
                     ID
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={3} align='center'>
                     CUID
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     SYSTEM
                  </FlexboxGrid.Item>  
                  <FlexboxGrid.Item colspan={4} align='center'>
                     MODEL
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     S/N
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={4} align='center'>
                     ROOM
                  </FlexboxGrid.Item>  
                  <FlexboxGrid.Item colspan={1} align='center'>
                     SHOW
                  </FlexboxGrid.Item>
                  {/* <FlexboxGrid.Item colspan={3} align='center'>
                     EDU | MAG | LOG
                  </FlexboxGrid.Item>         */}
               </FlexboxGrid>
            </ListHeader>
            
            {sites.length > 0 ?
               sites?.map((site) => (
                  <>
                     <SiteText>{site ? site : 'No Site Name'}</SiteText>
                     <List>
                        {systems.filter(system => {return system.site_name === site}).map(
                           (system, index) => (
                              system.id ?
                                 <List.Item key={index} value={system}>     
                                    <FlexboxGrid>
                                       <FlexboxGrid.Item colspan={1} align='center'>
                                       <EditSystem sites={siteList} system={system} update={update} setUpdate={setUpdate}/>
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={3} align='center'>
                                          {system.id ? system.id : ""}  
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={3} align='center'>
                                          {system.cus_sys_id ? system.cus_sys_id : ""}  
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={4} align='center'>
                                          {system.manufacturer ? system.manufacturer : ""} - {system.modality ? system.modality : ""} 
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={4} align='center'>
                                          {system.model ? system.model : ""}  
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={4} align='center'>
                                          {system.serial_number ? system.serial_number : ""}  
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={4} align='center'>
                                          {system.room ? system.room : ""}  
                                       </FlexboxGrid.Item>
                                       <FlexboxGrid.Item colspan={1} align='center'>
                                          {system.show_on_website ? <VisibleIcon color="green" /> : <UnvisibleIcon color="red" /> }  
                                       </FlexboxGrid.Item>
                                       {/* <FlexboxGrid.Item colspan={3} align='center'>
                                          {system.process_edu ? <CheckRoundIcon color="green" /> : <CheckRoundIcon color="grey" /> } | {system.process_mag ? <CheckRoundIcon color="green" /> : <CheckRoundIcon color="grey" /> } | {system.process_log ? <CheckRoundIcon color="green" /> : <CheckRoundIcon color="grey" /> } 
                                       </FlexboxGrid.Item> */}
                                    </FlexboxGrid>
                                 </List.Item> 
                           : 
                           <List.Item>No Systems</List.Item>       
                        ))}
                     </List>
                  </>
               ))           
            : loadingSystems ? 
               <Loader content="Loading..." />
            :
               <> No Systems </>
            }                           
         </ListTable>
      </SettingBody>
   );
};

const SubText = styled.div`
   padding-left: 2.5%;
   padding-right: 2.5%;
   display: inline-block;
   font-family: inherit;
   font-size: 1.25vw;
   font-weight: 300;
   color: grey;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const SiteText = styled.div`
   font-family: inherit;
   font-size: 1.0vw;
   font-weight: 300;
   color: rgb(0, 91, 148);
   padding-bottom: 0.5%;
   padding-top: 0.5%;
   padding-left: 1%;
   background-color: rgb(243, 248, 252);

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;