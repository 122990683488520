import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, IconButton, Toaster, Message, useToaster, Nav, Toggle } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../_context/UserContext';
import { ListHeader, MainDivider } from '../../admin/helpers/Admin_StyledComponents';
import { LogAddAlertFC } from './LogAddAlertFC';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import TrashIcon from '@rsuite/icons/Trash';
import AdminIcon from '@rsuite/icons/Admin';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { SeverityAlert } from '../../../../../appHelpers/appCommonSC';
import { EditLogAlertFC } from './EditLogAlertFC';
import { HHMAlertAllFC } from './alerts/HHMAlertAllFC';

export const LogAlertTableFC = ({ logAlerts, dataConfig, metadata, refresh }) => {
   const [adminViewToggle, setAdminViewToggle] = useState('User');
   const { id } = useParams();
   const { getAccessTokenSilently } = useAuth0();
   const toaster = useToaster();
   const {
      user,
      userRoles
   } = useContext(UserContext);

   const handleEnabled = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/toggle/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: id,
               model_id: config
            }),
         });
         // setResMsg(response.data);
         refresh();
         toaster.push(updateMessage,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleAdd = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/model/add`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               alert_id: config,
               user_id: user.email_address
            }),
         });
         // setResMsg(response.data);
         refresh();
         toaster.push(addMessage,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   const handleDelete = async (config) => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const response = await axiosInstance({
            method: 'POST',
            url: `/alert/delete/model/`,
            headers: { 
               'Content-Type': 'application/json',
               authorization: `Bearer ${token}`,
               },
            data:  JSON.stringify({
               sme: id,
               id: config
            }),
         });
         // setResMsg(response.data);
         refresh();
         toaster.push(message,'topCenter');
      } catch (error) {
         console.error(error);
         toaster.push(errMessage,'topCenter');
      }
   }

   useEffect(() => {
      
   }, [logAlerts]);

   return (
      <AlertTableSC>
         {/* Admin Alert Center */}
         {userRoles.includes('superuser') || userRoles.includes('alerter') ?
         <>
            <Nav activeKey={adminViewToggle} onSelect={setAdminViewToggle} appearance='subtle'>
               <Nav.Item disabled style={{color: 'inherit', paddingLeft: 0}} icon={<AdminIcon style={{fontSize: '1.5em'}}/>}>Admin Alert Center</Nav.Item>
               <Nav.Item eventKey='User'>View User Alerts</Nav.Item>
               <Nav.Item eventKey='Default'>View Default Alerts</Nav.Item>
               <Nav.Item eventKey='All'>All User Alerts</Nav.Item>
            </Nav>

            <MainDivider/>
         </> : null
         }

         {(userRoles.includes('superuser') || userRoles.includes('alerter')) && adminViewToggle === 'Default' ?
            <>
            <HeaderText>Default Alerts <LogAddAlertFC dataConfig={dataConfig} metadata={metadata} sme={id} refresh={refresh} /> </HeaderText>

            <MainDivider/>

            {logAlerts.length > 0 ?
               <>
                  <ListHeader>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           SEVERITY
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={7}  style={{paddingLeft: 10}}>
                           MESSAGE
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           COLUMN KEY(S)
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           OPERATOR
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}  align='center'>
                           THRESHOLD(S)
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}  align='center'>
                           FREQUENCY
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1}  align='center'>
                           ENABLED
                        </FlexboxGrid.Item>   
                        <FlexboxGrid.Item colspan={1}  align='center'>
                           
                        </FlexboxGrid.Item>              
                     </FlexboxGrid>
                  </ListHeader>
                  {logAlerts.filter(filter => filter.user_id === 'default').map(config => (
                     <List.Item key={config.id} value={config}>
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={7}  style={{paddingLeft: 10}}>
                              {config?.field_name_alias}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              [{config?.field_name.replace(/\|\|\|/g, '] [')}]
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              {config?.operator}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={4}  align='center'>
                              [{config?.threshold.replace(/\|\|\|/g, '] [')}]
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <b>{config.notify_frequency}</b> hours
                           </FlexboxGrid.Item>                
                           <FlexboxGrid.Item colspan={1}  align='center'>
                              <div>
                                 {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                                    <Toggle checked={config.enabled} onClick={() => {handleEnabled(config.id)}}/>
                                    : null
                                 }
                              </div>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={1}  align='center'>
                              <div>
                                 {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                                    <EditLogAlertFC config={config} dataConfig={dataConfig} sme={id} metadata={metadata} refresh={refresh}/>
                                    : null
                                 }   
                                 {userRoles.includes('superuser') || userRoles.includes('alerter') ?
                                    <IconButton icon={<TrashIcon color='red'/>} style={{padding: '5px', marginLeft: '5px', display: 'inline-block'}} onClick={() => {handleDelete(config.id)}} />
                                    : null
                                 } 
                              </div>
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     </List.Item>
                  ))}
               </>
            : null
            }
            </>
         : (userRoles.includes('superuser') || userRoles.includes('alerter')) && adminViewToggle === 'All' ?
            <HHMAlertAllFC defaultConfigs={logAlerts} handleEnabled={handleEnabled}/>
         :
         <>
            {logAlerts.filter(config => (config.user_id === 'default' && !logAlerts.map(alert => alert['default_id']).includes(config.id))).length > 0 ?
            <>
               <HeaderText>Unset Default Alerts</HeaderText>

               <MainDivider/>

               <ListHeader>
                  <FlexboxGrid>
                     <FlexboxGrid.Item colspan={3}  align='center'>
                           SEVERITY
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={14} align='center'>
                           MESSAGE
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}  align='center'>
                           
                        </FlexboxGrid.Item>   
                        <FlexboxGrid.Item colspan={2}  align='center'>
                           
                        </FlexboxGrid.Item>            
                  </FlexboxGrid>
               </ListHeader>
               <List>
                  {logAlerts.filter(config => (config.user_id === 'default' && !logAlerts.map(alert => alert['default_id']).includes(config.id))).map(config => (
                     <List.Item key={config.id} value={config}>
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={14}  align='center'>
                              {config?.field_name_alias}
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2} align='center'>
                              <IconButton icon={<AddOutlineIcon color='green'/>} style={{ marginLeft: '5px', display: 'inline-block'}} onClick={() => {handleAdd(config.id)}}>Add</IconButton>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2} align='center'>
                              
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     </List.Item>
                  ))}
               </List>
            </> : null
            }

            <HeaderText>Your Currently Set Alerts</HeaderText>

            <MainDivider/>

            {logAlerts.length > 0 ?
               <>
                  <ListHeader>
                     <FlexboxGrid>
                        <FlexboxGrid.Item colspan={3}  align='center'>
                           SEVERITY
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={14} style={{paddingLeft: 10}}>
                           MESSAGE
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}  align='center'>
                           FREQUENCY
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}  align='center'>
                           ENABLED
                        </FlexboxGrid.Item>   
                        <FlexboxGrid.Item colspan={1}  align='center'>
                           
                        </FlexboxGrid.Item>              
                     </FlexboxGrid>
                  </ListHeader>
                  {logAlerts.filter(filter => filter.user_id?.toLowerCase() === user.email_address?.toLowerCase()).map(config => (
                     <List.Item key={config.id} value={config}>
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={3}  align='center'>
                              <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={14} style={{paddingLeft: 10}}>
                              {config?.operator === 'offline' ? 
                              `Trigger: ${config?.field_name_alias} ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                              :
                              config?.field_name_alias
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <b>{config?.notify_frequency ? 
                                 config?.notify_frequency
                                 : logAlerts?.find(def => def.id === config.default_id)?.notify_frequency 
                              }</b> hours
                           </FlexboxGrid.Item>                 
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <Toggle checked={config.enabled} onClick={() => {handleEnabled(config.id)}}/>
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                     </List.Item>
                  ))}
               </>
            : null
         }
         </>
         }
      </AlertTableSC>
   );
};

const AlertTableSC = styled.div`
   width: 85vw;
`;

const message = (
   <Message showIcon type="success" header="Success">
      You successfully deleted the alert.
   </Message>
);

const updateMessage = (
   <Message showIcon type="success" header="Success">
      You successfully updated the alert.
   </Message>
);

const addMessage = (
   <Message showIcon type="success" header="Success">
      You successfully added the alert.
   </Message>
);

const errMessage = (
   <Message showIcon type="error" header="Error">
      Something went wrong. Please try again.
   </Message>
);

const HeaderText = styled.div `
   width: 100%;
   font-size: 1.3em;

   display: inline-block;

   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;