import React, { useContext } from 'react';
import styled from 'styled-components';
import { DarkModeContext } from '../../appContexts/DarkModeContext';

export const Icon_Admin = () => {
    const { darkMode } = useContext(DarkModeContext);

    return (
        <Icon_AdminSC darkMode={darkMode}>
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16">
                <path d="M8 7a3 3 0 100-6 3 3 0 000 6zm0 1a4 4 0 110-8 4 4 0 010 8z"></path>
                <path d="M6.5 8A3.5 3.5 0 003 11.5V15h10v-3.5A3.5 3.5 0 009.5 8h-3zm0-1h3a4.5 4.5 0 014.5 4.5V15a1 1 0 01-1 1H3a1 1 0 01-1-1v-3.5A4.5 4.5 0 016.5 7z"></path>
                <path d="M10.016 7.674a.5.5 0 11.968.252l-2 7.7a.5.5 0 11-.968-.252l2-7.7z"></path>
                <path d="M5.016 7.926a.501.501 0 01.968-.252l2 7.7a.501.501 0 01-.968.252l-2-7.7z"></path>
            </svg>
        </Icon_AdminSC>
    )
};

const Icon_AdminSC = styled.div`
   display: inline-block;
   margin: 0 auto;

   svg {
        vertical-align: text-bottom;
        fill: ${({ theme, darkMode }) =>
            darkMode ? theme.dark_accent : theme.avante_dark_blue};
   }

   svg rect {
        fill: ${({ theme }) => theme.avante_light_blue};
   }
`;