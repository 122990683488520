import { parseFile } from './parseFile';
import { qaFile } from './qaFile';

export const readFile = (file, setParsedLogfile) => {
   const reader = new FileReader();
   reader.onload = () => {
      const fileReadyForParse = reader.result;
      const start = new Date();
      setParsedLogfile(parseFile(fileReadyForParse));
      qaFile(fileReadyForParse);
      console.log(new Date() - start);
   };
   reader.onabort = () => console.log('file reading was aborted');
   reader.onerror = () => console.log('file reading has failed');

   // TODO: RESEARCH FILEREADER READ TYPES
   // reader.readAsBinaryString(file);
   reader.readAsText(file);
};
