import React, { useState, useContext, useEffect, useRef } from 'react';
import { Checkbox, IconButton, List, FlexboxGrid, Button, Whisper, Tooltip, Panel, Popover, CheckboxGroup, Dropdown, CheckPicker, Badge } from 'rsuite';
import styled from 'styled-components';
import { DateTime, Interval } from 'luxon';
import NoticeIcon from '@rsuite/icons/Notice';
import { useHistory } from 'react-router-dom';
import { MachinesContext } from './_context/MachinesContext';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import FunnelIcon from '@rsuite/icons/Funnel';
import { InputContext } from '../../_context/InputContext';
import { SeverityAlert, SeverityAlertRow } from '../../../../../appHelpers/appCommonSC';

export const AlertsFC = ({ alerts, systems, hhmSystems }) => { 
   const [filteredAlerts, setFilteredAlerts] = useState(alerts);
   const [displayAlerts, setDisplayAlerts] = useState(alerts);
   const [allAlerts, setAllAlerts] = useState(alerts);
   const [severityFilter, setSeverityFilter] = useState(['low', 'medium', 'high']);
   const history = useHistory();
   const triggerRef = useRef();
   const [whisperOpen, setWhisperOpen] = useState(false);
   const [value, setValue] = React.useState([]);
   const [filterData, setFilterData] = useState([]);
   const { viewToggle, systemCache } = useContext(InputContext);

   const handleWhisperToggle = () => {
      setWhisperOpen(!whisperOpen);
   };

   const handleWhisperClose = () => {
      setWhisperOpen(false);
   };

   const speaker = (
      <Popover style={{padding: 0}}>
        <CheckPicker value={value} onChange={setValue} data={filterData} searchable={false} style={{ width: 224 }} onExit={handleWhisperClose}/>
      </Popover>
   );

   const {
      loadingDisplayRecentValues,
      displayRecentValues,
   } = useContext(MachinesContext);

   const AlertHeader = (
      <FlexboxGrid>
         <Whisper followCursor speaker={<Tooltip>Detected Alerts in the Past 5 Days.</Tooltip>}>
            <FlexboxGrid.Item colspan={18} style={{alignSelf: 'left', paddingLeft: 15, paddingBottom: 10}}>
               <NoticeIcon style={{fontSize: '1.25em', marginRight: 10, paddingBottom: 1}}/> 
               Total Detected Alerts: {displayAlerts.length} 
            </FlexboxGrid.Item>
         </Whisper>
      </FlexboxGrid>
   );

   const filterAlerts = () => {
      let copyAlerts = alerts;

      // Check View Toggle to Filter Alerts
      if(viewToggle === 'MMB') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("mmb") || alert.pg_table?.toLowerCase().includes("mag.")) || alert.pg_table?.toLowerCase().includes("monitoring") || alert.pg_table?.toLowerCase().includes("alert.offline_mmb_conn"));
         setAllAlerts(copyAlerts);
      } else if (viewToggle === 'EDU') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("edu")));
         setAllAlerts(copyAlerts);
      } else if (viewToggle === 'HHM') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("hhm") || alert.pg_table?.toLowerCase().includes("log") || alert.pg_table?.toLowerCase().includes("alert.offline_hhm_conn")));
         setAllAlerts(copyAlerts);
      }

      setFilteredAlerts(copyAlerts);

      if (value.length > 0) {
         copyAlerts = copyAlerts.filter(alert => value.includes(alert.field_name_alias));
      }

      if(systemCache.length > 0) {
         copyAlerts = copyAlerts.filter(alert => (systemCache.includes(alert.system_id)));
      }

      copyAlerts = copyAlerts.filter(alert => severityFilter.includes(alert.severity));
      setDisplayAlerts(copyAlerts);
   };

   const filterViewToggle = () => {
      let copyAlerts = alerts;

      // Check View Toggle to Filter Alerts
      if(viewToggle === 'MMB') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("mmb")));
      } else if (viewToggle === 'EDU') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("edu")));
      } else if (viewToggle === 'HHM') {
         copyAlerts = copyAlerts?.filter(alert => (alert.pg_table?.toLowerCase().includes("hhm") || alert.pg_table?.toLowerCase().includes("log")));
      }

      setValue([]);
      setFilterData([...new Set(copyAlerts.map(alert => alert.field_name_alias))].map(
         item => ({ label: item, value: item })
      ));

      setDisplayAlerts(copyAlerts);
   };

   useEffect(() => {
      filterViewToggle();
   }, [viewToggle]);

   useEffect(() => {
      filterAlerts();
   }, [severityFilter, value, viewToggle, systemCache]);

   useEffect(() => {
    
   }, [displayAlerts, filteredAlerts, allAlerts]);

   return (
      <AlertsSC> 
         {alerts.length > 0 ?
            window.screen.width >= 768 ?
            <AlertPanel>     
               <FlexboxGrid style={{padding: 5, justifyContent: 'center'}}>
                  <CheckboxGroup value={severityFilter} onChange={setSeverityFilter} inline>
                     {[...new Set(alerts.filter(alert => alert.severity.includes('high')))].length > 0 ?
                        <FlexboxGrid.Item colspan={5} style={{alignSelf: 'center', paddingRight: 5, paddingLeft: 5}}>
                           <SeverityAlert severity='high' align='center'><Checkbox value="high"/>{[...new Set(displayAlerts.filter(alert => alert.severity.includes('high')))].length} High Alerts</SeverityAlert> 
                        </FlexboxGrid.Item>
                     : null
                     }
                     {[...new Set(alerts.filter(alert => alert.severity.includes('medium')))].length > 0 ?
                        <FlexboxGrid.Item colspan={5} style={{alignSelf: 'center', paddingRight: 5, paddingLeft: 5}}>
                           <SeverityAlert severity='medium' align='center'><Checkbox value="medium"/>{[...new Set(displayAlerts.filter(alert => (alert.severity.includes('medium') && !alert.severity.includes('high'))))].length} Medium Alerts</SeverityAlert>
                        </FlexboxGrid.Item>
                     : null
                     }
                  </CheckboxGroup>
               </FlexboxGrid>
               <Panel header={AlertHeader} collapsible bordered>
                  <FlexboxGrid style={{paddingTop: 1, paddingTop: 5}}>
                     <FlexboxGrid.Item colspan={1} style={{paddingBottom: 5, paddingLeft: 5}}>
                        
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} style={{paddingBottom: 5, paddingLeft: 5}}>
                        SYSTEM ID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={3} style={{paddingBottom: 5, paddingLeft: 5}}>
                        MANUFACTURER
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={6} style={{paddingBottom: 5, paddingLeft: 5}}>
                        SITE
                     </FlexboxGrid.Item>
                     {/* <FlexboxGrid.Item colspan={2} style={{paddingBottom: 5}} align='center'>
                        SEVERITY
                     </FlexboxGrid.Item> */}
                     <FlexboxGrid.Item colspan={6} style={{paddingBottom: 5, paddingLeft: 5}}>
                        DETECTED ALERT
                        <Whisper placement="top" speaker={speaker} trigger="none" open={whisperOpen}>
                           <IconButton size="sm" icon={<FunnelIcon />}  onClick={handleWhisperToggle}/>   
                        </Whisper>               
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={2} style={{paddingBottom: 5}} align='center'>
                        CURRENT
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={1} style={{paddingBottom: 5}} align='center'>
                        COUNT
                     </FlexboxGrid.Item> 
                     <FlexboxGrid.Item colspan={3} style={{paddingBottom: 5}} align='center'>
                        DETECTED AT
                     </FlexboxGrid.Item>   
                  </FlexboxGrid>
   
                  <List size="sm" hover>
                     {displayAlerts?.map((alert, index) => (
                        <List.Item index={index + 1} style={{paddingTop: 1, paddingBottom: 1}}>
   
                           <FlexboxGrid onClick={event => {
                              alert.pg_table.includes('log') || alert.pg_table.includes('hhm') ?
                                 history.push(`/system/hhm/${alert.system_id}`)
                              :
                                 history.push(`/system/${alert.system_id}`)
                           }} style={{backgroundColor: `${alert.discrepancies ? 'lightgrey' : 'transparent'}`}}>
                              <FlexboxGrid.Item colspan={1} style={{alignSelf: 'center', paddingLeft: 15 }}>
                                 <>
                                 {alert.discrepancies ?                     
                                    <Whisper placement="right" speaker={<Popover title={'Alert Model Changed'}><p>The alert model was changed after this alert was triggered.</p><p>Current: <SeverityAlert severity={alert.current_severity}>{alert.current_severity?.toUpperCase()}</SeverityAlert> An alert is triggered if {alert.field_name_alias} is {alert.current_operator?.replace(/_/g, ' ')} {alert.current_threshold} {alert.resolved_field_units}.</p> <p>Previous: <SeverityAlert severity={alert.severity}>{alert.severity?.toUpperCase()}</SeverityAlert> An alert is triggered if {alert.field_name_alias} is {alert.operator?.replace(/_/g, ' ')} {alert.threshold}  {alert.resolved_field_units}.</p></Popover>} ref={triggerRef} trigger="hover" key={'helium'}>
                                       <RemindFillIcon/>
                                    </Whisper>
                                 : <></>
                                 }
                                 {alert?.severity.includes('high') ? 
                                       <Badge style={{ background: '#e51b1d' }} />
                                    : alert?.severity.includes('medium') ?
                                       <Badge style={{ background: '#fda005' }} />
                                    : alert?.severity.includes('low') ?
                                       <Badge style={{ background: 'darkgrey' }} />
                                    : <></>
                                    }
                                    </>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={2} style={{alignSelf: 'center'}}>
                                 {alert.cus_sys_id ?
                                    <>
                                       <ItemMainText> {alert.cus_sys_id} </ItemMainText>
                                       <ItemSubText> {alert.system_id} </ItemSubText>
                                    </>
                                    : <ItemMainText> {alert.system_id} </ItemMainText>
                                 }
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={3} style={{alignSelf: 'center', paddingLeft: 5}}>
                                 <ItemMainText> {alert.manufacturer} </ItemMainText>
                                 <ItemSubText> {alert.model} </ItemSubText>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={6} style={{alignSelf: 'center', paddingLeft: 5}}>
                                 {alert.site_name ?
                                    <>
                                       <ItemMainText> {alert.site_name} </ItemMainText>
                                       <ItemSubText> {alert.state} · {alert.city} </ItemSubText>
                                    </>
                                    : <></>
                                 }
                              </FlexboxGrid.Item>
                              {/* <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>
                                 {alert?.severity.includes('high') ? 
                                    <SeverityAlert severity={'high'}>{'HIGH'}</SeverityAlert>
                                 : alert?.severity.includes('medium') ?
                                    <SeverityAlert severity={'medium'}>{'MEDIUM'}</SeverityAlert>
                                 : alert?.severity.includes('low') ?
                                    <SeverityAlert severity={'low'}>{'LOW'}</SeverityAlert>
                                 : <></>
                                 }
                              </FlexboxGrid.Item> */}
                              <FlexboxGrid.Item colspan={6} style={{alignSelf: 'center', paddingLeft: 5}}>
                                 {alert.field_name_alias === 'Compressor' ? 
                                       <>{alert.field_name_alias} is {alert.threshold === 'false' || alert.threshold === 0 ? 'OFF' : ''}!</>
                                    : alert.operator === 'offline' || alert.operator === 'frozen_host'?
                                       <>{alert.field_name_alias} {alert.threshold} {alert.threshold_units}</>
                                    : alert.field_name_alias?.toLowerCase() === 'he level' && alert.operator === 'delta_neg'?
                                       <>{alert.field_name_alias} dropped {alert.threshold} {alert.threshold_units}</>
                                    : alert.operator === 'contains' || alert.operator === 'composite_equals' ?
                                       <>{alert.field_name_alias}</>
                                    : alert.operator === 'absent_null_data' ?
                                       <>{alert.field_name_alias}: {alert.threshold} {alert.threshold_units}</>
                                    :
                                       <>{alert.field_name_alias} is {alert.operator.replace(/_/g, ' ')} {alert.threshold} {alert.threshold_units}</>
                                 }           
                                 
                              </FlexboxGrid.Item>

                              <FlexboxGrid.Item colspan={2} align='center' style={{alignSelf: 'center'}}>

                                 { alert.operator === 'delta_neg' &&  displayRecentValues ? 
                                    <></>
                                    :
                                    alert.field_name_alias === 'He Level' &&  displayRecentValues ? 
                                    // IF TANK VOLUME EXISTS
                                    
                                    systems?.find(system => system.id === alert.system_id)?.he_tank_volume && displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_units.toUpperCase() === 'LTRS'  ?          
                                       ((( parseFloat(displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value / systems?.find(system => system.id === alert.system_id)?.he_tank_volume  * 100).toFixed(2)) < parseFloat(alert.current_threshold) && alert.current_operator === 'less_than')
                                       || ((parseFloat(displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value / systems?.find(system => system.id === alert.system_id)?.he_tank_volume  * 100).toFixed(2)) > parseFloat(alert.current_threshold) && alert.current_operator === 'greater_than')) ?
                                          <SeverityAlert severity={alert.current_severity}>{(parseFloat(displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value / systems?.find(system => system.id === alert.system_id)?.he_tank_volume * 100).toFixed(2))} {alert.threshold_units}</SeverityAlert>
                                       : <SeverityAlert>{(parseFloat(displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value / systems?.find(system => system.id === alert.system_id)?.he_tank_volume  * 100).toFixed(2))} {alert.threshold_units}</SeverityAlert>
                                    :
                                    ((displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value < parseFloat(alert.current_threshold) && alert.current_operator === 'less_than')
                                    || (displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value > parseFloat(alert.current_threshold) && alert.current_operator === 'greater_than')) ?
                                       <SeverityAlert severity={alert.current_severity}>{displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value} {alert.threshold_units}</SeverityAlert>
                                    : <SeverityAlert>{displayRecentValues.find(system => system.system_id === alert.system_id)?.helium_value} {alert.threshold_units}</SeverityAlert>
                                    
                                 : alert.field_name_alias === 'He Pressure'  &&  displayRecentValues ? 
                                    ((displayRecentValues.find(system => system.system_id === alert.system_id)?.pressure_value < parseFloat(alert.current_threshold) && alert.current_operator === 'less_than')
                                    || (displayRecentValues.find(system => system.system_id === alert.system_id)?.pressure_value > parseFloat(alert.current_threshold) && alert.current_operator === 'greater_than')) ?
                                       <SeverityAlert severity={alert.current_severity}>{displayRecentValues.find(system => system.system_id === alert.system_id)?.pressure_value} {alert.threshold_units}</SeverityAlert>
                                       : <SeverityAlert>{displayRecentValues.find(system => system.system_id === alert.system_id)?.pressure_value} {alert.threshold_units}</SeverityAlert>
                                 :  alert.field_name_alias === 'Compressor'  &&  displayRecentValues ? 
                                    ((String(displayRecentValues?.find(system => system.system_id === alert.system_id)?.comp_vib_status) === String(alert.current_threshold) && alert.current_operator === 'equals')) ?
                                          <SeverityAlert severity={alert.current_severity}>{String(displayRecentValues?.find(system => system.system_id === alert.system_id)?.comp_vib_status) === 'false' ? 'OFF' : 'ON'} {alert.threshold_units}</SeverityAlert>
                                    : displayRecentValues?.find(system => system.system_id === alert.system_id)?.comp_vib_status !== null ?
                                       <SeverityAlert>{String(displayRecentValues?.find(system => system.system_id === alert.system_id)?.comp_vib_status) === 'false' ? 'OFF' : 'ON'} {alert.threshold_units}</SeverityAlert>
                                    :<>n/a</>
                                 : alert.field_name_alias === 'Shield Temp'  &&  displayRecentValues ? 
                                    ((displayRecentValues.find(system => system.system_id === alert.system_id)?.shield_temp_value < parseFloat(alert.current_threshold) && alert.current_operator === 'less_than')
                                    || (displayRecentValues.find(system => system.system_id === alert.system_id)?.shield_temp_value > parseFloat(alert.current_threshold) && alert.current_operator === 'greater_than')) ?
                                       <SeverityAlert severity={alert.current_severity}>{displayRecentValues.find(system => system.system_id === alert.system_id)?.shield_temp_value} {alert.threshold_units}</SeverityAlert>
                                       : <SeverityAlert>{displayRecentValues.find(system => system.system_id === alert.system_id)?.shield_temp_value} {alert.threshold_units}</SeverityAlert>
                                 : alert.operator === 'offline' && alert.field_name_alias?.includes('MMB') &&             
                                    systems?.find(system => system.id === alert.system_id)?.capture_datetime ?
                                       (Math.round(DateTime.now().diff(DateTime.fromISO(systems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours) > alert.threshold) ?
                                       <SeverityAlert severity={alert.current_severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(systems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours)} {alert?.threshold_units ? alert.threshold_units : ''}</SeverityAlert> 
                                    : <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(systems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours)} {alert?.threshold_units ? alert.threshold_units : ''}</SeverityAlert> 
                                 : alert.operator === 'offline' && alert.field_name_alias?.includes('HHM') &&
                                    hhmSystems?.find(system => system.id === alert.system_id)?.capture_datetime ?
                                       (Math.round(DateTime.now().diff(DateTime.fromISO(hhmSystems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours) > alert.threshold) ?
                                       <SeverityAlert severity={alert.current_severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(hhmSystems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours)} {alert?.threshold_units ? alert.threshold_units : ''}</SeverityAlert> 
                                    : <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(hhmSystems?.find(system => system.id === alert.system_id)?.capture_datetime), 'hours').hours)} {alert?.threshold_units ? alert.threshold_units : ''}</SeverityAlert> 
                                 : <></>
                                 }      
                              </FlexboxGrid.Item> 
                              <FlexboxGrid.Item colspan={1} align='center' style={{alignSelf: 'center'}}>
                                 <ItemSubText>
                                 {alert.count}
                                 </ItemSubText>
                              </FlexboxGrid.Item>   
                              <FlexboxGrid.Item colspan={3} align='center' style={{alignSelf: 'center'}}>
                                 {DateTime.fromISO(alert.inserted_at).toFormat(
                                    'ff'
                                 )}
                              </FlexboxGrid.Item>   
                           </FlexboxGrid>
                        </List.Item>
                     ))}
                  </List>
               </Panel>
            </AlertPanel>
            :
            <AlertPanel>
               <FlexboxGrid  style={{padding: 1, justifyContent: 'center'}}>
                  <CheckboxGroup value={severityFilter} onChange={setSeverityFilter} inline>
                     <FlexboxGrid.Item colspan={10} style={{alignSelf: 'center', paddingRight: 1, paddingLeft: 1}}>
                        <SeverityAlert severity='high' align='center'><Checkbox value="high"/>High</SeverityAlert> 
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={10} style={{alignSelf: 'center', paddingRight: 1, paddingLeft: 1}}>
                        <SeverityAlert severity='medium' align='center'><Checkbox value="medium"/>Medium</SeverityAlert>
                     </FlexboxGrid.Item>
                  </CheckboxGroup>
               </FlexboxGrid>
               <Panel header={AlertHeader} collapsible bordered>
                  <FlexboxGrid style={{paddingTop: 5}}>
                     <FlexboxGrid.Item colspan={6} style={{paddingBottom: 5, paddingLeft: 5}}>
                        SYSTEM ID
                     </FlexboxGrid.Item>
                     <FlexboxGrid.Item colspan={10} style={{paddingBottom: 5}} align='center'>
                        ALERT
                     </FlexboxGrid.Item>            
                     <FlexboxGrid.Item colspan={8} style={{paddingBottom: 5}} align='center'>
                        DETECTED AT
                     </FlexboxGrid.Item>   
                  </FlexboxGrid>
   
                  <List size="sm" hover>
                     {displayAlerts?.map((alert, index) => (
                        <List.Item index={index + 1} style={{paddingTop: 1, paddingBottom: 1}}>
   
                           <FlexboxGrid onClick={event => {
                              alert.operator === 'contains' || alert.operator ==='composite_equals' ?
                              history.push(`/system/hhm/${alert.system_id}`)
                              :
                              history.push(`/system/${alert.system_id}`)
                           }} style={{backgroundColor: `${alert.discrepancies ? 'lightgrey' : 'transparent'}`}}>
                                 {alert?.severity.includes('high') ? 
                                    <SeverityAlertRow severity={'high'}>
                                       <FlexboxGrid>
                                          <FlexboxGrid.Item colspan={6} style={{paddingLeft: 5, alignSelf: 'center'}}>
                                             {alert.cus_sys_id ?
                                                <>
                                                   <ItemMainText> {alert.cus_sys_id} </ItemMainText>
                                                   <ItemSubText> {alert.system_id} </ItemSubText>
                                                </>
                                                : <ItemMainText> {alert.system_id} </ItemMainText>
                                             }
                                          </FlexboxGrid.Item>     
                                          <FlexboxGrid.Item colspan={10} style={{alignSelf: 'center', paddingLeft: '2%', fontSize: '0.8em'}}>           
                                             {alert.field_name_alias === 'Compressor' ? 
                                                   <>{alert.field_name_alias} {alert.threshold === 'false' || alert.threshold === 0 ? 'OFF' : ''}!</>
                                                : alert.field_name_alias === 'Offline' ?
                                                   <> System Offline: {alert.threshold} {alert.threshold_units}</>
                                                : alert.field_name_alias?.toLowerCase() === 'he level' && alert.operator === 'delta_neg'?
                                                   <>{alert.field_name_alias} dropped {alert.threshold} {alert.threshold_units}</>
                                                : alert.operator === 'contains' || alert.operator === 'composite_equals' ?
                                                   <>{alert.field_name_alias}</>
                                                : 
                                                   <>{alert.field_name_alias} is {alert.operator.replace(/_/g, ' ')} {alert.threshold} {alert.threshold_units}</>
                                             }  
                                             </FlexboxGrid.Item>
                                          <FlexboxGrid.Item colspan={8} align='center' style={{alignSelf: 'center', fontSize: '0.80em'}}>
                                             {DateTime.fromISO(alert.inserted_at).toFormat('LLL dd - hh:mm a')}
                                          </FlexboxGrid.Item>
                                       </FlexboxGrid> 
                                    </SeverityAlertRow>
                                    : alert?.severity.includes('medium') ?
                                       <SeverityAlertRow severity={'medium'}>
                                          <FlexboxGrid>
                                             <FlexboxGrid.Item colspan={6} style={{paddingLeft: 5, alignSelf: 'center'}}>
                                                {alert.cus_sys_id ?
                                                   <>
                                                      <ItemMainText> {alert.cus_sys_id} </ItemMainText>
                                                      <ItemSubText> {alert.system_id} </ItemSubText>
                                                   </>
                                                   : <ItemMainText> {alert.system_id} </ItemMainText>
                                                }
                                             </FlexboxGrid.Item>     
                                             <FlexboxGrid.Item colspan={10} style={{alignSelf: 'center', paddingLeft: '2%', fontSize: '0.8em'}}>           
                                                {alert.field_name_alias === 'Compressor' ? 
                                                      <>{alert.field_name_alias} {alert.threshold === 'false' || alert.threshold === 0 ? 'OFF' : ''}!</>
                                                   : alert.field_name_alias === 'Offline' ?
                                                      <> System Offline: {alert.threshold} {alert.threshold_units}</>
                                                   : alert.field_name_alias?.toLowerCase() === 'he level' && alert.operator === 'delta_neg'?
                                                      <>{alert.field_name_alias} dropped {alert.threshold} {alert.threshold_units}</>
                                                   : alert.operator === 'contains' || alert.operator === 'composite_equals' ?
                                                      <>{alert.field_name_alias}</>
                                                   : 
                                                      <>{alert.field_name_alias} is {alert.operator.replace(/_/g, ' ')} {alert.threshold} {alert.threshold_units}</>
                                                }  
                                             </FlexboxGrid.Item>
                                             <FlexboxGrid.Item colspan={8} align='center' style={{alignSelf: 'center', fontSize: '0.80em'}}>
                                                {DateTime.fromISO(alert.inserted_at).toFormat('LLL dd - hh:mm a')}
                                             </FlexboxGrid.Item>
                                          </FlexboxGrid>  
                                       </SeverityAlertRow>
                                    : <></>
                                 } 
                           </FlexboxGrid>
                        </List.Item>
                     ))}
                  </List>
               </Panel>
            </AlertPanel>
            : null        
         }           
      </AlertsSC>
   );
};

const AlertsSC = styled.div`
   width: 94vw;
   padding-bottom: 0.35%;

   .rs-panel-bordered {
      border: none;
      border-radius: 0;
   }

   .rs-panel-collapsible>.rs-panel-header>.rs-icon {
      font-size: 1.5em;
   }

   .rs-panel-header {
      background-color: rgb(243, 248, 252);
   }

   .rs-panel-body, .rs-panel-header {
      padding: 10px;
  }
`

const ItemMainText = styled.div`
    font-size: 0.95em;
    font-weight: 500;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: bold;
    }
`;

const ItemSubText = styled.div`
    font-size: 0.8em;
    color: #97969B;
    font-weight: normal;

    @media screen and (max-width: 769px) {
        font-size: 0.8em;
        font-weight: normal;
    }
`;

const AlertPanel = styled.div`
   background-color: rgb(243, 248, 252);
   border: 1px solid #e5e5ea;
   border-radius: 5px;

   .rs-checkbox-group-inline {
      width: 100%;
      justify-content: center;
      margin-left: 0;
   }

   .rs-checkbox-inline {
      margin-left: 0;
   }
`;